import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../../components/formikControl/FormikControl";
import MandatoryCourseSubmitBtn from "../../../components/MandatoryCourseSubmitBtn";
import axios from "axios";
import Loader from "../../../components/Loader";
import extractErrorFromRes from "../../../helpers/extractErrorFromRes";
import withAlert from "../../../hoc/withAlert";
import { ReactComponent as UserIcon } from "../../../asset/icons/UserSymbol.svg";
import toast from "react-hot-toast";
import { ReactComponent as Designationicon } from "../../../asset/icons/DesignationIcon.svg";
import { ReactComponent as DepartmentIcon } from "../../../asset/icons/DepartmentIcon.svg";
import { ReactComponent as TeamIcon } from "../../../asset/icons/TeamsIcon.svg";
import { ReactComponent as LocationIcon } from "../../../asset/icons/LocationIcon.svg";
import endpoints from "../../../services/endpoints";
import { useAxiosGetMultiple } from "../../../services/axiosHooks";
import SearchableSelectableList from "./SearchableSelectableList";

function MandatoryCourseForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    data,
    showAlert,
    isEditForm,
}) {
    const initialFormValues = updateValues || {
        COURSE_ID: "",
        PARTICIPANT_ID: [],
    };

    const dropDownContainerStyles = {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
    };

    const aignUsersTextStyles = {
        color: "#344054",
        fontSize: "14px",
        paddingTop: "5px",
        paddingBottom: "5px",
        fontWeight: "600",
    };

    const { response, loading, reFetch } = useAxiosGetMultiple({
        location: endpoints.mandatoryCourse.location,
        teams: endpoints.mandatoryCourse.teams,
        designation: endpoints.mandatoryCourse.designations,
        department: endpoints.mandatoryCourse.departments,
        mandatoryCourses: endpoints.mandatoryCourse.mandatoryCourses,
    });

    const [participants, setParticipants] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [selectedDesignation, setSelectedDesignation] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isFilterInvoked, setIsFilterInvoked] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState(
        updateValues?.courseID || null
    );

    useEffect(() => {
        if (selectedCourseId && !updateValues) {
            const selectedCourseData = data.find(
                (course) => course.courseID === Number(selectedCourseId)
            );
            setSelectedCourse(
                data?.filter(
                    (course) => course?.courseID === Number(selectedCourseId)
                )
            );
            if (selectedCourseData) {
                setFilteredParticipants(selectedCourseData.PARTICIPANT_ID);
            } else {
                setFilteredParticipants([]);
            }
        }
    }, [selectedCourseId]);

    useEffect(() => {
        axios
            .post(
                endpoints.mandatoryCourse.participants +
                    `?page=${page}&limit=10`,
                {
                    deptId: selectedDepartment || null,
                    locationId: selectedLocation || null,
                    designationId: selectedDesignation || null,
                    teamId: selectedTeam || null,
                    course_id: selectedCourseId,
                    is_edit: isEditForm,
                }
            )
            .then((res) => {
                if (isFilterInvoked) {
                    setParticipants(res.data.responseData);
                } else {
                    setParticipants((prevParticipants) => [
                        ...prevParticipants,
                        ...res.data.responseData,
                    ]);
                }
                setTotalPages(res?.data?.pagination?.totalPages);
            })
            .catch((error) => {
                setParticipants([]);
            });
    }, [
        selectedLocation,
        selectedTeam,
        selectedDesignation,
        selectedDepartment,
        page,
        selectedCourseId,
    ]);

    let preSelectedOptions = {};
    if (updateValues) {
        preSelectedOptions = {
            participants: participants.filter((particip) =>
                updateValues.PARTICIPANT_ID.find(
                    (id) => Number(id) === Number(particip.PARTICIPANT_ID)
                )
            ),
        };
    }

    const { location, teams, designation, department, mandatoryCourses } =
        response || {};

    const submitHandler = (values, { setSubmitting, setFieldValue }) => {
        setSubmitting(true);
        axios
            .post(endpoint, { ...values, PARTICIPANT_ID: selectedUsers })
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleFilterChange = (selectedOption, setFilterState, idKey) => {
        const selectedId = selectedOption ? selectedOption[idKey] : "";
        setFilterState(selectedId);
        setIsFilterInvoked(!isFilterInvoked);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                COURSE_ID: Yup.string().trim().required("Course is required"),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form className='mandatory-course-form'>
                    <div>
                        <UserIcon />
                    </div>
                    <div className='header-title py-2'>
                        Assign Users to Mandatory Course
                    </div>
                    <span className='content'>
                        You've created a Mandatory Course! Assign users to this
                        Course.
                    </span>

                    <FormikControl
                        control='select'
                        required
                        name='COURSE_ID'
                        label='Course'
                        disabled={updateValues}
                        onChange={(e) => {
                            const courseId = e.target.value;
                            setSelectedCourseId(courseId);
                            setFieldValue("COURSE_ID", courseId);
                        }}
                    >
                        <option value={""}>Choose Course</option>
                        {mandatoryCourses.map((item, index) => (
                            <option key={index} value={item.Course_ID}>
                                {item.Course_NAME}
                            </option>
                        ))}
                    </FormikControl>

                    <span className='content'>Course to be Assigned</span>
                    <div style={aignUsersTextStyles}>Assign Users</div>
                    <div style={dropDownContainerStyles}>
                        {!updateValues && (
                            <>
                                <FormikControl
                                    control='ltd-select-advanced'
                                    name='Location'
                                    label='Location'
                                    options={location}
                                    onChange={(selectedOption) =>
                                        handleFilterChange(
                                            selectedOption,
                                            setSelectedLocation,
                                            "Location_ID"
                                        )
                                    }
                                    optionValue='Location_ID'
                                    optionLabel='Location_NAME'
                                    className='mandatory-course-dropdown-container'
                                    icon={<LocationIcon />}
                                />
                                <FormikControl
                                    control='ltd-select-advanced'
                                    name='Team'
                                    label='Team'
                                    options={teams}
                                    onChange={(selectedOption) =>
                                        handleFilterChange(
                                            selectedOption,
                                            setSelectedTeam,
                                            "TEAM_ID"
                                        )
                                    }
                                    optionValue='TEAM_ID'
                                    optionLabel='TEAM_NAME'
                                    className='mandatory-course-dropdown-container'
                                    icon={<TeamIcon />}
                                />
                                <FormikControl
                                    control='ltd-select-advanced'
                                    name='Designation'
                                    label='Designation'
                                    options={designation}
                                    onChange={(selectedOption) => {
                                        handleFilterChange(
                                            selectedOption,
                                            setSelectedDesignation,
                                            "DESIGNATION_ID"
                                        );
                                    }}
                                    optionValue='DESIGNATION_ID'
                                    optionLabel='DESIGNATION_NAME'
                                    className='mandatory-course-dropdown-container'
                                    icon={<Designationicon />}
                                />
                                <FormikControl
                                    control='ltd-select-advanced'
                                    name='Department'
                                    label='Department'
                                    options={department}
                                    onChange={(selectedOption) => {
                                        handleFilterChange(
                                            selectedOption,
                                            setSelectedDepartment,
                                            "DEPT_ID"
                                        );
                                        setFieldValue(
                                            "Department",
                                            selectedOption.DEPT_ID
                                        );
                                    }}
                                    optionValue='DEPT_ID'
                                    optionLabel='DEPT_NAME'
                                    className='mandatory-course-dropdown-container'
                                    title={values.Department}
                                    icon={<DepartmentIcon />}
                                />
                            </>
                        )}
                    </div>

                    <SearchableSelectableList
                        name='Users'
                        valueProp='PARTICIPANT_ID'
                        labelProp='PARTICIPANT_NAME'
                        placeholder='Search for Users or Select Users'
                        data={selectedCourseId ? participants : undefined}
                        onSelectionChange={setSelectedUsers}
                        isFilterInvoked={isFilterInvoked}
                        defaultValue={
                            filteredParticipants.length > 0
                                ? filteredParticipants
                                : updateValues?.PARTICIPANT_ID || []
                        }
                        selectedCouseId={selectedCourseId}
                        filteredParticipants={filteredParticipants}
                        setPage={setPage}
                        page={page}
                        totalPages={totalPages}
                        selectedCourse={selectedCourse}
                        updateValues={updateValues}
                    />

                    <MandatoryCourseSubmitBtn
                        submitBtnName='Assign to Mandatory Course'
                        onCancel={onCancel}
                    />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(MandatoryCourseForm);
