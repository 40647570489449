import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import InsAvatar from '../../../asset/icons/InsAvatar.svg';
import LocationForm from './LocationForm';
import viewProps from '../viewProps';

function Location() {
    const fetchUtils = useAxiosPost(endpoints.location.selectBy, {
        selectcolumns: '*',
        wherecondition: '1=1',
    });

    return (
        <>
            <CRUDTable
                endpoints={endpoints.location}
                Form={LocationForm}
                {...viewProps.Location}
                // irremovable
                {...fetchUtils}
            />
        </>
    );
}

export default Location;
