import React from 'react';
import { Form as BSForm } from 'react-bootstrap';
import Input from './Input';
import Textarea from './Textarea';
import BuiltInSelect from './BuiltInSelect';
import AdvancedSelect from './AdvancedSelect';
import Check from './Check';
import Tags from './Tags';
import SelectWithAdd from './SelectWithAdd';
import AdminProps from '../../views/admin/viewProps';
import FacultyProps from '../../views/faculty/viewProps';
import Time from './Time';
import AsyncAdvancedSelect from './AsyncAdvancedSelect';
import CreatableSelect from './CreatableSelect';
import ListFormInput from './ListFormInput';
import PhoneNumberInput from './PhoneInput';
import LtdAdvancedSelect from './LtdAdvancedSelect';
// import SearchableSelectableList from './SearchableSelectableList';

const labelProvider = {};

Object.values({ ...FacultyProps, ...AdminProps }).forEach((view) => {
    view.relevants.forEach((fieldName, index) => {
        labelProvider[fieldName] = view.columnHeads[index];
    });
});

function FormikControl({ control, children, ...rest }) {
    switch (control) {
        case 'input':
            return (
                <Input
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );
        case 'phone-input':
            return (
                <PhoneNumberInput
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );

        case 'textarea':
            return (
                <Textarea
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );
        case 'select':
            return (
                <BuiltInSelect
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                >
                    {children}
                </BuiltInSelect>
            );
        case 'select-advanced':
            return (
                <AdvancedSelect
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                />
            );
        case 'creatable-select':
            return (
                <CreatableSelect
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                />
            );
        case 'async-select':
            return (
                <AsyncAdvancedSelect
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );
        case 'select-add':
            return (
                <SelectWithAdd
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );
        case 'check':
            return (
                <Check
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );
        case 'tags':
            return (
                <Tags
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );
        case 'time':
            return (
                <Time
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );
        case 'list-form-input':
            return (
                <ListFormInput
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                />
            );
        case 'ltd-select-advanced':
            return (
                <LtdAdvancedSelect
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                />
            );
        // case 'checkBoxList':
        //     return (
        //         <SearchableSelectableList
        //             {...rest}
        //             ErrorFeedback={ErrorFeedback}
        //             validityClass={validityClass}
        //         />
        //     );

        default:
            return (
                <Input
                    {...rest}
                    ErrorFeedback={ErrorFeedback}
                    validityClass={validityClass}
                    labelProvider={labelProvider}
                />
            );
    }
}

const validityClass = (error, value, touched) => {
    if (!error && value === 0) {
        return 'text-success';
    }
    if (!touched && !value) {
        return 'text-danger';
    }
    if (!!error || !value) {
        return 'text-danger';
    } else {
        return 'text-success';
    }
};
const ErrorFeedback = ({ children }) => (
    <BSForm.Control.Feedback
        type='invalid'
        style={{ top: 'auto', left: 'auto', fontSize: '.75em' }}
        className='bg-white text-danger d-block py-0 mt--0'
        tooltip
    >
        {children}
    </BSForm.Control.Feedback>
);

export default FormikControl;
