import React, { useState, useEffect, useRef } from "react";
import { Form as BSForm, ListGroup, InputGroup, Button } from "react-bootstrap";
import { Field, ErrorMessage, getIn } from "formik";
import WithModal from "../../../components/WithModal";
import { ReactComponent as SearchIcon } from "../../../asset/icons/userSearch.svg";
import { ReactComponent as UserIcon } from "../../../asset/icons/UserSymbol.svg";
import { set } from "lodash";

function SearchableSelectableList({
    name,
    data = [],
    label,
    onChange,
    isMulti = true,
    className = "mb-3",
    disabled = false,
    required = false,
    ErrorFeedback,
    validityClass,
    labelProvider,
    labelProp,
    valueProp,
    onSelectionChange,
    defaultValue,
    isFilterInvoked,
    setPage,
    page,
    totalPages,
    selectedCouseId,
    updateValues,
    ...props
}) {
    const defaultIds = defaultValue?.map((item) => Number(item));
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState(data);
    const [selectedItems, setSelectedItems] = useState(defaultIds || []);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [modalSearchTerm, setModalSearchTerm] = useState("");
    const [modalFilteredData, setModalFilteredData] = useState(data);
    const [tempSelectedItems, setTempSelectedItems] = useState([]);
    const [tempSelection, setTempSelection] = useState(defaultIds || []);
    const [prevScrollTop, setPrevScrollTop] = useState(0);
    const scrollableRef = useRef(null);

    useEffect(() => {
        if(!updateValues){
        if(props?.selectedCourse.length>0){
        const defaultIds = props?.selectedCourse[0]?.PARTICIPANT_ID?.map(
            (item) => Number(item)
        );
        setSelectedItems(defaultIds);
        setTempSelection(defaultIds);
    }
    // else{
    //     setSelectedItems([]);
    //     setTempSelection([]);
    // }
}
    }, [props?.selectedCourse]);


    const handleScroll = () => {
        if (scrollableRef.current) {
            const scrollTop = scrollableRef.current.scrollTop;
            const scrollHeight = scrollableRef.current.scrollHeight;
            const clientHeight = scrollableRef.current.clientHeight;

            if (
                scrollTop + clientHeight >= scrollHeight - 10 &&
                page < totalPages
            ) {
                setPage(page + 1);
            } else if (scrollTop < prevScrollTop && scrollTop === 0) {
                setPage(1);
            }

            setPrevScrollTop(scrollTop);
        }
    };


    useEffect(() => {
        const removeDuplicates = (array) => {
            const seen = new Set();
            return array.filter((item) => {
                const duplicate = seen.has(item[valueProp]);
                seen.add(item[valueProp]);
                return !duplicate;
            });
        };
        setFilteredData(removeDuplicates(data));
        setModalFilteredData(removeDuplicates(data));
    }, [data]);

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedItems);
        }
    }, [selectedItems]);

    const handleSearchTermChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        setFilteredData(
            data?.filter((item) =>
                item[labelProp].toLowerCase().includes(value)
            )
        );
    };

    const handleModalSearchTermChange = (e) => {
        const value = e.target.value.toLowerCase();
        setModalSearchTerm(value);
        setModalFilteredData(
            data?.filter((item) =>
                item[labelProp].toLowerCase().includes(value)
            )
        );
        setTempSelectedItems([]);
    };

    const handleToggle = (item) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(item[valueProp])
                ? prevSelectedItems.filter((i) => i !== item[valueProp])
                : [...prevSelectedItems, item[valueProp]]
        );
        setTempSelection((prevSelectedItems) =>
            prevSelectedItems.includes(item[valueProp])
                ? prevSelectedItems.filter((i) => i !== item[valueProp])
                : [...prevSelectedItems, item[valueProp]]
        );
    };

    const handleModalToggle = (item) => {
        setTempSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(item[valueProp])
                ? prevSelectedItems.filter((i) => i !== item[valueProp])
                : [...prevSelectedItems, item[valueProp]]
        );
    };

    const handleRemove = (item, setFieldValue) => {
        setTempSelection((prevSelectedItems) => {
            const newSelectedItems = prevSelectedItems.filter(
                (i) => i !== item[valueProp]
            );
            setFieldValue(name, newSelectedItems);
            return newSelectedItems;
        });
        setTempSelectedItems((prevTempSelectedItems) =>
            prevTempSelectedItems.filter((i) => i !== item[valueProp])
        );
    };

    const handleSelectAllToggle = (setFieldValue) => {
        setSelectAllChecked((prev) => !prev);
        if (!selectAllChecked) {
            const allIds = filteredData.map((item) => item[valueProp]);
            setFieldValue(name, allIds);
            setSelectedItems(allIds);
        } else {
            setFieldValue(name, []);
            setSelectedItems([]);
        }
    };

    const handleDone = (setFieldValue, closeModal) => {
        setSelectedItems([...tempSelectedItems, ...tempSelection]);
        setFieldValue(name, tempSelectedItems);
        closeModal();
    };

    const handleCancel = (closeModal) => {
        setTempSelectedItems([]);
        closeModal();
    };

    return (
        <BSForm.Group className={className} controlId={name}>
            <Field name={name}>
                {({ field, form }) => (
                    <>
                        {label && (
                            <BSForm.Label>
                                {label ||
                                    labelProvider[name] ||
                                    name ||
                                    "Provide Label"}
                                {required && (
                                    <span
                                        className={`small ${validityClass(
                                            getIn(form.errors, name),
                                            getIn(form.values, name),
                                            getIn(form.touched, name)
                                        )}`}
                                    >
                                        *
                                    </span>
                                )}
                            </BSForm.Label>
                        )}
                        <InputGroup className='mb-3'>
                            <div
                                style={{
                                    width: "100%",
                                    background: "#fff",
                                    border: "1px solid #bdbdbd",
                                    borderRadius: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "5px",
                                }}
                            >
                                <SearchIcon />
                                <BSForm.Control
                                    style={{
                                        border: "none",
                                        background: "#fff",
                                    }}
                                    type='text'
                                    value={searchTerm}
                                    onChange={handleSearchTermChange}
                                    {...props}
                                />
                            </div>
                        </InputGroup>
                        {selectedItems.length > 0 &&
                            filteredData.length > 0 && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "end",
                                    }}
                                >
                                    <WithModal
                                        modalTitle={<UserIcon />}
                                        modalAttrs={{
                                            dialogClassName: "modal-below-md",
                                        }}
                                        disableHeader
                                        renderModalBody={(closeModal) => (
                                            <>
                                                <div
                                                    style={{
                                                        marginBottom: "20px",
                                                    }}
                                                >
                                                    <UserIcon />
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#101828",
                                                        fontWeight: 600,
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    Selected Users
                                                </div>
                                                <div
                                                    style={{
                                                        color: "#667085",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    The following users have
                                                    been assigned to this
                                                    Course:
                                                </div>
                                                <ListGroup className='mt-3'>
                                                    {tempSelection.length ===
                                                    0 ? (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            No selected Users
                                                        </div>
                                                    ) : (
                                                        tempSelection.map(
                                                            (itemId, index) => {
                                                                const item =
                                                                    data.find(
                                                                        (i) =>
                                                                            i[
                                                                                valueProp
                                                                            ] ===
                                                                            itemId
                                                                    );
                                                                return (
                                                                    <ListGroup.Item
                                                                        key={
                                                                            index
                                                                        }
                                                                        className='d-flex justify-content-between align-items-center'
                                                                    >
                                                                        {item &&
                                                                            item[
                                                                                labelProp
                                                                            ]}
                                                                        <span
                                                                            style={{
                                                                                color: "red",
                                                                                cursor: "pointer",
                                                                                marginLeft:
                                                                                    "10px",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleRemove(
                                                                                    item,
                                                                                    form.setFieldValue
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove
                                                                        </span>
                                                                    </ListGroup.Item>
                                                                );
                                                            }
                                                        )
                                                    )}
                                                </ListGroup>
                                                <InputGroup className='mb-3 mt-3'>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            background: "#fff",
                                                            border: "1px solid #bdbdbd",
                                                            borderRadius: "8px",
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            paddingLeft: "5px",
                                                        }}
                                                    >
                                                        <SearchIcon />
                                                        <BSForm.Control
                                                            style={{
                                                                border: "none",
                                                                background:
                                                                    "#fff",
                                                            }}
                                                            type='text'
                                                            value={
                                                                modalSearchTerm
                                                            }
                                                            onChange={
                                                                handleModalSearchTermChange
                                                            }
                                                            placeholder='Search users'
                                                        />
                                                    </div>
                                                </InputGroup>
                                                <ListGroup>
                                                    <div
                                                        style={{
                                                            maxHeight: "180px",
                                                            overflow: "auto",
                                                        }}
                                                    >
                                                        {modalFilteredData.length ===
                                                        0 ? (
                                                            <div className='text-center'>
                                                                No users found
                                                            </div>
                                                        ) : (
                                                            modalFilteredData
                                                                .filter(
                                                                    (item) =>
                                                                        !selectedItems.includes(
                                                                            item[
                                                                                valueProp
                                                                            ]
                                                                        )
                                                                )
                                                                .map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <ListGroup.Item
                                                                            key={
                                                                                index
                                                                            }
                                                                            onClick={() =>
                                                                                handleModalToggle(
                                                                                    item
                                                                                )
                                                                            }
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                            // active={tempSelectedItems.includes(
                                                                            //     item[
                                                                            //         valueProp
                                                                            //     ]
                                                                            // )}
                                                                        >
                                                                            <BSForm.Check
                                                                                type='checkbox'
                                                                                label={
                                                                                    item &&
                                                                                    item[
                                                                                        labelProp
                                                                                    ]
                                                                                }
                                                                                checked={tempSelectedItems.includes(
                                                                                    item[
                                                                                        valueProp
                                                                                    ]
                                                                                )}
                                                                                // onChange={() =>
                                                                                //     handleModalToggle(
                                                                                //         item
                                                                                //     )
                                                                                // }
                                                                            />
                                                                        </ListGroup.Item>
                                                                    )
                                                                )
                                                        )}
                                                    </div>
                                                </ListGroup>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        marginTop: "20px",
                                                    }}
                                                >
                                                    <Button
                                                        // variant='outline-info'
                                                        variant='outline'
                                                        onClick={() =>
                                                            handleCancel(
                                                                closeModal
                                                            )
                                                        }
                                                        style={{
                                                            width: "48%",
                                                            border: "1px solid #D0D5DD",
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant='primary'
                                                        onClick={() =>
                                                            handleDone(
                                                                form.setFieldValue,
                                                                closeModal
                                                            )
                                                        }
                                                        style={{
                                                            width: "48%",
                                                            background:
                                                                "#006AF5",
                                                            border: "none",
                                                        }}
                                                    >
                                                        Done
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    >
                                        <div
                                            style={{
                                                color: "#006AF5",
                                                fontSize: "12px",
                                                fontWeight: 500,
                                                cursor: "pointer",
                                            }}
                                        >
                                            Selected Users
                                        </div>
                                    </WithModal>
                                </div>
                            )}
                        {filteredData.length > 0 && (
                            <div
                                style={{ maxHeight: "200px", overflow: "auto" }}
                                ref={scrollableRef}
                                onScroll={handleScroll}
                            >
                                <ListGroup>
                                    {filteredData.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => handleToggle(item)}
                                        >
                                            <ListGroup.Item
                                                style={{
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                                // active={selectedItems.includes(
                                                //     item[valueProp]
                                                // )}
                                            >
                                                <BSForm.Check
                                                    type='checkbox'
                                                    label={
                                                        item && item[labelProp]
                                                    }
                                                    checked={selectedItems.includes(
                                                        item[valueProp]
                                                    )}
                                                    // onChange={() =>
                                                    //     // handleToggle(item)
                                                    //     console.log('itemitem',item)
                                                    // }
                                                />
                                            </ListGroup.Item>
                                        </div>
                                    ))}
                                </ListGroup>
                            </div>
                        )}
                        {filteredData.length === 0 && (
                            <div className='text-center'>No Data</div>
                        )}
                        <ErrorMessage
                            name={name}
                            component={ErrorFeedback || "div"}
                        />
                    </>
                )}
            </Field>
        </BSForm.Group>
    );
}

export default SearchableSelectableList;
