import React from 'react';
import { ReactComponent as MediaMultiUpload } from '../../../../asset/icons/MediaUploadMulti.svg';

function MediaUploadSelector({ onChange, accept, label, id, inputAttrs }) {
    return (
        <label
            htmlFor={id}
            className='border rounded d-flex flex-column fs-6 align-items-center py-3 mt-3'
            role='button'
        >
            <MediaMultiUpload />
            <div
                style={{
                    color: '#107BFF',
                    fontWeight: '600',
                    textTransform: 'none',
                }}
            >
                {label}
            </div>
            <input
                id={id}
                type='file'
                accept={accept}
                onChange={onChange}
                style={{ display: 'none' }}
                {...inputAttrs}
            />
        </label>
    );
}

export default MediaUploadSelector;
