import axios from 'axios';
import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import Loader from './Loader';
import ModalWrapper from './WithModal';
import { ReactComponent as Upload } from '../asset/icons/Upload.svg';
import withAlert from '../hoc/withAlert';
import extractErrorFromRes from '../helpers/extractErrorFromRes';
import toast from 'react-hot-toast';
import InitialBody from './BulkUploadModals/UploadModal';
import NewItemConfirmModal from './BulkUploadModals/NewItemConfirmModal';
import SuccessBody from './BulkUploadModals/UploadSuccessModal';

function BulkUpload({
    uploadUrl,
    confirmNewItemsUrl,
    templateUrl,
    filename,
    templateExtension = '.xlsx',
    afterUpload,
    errorObjects,
    onAfterAction = () => {},
}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileError, setFileError] = useState(null);
    const [modalState, setmodalState] = useState('initiated');
    const [newFields, setNewFields] = useState('');
    const [successResponse, setSuccessResponse] = useState();

    const downloadTemplate = () => {
        setLoading(true);
        axios({
            url: templateUrl,
            method: 'GET',
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename + templateExtension);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                toast.error(err.response.data.statusText);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const upload = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('csv_file', selectedFile);
        if (
            selectedFile.type ===
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            axios
                .post(uploadUrl, formData)
                .then((res) => {
                    setLoading(false);
                    setSuccessResponse(res.data);
                    setmodalState('success');
                    toast.success(res.data.statusText);
                    afterUpload();
                })
                .catch((error) => {

                    setLoading(false);

                    // Set Flag==1 to check whether there is dependency in fields

                    if (error.response.data.Flag == 1) {
                        setNewFields(error.response.data.responseData);
                        setSuccessResponse(error.response.data);
                        setmodalState('dependent');
                        afterUpload();
                    }
                    //Condition to check if all the data row is incorrect
                    else if (
                        error.response.data.allErrorResponseData?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    } else if (
                        error.response.data.invalidHr?.length > 0 ||
                        error.response.data.invalidManager?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    } else if (error.response.data.invalidEmail?.length > 0) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    } else if (error.response.data.repeatEmail?.length > 0) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    } else if (
                        error.response.data.removedMediaRows?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    } else if (
                        error.response.data.excludedCourseRow?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    } else if (
                        error.response.data.invalidLiveClass?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    }else if (
                        error.response.data.invalidMedia?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    }else if (
                        error.response.data.duplicateChoice?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    }else if (
                        error.response.data.invalidRows?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    }else if (
                        error.response.data.invalidStructureRow?.length > 0
                    ) {
                        toast.error(error.response.data.statusText);
                        setSuccessResponse(error.response.data);
                        setmodalState('success');
                        afterUpload();
                    } else {
                        toast.error(error.response.data.statusText);
                    }
                });
        } else {
            setFileError(true);
            setLoading(false);
            return;
        }
    };

    const modalTitle =
        modalState === 'initiated'
            ? 'Upload Details via xlsx'
            : modalState === 'success'
            ? 'Bulk Upload'
            : '';

    return (
        <>


            <ModalWrapper
                disableHeader={modalState !== 'initiated' && true}
                modalTitle={modalTitle}
                setFileError={setFileError}
                modalAttrs={{
                    size: modalState === 'initiated' ? 'sm' : 'xs',
                    className: 'p-0',
                    onExiting: () => {
                        console.log('first');
                        setSelectedFile(null);
                        setFileError(false);
                    },
                }}
                renderModalBody={(closeModal) =>
                    modalState === 'initiated' ? (
                        <InitialBody
                            closeModal={() => {
                                closeModal();
                            }}
                            setFileError={setFileError}
                            setSelectedFile={setSelectedFile}
                            upload={upload}
                            downloadTemplate={downloadTemplate}
                            fileError={fileError}
                            selectedFile={selectedFile}
                        />
                    ) : modalState === 'success' ? (
                        <SuccessBody
                            header={modalTitle}
                            closeModal={() => {
                                closeModal();
                                setmodalState('initiated');
                            }}
                            successResponse={successResponse}
                            setSelectedFile={setSelectedFile}
                            selectedFile={selectedFile}
                            errorObjects={errorObjects}
                        />
                    ) : (
                        <NewItemConfirmModal
                            closeModal={() => {
                                setSelectedFile(null);
                                closeModal();
                                setmodalState('initiated');
                            }}
                            setLoading={setLoading}
                            setSelectedFile={setSelectedFile}
                            setmodalState={setmodalState}
                            newField={newFields}
                            confirmURL={confirmNewItemsUrl}
                        />
                    )
                }
            >
                <Button className='primaryBtn btnAnime ms-4 px-3 py-1'>
                    <Upload />
                </Button>
            </ModalWrapper>
            {loading && <Loader />}
        </>
    );
};

export default BulkUpload;