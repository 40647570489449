import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import { useAuthenticationState } from '../../../context/Auth.context';
import StructureForm from '../../faculty/courseStructure/StructureForm';

function Approved({ fetchUtils, response }) {
    const { user } = useAuthenticationState();

    const cellModifier = {
        Course_Structure_Content_Type: ({ value }) => {
            switch (value) {
                case 1:
                    return 'Live Class';
                case 2:
                    return 'Video';
                case 3:
                    return 'Quiz';
                case 4:
                    return 'Document';
                default:
                    break;
            }
        },
    };

    return (
        <CRUDTable
            endpoints={endpoints.courseStructure}
            relevants={[
                ...viewProps.CourseStructureApproval.relevants,
                // 'Rereview',
            ]}
            columnHeads={[
                ...viewProps.CourseStructureApproval.columnHeads,
                // 'Rereview',
            ]}
            {...fetchUtils}
            name='Approved Structure'
            response={response}
            cellModifier={cellModifier}
            irremovable
            immutable
            insertable={user.type === 'BOTH'}
            Form={StructureForm}
            addBtnLabel='Add Structure'
        />
    );
}

export default Approved;
