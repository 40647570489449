import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function DepartmentForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const submitHandler = (values, { setSubmitting: setLoading }) => {
        setLoading(true);

        console.log(values);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setLoading(false));
    };
    const initialFormValues = updateValues || {
        DEPT_NAME: '',
        DEPTTAG: '',
    };
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                DEPT_NAME: Yup.string()
                    .trim()
                    .required('Department Name is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces are allowed'
                    )
                    .notOneOf([''], 'Department Name cannot be empty')
                    .required(),
                DEPTTAG: Yup.string()
                    .trim()
                    .required('Department Tag is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces are allowed'
                    )
                    .notOneOf([''], 'Department Tag cannot be empty')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormikControl control='input' required name='DEPT_NAME' />
                    <FormikControl control='input' required name='DEPTTAG' />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(DepartmentForm);
