import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import { Col, Collapse, FormLabel, Row } from 'react-bootstrap';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import CourseAvatar from '../../../asset/icons/InsAvatar.svg';
import withAlert from '../../../hoc/withAlert';
import {
    getMediaUploadLink,
    uploadMedia,
} from '../../faculty/mediaLibrary/mediaServices';
import toast from 'react-hot-toast';

function SuperAdminCourseForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response: courseCategories, loading } = useAxiosGet(
        endpoints.courseCategory.get
    );

    const [file, setFile] = useState(null);

    const [fileError, setFileError] = useState(null);
    const [progress, setProgress] = useState(0);

    const initialFormValues = updateValues || {
        Course_NAME: '',
        Course_Category_ID: '',
        Course_Credits: '',
        Course_Duration_Days: '',
        Course_Duration_hours: '',
        Suggested_Course_Frequency: '',
        Last_Course_Schedule_Date: '',
        Course_Description: '',
        Version_ID: '',
    };

    const submitHandler = async (values, { setSubmitting }) => {
        if (!updateValues) {
            values.Course_STATUS = 1;
        }
        if (file) {
            if (!file.type.includes('image')) {
                setFileError(true);
                setSubmitting(false);
                return;
            }

            const uploadLink = await getMediaUploadLink(file.name);

            await uploadMedia(uploadLink.signedUrl, file, setProgress);

            values.DP_URL = uploadLink.publicUrl;
            values.COURSE_DP_URL = uploadLink.publicUrl;
        } else {
            values.DP_URL = values.COURSE_DP_URL;
        }
        setSubmitting(true);
        axios
            .post(endpoint, values)
            .then((res) => {
                axios
                    .post(endpoints.course.contentUpdate, {
                        ...values,
                        Course_ID: updateValues
                            ? updateValues.Course_ID
                            : res.data.responseData.insertId,
                    })
                    .then(() => {
                        console.log('first');
                        setSubmitting(false);
                        onAfterSubmit();
                        toast.success('success');
                    })
                    .catch((err) => {
                        setSubmitting(false);
                        toast.error(extractErrorFromRes(err));
                    });
            })
            .catch((err) => {
                setSubmitting(false);
                toast.error(extractErrorFromRes(err));
            });
    };
    if (loading) {
        return <Loader />;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const pic = updateValues?.COURSE_DP_URL
        ? encodeURI(updateValues?.COURSE_DP_URL)
        : CourseAvatar;

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Course_NAME: Yup.string().required(),
                Course_Category_ID: Yup.string().required(),
                Course_Content_CONTENT: Yup.string().nullable(),
                Special_Notes: Yup.string().nullable(),
                special_exception: Yup.string().nullable(),
                Course_Description: Yup.string().required(),
                Course_Credits: Yup.number().min(0).required(),
                Course_Duration_Days: Yup.number().min(0).required(),
                Course_Duration_hours: Yup.number().min(0).required(),
                Suggested_Course_Frequency: Yup.number().min(0).required(),
                Last_Course_Schedule_Date: Yup.date()
                    .min(today, 'Please enter upcoming date')
                    .required(),
                Version_ID: Yup.number().min(0).required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    {console.log(isSubmitting)}
                    <Row>
                        <Col sm='6'>
                            <FormikControl name='Course_NAME' required />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select-advanced'
                                name='Course_Category_ID'
                                required
                                label='Course Category'
                                options={courseCategories}
                                defaultValue={courseCategories.find(
                                    (cat) =>
                                        Number(cat.Course_Category_ID) ===
                                        Number(
                                            initialFormValues.Course_Category_ID
                                        )
                                )}
                                optionValue='Course_Category_ID'
                                optionLabel='Course_Category_NAME'
                            />
                        </Col>
                        <Col sm='12'>
                            <FormikControl
                                control='textarea'
                                height='200px'
                                required
                                name='Course_Content_CONTENT'
                                label='Contents in Course'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                name='Special_Notes'
                                control='textarea'
                                label='Special Notes'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                name='special_exception'
                                control='textarea'
                                label='Special Exception'
                            />
                        </Col>
                        <Col sm='12'>
                            <FormikControl
                                control='textarea'
                                height='200px'
                                name='Course_Description'
                                label='Course Description'
                                required
                            />
                        </Col>
                        <Col sm='6'>
                            {updateValues && !file && (
                                <>
                                    <FormLabel className='small'>
                                        Course Image
                                    </FormLabel>

                                    <FormLabel
                                        as={FormLabel}
                                        htmlFor='courseImage'
                                        src={updateValues.COURSE_DP_URL}
                                        style={{
                                            backgroundImage: 'url(' + pic + ')',
                                            width: '100%',
                                            height: '150px',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                        }}
                                    />
                                </>
                            )}
                            <div
                                style={{
                                    visibility:
                                        updateValues && !file
                                            ? 'hidden'
                                            : 'visible',
                                    marginBottom:
                                        updateValues && !file ? '-50px' : '0',
                                }}
                            >
                                <FormikControl
                                    control='input'
                                    type='file'
                                    name='image'
                                    id='courseImage'
                                    label='Course Image'
                                    onChange={(e) => {
                                        setFileError(false);
                                        console.log(e);
                                        setFile(e.target.files[0]);
                                    }}
                                />
                                <Collapse in={fileError}>
                                    <div className='text-danger text-center small mt--1'>
                                        Only supports image
                                    </div>
                                </Collapse>
                            </div>
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                name='Course_Credits'
                                required
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                name='Course_Duration_Days'
                                required
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                name='Course_Duration_hours'
                                required
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                name='Suggested_Course_Frequency'
                                required
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                name='Last_Course_Schedule_Date'
                                required
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                label='Version'
                                name='Version_ID'
                                required
                            />
                        </Col>
                    </Row>

                    {isSubmitting && (
                        <Loader
                            type={file ? 'progress' : ''}
                            progress={progress}
                        />
                    )}
                    <FormSubmissionBtn onCancel={onCancel} />
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(SuperAdminCourseForm);
