import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide';

const Program = ({ direction = 'column' }) => {
    const [column] = useState(direction === 'column');
    const steps = [
        {
            id: 256,
            para: 'Click on "Program" from the sidebar to see more options regarding Program.',
            imageUrl:
                images.Admin_Program_img_1,
        },
        {
            id: 257,
            para: 'Clicking on the "Program" module in the sidebar allows you to navigate to the Program page.',
            imageUrl:
                images.Admin_Program_img_2,
        },
        {
            id: 258,
            para: 'Here, we can see the list of Programs.',
            imageUrl:
                images.Admin_Program_img_3,
        },
        {
            id: 259,
            para: 'To add a new program, Click on "Add Programs".',
            imageUrl:
                images.Admin_Program_img_4,
        },
        {
            id: 260,
            para: 'Fill the form with appropriate details to add a Program.',
            imageUrl:
                images.Admin_Program_img_5,
        },
        {
            id: 261,
            para: 'Press the "Submit" button to successfully add the Program.',
            imageUrl:
                images.Admin_Program_img_6,
        },
        {
            id: 262,
            para: 'Here, you can see the newly added Program.',
            imageUrl:
                images.Admin_Program_img_7,
        },
        {
            id: 263,
            para: 'Click on the "Download Icon" to download the Program details as an Excel file.',
            imageUrl:
                images.Admin_Program_img_8,
        },
        {
            id: 264,
            para: 'Click the "Delete" button to delete the Program.',
            imageUrl:
                images.Admin_Program_img_9,
        },
        {
            id: 265,
            para: 'To modify the Program, click the "Edit" button.',
            imageUrl:
                images.Admin_Program_img_10,
        },
        {
            id: 266,
            para: 'Update the Program details to modify the Program data.',
            imageUrl:
                images.Admin_Program_img_11,
        },
        {
            id: 267,
            para: 'Press the "Submit" button to successfully update the Programs.',
            imageUrl:
                images.Admin_Program_img_12,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading text='Programs' numberOfScreens={steps.length} />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default Program;
