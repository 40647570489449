import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from './Loader';
import withAlert from '../hoc/withAlert';
import extractErrorFromRes from '../helpers/extractErrorFromRes';
import toast from 'react-hot-toast';

function Warning({
    showAlert,
    endpoint,
    onCancel,
    configBody,
    onAfterAction = () => {},
}) {
    const [loading, setLoading] = useState(false);
    const onAction = () => {
        setLoading(true);
        axios
            .post(endpoint, configBody)
            .then((res) => {
                setLoading(false);
                onAfterAction();
                toast.success(res.data.statusText);

                onClose();
            })
            .catch((err) => {
                setLoading(false);

                toast.error(err.response.data.statusText);
            });
    };
    const onClose = () => {
        onCancel();
        setLoading(false);
    };

    return (
        <>
            <div className='text-center'>
                <Button
                    variant='outline-danger'
                    className='mx-2 px-4'
                    disabled={loading}
                    onClick={onAction}
                >
                    Yes
                </Button>
                <Button
                    variant='outline-success'
                    className='mx-2 px-4'
                    disabled={loading}
                    onClick={onClose}
                >
                    No
                </Button>
            </div>

            {loading && <Loader />}
        </>
    );
}

export default withAlert(Warning);
