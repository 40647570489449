import React from 'react';
import { ReactComponent as Right } from '../../../asset/icons/Right.svg';
import { ReactComponent as Wrong } from '../../../asset/icons/Wrong.svg';
import Select from 'react-select';

function Choices({ i, option, onRemove, ...rest }) {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    const isCorrect = option.MCQRIGHTANSWER.toString() === '1';

    const selectOptions = [
        {
            label: (
                <span>
                    Correct &nbsp;&nbsp;
                    <Right />
                </span>
            ),
            value: '1',
        },
        {
            label: (
                <span>
                    Incorrect &nbsp;&nbsp;
                    <Wrong />
                </span>
            ),
            value: '0',
        },
    ];

    return (
        <div
            style={{
                border: isCorrect ? '1px solid #96FF62' : '1px solid #EAECF0',
                display: 'flex',
                padding: '10px',
                opacity: '11px',
                background: isCorrect ? '#EBFFE1' : 'rgba(255, 62, 62, 0.11)',
                marginTop: '10px',
                borderRadius: '8px',
                alignItems: 'center',
            }}
            className='choice'
        >
            <div
                style={{
                    width: '10%',
                }}
            >
                <div
                    style={{
                        borderRadius: '50%',
                        width: '32px',
                        height: '32px',
                        background: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '4px solid #F9F5FF',
                        cursor: 'pointer',
                    }}
                    onClick={() => onRemove(option, i)}
                    className='remove-choice'
                >
                    {alphabet[i]}
                </div>
            </div>
            <div
                style={{
                    width: '54%',
                    wordWrap: 'break-word',
                }}
            >
                {option.MCQ_CHOICE_TEXT}
            </div>
            <div
                style={{
                    width: '36%',
                    marginLeft: 'auto',
                    maxHeight: '36px',
                }}
            >
                <Select
                    defaultValue={
                        isCorrect ? selectOptions[0] : selectOptions[1]
                    }
                    styles={{
                        control: (provided) => {
                            return {
                                ...provided,
                                background: 'rgba(26, 56, 96, 0.10)',
                                border: 'none',
                                fontWeight: '500',
                                fontSize: '16px',
                            };
                        },
                        indicatorSeparator: (provided) => {
                            return {
                                ...provided,
                                width: '0px',
                            };
                        },

                        dropdownIndicator: (provided) => {
                            return {
                                ...provided,
                                color: '#1C2E4599',
                            };
                        },
                    }}
                    value={selectOptions.find((obj) => {
                        if (obj.value === option.MCQRIGHTANSWER.toString())
                            return true;
                        else return false;
                    })}
                    options={selectOptions}
                    {...rest}
                />
            </div>
        </div>
    );
}

export default Choices;
