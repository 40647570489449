import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function InstructorCourseMapForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    updateValues,
}) {
    const { response, loading } = useAxiosGetMultiple({
        instructors: endpoints.instructor.get,
        courses: endpoints.course.get,
    });
    const { instructors, courses } = response;

    const nonRetiredCourses = courses.filter(
        (course) => Number(course.Course_STATUS) !== 4
    );

    const submitHandler = (values, { setSubmitting }) => {
        setSubmitting(true);

        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        Course: [],
        INSTRUCTOR_ID: '',
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOption = {
        instructor: instructors.find(
            (instructor) =>
                Number(instructor.instructor_ID) ===
                Number(initialFormValues.instructor_ID)
        ),
        course: nonRetiredCourses.filter(
            (course) =>
                !!initialFormValues.Course.find(
                    (courseMap) =>
                        Number(courseMap.Course_ID) === Number(course.Course_ID)
                )
        ),
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                instructor_ID: Yup.string().required(),
                Course: Yup.array()
                    .of(
                        Yup.object({
                            Course_ID: Yup.string(),
                        })
                    )
                    .min(1, 'Select min 1 course')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='select-advanced'
                            required
                            name='instructor_ID'
                            label='INSTRUCTOR'
                            options={instructors}
                            optionValue='instructor_ID'
                            defaultValue={preSelectedOption.instructor}
                            optionLabel='Instructor_Name'
                        />
                    )}
                    <FormikControl
                        disabled={!values.instructor_ID}
                        control='select-advanced'
                        required
                        name='Course'
                        label='COURSE'
                        options={nonRetiredCourses}
                        defaultValue={preSelectedOption.course}
                        isMulti
                        keyProp='Course_ID'
                        optionValue='Course_ID'
                        optionLabel='Course_NAME'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {(isSubmitting || loading) && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(InstructorCourseMapForm);
