import React from "react";
import CRUDTable from "../../../components/table/CRUDTable";
import { useAxiosPost } from "../../../services/axiosHooks";
import endpoints from "../../../services/endpoints";
import ProgramForm from "./ProgramForm";
import viewProps from "../viewProps";
import DeleteWithWarning from "../../../components/DeleteWithWarning";

function Program() {
    const fetchUtils = useAxiosPost(endpoints.program.select2,{
        selectcolumns: '*', wherecondition: '1=1'
    });

    const cellModifier = {
        Course_Program_STATUS: ({ value }) => {
            switch (value) {
                case 1:
                    return 'In draft';
                case 2:
                    return 'Under Review';
                case 3:
                    return 'Published';
                case 4:
                    return 'Retired';
                default:
                    return value
            }
        },
        Delete: ({row})=> (
            <div className='td text-center'>
                <DeleteWithWarning
                    disabled={
                        Number(row.original.Course_Program_STATUS) === 3
                    }
                    title={'Program'}
                    configBody={{
                        ...row.original,
                    }}
                    className='mx-auto'
                    onAfterDelete={fetchUtils.reFetch}
                    endpoint={endpoints.program.delete}
                />
            </div>
        )
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.program}
                Form={ProgramForm}
                irremovable
                {...viewProps.Program}
                cellModifier={cellModifier}
                {...fetchUtils}
                formSize="md"
            />
        </>
    );
}

export default Program;
