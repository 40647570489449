import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import {useAxiosGet} from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import DesignationForm from './DesignationForm';

function Designation() {

    const fetchUtils = useAxiosGet(endpoints.designation.get);

    return (
        <>
            <CRUDTable
                endpoints={endpoints.designation}
                Form={DesignationForm}
                {...fetchUtils}
                // irremovable
                {...viewProps.Designation}
            />
        </>
    );
}

export default Designation;
