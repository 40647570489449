import React from 'react';
import { useLocation } from 'react-router-dom';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';

function CourseReviews() {
    const { state } = useLocation();

    const fetchUtils = useAxiosGet(
        endpoints.courseReviews.get + state.courseId
    );

    const cellModifier = {
        Review_DATE: ({ value }) => new Date(value).toDateString(),
    };

    return (
        <>
            <CRUDTable
                endpoints={endpoints.courseReview}
                cellModifier={cellModifier}
                {...fetchUtils}
                {...viewProps.CourseReviews}
                name={'Reviews'}
                insertable={false}
                irremovable
                immutable
            />
        </>
    );
}

export default CourseReviews;
