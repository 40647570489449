import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HatIcon } from '../../../asset/icons/userGuide/BlackHat.svg';
import { ReactComponent as LiveClassIcon } from '../../../asset/icons/userGuide/LiveClassSymbol.svg';
import { ReactComponent as ProgramIcon } from '../../../asset/icons/userGuide/square_foot.svg';
import { ReactComponent as NotesIcon } from '../../../asset/icons/userGuide/lab_profile.svg';
import { ReactComponent as KeyIcon } from '../../../asset/icons/userGuide/Vertical_Key.svg';
import './helpcss/index.css';

const commonTopics = [
    {
        path: 'instructor-course',
        name: 'Courses by instructor',
        icon: <HatIcon />,
        iconBodyColor: '#FFBE0B4A',
        text: 'Add courses to instructor',
    },
    {
        path: 'live-class-recording',
        name: 'Live class recordings',
        icon: <LiveClassIcon />,
        iconBodyColor: '#5F9DFFC7',
        text: 'Live class recording',
    },
    {
        path: 'media-lib-approval',
        name: 'Media library approval',
        icon: <NotesIcon />,
        iconBodyColor: '#667085',
        text: 'Approve media library',
    },
    {
        path: 'helpForgotPassword',
        name: 'Forgot Password',
        icon: <KeyIcon />,
        iconBodyColor: '#93FF90C7',
        text: 'Forgot Password',
    },
];

const otherTopics = [
    {
        topic: 'How to upload a group of participants using a single Excel file?',
        path: 'participants',
    },
    {
        topic: 'How do I map a course to a department?',
        path: 'dept-course-category-mapping',
    },
    {
        topic: 'First Time User Login',
        path: 'helpLogin',
    },
];

const Index = () => {
    const navigate = useNavigate();
    const handleOnclick = ({ path }) => {
        navigate(path);
    };

    const Card = ({ icon, text, iconBodyColor, path }) => (
        <div className='card' onClick={() => handleOnclick({ path: path })}>
            <div
                className='card-icon'
                style={{ backgroundColor: iconBodyColor }}
            >
                {icon}
            </div>
            <div className='card-text'>{text}</div>
        </div>
    );

    return (
        <div className='container'>
            <div className='flex center'>
                <div className='flex scrollable' style={{ width: '60%' }}>
                    <div
                        className='padding-horizontal'
                        style={{ width: '100%' }}
                    >
                        <div className='spacing-top'>
                            <span className='topic-title'>Common Topics</span>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {commonTopics.map((topics, index) => (
                                <div
                                    key={index}
                                    className='flex center'
                                    style={{ width: '30%', height: '100%' }}
                                >
                                    <Card
                                        icon={topics.icon}
                                        text={topics.text}
                                        iconBodyColor={topics.iconBodyColor}
                                        path={topics.path}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='spacing-top'>
                            <span className='topic-title'>Other Flows</span>
                        </div>
                        <div className='spacing-bottom'>
                            {otherTopics.map((topics, index) => (
                                <div
                                    key={index}
                                    className='topic-link'
                                    onClick={() =>
                                        handleOnclick({ path: topics.path })
                                    }
                                >
                                    <span>{topics.topic}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
