import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide'

const Department = ({ column, direction }) => {
    const steps = [
        {
            id: 26,
            para: 'Clicking on the "Departments" module in the sidebar allows you to navigate to the Department page.',
            imageUrl: images.Admin_Department_img_1,
        },
        {
            id: 27,
            para: 'Here, we can see the list of Departments.',
            imageUrl: images.Admin_Department_img_2,
        },
        {
            id: 28,
            para: 'To add a Department, click on "Add Department".',
            imageUrl: images.Admin_Department_img_3,
        },
        {
            id: 29,
            para: 'Fill the form with "Department name" and "Department Tag".',
            imageUrl: images.Admin_Department_img_4,
        },
        {
            id: 30,
            para: 'Press the "Submit" button to successfully add the department.',
            imageUrl: images.Admin_Department_img_5,
        },
        {
            id: 31,
            para: 'Here, you can see the newly added Department.',
            imageUrl: images.Admin_Department_img_6,
        },
        {
            id: 32,
            para: 'Click on the "Download Icon" to download the Department details as an Excel file.',
            imageUrl: images.Admin_Department_img_7,
        },
        {
            id: 33,
            para: 'To modify the Department, click the "Edit" button.',
            imageUrl: images.Admin_Department_img_8,
        },
        {
            id: 34,
            para: 'Update the Department details to modify the Department data.',
            imageUrl: images.Admin_Department_img_9,
        },
        {
            id: 35,
            para: 'Press the "Submit" button to successfully update the Department details.',
            imageUrl: images.Admin_Department_img_10,
        },
        {
            id: 36,
            para: 'Click the "Delete" button to delete the Department.',
            imageUrl: images.Admin_Department_img_11,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading text='Department' numberOfScreens={steps.length} />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default Department;
