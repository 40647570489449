import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DownArrow } from '../../../../asset/icons/userGuide/chevron-down.svg';
import '../helpcss/scrolldiv.css';

function SearchBar() {
    const [isClicked, setClick] = useState(false);
    const [navigationPath, setNavigationPath] = useState('');
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const searchBarRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                searchBarRef.current &&
                !searchBarRef.current.contains(event.target)
            ) {
                setSearchTerm('');
                setNavigationPath('');
                setClick(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchBarRef]);

    const links = [
        {
            path: 'helpLogin',
            name: 'Login',
        },
        {
            path: 'helpForgotPassword',
            name: 'Forgot Password',
        },
        {
            path: 'helpDashboard',
            name: 'Dashboard',
        },
        {
            path: 'helpNotification',
            name: 'Notification',
        },
        {
            path: 'change-password',
            name: 'Change password',
        },
        {
            path: 'department',
            name: 'Department',
        },
        {
            path: 'course-category',
            name: 'Course category',
        },
        {
            path: 'dept-course-category-mapping',
            name: 'Department-course category mapping',
        },
        {
            path: 'participants',
            name: 'Participants',
        },
        {
            path: 'instructors',
            name: 'Instructors',
        },
        {
            path: 'courses',
            name: 'Courses',
        },
        {
            path: 'instructor-course',
            name: 'Courses by instructor',
        },
        {
            path: 'media-lib-approval',
            name: 'Media library approval',
        },
        {
            path: 'course-struct-approval',
            name: 'Course structure approval',
        },
        {
            path: 'program',
            name: 'Program',
        },
        {
            path: 'live-class-recording',
            name: 'Live class recording',
        },
        {
            path: 'logout',
            name: 'Log out',
        },
    ];

    const filteredLinks = links.filter((link) =>
        link.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleRadioChange = (path) => {
        setNavigationPath(path);
    };

    const handleDropdownClick = () => {
        setClick(!isClicked);
    };

    const handleCancel = () => {
        setNavigationPath('');
        setClick(false);
        setSearchTerm('');
    };

    const handleNavigate = () => {
        if (navigationPath) {
            setClick(!isClicked);
            navigate(navigationPath);
            setNavigationPath('');
            setSearchTerm('');
            setClick(false);
        }
    };

    return (
        <div>
            <div
                style={{
                    width: '77%',
                    fontSize: '1rem',
                    zIndex: '900',
                    position: 'fixed',
                    top: '0',
                    left: '256px',
                    paddingTop: '8%',
                    marginLeft: '2%',
                    alignItems: 'center',
                }}
                ref={searchBarRef}
            >
                <div
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid #D0D5DD',
                        borderRadius: '15px',
                        padding: '15px',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.1)',
                        marginLeft: '4px',
                    }}
                >
                    <input
                        type='text'
                        placeholder='Search or Choose Flows'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                            width: '98%',
                            border: 'none',
                            outline: 'none',
                        }}
                    />
                    <span
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={handleDropdownClick}
                    >
                        <DownArrow />
                    </span>
                </div>
                {(isClicked || searchTerm) && filteredLinks.length > 0 ? (
                    <div
                        style={{
                            marginTop: '4px',
                            backgroundColor: 'white',
                            borderRadius: '16px',
                            width: '100%',
                            boxShadow:
                                '2px 2px 4px 2px rgba(0.3, 0.3, 0.3, 0.3)',
                        }}
                    >
                        <div className='scroll'>
                            {filteredLinks.map((link) => (
                                <div key={link.path}>
                                    {link.heading && (
                                        <div
                                            style={{
                                                padding: '10px',
                                                borderBottom:
                                                    '1px solid #F2F4F7',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {link.heading}
                                        </div>
                                    )}
                                    <label
                                        style={{
                                            cursor: 'pointer',
                                            padding: '10px',
                                            borderBottom: '1px solid #F2F4F7',
                                        }}
                                    >
                                        <input
                                            type='radio'
                                            name='sidebarRadio'
                                            value={link.path}
                                            onChange={() =>
                                                handleRadioChange(link.path)
                                            }
                                        />
                                        {link.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <button
                                onClick={handleCancel}
                                style={{
                                    cursor: 'pointer',
                                    padding: '10px',
                                    marginRight: '10px',
                                    borderRadius: '10px',
                                    border: '1px solid #ccc',
                                    backgroundColor: 'white',
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleNavigate}
                                style={{
                                    cursor: 'pointer',
                                    padding: '10px',
                                    borderRadius: '10px',
                                    backgroundColor: '#577CFF',
                                    color: 'white',
                                    border: 'none',
                                }}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                ) : (
                    searchTerm && (
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: '4px',
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                padding: '16px',
                                boxShadow:
                                    '4px 4px 4px 4px rgba(0.3, 0.3, 0.3, 0.3)',
                            }}
                        >
                            No Flows Found For The Search
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default SearchBar;
