import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Approved from './Approved';
import ForReview from './ForReview';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';

function MediaLibrary() {
    const fetchUtils = useAxiosGetMultiple({
        forReview: endpoints.mediaLibrary.getForApproval,
        approved: endpoints.mediaLibrary.getApproved,
    });
    
    return (
        <Tabs fill className='mx-5' mountOnEnter>
            <Tab eventKey='review' title='Under Review' >
                <ForReview fetchUtils={fetchUtils}/>
            </Tab>
            <Tab eventKey='approved' title='Approved' >
                <Approved fetchUtils={fetchUtils}/>
            </Tab>
        </Tabs>
    );
}

export default MediaLibrary;
