import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import { Col, Collapse, FormLabel, Row } from 'react-bootstrap';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import {
    getMediaUploadLink,
    uploadMedia,
} from '../../faculty/mediaLibrary/mediaServices';
import CourseAvatar from '../../../asset/icons/InsAvatar.svg';
import toast from 'react-hot-toast';

function CourseForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response: courseCategories, loading } = useAxiosGet(
        endpoints.courseCategory.get
    );

    const [file, setFile] = useState(null);

    const [fileError, setFileError] = useState(null);
    const [progress, setProgress] = useState(0);

    const initialFormValues = updateValues || {
        Course_NAME: '',
        Course_Category_ID: '',
        Course_Credits: '',
        Course_Duration_Days: '',
        Course_Duration_hours: '',
        Suggested_Course_Frequency: '',
        Last_Course_Schedule_Date: '',
        Course_Description: '',
        Version_ID: '',
        Is_Mandatory: '',
        'Start Date': '',
        'End Date': '',
    };

    const submitHandler = async (values, { setSubmitting }) => {
        if (!updateValues) {
            values.Course_STATUS = 1;
        }

        try {
            if (file) {
                if (!file.type.includes('image')) {
                    setFileError(true);
                    return;
                }

                const uploadLink = await getMediaUploadLink(file.name);
                console.log(uploadLink);

                await uploadMedia(uploadLink.signedUrl, file, setProgress);

                values.COURSE_DP_URL = uploadLink.publicUrl;
            }

            values.DP_URL = values.COURSE_DP_URL;

            await axios.post(endpoint, values);
            toast.success('Course submitted successfully');
            // showAlert('success', 'Course submitted successfully');
            onAfterSubmit();
        } catch (err) {
            toast.error(extractErrorFromRes(err));
        } finally {
            setSubmitting(false);
        }
    };
    if (loading) {
        return <Loader />;
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const pic = updateValues?.COURSE_DP_URL
        ? encodeURI(updateValues?.COURSE_DP_URL)
        : CourseAvatar;
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Course_NAME: Yup.string()
                    .max(45, "Name mustn't exceed 45 characters")
                    .required(),
                Course_Category_ID: Yup.string().required(),
                Course_Description: Yup.string()
                    .test(
                        'no-leading-space',
                        'Description should not be empty or consist of only spaces',
                        (value) => {
                            return !value || value.trim() !== '';
                        }
                    )
                    .required(),
                Course_Credits: Yup.number().min(0).required(),
                Course_Duration_Days: Yup.number().min(0).required(),
                Course_Duration_hours: Yup.number().min(0).required(),
                Suggested_Course_Frequency: Yup.number().min(0).required(),
                Last_Course_Schedule_Date: Yup.date()
                    .min(today, 'Please enter upcoming date')
                    .required(),
                Version_ID: Yup.number().min(0).required(),
                'Start Date': Yup.date().when('Is_Mandatory', {
                    is: '1',
                    then: () => Yup.date().required(),
                    otherwise: () => Yup.date().nullable(),
                }),
                'End Date': Yup.date()
                    .when('Is_Mandatory', {
                        is: '1',
                        then: Yup.date().required('End date is required'),
                        otherwise: Yup.date().nullable(),
                    })
                    .when('Start Date', (startDate, schema) => {
                        return startDate
                            ? schema.min(
                                  startDate,
                                  'The end date cannot be less than the start date'
                              )
                            : schema;
                    }),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values }) => (
                <Form>
                    <Row>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='Course_NAME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select-advanced'
                                required
                                name='Course_Category_ID'
                                label='Course Category'
                                options={courseCategories}
                                defaultValue={courseCategories.find(
                                    (cat) =>
                                        Number(cat.Course_Category_ID) ===
                                        Number(
                                            initialFormValues.Course_Category_ID
                                        )
                                )}
                                optionValue='Course_Category_ID'
                                optionLabel='Course_Category_NAME'
                            />
                        </Col>
                        <Col sm='12'>
                            <FormikControl
                                control='textarea'
                                required
                                name='Course_Description'
                                label='Course Description'
                            />
                        </Col>
                        <Col sm='6'>
                            {updateValues && !file && (
                                <>
                                    <FormLabel className='small'>
                                        Course Image
                                    </FormLabel>

                                    <FormLabel
                                        as={FormLabel}
                                        htmlFor='courseImage'
                                        src={updateValues.COURSE_DP_URL}
                                        style={{
                                            backgroundImage: 'url(' + pic + ')',
                                            width: '100%',
                                            height: '150px',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                        }}
                                    />
                                </>
                            )}
                            <div
                                style={{
                                    visibility:
                                        updateValues && !file
                                            ? 'hidden'
                                            : 'visible',
                                    marginBottom:
                                        updateValues && !file ? '-50px' : '0',
                                }}
                            >
                                <FormikControl
                                    control='input'
                                    type='file'
                                    name='image'
                                    controlId='courseImage'
                                    label='Course Image'
                                    onChange={(e) => {
                                        setFileError(false);
                                        setFile(e.target.files[0]);
                                    }}
                                />
                                <Collapse in={fileError}>
                                    <div
                                        className='text-danger small'
                                        style={{
                                            transform: 'translateY(-10px)',
                                        }}
                                    >
                                        Only supports image
                                    </div>
                                </Collapse>
                            </div>
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                required
                                name='Course_Credits'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                required
                                name='Course_Duration_Days'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                required
                                name='Course_Duration_hours'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                required
                                name='Suggested_Course_Frequency'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='Last_Course_Schedule_Date'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                required
                                label='Version'
                                name='Version_ID'
                            />
                        </Col>

                        <FormikControl
                            control='check'
                            type='radio'
                            required
                            label='Is Mandatory ?'
                            name='Is_Mandatory'
                            options={[
                                { key: 'Yes', value: '1' },
                                { key: 'No', value: '2' },
                            ]}
                        />

                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required={values.Is_Mandatory == 1}
                                name='Start Date'
                            />
                        </Col>

                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required={values.Is_Mandatory == 1}
                                name='End Date'
                            />
                        </Col>
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && (
                        <Loader
                            type={!!file ? 'progress' : 'spin'}
                            progress={progress}
                        />
                    )}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(CourseForm);
