import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import objCloneKeyValue from '../../../helpers/objCloneKeyValue';
// import LiveClassTopicForm from '../liveClassTopic/LiveClassTopicForm';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
// import QuizAssignmentForm from '../quizAssignment/QuizAssignmentForm';
// import AssignMediaForm from '../mediaAssignment/AssignMediaForm';
import { useAuthenticationState } from '../../../context/Auth.context';
import { useLocation } from 'react-router-dom';
import { assignToCourse, createQuiz, generatePriorityId } from './helpers';
import { ReactComponent as Info } from '../../../asset/icons/Info.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import QuizCreation from './QuizCreation';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';

function StructureForm(props) {
    const { endpoint, onCancel, onAfterSubmit, showAlert } = props;
    const [tags, setTags] = useState([]);

    const { response, loading } = useAxiosGetMultiple({
        media: endpoints.mediaLibrary.get,
        liveClassTopics: endpoints.liveclassTopic.get,
    });
    const { user } = useAuthenticationState();

    const { state: courseData } = useLocation();
    const { courseId, versionId, courseCategoryId } = courseData;

    // ************************
    const mediaShareSamecategory = response.media.filter(
        (media) => Number(media.Course_Category_ID) === Number(courseCategoryId)
    );
    let media = objCloneKeyValue(mediaShareSamecategory, 'MEDIA_DESC', 'Label');
    media = objCloneKeyValue(media, 'MEDIA_ID', 'Value');
    // ************************
    let liveClassTopics = objCloneKeyValue(
        response.liveClassTopics,
        'LIVE_CLASS_TOPIC_NAME',
        'Label'
    );
    liveClassTopics = objCloneKeyValue(
        liveClassTopics,
        'LIVE_CLASS_TOPIC_ID',
        'Value'
    );

    const contentProp = {
        1: {
            name: 'liveClassTopics',
            contents: liveClassTopics,
            label: 'Live Class Topic',
        },
        2: {
            name: 'media',
            contents: media.filter((media) => Number(media.MEDIA_TYPE) === 2),
            label: 'Video',
        },
        3: {
            name: 'quizzes',
            // contents: quizzes,
            label: 'Quiz',
        },
        4: {
            name: 'media',
            contents: media.filter((media) => Number(media.MEDIA_TYPE) === 4),
            label: 'Document',
        },
    };

    const submitHandler = async (values, { setSubmitting: setLoading }) => {
        try {
            const body = { ...values };
            let assignToCourseMeta = {};

            if (
                Number(body.Course_Structure_Content_Type) === 4 ||
                Number(body.Course_Structure_Content_Type) === 2
            ) {
                const matchedMedia = media.find(
                    (media) =>
                        media.MEDIA_ID === body.Course_Structure_Content_ID
                );

                assignToCourseMeta = {
                    body: {
                        ASSIGNEDMEDIA_DESC: matchedMedia.MEDIA_DESC,
                        MEDIA_ID: matchedMedia.MEDIA_ID,
                        MEDIA_TYPE: body.Course_Structure_Content_Type,
                        Course_Category_ID: matchedMedia.Course_Category_ID,
                        COURSE_ID: courseId,
                        SEQUENCE_ID: 1,
                        ASSIGNED_STATUS: 1,
                    },
                    endpoint: endpoints.mediaAssign.add,
                };
            }

            if (Number(body.Course_Structure_Content_Type) === 3) {
                const quizId = await createQuiz({
                    Course_ID: courseId,
                    QUIZ_NAME: body.Quiz.Quiz_Title,
                    Course_Category_ID: courseCategoryId,
                    QUIZ_DURATION: '0',
                    QUIZ_PASSMARK_PERCENTAGE: body.Quiz.PassMark,
                    TotalQuestioinsCount: body.Quiz.Question_Number,
                    MAX_COUNT: body.Quiz.Retake_Attempts,
                    SEQUENCE_ID: 1,
                    Question_Tag_array: body.Quiz.Selected_Questions,
                });
                if (quizId) {
                    body.Course_Structure_Content_ID = quizId;
                } else {
                    return;
                }
            }

            body.Course_Structure_Priority_ID = await generatePriorityId(
                courseData
            );

            if (
                Number(body.Course_Structure_Content_Type) !== 1 &&
                Number(body.Course_Structure_Content_Type) !== 3
            ) {
                const res = await assignToCourse(assignToCourseMeta);
                body.Course_Structure_Content_ID =
                    res.data.responseData.insertId;

                addToStructure();
            } else {
                // if type is live class
                addToStructure();
            }

            async function addToStructure() {
                try {
                    if (user.type === 'BOTH') {
                        body.Course_Structure_Status = 3;
                        body.Review_Comments = '_';
                        body.Reviewed_By = user.id;
                        body.Reviewed_Status = 1;
                        body.Approved_By = user.id;
                    }

                    const res = await axios.post(endpoint, body);
                    onAfterSubmit();
                } catch (error) {
                    toast.error(extractErrorFromRes(error));
                } finally {
                    setLoading(false);
                }
            }
        } catch (error) {
            console.log('second');
            setLoading(false);
            toast.error(extractErrorFromRes(error));
        }
    };

    const fetchTags = debounce(async (keyword, courseCatId) => {
        return axios
            .post(endpoints.course.autopopulateTag, {
                Course_Category_ID: courseCatId,
                keyword: keyword,
            })
            .then((res) => {
                const tags = res.data.responseData.map((tag) => ({
                    name: tag,
                    value: tag,
                }));
                setTags(tags);
            })
            .catch((err) => {
                setTags([]);
            });
    }, 500);

    const initialFormValues = {
        Course_ID: courseId,
        Version_ID: versionId,
        Course_Category_ID: courseCategoryId,
        Course_Structure_Content_Type: '',
        Course_Structure_Name: '',
        Course_ContentTags: '',
        Course_Structure_Priority_ID: '',
        Course_Structure_Content_ID: '',
        Course_Structure_Status: '1',
        Review_Comments: '_',
        Reviewed_By: '0',
        Reviewed_Status: '0',
        Approved_By: '0',
        Quiz: {
            Quiz_Title: '',
            Question_Number: '',
            PassMark: '',
            Retake_Attempts: '',
            Question_Tag_array: [],
            Selected_Questions: [],
        },
    };
    if (loading) {
        return <Loader />;
    }
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Course_Structure_Name: Yup.string().required(),
                Course_Structure_Content_Type: Yup.string().required(),
                Course_Structure_Content_ID: Yup.string().when(
                    'Course_Structure_Content_Type',
                    {
                        is: (val) => val !== '3',
                        then: Yup.string().required(),
                    }
                ),
                Course_ContentTags: Yup.string().when(
                    'Course_Structure_Content_Type',
                    {
                        is: (val) => val !== '3',
                        then: Yup.string()
                            .matches(/^[^\s]+$/, 'Spaces are not allowed.')
                            .required(),
                    }
                ),
                Quiz: Yup.object({}).when('Course_Structure_Content_Type', {
                    is: '3',
                    then: Yup.object({
                        Quiz_Title: Yup.string().required(),
                        Question_Number: Yup.number('Only numbers are allowed')
                            .min(5, 'Value must be at least 5')
                            .required(),
                        PassMark: Yup.number('Only numbers are allowed')
                            .min(1, 'Please enter value greater than 1')
                            .max(100, 'Percentage should not exceed 100')
                            .required(),
                        Retake_Attempts: Yup.number('Only numbers are allowed')
                            .min(1, 'Value must be at least 1')
                            .required(),

                        Question_Tag_array: Yup.array().of(
                            Yup.object().shape({
                                Question_Tag: Yup.string(),
                            })
                        ),
                        Selected_Questions: Yup.array().test(
                            'Question_Number',
                            'Number of selected questions must be more than or equal to Total No. of questions',
                            function (value) {
                                const { Question_Number } = this.parent;
                                return value.length >= Question_Number;
                            }
                        ),
                    }),
                }),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, setFieldValue, values, errors }) => (
                <Form>
                    <FormikControl
                        name='Course_Structure_Name'
                        required
                        label='Structure Name'
                    />
                    <FormikControl
                        control='select'
                        required
                        name='Course_Structure_Content_Type'
                        label='Structure Type'
                        onChange={(e) => {
                            setFieldValue(
                                'Course_Structure_Content_Type',
                                e.target.value
                            );
                            setFieldValue('Course_Structure_Content_ID');
                            setFieldValue('Course_ContentTags', '');
                        }}
                    >
                        <option value=''>Select content type</option>
                        <option value={'1'}>Live Class</option>
                        <option value={'2'}>Video</option>
                        <option value={'3'}>Quiz</option>
                        <option value={'4'}>Document</option>
                    </FormikControl>

                    {values.Course_Structure_Content_Type && (
                        <>
                            {values.Course_Structure_Content_Type === '3' ? (
                                <div style={{ paddingBottom: '15px' }}>
                                    <QuizCreation
                                        setFieldValue={setFieldValue}
                                        endpoint={endpoint}
                                        courseCategoryId={courseCategoryId}
                                        formValues={values}
                                    />
                                </div>
                            ) : (
                                <>
                                    <div
                                        style={{
                                            position: 'relative',
                                        }}
                                    >
                                        <FormikControl
                                            control='creatable-select'
                                            required
                                            name='Course_ContentTags'
                                            label='Course Structure Tag'
                                            placeholder='Add or search tag...'
                                            options={tags}
                                            onInputChange={(val) => {
                                                if (val) {
                                                    fetchTags(
                                                        val,
                                                        values.Course_Category_ID
                                                    );
                                                }
                                            }}
                                            styles={{
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 10,
                                                }),

                                                control: (provided, state) => ({
                                                    ...provided,

                                                    fontSize: '.9em',
                                                    borderRadius: '8px',
                                                    boxShadow: 'none',
                                                    border: '1px solid #BDBDBD',
                                                    background:
                                                        'rgba(242, 242, 242, 0.4)',
                                                }),
                                                dropdownIndicator: (
                                                    provided,
                                                    state
                                                ) => ({
                                                    ...provided,
                                                    visibility: 'hidden',
                                                }),
                                            }}
                                        />

                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '55%',
                                                right: '2%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <OverlayTrigger
                                                placement='top'
                                                overlay={(props) => (
                                                    <Tooltip {...props}>
                                                        <div
                                                            style={{
                                                                padding: '5px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: '#39F056',
                                                                    textAlign:
                                                                        'start',
                                                                }}
                                                            >
                                                                Course Structure
                                                                Tag 🙋‍♀️{' '}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: '#ffffff',
                                                                    textAlign:
                                                                        'start',
                                                                    marginTop:
                                                                        '10px',
                                                                }}
                                                            >
                                                                {' '}
                                                                The course
                                                                structure tag
                                                                facilitates a
                                                                comprehensive
                                                                evaluation of a
                                                                student's
                                                                performance,
                                                                highlighting
                                                                their strengths
                                                                💪 , weakness 🛟
                                                                and areas for
                                                                improvement
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                            >
                                                <Info />
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <FormikControl
                                        control='select-advanced'
                                        name='Course_Structure_Content_ID'
                                        options={
                                            contentProp[
                                                values
                                                    .Course_Structure_Content_Type
                                            ]?.contents
                                        }
                                        optionValue='Value'
                                        required
                                        optionLabel='Label'
                                        label={
                                            contentProp[
                                                values
                                                    .Course_Structure_Content_Type
                                            ]?.label
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}
                    <div className='text-center text-danger small'>
                        <ErrorMessage name='Quiz.Selected_Questions' />
                    </div>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(StructureForm);
