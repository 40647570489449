import { useState } from 'react';
import { FormSelect, Stack, Table } from 'react-bootstrap';
import FallbackImg from '../../../asset/icons/UserAvatar.svg';

function StudentProgress({ studentProgress }) {
    const [courseId, setCourseId] = useState('');

    const courseAttendingParticipants = studentProgress.filter(
        (item) => Number(item.courseId) === Number(courseId)
    );

    return (
        <div className='mt-5'>
            <div
                style={{ fontWeight: '900' }}
                className='small mb-4 d-flex justify-content-between'
            >
                <span>Participant's Analytics</span>
                <FormSelect
                    className='w-25'
                    size='sm'
                    value={courseId}
                    onChange={(e) => setCourseId(e.target.value)}
                >
                    <option value=''>Select Course</option>
                    {studentProgress.map((course) => (
                        <option value={course.courseId} key={course.courseId}>
                            {course.course}
                        </option>
                    ))}
                </FormSelect>
            </div>
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                {courseId === '' ? (
                    <div className='text-center fs-4 my-5'>Select Course</div>
                ) : (
                    <Table className='bg-transparent small vh-25'>
                        <tbody>
                            {courseAttendingParticipants.map((course) => {
                                return course?.attendees.length > 0 ? (
                                    course?.attendees.map((item) => (
                                        <TR
                                            img={item?.DP}
                                            name={item?.Name}
                                            course={course?.course}
                                            completedTaskNo={
                                                item?.completedTaskNo
                                            }
                                            totalTaskNo={item?.totalTaskNo}
                                            isCompleted={
                                                item?.totalTaskNo ===
                                                item?.completedTaskNo
                                            }
                                        />
                                    ))
                                ) : (
                                    <tr className='text-center fs-4 my-5'>
                                        <td className='py-4'>
                                            No participants enrolled to it
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </div>
        </div>
    );
}

export default StudentProgress;

const TR = ({
    img,
    name,
    course,
    completedTaskNo,
    totalTaskNo,
    isCompleted,
}) => {
    const image = img || FallbackImg;

    return (
        <tr
            className='small'
            style={{ border: 'none', borderBottom: '1px solid #E8E8E8' }}
        >
            <td>
                <div
                    style={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '12px',
                        backgroundImage: 'url(' + image + ')',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
            </td>
            <td className='pe-3 fw-bold'>{name}</td>
            <td className='pe-3 fw-bold'>{course}</td>
            <td className='d-inline'>
                <Stack style={{ width: '100px' }}>
                    <div className='small d-flex'>
                        <span className='small fw-bold text-success'>
                            {Math.round((completedTaskNo / totalTaskNo) * 100)}%
                        </span>
                        <span className='ms-auto text-muted small'>
                            {completedTaskNo}/{totalTaskNo}
                        </span>
                    </div>
                    <div
                        className='position-relative rounded-smooth w-100 overflow-hidden '
                        style={{
                            height: '4px',
                            backgroundColor: '#e7e7e7',
                        }}
                    >
                        <div
                            className='position-absolute rounded-smooth bg-warning'
                            style={{
                                width:
                                    (completedTaskNo / totalTaskNo) * 100 + '%',
                                height: '4px',
                            }}
                        />
                    </div>
                </Stack>
            </td>
            <td className='text-end'>
                <span
                    className='px-3 py-1 small rounded-smooth d-inline-block fw-bold'
                    style={{
                        backgroundColor: isCompleted ? '#CBF0EE' : '#FDF2D6',
                        color: isCompleted ? '#00CCCC' : '#E2B33A',
                    }}
                >
                    {isCompleted ? 'Completed' : 'In progress'}
                </span>
            </td>
        </tr>
    );
};
