import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import { Button } from 'react-bootstrap';
import { useAuthenticationState } from '../../../context/Auth.context';
import { processLiveClassVideo } from './liveClassService';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import moment from 'moment';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';
var FileSaver = require('file-saver');

function LiveClassVideoRecordings({ showAlert }) {
    const { user } = useAuthenticationState();
    const fetchUtils = useAxiosGet(endpoints.liveclass.videoRecordingList, {
        selectcolumns: '*',
        wherecondition: 'INSTRUCTOR_ID=' + user.id,
    });

    // const downloadFile = async (url) => {
    //     fetch(url).then((response) => {
    //       response.blob().then((blob) => {
    //         var FileSaver = require('file-saver');
    //         var blob = new Blob(blob, {type: "video/mp4"});
    //         FileSaver.saveAs(blob, "video.mp4");
    //       });
    //     });
    // }
    // const downloadFile = (url) => {
    //     fetchUtils.setLoading(true);
    //     axios({
    //         url: url,
    //         method: 'GET',
    //         responseType: 'blob',
    //     })
    //         .then((response) => {
    //             const url = window.URL.createObjectURL(
    //                 new Blob([response.data])
    //             );
    //             const link = document.createElement('a');
    //             link.href = url;
    //             link.setAttribute('download','file.mp4');
    //             document.body.appendChild(link);
    //             link.click();
    //         })
    //         .catch((err) => {
    //             showAlert('error', extractErrorFromRes(err));
    //         })
    //         .finally(() => {
    //             fetchUtils.setLoading(false);
    //         });
    // };
    const cellModifier = {
        createdDate: ({ row }) =>
            moment(row.original.createdDate).format('DD-MM-YYYY'),
        expiryDate: ({ row }) =>
            row.original.expiryDate
                ? moment(row.original.expiryDate).format('DD-MM-YYYY')
                : '',
        videoRecordingStatus: ({ row }) => {
            let statusColor = 'bg-primary';
            if (
                row.original.videoRecordingStatus == 0 ||
                row.original.videoRecordingStatus == 1 ||
                row.original.videoRecordingStatus == 3
            ) {
                statusColor = 'bg-warning';
            } else if (
                row.original.videoRecordingStatus == 2 ||
                row.original.videoRecordingStatus == 4
            ) {
                statusColor = 'bg-success';
            }
            if (row.original.videoRecordingStatus == 5) {
                statusColor = 'bg-danger';
            }
            return (
                <span
                    className={`${statusColor} text-white rounded`}
                    style={{
                        padding: '0.25rem 0.5rem',
                        lineHeight: '1.5',
                        fontSize: '0.70rem',
                    }}
                    title='You can only enter the class 5 minutes earlier than the actual start time, if time has started and button is disabled, please reload the page'
                >
                    {row.original.statusText}
                </span>
            );
        },
        Start: ({ row }) => {
            let buttonName = '';
            if (row.original.videoRecordingStatus == 0) {
                buttonName = 'CHECK PRESENTATION STATUS';
            } else if (row.original.videoRecordingStatus == 1) {
                buttonName = 'CHECK PRESENTATION STATUS';
            } else if (row.original.videoRecordingStatus == 2) {
                buttonName = 'CREATE MP4';
            } else if (row.original.videoRecordingStatus == 3) {
                buttonName = 'CHECK MP4 STATUS';
            } else if (row.original.videoRecordingStatus == 4) {
                buttonName = 'VIEW & DOWNLOAD MP4';
            }
            return (
                buttonName != '' && (
                    <Button
                        onClick={() => {
                            fetchUtils.setLoading(true);
                            processLiveClassVideo({
                                VIDEORECORD_ID: row.original.videoRecordID,
                            })
                                .then((response) => {
                                    fetchUtils.setLoading(false);
                                    if (response) {
                                        fetchUtils.reFetch();
                                        if (response?.data?.downloadURL) {
                                            FileSaver.saveAs(
                                                response.data.downloadURL,
                                                response.data.fileName
                                            );
                                        } else {
                                            toast.success(
                                                response.data.statusText
                                            );
                                        }
                                    }
                                })
                                .catch((err) => {
                                    fetchUtils.setLoading(false);
                                    fetchUtils.reFetch();
                                    toast.error(extractErrorFromRes(err));
                                });
                        }}
                        size='sm'
                        title='Process live class video recordings'
                        style={{
                            padding: '0.25rem 0.5rem',
                            lineHeight: '1.5',
                            fontSize: '0.70rem',
                        }}
                    >
                        {buttonName}
                    </Button>
                )
            );
        },
    };

    return (
        <>
            <CRUDTable
                downloadable={false}
                immutable={true}
                irremovable={true}
                insertable={false}
                endpoints={endpoints.liveclass}
                {...viewProps.LiveClassVideoRecordings}
                {...fetchUtils}
                cellModifier={cellModifier}
                noDataMessage={'No records found.'}
            />
        </>
    );
}

export default withAlert(LiveClassVideoRecordings);
