function QuizTagList({ handleTagChange, questionTag, selectedTags }) {
    return (
        <div
            className='px-3 pt-3 pb-4'
            style={{
                borderTop: '1px solid rgba(140, 140, 140, 0.71)',
                borderBottom: '1px solid rgba(140, 140, 140, 0.71)',
            }}
        >
            <div
                className='d-flex'
                style={{
                    fontSize: '14px',
                    color: '#334155',
                }}
            >
                <span
                    style={{
                        fontWeight: '600',
                    }}
                >
                    Course Structure Tag
                </span>
                <span className='ms-auto' style={{ fontWeight: '400' }}>
                    {questionTag.length} Results
                </span>
            </div>
            <div
                className='mt-2 pl-1'
                style={{ display: 'flex', flexWrap: 'wrap' }}
            >
                {selectedTags.map((tag) => (
                    <span
                        style={{
                            background: '#F9F5FF',
                            color: '#107BFF',
                            marginRight: '8px',
                            padding: '8px',
                            borderRadius: '6px',
                            marginBottom: '5px',
                            fontSize: '14px',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleTagChange(tag.Question_Tag)}
                    >
                        {tag.Question_Tag}
                    </span>
                ))}
            </div>
            <div className='pt-2' style={{ display: 'flex', flexWrap: 'wrap' }}>
                {questionTag.map((item) => {
                    const isChecked = selectedTags.some(
                        (tag) => tag.Question_Tag === item
                    );
                    return (
                        <span className='me-2' key={item + Math.random()}>
                            <input
                                type='checkbox'
                                className='m-1'
                                checked={isChecked}
                                onChange={() => {
                                    handleTagChange(item);
                                }}
                                style={{ border: '#107BFF' }}
                                name='Quiz.Selected_Question_Tags'
                            />
                            <span>{item}</span>
                        </span>
                    );
                })}
            </div>
        </div>
    );
}

export default QuizTagList;
