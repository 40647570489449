import React from 'react';
import { Button } from 'react-bootstrap';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAuthenticationState } from '../../../context/Auth.context';
import {
    approveStructure,
    rejectStructure,
} from '../../../services/courseStructureService';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import WithModal from '../../../components/WithModal';
import StructureForm from '../../faculty/courseStructure/StructureForm';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function ForReview({ fetchUtils, response, showAlert }) {
    const { user } = useAuthenticationState();

    const approve = (body, afterApprove) => {
        fetchUtils.setLoading(true);

        approveStructure(body, user.id)
            .then((res) => {
                fetchUtils.reFetch();
                afterApprove();
            })
            .catch((err) => {
                fetchUtils.setLoading(false);
                toast.error(extractErrorFromRes(err));
            });
    };

    const reject = (body, afterReject) => {
        fetchUtils.setLoading(true);

        rejectStructure(body, user.id)
            .then((res) => {
                fetchUtils.reFetch();
                afterReject();
            })
            .catch((err) => {
                fetchUtils.setLoading(false);
                toast.error(extractErrorFromRes(err));
            });
    };

    const cellModifier = {
        Course_Structure_Content_Type: ({ value }) => {
            switch (value) {
                case 1:
                    return 'Live Class';
                case 2:
                    return 'Video';
                case 3:
                    return 'Quiz';
                case 4:
                    return 'Document';
                default:
                    break;
            }
        },
        Approve: ({ row }) => (
            <WithModal
                modalTitle={'Approve Structure?'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <div className='text-center'>
                        <Button
                            variant='outline-danger'
                            className='mx-2 px-4'
                            onClick={() => approve(row.original, closeModal)}
                        >
                            Yes
                        </Button>
                        <Button
                            variant='outline-success'
                            className='mx-2 px-4'
                            onClick={closeModal}
                        >
                            No
                        </Button>
                    </div>
                )}
            >
                <span className='border border-success p-1 text-success rounded'>
                    Approve
                </span>
            </WithModal>
        ),
        Reject: ({ row }) => (
            <WithModal
                modalTitle={'Reject Structure?'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <div className='text-center'>
                        <Button
                            variant='outline-danger'
                            className='mx-2 px-4'
                            onClick={() => reject(row.original, closeModal)}
                        >
                            Yes
                        </Button>
                        <Button
                            variant='outline-success'
                            className='mx-2 px-4'
                            onClick={closeModal}
                        >
                            No
                        </Button>
                    </div>
                )}
            >
                <span className='border border-danger p-1 text-danger rounded'>
                    Reject
                </span>
            </WithModal>
        ),
    };

    return (
        <CRUDTable
            endpoints={endpoints.courseStructure}
            relevants={[
                ...viewProps.CourseStructureApproval.relevants,
                'Approve',
                'Reject',
            ]}
            columnHeads={[
                ...viewProps.CourseStructureApproval.columnHeads,
                'Approve',
                'Reject',
            ]}
            {...fetchUtils}
            name='Structure Under Review'
            response={response}
            cellModifier={cellModifier}
            irremovable
            immutable
            insertable={user.type === 'BOTH'}
            Form={StructureForm}
            addBtnLabel='Add Structure'
        />
    );
}

export default withAlert(ForReview);
