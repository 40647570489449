import React, { useState } from 'react';
import { FormControl, Stack } from 'react-bootstrap';
import { ReactComponent as Calendar } from '../../../asset/icons/Calendar.svg';
import { ReactComponent as Cam } from '../../../asset/icons/Cam.svg';
import { joinLiveClass, startLiveClass } from '../liveClass/liveClassService';
import Loader from '../../../components/Loader';
import { compareAsc, isSameDay } from 'date-fns';

function ClassSchedule({ schedules }) {
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

    const [loading, setLoading] = useState(false);

    const schedulesOnSelectedDate =
        schedules
            .filter((schedule) => {
                const sameDay = isSameDay(
                    new Date(schedule.LIVECLASSSCHEDULESTARTDATE),
                    new Date(date)
                );
                return sameDay;
            })
            ?.sort((x, y) =>
                compareAsc(
                    new Date(x.LIVECLASSSCHEDULESTARTDATE),
                    new Date(y.LIVECLASSSCHEDULESTARTDATE)
                )
            ) ?? [];

    return (
        <div
            className='px-5 border-start small '
            style={{ maxHeight: '75vh', overflow: 'auto' }}
        >
            <div style={{ fontWeight: '900' }} className='mb-2'>
                Time Table
            </div>
            <FormControl
                type='date'
                value={date}
                size='sm'
                onChange={(e) => setDate(e.target.value)}
            />
            {schedulesOnSelectedDate.length === 0 ? (
                <div className='fw-bold fs-5 my-4 text-center'>
                    No Classes scheduled on <br />
                    {new Date(date).toDateString()}
                </div>
            ) : (
                <Stack gap={3} className='mt-4'>
                    <span className='fw-bold'>
                        Classes on <br /> {new Date(date).toDateString()}
                    </span>
                    {schedulesOnSelectedDate.map((schedule) => (
                        <Schedule schedule={schedule} setLoading={setLoading} />
                    ))}
                </Stack>
            )}
            {loading && <Loader />}
        </div>
    );
}

export default ClassSchedule;

const Schedule = ({ schedule, setLoading }) => {
    const {
        LIVECLASSSCHEDULESTARTTIME: date,
        LIVE_CLASS_TITLE: topic,
        LIVECLASSSCHEDULESTARTTIME,
        LIVECLASSSCHEDULEENDTIME,
        LIVE_CLASS_TITLE,
        LIVE_CLASS_ROOM_ID,
        LIVECLASSSCHEDULEDURATION,
        Instructor_Name,
    } = schedule;

    const now = new Date();
    const startTime = new Date(LIVECLASSSCHEDULESTARTTIME);
    const endTime = new Date(LIVECLASSSCHEDULEENDTIME);
    const isbetween = now > startTime && now < endTime;

    return (
        <Stack className='border border-2 border-dark px-3 pt-2 pb-3 bg-white rounded-smoother'>
            <span className='fw-bold'>{topic}</span>
            <span className='d-flex align-items-center mt-3 small'>
                <Calendar />
                <span className='ms-1'>{new Date(date).toDateString()}</span>
            </span>
            <span
                className='small px-2 mt-3 text-success'
                style={{
                    width: 'fit-content',
                    backgroundColor: '#DCFCE7',
                }}
            >
                {new Date(startTime).toLocaleTimeString('dd', {
                    timeStyle: 'short',
                })}{' '}
                to{' '}
                {new Date(endTime).toLocaleTimeString('dd', {
                    timeStyle: 'short',
                })}
            </span>
            {isbetween && (
                <span
                    className='mt-3 small d-flex'
                    type='button'
                    onClick={() => {
                        setLoading(true);
                        startLiveClass({
                            LIVE_CLASS_TITLE: LIVE_CLASS_TITLE,
                            LIVE_CLASS_ROOM_ID: LIVE_CLASS_ROOM_ID,
                            LIVECLASSSCHEDULEDURATION:
                                LIVECLASSSCHEDULEDURATION,
                        }).then((isCreated) => {
                            if (isCreated) {
                                joinLiveClass(
                                    LIVE_CLASS_ROOM_ID,
                                    'INS-' + Instructor_Name
                                )
                                    .then((response) => {
                                        if (response.data.responseData.data)
                                            window.open(
                                                response.data.responseData.data,
                                                '_blank'
                                            );
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            } else {
                                setLoading(false);
                            }
                        });
                    }}
                >
                    <Cam />
                    <span className='ms-2'>Start class</span>
                </span>
            )}
        </Stack>
    );
};
