import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import CRUDTable from '../../../components/table/CRUDTable';
import WithModal from '../../../components/WithModal';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import Loader from '../../../components/Loader';
import toast from 'react-hot-toast';
import { ReactComponent as SmallStar } from '../../../asset/icons/SmallStar.svg';

function Published({ fetchUtils, response, courseCellModifier, showAlert }) {
    const [busy, setBusy] = useState(false);

    const retireCourse = (body, closeModal) => {
        body.Course_STATUS = 4;
        setBusy(true);

        axios
            .post(endpoints.course.update, body)
            .then((res) => {
                closeModal();
                fetchUtils.reFetch();
                toast.success('success');
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => {
                setBusy(false);
            });
    };
    const cellModifier = {
        Retire: ({ row }) => (
            <WithModal
                modalTitle={'Retire Course ?'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <div className='text-center'>
                        <Button
                            variant='outline-danger'
                            className='mx-2 px-4'
                            onClick={() =>
                                retireCourse(row.original, closeModal)
                            }
                            disabled={busy}
                        >
                            Yes
                        </Button>
                        <Button
                            variant='outline-success'
                            className='mx-2 px-4'
                            onClick={closeModal}
                            disabled={busy}
                        >
                            No
                        </Button>
                    </div>
                )}
            >
                <Button size='sm' className='py-0' variant='outline-danger'>
                    Retire
                </Button>
            </WithModal>
        ),
        courserating: ({ row, value: totalRatings }) => {
            // here rating is provided as total number of stars
            // for example 15 stars if three persons rated 5 star each

            if (totalRatings) {
                return (
                    <div className='d-flex align-items-center'>
                        <span className='me-1'>
                            {(
                                totalRatings /
                                (row.original?.rated_participants_count || 1)
                            ).toFixed(1)}
                        </span>
                        <SmallStar />
                        <span className='text-muted small ms-1'>
                            {row.original?.rated_participants_count} ratings
                        </span>
                    </div>
                );
            }
            return 'No rating';
        },
        ...courseCellModifier,
    };
    return (
        <>
            <CRUDTable
                {...fetchUtils}
                response={response}
                relevants={[...viewProps.Course.relevants, 'Retire']}
                columnHeads={[...viewProps.Course.columnHeads, 'Retire']}
                endpoints={endpoints.course}
                cellModifier={cellModifier}
                name='Published Courses'
                irremovable
                immutable
                insertable={false}
            />
            {busy && <Loader />}
        </>
    );
}

export default withAlert(Published);
