import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../../../asset/icons/userGuide/RightArrow.svg';
import { ReactComponent as Line } from '../../../../asset/icons/userGuide/Line.svg';

function SidebarDropdown({ label, path, child, icon }) {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
        navigate(path);
    };

    return (
        <div style={{ paddingTop: '12px' }}>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    cursor: 'pointer',
                    alignItems: 'center',
                }}
                onClick={handleClick}
            >
                <span
                    style={{
                        transform: open ? 'rotate(90deg)' : '',
                        transition: 'transform 380ms ease',
                        paddingRight: '5px',
                    }}
                >
                    <Arrow />
                </span>
                <span style={{ paddingRight: '5px' }}>{icon}</span>
                <div style={{ fontSize: '14px', fontWeight: 400 }}>{label}</div>
            </div>
            {open && (
                <div style={{ paddingLeft: '15px', marginTop: '5px' }}>
                    {child?.map((item, index) => (
                        <div
                            key={index}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <Line />
                            <div
                                style={{
                                    paddingLeft: '5px',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    paddingTop: '5px',
                                }}
                            >
                                {item}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default SidebarDropdown;
