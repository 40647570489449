import React, { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { ReactComponent as Dashboard } from '../../asset/icons/Dashboard.svg';
import { ReactComponent as DashboardActive } from '../../asset/icons/DashboardActive.svg';
import { ReactComponent as Department } from '../../asset/icons/Department.svg';
import { ReactComponent as DepartmentActive } from '../../asset/icons/DepartmentActive.svg';
import { ReactComponent as CourseCategory } from '../../asset/icons/Course_Category.svg';
import { ReactComponent as CourseCategoryActive } from '../../asset/icons/Course_CategoryActive.svg';
import { ReactComponent as DeptCourse } from '../../asset/icons/LinkIcon.svg';
import { ReactComponent as DeptCourseActive } from '../../asset/icons/LinkIconActive.svg';
import { ReactComponent as Course } from '../../asset/icons/Course.svg';
import { ReactComponent as CourseActive } from '../../asset/icons/CourseActive.svg';
import { ReactComponent as Instructor } from '../../asset/icons/Instructor.svg';
import { ReactComponent as InstructorActive } from '../../asset/icons/InstructorActive.svg';
import { ReactComponent as MediaApprove } from '../../asset/icons/Review.svg';
import { ReactComponent as MediaApproveActive } from '../../asset/icons/ReviewActive.svg';
import { ReactComponent as Media } from '../../asset/icons/Media.svg';
import { ReactComponent as MediaActive } from '../../asset/icons/MediaActive.svg';
import { ReactComponent as Quiz } from '../../asset/icons/Quiz.svg';
import { ReactComponent as QuizActive } from '../../asset/icons/QuizActive.svg';
import { ReactComponent as LiveClass } from '../../asset/icons/LiveClass.svg';
import { ReactComponent as LiveClassActive } from '../../asset/icons/LiveClassActive.svg';
import { ReactComponent as Participant } from '../../asset/icons/Participant.svg';
import { ReactComponent as ParticipantActive } from '../../asset/icons/ParticipantActive.svg';
import { ReactComponent as Program } from '../../asset/icons/Program.svg';
import { ReactComponent as ProgramActive } from '../../asset/icons/ProgramActive.svg';

import { ReactComponent as Master } from '../../asset/icons/Master.svg';
import { ReactComponent as MasterActive } from '../../asset/icons/MasterActive.svg';
import { ReactComponent as TeamCourse } from '../../asset/icons/LinkIcon.svg';
import { ReactComponent as TeamCourseActive } from '../../asset/icons/LinkIconActive.svg';

import { ReactComponent as LocationCourse } from '../../asset/icons/LinkIcon.svg';
import { ReactComponent as LocationCourseActive } from '../../asset/icons/LinkIconActive.svg';
import { ReactComponent as TeamParticipant } from '../../asset/icons/LinkIcon.svg';
import { ReactComponent as TeamParticipantActive } from '../../asset/icons/LinkIconActive.svg';
import { ReactComponent as DesignationCourseCategory } from '../../asset/icons/LinkIcon.svg';
import { ReactComponent as DesignationCourseCategoryActive } from '../../asset/icons/LinkIconActive.svg';
import { ReactComponent as Help } from '../../asset/icons/help.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';

function Menu({ routes }) {
    const [activeModule, setActiveModule] = useState('');

    const { pathname } = useLocation();
    const { user } = useAuthenticationState();
    useEffect(() => {
        const routesInsideModule = routes.filter((route) => !!route.module);

        const currentRouteInsideModule = routesInsideModule.find(
            (route) => pathname === '/' + route.path
        );
        if (currentRouteInsideModule) {
            setActiveModule(currentRouteInsideModule.module);
        } else {
            setActiveModule(pathname);
        }

        return () => {
            setActiveModule('');
        };
    }, [pathname]);

    const menuIconMap = {
        'ins-overview': {
            active: <DashboardActive />,
            inactive: <Dashboard />,
        },
        overview: { active: <DashboardActive />, inactive: <Dashboard /> },
        department: { active: <DepartmentActive />, inactive: <Department /> },
        'course-category': {
            active: <CourseCategoryActive />,
            inactive: <CourseCategory />,
        },
        'dept-course-category': {
            active: <DeptCourseActive />,
            inactive: <DeptCourse />,
        },
        instructor: { active: <InstructorActive />, inactive: <Instructor /> },
        course: { active: <CourseActive />, inactive: <Course /> },
        'master settings': { active: <MasterActive />, inactive: <Master /> },
        'media-approve': {
            active: <MediaApproveActive />,
            inactive: <MediaApprove />,
        },
        program: { active: <ProgramActive />, inactive: <Program /> },
        'team-course_category': {
            active: <TeamCourseActive />,
            inactive: <TeamCourse />,
        },
        participants: {
            active: <ParticipantActive />,
            inactive: <Participant />,
        },
        'media-library': { active: <MediaActive />, inactive: <Media /> },
        quiz: { active: <QuizActive />, inactive: <Quiz /> },
        'live-class': { active: <LiveClassActive />, inactive: <LiveClass /> },
        'location-course-category': {
            active: <LocationCourseActive />,
            inactive: <LocationCourse />,
        },
        'team-participant': {
            active: <TeamParticipantActive />,
            inactive: <TeamParticipant />,
        },
        'designation-course_category': {
            active: <DesignationCourseCategoryActive />,
            inactive: <DesignationCourseCategory />,
        },
        helpAdmin: {
            active: <Help />,
            inactive: <Help />,
        },
        // 'mandatory-course': {
        //     active: <Help />,
        //     inactive: <Help />,
        // },
        'mandatory-course': {
            active: <CourseActive />,
            inactive: <Course />,
        },
    };

    const links = {};
    const createInnerLinks = (linkProps) => {
        const { module, path, name } = linkProps;

        links[module] = links[module] ?? [];

        links[module].push(
            <NavLink
                key={path}
                to={'/' + path}
                className={({ isActive }) => {
                    return `${isActive ? 'text-info' : 'text-light'
                        } d-block my-2 small`;
                }}
            >
                {name}
            </NavLink>
        );
    };

    const createOuterLinks = (linkProps) => {
        const { path, name } = linkProps;

        links[name] = (
            <NavLink
                key={path}
                to={'/' + path}
                className='d-block my-1 py-3 small rounded-smooth text-white'
            >
                <Row>
                    <Col sm={1}>
                        {pathname === '/' + path
                            ? menuIconMap[path].active
                            : menuIconMap[path].inactive}
                    </Col>
                    <Col sm={10}>
                        <div className='ps-3'>{name}</div>
                    </Col>
                </Row>
            </NavLink>
        );
    };

    routes
        .filter((route) => !route.hiddenFromMenu)
        .forEach((route) => {
            if (!!route.module) {
                createInnerLinks(route);
            } else {
                createOuterLinks(route);
            }
        });

    return (
        // order of menu is based on order of routes
        <>
            <Accordion flush>
                {Object.keys(links).map((module, i) =>
                    !Array.isArray(links[module]) ? (
                        links[module]
                    ) : (
                        <Accordion.Item
                            eventKey={i}
                            key={i}
                            className='bg-transparent border-0'
                        >
                            <Accordion.Button className='bg-transparent px-0'>
                                {activeModule === module
                                    ? menuIconMap[module].active
                                    : menuIconMap[module].inactive}
                                <span className='ps-3 text-white small text-capitalize'>
                                    {module}
                                </span>
                            </Accordion.Button>
                            <Accordion.Body className='pt-2 pb-0 ps-4 ms-3 pe-0'>
                                {links[module]}
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                )}
            </Accordion>
            {user?.type?.toLowerCase() === 'admin' && (<NavLink
                to='/help'
                className='d-block my-4 py-3 small rounded-smooth text-white'
            >
                <Row>
                    <Col sm={1}>
                        <Help />
                    </Col>
                    <Col
                        sm={10}
                        className='ps-3'
                    >
                        Admin Guide
                    </Col>
                </Row>
            </NavLink>)}
        </>
    );
}

export default Menu;
