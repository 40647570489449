import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import images from '../../../../../src/asset/images/AdminUserGuide';

const CourseStructApproval = ({ direction = 'column' }) => {
    const [column, setColumn] = useState(direction === 'column');

    const steps = [
        {
            id: 242,
            para: 'Login as ADMIN to Approve the course structure',
            imageUrl: images.Admin_CourseStructApp_img_1,
        },
        {
            id: 243,
            para: 'Click on "Course" from the sidebar to see more options regarding the course.',
            imageUrl:
                images.Admin_CourseStructApp_img_2,
        },
        {
            id: 244,
            para: 'Clicking on the "Course Structure" module in the sidebar allows you to navigate to the Course Structure page.',
            imageUrl:
                images.Admin_CourseStructApp_img_3,
        },
        {
            id: 245,
            para: 'Here, we can see the list of Course Structures.',
            imageUrl:
                images.Admin_CourseStructApp_img_3,
        },
        {
            id: 246,
            para: 'To see the course structure, simply click on the "Structure" tab associated with the respective course.',
            imageUrl:
                images.Admin_CourseStructApp_img_4,
        },
        {
            id: 247,
            para: 'To approve the course structure, click on "Approve" for Live Class, Video, Document, and Quiz.',
            imageUrl:
                images.Admin_CourseStructApp_img_5,
        },
        {
            id: 248,
            para: 'Click on "Yes" to approve the content.',
            imageUrl:
                images.Admin_CourseStructApp_img_6,
        },
        {
            id: 249,
            para: 'By clicking the "Approved" tab you can see the Approved courses.',
            imageUrl:
                images.Admin_CourseStructApp_img_7,
        },
        {
            id: 250,
            para: 'Clicking on the "Courses" module in the sidebar allows you to navigate to the Courses page.',
            imageUrl:
                images.Admin_CourseStructApp_img_8,
        },
        {
            id: 251,
            para: 'Here the list of Draft courses will be displayed. Scroll horizontally to view the "publish" button.',
            imageUrl:
                images.Admin_CourseStructApp_img_9,
        },
        {
            id: 252,
            para: 'Click on "publish" to publish the course.',
            imageUrl:
                images.Admin_CourseStructApp_img_10,
        },
        {
            id: 253,
            para: 'Click on "Yes" to publish the course.',
            imageUrl:
                images.Admin_CourseStructApp_img_11,
        },
        {
            id: 254,
            para: 'Click on the "Published" tab to view the published courses.',
            imageUrl:
                images.Admin_CourseStructApp_img_12,
        },
        {
            id: 255,
            para: 'Here, you can see the newly published course structure.',
            imageUrl:
                images.Admin_CourseStructApp_img_13,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: column ? '#F3F2F2' : 'transparent',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <div style={{ display: direction !== 'row' && 'none' }}>
                <Heading
                    text='Course Structure Approval'
                    numberOfScreens={steps.length}
                />
            </div>
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: direction,
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            padding: '16px',
                            width: '60%',
                            display: direction === 'row' && 'none',
                        }}
                    >
                        <Heading
                            mb='0'
                            text='Course Structure Approval'
                            numberOfScreens={steps.length}
                        />
                    </div>
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={direction}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default CourseStructApproval;
