import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function LiveClassTopicForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const submitHandler = (values, { setSubmitting: setLoading }) => {
        setLoading(true);

        console.log(values);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setLoading(false));
    };

    const initialFormValues = updateValues || {
        LIVE_CLASS_TOPIC_NAME: '',
        LIVE_CLASS_TOPIC_DESCRIPTION: '',
    };
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                LIVE_CLASS_TOPIC_NAME: Yup.string().required(),
                LIVE_CLASS_TOPIC_DESCRIPTION: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormikControl
                        control='input'
                        required
                        name='LIVE_CLASS_TOPIC_NAME'
                    />
                    <FormikControl
                        control='textarea'
                        required
                        name='LIVE_CLASS_TOPIC_DESCRIPTION'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(LiveClassTopicForm);
