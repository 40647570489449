import React from 'react';
import { Col, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import avatar from '../../../asset/icons/UserAvatar.svg';

function TopStudents({ studentsList }) {
    return (
        <div className='mt-5'>
            <div style={{ fontWeight: '900' }} className='small mb-2'>
                Top Participants
            </div>
            <Row>
                {studentsList.length > 0 ? (
                    studentsList.map((studentDetail, i) => (
                        <Col md='3' className='mt-4'>
                            <StudentDetail
                                name={studentDetail.PARTICIPANT_NAME}
                                pic={studentDetail.DP ?? dummyData[0].DP}
                                username={
                                    studentDetail?.USERNAME ??
                                    dummyData[0].USERNAME
                                }
                                rank={i + 1}
                                taskDetails={{
                                    totalVideos:
                                        studentDetail?.totalVideos ??
                                        dummyData[0].totalVideos,
                                    completedVideos:
                                        studentDetail?.completedVideos ??
                                        dummyData[0].completedVideos,
                                    totalDocs:
                                        studentDetail?.totalDocs ??
                                        dummyData[0].totalDocs,
                                    completedDocs:
                                        studentDetail?.completedDocs ??
                                        dummyData[0].completedDocs,
                                    totalLiveClass:
                                        studentDetail?.totalLiveClass ??
                                        dummyData[0].totalLiveClass,
                                    completedLiveClass:
                                        studentDetail?.completedLiveClass ??
                                        dummyData[0].completedLiveClass,
                                    totalQuiz:
                                        studentDetail?.totalQuiz ??
                                        dummyData[0].totalQuiz,
                                    completedQuiz:
                                        studentDetail?.completedQuiz ??
                                        dummyData[0].completedQuiz,
                                }}
                            />
                        </Col>
                    ))
                ) : (
                    <div className='text-center fs-4 my-5'>
                        No top participants has been ranked
                    </div>
                )}
            </Row>
        </div>
    );
}

export default TopStudents;

const StudentDetail = ({ username, name, pic, rank, taskDetails }) => {
    return (
        <div className='bg-white rounded-smoother text-center p-3 small position-relative'>
            <div>
                <OverlayTrigger
                    placement='top'
                    overlay={
                        <Tooltip>
                            <TaskProgress
                                name={'Videos'}
                                total={taskDetails.totalVideos}
                                completed={taskDetails.completedVideos}
                                variant='#BD8DFF'
                            />
                            <TaskProgress
                                name={'Documents'}
                                total={taskDetails.totalDocs}
                                completed={taskDetails.completedDocs}
                                variant='#A2EA74'
                            />
                            <TaskProgress
                                name={'Live Class'}
                                total={taskDetails.totalLiveClass}
                                completed={taskDetails.completedLiveClass}
                                variant='#FF447E'
                            />
                            <TaskProgress
                                name={'Quiz'}
                                total={taskDetails.totalQuiz}
                                completed={taskDetails.completedQuiz}
                                variant='#F4D786'
                            />
                        </Tooltip>
                    }
                >
                    <div
                        className='rounded-circle mx-auto'
                        style={{
                            backgroundImage: 'url(' + pic + ')',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: '80px',
                            width: '80px',
                        }}
                    />
                </OverlayTrigger>
            </div>
            <div className='fw-bold mt-3'>{name}</div>
            <div className='small text-muted '>{username}</div>
            <div
                className='d-flex align-items-center justify-content-center rounded-circle fw-bold fs-5'
                style={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: '#FFD0A3',
                    position: 'absolute',
                    top: '-20px',
                    left: 'calc(100% - 20px)',
                }}
            >
                {rank}
            </div>
        </div>
        // </OverlayTrigger>
    );
};

const TaskProgress = ({ name, total, completed, variant }) => (
    <Stack className='p-2' style={{ width: '100px' }}>
        <div className='small d-flex  text-white'>
            <span className='small '>{name}</span>
            <span className='ms-auto small'>{completed}</span>
        </div>
        <div
            className='position-relative rounded-smooth w-100 overflow-hidden '
            style={{
                height: '4px',
                backgroundColor: '#323232',
            }}
        >
            <div
                className='position-absolute rounded-smooth'
                style={{
                    width: (completed / total) * 100 + '%',
                    height: '4px',
                    backgroundColor: variant,
                }}
            />
        </div>
    </Stack>
);

const dummyData = [
    {
        PARTICIPANT_NAME: 'Error',
        DP: avatar,
        USERNAME: 'Error',
        totalVideos: 'Error',
        completedVideos: 'Error',
        totalDocs: 'Error',
        completedDocs: 'Error',
        totalLiveClass: 'Error',
        completedLiveClass: 'Error',
        totalQuiz: 'Error',
        completedQuiz: 'Error',
    },
];
