import React, { useState } from 'react';

import QuizImage from './QuizImage';
import { useEffect } from 'react';
import { Button, FormControl, FormSelect } from 'react-bootstrap';
import { ReactComponent as Right } from '../../../asset/icons/RightArrow.svg';
import { ReactComponent as Left } from '../../../asset/icons/LeftArrow.svg';

import ReactPaginate from 'react-paginate';
import '../../../App.css';

function QuestionImageList({
    onClose,
    updateValues,
    handleUpload,
    medias = [],
}) {
    const [quizimage, setQuizImage] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [searchField, setSearchField] = useState('');

    const [sortKey, setSortKey] = useState('sortedby');

    const [filteredData, setFilteredData] = useState(medias);

    useEffect(() => {
        const filteredImageList = medias.filter((image) => {
            return image.MEDIA_DESC.toLowerCase().includes(
                searchField.toLowerCase()
            );
        });
        setFilteredData(filteredImageList);
        const sortedMediaArray = filteredImageList.sort((a, b) => {
            if (sortKey === 'approveddate') {
                return new Date(b.APPROVEDDATE) - new Date(a.APPROVEDDATE);
            } else if (sortKey === 'mediadesc') {
                return a.MEDIA_DESC.localeCompare(b.MEDIA_DESC);
            } else if (sortKey === 'instructorname') {
                return a.Instructor_Name.localeCompare(b.Instructor_Name);
            }
            // Default to sorting by approved date if sortKey is not recognized

            return new Date(b.APPROVEDDATE) - new Date(a.APPROVEDDATE);
        });
        setFilteredData(sortedMediaArray);
    }, [searchField, sortKey, medias]);

    const handleChange = (e) => {
        setSearchField(e.target.value);
    };

    const handleClick = (image) => {
        setQuizImage(image);
        setSelectedImage(image);
    };

    const handleSortChange = (e) => {
        setSortKey(e.target.value);
    };

    // pagination

    const itemsPerPage = 8;

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = filteredData.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(filteredData.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredData.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <div>
            Total Images: {medias.length}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    paddingBottom: '12px',
                    borderBottom: '1px solid #D9D9D9',
                }}
            >
                {console.log(sortKey, 'sorted key')}
                <span
                    style={{
                        display:
                            sortKey === 'approveddate'
                                ? 'inline-block'
                                : 'none',
                        fontSize: '19px',
                        fontWeight: '500',
                    }}
                >
                    Recents
                </span>

                <FormControl
                    type='search'
                    placeholder='Search'
                    onChange={handleChange}
                    style={{
                        width: '32%',
                        marginRight: '36px',
                        borderRadius: '6px',
                        borderColor: '#CBD5E1',
                    }}
                    className='ms-auto bg-white '
                />

                <FormSelect
                    value={sortKey}
                    onChange={handleSortChange}
                    style={{
                        width: '30%',
                        borderRadius: '6px',
                        borderColor: '#CBD5E1',
                    }}
                >
                    <option value='approveddate'>Approved Date</option>

                    {/* <option value='sortBy'>Sort By</option> */}
                    <option value='instructorname'>Owner</option>
                    <option value='mediadesc'>Name</option>
                    {/* <option value='approveddate'>Approved Date</option> */}
                    <option value=''>Size</option>
                </FormSelect>
            </div>
            <>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {currentItems.length > 0 ? (
                        currentItems.map((image) => {
                            return (
                                <div
                                    style={{
                                        width: '25%',
                                        paddingRight: '25px',
                                    }}
                                >
                                    <QuizImage
                                        medias={filteredData}
                                        image={image}
                                        handleClick={(image) => {
                                            handleClick(image);
                                        }}
                                        isSelected={selectedImage === image}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <div className='py-5 display-5 text-center w-100'>
                            No Images found
                        </div>
                    )}
                </div>
                <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    className='mt-2'
                >
                    <ReactPaginate
                        breakLabel='...'
                        nextLabel={
                            <span>
                                Next &nbsp;&nbsp;
                                <Right />
                            </span>
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        previousLabel={
                            <span>
                                <Left />
                                &nbsp;&nbsp; Prev
                            </span>
                        }
                        renderOnZeroPageCount={null}
                        containerClassName='paginationContainer'
                        pageLinkClassName='page-num'
                        previousLinkClassName='pagePrev'
                        nextLinkClassName='pagenext'
                        activeClassName='activeNumber'
                        activeLinkClassName='activeLink'
                        pageClassName='pageNumber'
                    />
                </div>
            </>
            <div
                style={{
                    display: 'flex',
                    borderTop: '1px solid #D9D9D9',
                    paddingTop: '20px',
                }}
            >
                <div
                    style={{
                        marginLeft: 'auto',
                        width: '30%',
                    }}
                >
                    <Button
                        onClick={() => handleUpload(quizimage)}
                        style={{
                            width: '48%',
                            borderRadius: '6px',
                            background: '#107BFF',
                            color: '#ffffff',
                            border: 'none',
                            padding: '3px',
                        }}
                    >
                        Upload
                    </Button>
                    <Button
                        onClick={onClose}
                        style={{
                            width: '48%',
                            marginLeft: '5px',
                            borderRadius: '6px',
                            background: 'white',
                            color: '#107BFF',
                            border: '1px solid #107BFF',
                            padding: '3px',
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default QuestionImageList;
