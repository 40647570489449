import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide';

const Courses = () => {
    const steps = [
        {
            id: 85,
            para: 'Click on "Course" from the sidebar to see more options regarding Course.',
            imageUrl: images.Admin_Courses_img_1,
        },
        {
            id: 86,
            para: 'Clicking on "Courses" module in the sidebar allows you to navigate to the Courses page.',
            imageUrl: images.Admin_Courses_img_2,
        },
        {
            id: 87,
            para: 'Here, we can see the list of Courses.',
            imageUrl: images.Admin_Courses_img_3,
        },
        {
            id: 88,
            para: 'To add course, Click on "Add Course".',
            imageUrl: images.Admin_Courses_img_4,
        },
        {
            id: 89,
            para: 'Fill the form with appropriate details to add the course.',
            imageUrl: images.Admin_Courses_img_5,
        },
        {
            id: 90,
            para: 'Press the "Submit" button to successfully add the course.',
            imageUrl: images.Admin_Courses_img_6,
        },
        {
            id: 91,
            para: 'Here, you can see the newly added Course.',
            imageUrl: images.Admin_Courses_img_7,
        },
        {
            id: 92,
            para: 'Scroll horizontally to explore more options.',
            imageUrl: images.Admin_Courses_img_8,
        },
        {
            id: 93,
            para: 'Once the course structure and course content is added, Admin can publish the course by clicking "Publish" button.',
            imageUrl: images.Admin_Courses_img_9,
        },
        {
            id: 94,
            para: 'Click the "Retire" button to Retire the course.',
            imageUrl: images.Admin_Courses_img_10,
        },
        {
            id: 95,
            para: 'Click on "Yes" to retire the course.',
            imageUrl: images.Admin_Courses_img_11,
        },
        {
            id: 96,
            para: 'To modify the Course details click the "Edit" button.',
            imageUrl: images.Admin_Courses_img_12,
        },
        {
            id: 97,
            para: 'Update the Course details to modify the Course data.',
            imageUrl: images.Admin_Courses_img_13,
        },
        {
            id: 98,
            para: 'Press the "Submit" button to successfully update the Course.',
            imageUrl: images.Admin_Courses_img_14,
        },
        {
            id: 99,
            para: 'Once the course structure is added by the Instructor, Admin can see the course structures by clicking on "Structure".',
            imageUrl: images.Admin_Courses_img_15,
        },
        {
            id: 100,
            para: 'Click on the "Published" tab to view the published courses. And the published courses are listed here.',
            imageUrl:
                images.Admin_Courses_img_16,
        },
        {
            id: 101,
            para: 'Structure of published courses can be viewed by clicking "Structure".',
            imageUrl:
                images.Admin_Courses_img_17,
        },
        {
            id: 102,
            para: 'Click on the "Under Review" tab to view the course structure under review.',
            imageUrl:
                images.Admin_Courses_img_18,
        },
        {
            id: 103,
            para: 'Click on the "Approved" tab to view the approved course structure.',
            imageUrl:
                images.Admin_Courses_img_19,
        },
        {
            id: 104,
            para: 'Click on the "Rejected" tab to view the Rejected course structure.',
            imageUrl:
                images.Admin_Courses_img_20,
        },
        {
            id: 105,
            para: 'Click on "Retire" button to retire the published courses.',
            imageUrl:
                images.Admin_Courses_img_21,
        },
        {
            id: 106,
            para: 'Click on the "Retired" tab to view the retired courses.',
            imageUrl:
                images.Admin_Courses_img_22,
        },
        {
            id: 107,
            para: 'Click on "Structure" to view the structure of retired courses.',
            imageUrl:
                images.Admin_Courses_img_23,
        },
        {
            id: 108,
            para: 'Click the "Delete" button to delete the retired course.',
            imageUrl:
                images.Admin_Courses_img_24,
        },
        {
            id: 109,
            para: 'Click on the "All Courses" tab to view all courses.',
            imageUrl:
                images.Admin_Courses_img_25,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading text='Courses' numberOfScreens={steps.length} />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default Courses;
