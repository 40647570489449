import WithModal from './WithModal';
import Warning from './Warning';
import { ReactComponent as Delete } from '../asset/icons/Delete.svg';

function DeleteWithWarning({
    title,
    endpoint,
    configBody,
    onAfterDelete,
    disabled,
}) {
    return (
        <WithModal
            modalTitle={'Delete ' + title}
            modalAttrs={{ size: 'sm' }}
            disabled={disabled}
            renderModalBody={(closeModal) => (
                <Warning
                    onAfterAction={() => {
                        closeModal();
                        onAfterDelete();
                    }}
                    onCancel={closeModal}
                    endpoint={endpoint}
                    configBody={configBody}
                />
            )}
        >
            <Delete />
        </WithModal>
    );
}

export default DeleteWithWarning;
