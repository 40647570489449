import axios from "axios";
import endpoints from "../../../services/endpoints";

export function startLiveClass(body) {

    return axios.post(endpoints.liveclass.start, body).then((response) => {
        if (response.data.responseData.response.returncode === 'SUCCESS')
            return true;
        return false;
    });
}

export function joinLiveClass(roomId, userName,classId) {
    let body = {
        LIVE_CLASS_ROOM_ID: roomId,
        INSTRUCTOR_NAME: userName,
        LIVE_COURSE_CLASS_ID: classId,
    };
    return axios.post(endpoints.liveclass.join, body);
}
export function processLiveClassVideo(body) {
    return axios.post(endpoints.liveclass.processVideoRecording, body);
}
