import React from 'react';
import { useLocation } from 'react-router-dom';
import CRUDTable from '../../../components/table/CRUDTable';
import WithModal from '../../../components/WithModal';
import { useAuthenticationState } from '../../../context/Auth.context';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import Chat from './Chat';

function MediaReviews() {
    const { state: assignedMediaId } = useLocation();
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosPost(endpoints.mediaAssignReview.list, {
        ASSIGNED_MEDIA_ID: assignedMediaId,
        INSTRUCTOR_ID: user.id,
    });

    const cellModifier = {
        Conversation: ({ row }) => (
            <WithModal
                modalTitle={'Reply to ' + row.original.PARTICIPANT_NAME}
                modalAttrs={{ size: 'md' }}
                renderModalBody={(closeModal) => (
                    <Chat
                        onAfterAction={() => {
                            closeModal();
                        }}
                        mediaId={assignedMediaId}
                        participantId={row.original.PARTICIPANT_ID}
                        userId={user.id}
                        onCancel={closeModal}
                    />
                )}
            >
                <span className='text-info' type='button'>
                    Open Conversation
                </span>
            </WithModal>
        ),
    };

    return (
        <>
            <CRUDTable
                immutable
                irremovable
                insertable={false}
                {...fetchUtils}
                {...viewProps.MediaReviews}
                cellModifier={cellModifier}
                formSize='md'
            />
        </>
    );
}

export default MediaReviews;
