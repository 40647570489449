import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import TeamsForm from './TeamsForm';
import viewProps from '../viewProps';

function Teams() {
    const fetchUtils = useAxiosPost(endpoints.teams.selectBy, {
        selectcolumns: '*',
        wherecondition: '1=1',
    });

    return (
        <>
            <CRUDTable
                endpoints={endpoints.teams}
                Form={TeamsForm}
                {...viewProps.team}
                //irremovable
                {...fetchUtils}
                formSize='md'
            />
        </>
    );
}

export default Teams;
