// import { faSearch } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import { ReactComponent as Search } from '../../asset/icons/Search.svg';

const borderRadius = '1.2em';
export const GlobalFilter = ({ filter, setFilter, ...props }) => {
    const [inputValue, setInputValue] = useState(filter);

    const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
    }, 400);

    return (
        <div className='d-flex'>
            <div className='table-search'>
                <FormControl
                    value={inputValue || ''}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder='Search...'
                    {...props}
                />
            </div>
            <span
                style={{
                    background: 'rgb(211 215 219)',
                    width: '30px',
                    height: '30px',
                    display: 'inline-flex',
                    borderRadius: '2rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '-14px',
                    zIndex: '2',
                }}
            >
                <Search />
            </span>
        </div>
    );
};
