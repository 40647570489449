export const isIsoDate = (str) => {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str); 
    return d.toISOString()===str;
}
export const checkIsoAndReturnYMDFormat = str =>{
    if(isIsoDate(str)){
        return str.slice(0,10)
    }else{
        return str
    }
}
export const getDays = (startDate,endDate) => {

    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = date2 - date1;
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}