import { Field, ErrorMessage, getIn } from 'formik';
import React from 'react';
import { Form as BSForm } from 'react-bootstrap';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function PhoneNumberInput({
    name,
    onChange,
    label,
    className = 'mb-3',
    size = 'md',
    required = false,
    ErrorFeedback,
    validityClass,
    fontSize,
    color,
    fontWeight,
    labelProvider,
    children,
    ...props
}) {
    return (
        <BSForm.Group className={className} controlId={name}>
            <Field name={name}>
                {({
                    field,
                    form: { errors, values, touched, setFieldValue },
                }) => (
                    <>
                        <BSForm.Label>
                            {label || labelProvider[name] || name}
                            {required && (
                                <span
                                    className={`small ${validityClass(
                                        getIn(errors, name),
                                        getIn(values, name),
                                        getIn(touched, name)
                                    )}`}
                                >
                                    *
                                </span>
                            )}
                        </BSForm.Label>

                        <PhoneInputWithCountrySelect
                            {...field}
                            className='mb-0'
                            required={required}
                            countryOptionsOrder={['IN', 'GB', 'AE', '|', '...']}
                            defaultCountry='IN'
                            addInternationalOption={false}
                            focusInputOnCountrySelection={false}
                            {...props}
                            value={field.value}
                            initialValueFormat='national'
                            onChange={(value) => {
                                if (onChange) {
                                    onChange(value);
                                    return;
                                }
                                if (value === undefined) return;
                                setFieldValue(name, value);
                            }}
                            autoComplete='off'
                        />
                    </>
                )}
            </Field>

            <ErrorMessage name={name} component={ErrorFeedback} />
        </BSForm.Group>
    );
}

export default PhoneNumberInput;
