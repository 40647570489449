import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function LocationForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const initialFormValues = updateValues || {
        Location_NAME: '',
        LocationTAG: '',
    };

    const submitHandler = (values, { setSubmitting: setLoading }) => {
        setLoading(true);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setLoading(false));
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Location_NAME: Yup.string()
                    .trim()
                    .required('Location Name is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces are allowed'
                    )
                    .notOneOf([''], 'Location Name cannot be empty'),
                LocationTAG: Yup.string()
                    .trim()
                    .required('Location Tag is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces are allowed'
                    )
                    .notOneOf([''], 'Location Tag cannot be empty'),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormikControl
                        control='input'
                        required
                        name='Location_NAME'
                        label='Location Name'
                    />
                    <FormikControl
                        control='input'
                        required
                        name='LocationTAG'
                        label='Location Tag'
                    />
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(LocationForm);
