import axios from 'axios';
import endpoints from '../../../services/endpoints';
import toast from 'react-hot-toast';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';

async function assignToCourse({ body, endpoint }) {
    return await axios.post(endpoint, body);
}
const generatePriorityId = async (courseData) => {
    return axios
        .post(endpoints.courseStructure.selectBy, {
            selectcolumns: '*',
            wherecondition: `Course_ID = ${courseData.courseId} AND
                        Course_Category_ID = ${courseData.courseCategoryId} AND
                        Version_ID = ${courseData.versionId}`,
        })
        .then((res) => {
            const bindingIds = res.data.responseData.map(
                (obj) => obj.Course_Structure_Priority_ID
            );
            let id = 1;

            if (bindingIds.length > 0) {
                id = Math.max(...bindingIds) + 1;
            }
            return id;
        })
        .catch((err) => {
            return 1;
        });
};

const createQuiz = async (body) => {
    return axios
        .post(endpoints.quizAssignment.addQuiz, body)
        .then((res) => {
            console.log(res);
            return res.data.responseData.Quiz_ID;
        })
        .catch((err) => {
            console.log(err);
            toast.error(extractErrorFromRes(err));
            return;
        });
};

export { assignToCourse, generatePriorityId, createQuiz };
