import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function DesignationForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const submitHandler = (values, { setSubmitting: setLoading }) => {
        setLoading(true);

        console.log(values);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setLoading(false));
    };

    const initialFormValues = updateValues || {
        DESIGNATION_NAME: '',
        DESIGNATIONTAG: '',
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                DESIGNATION_NAME: Yup.string()
                    .trim()
                    .required('Designation Name is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces are allowed'
                    )
                    .notOneOf([''], 'Designation Name cannot be empty'),
                DESIGNATIONTAG: Yup.string()
                    .trim()
                    .required('Designation Tag is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces are allowed'
                    )
                    .notOneOf([''], 'Designation Tag cannot be empty'),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormikControl
                        control='input'
                        required
                        name='DESIGNATION_NAME'
                        label='Designation Name'
                    />
                    <FormikControl
                        control='input'
                        required
                        name='DESIGNATIONTAG'
                        label='Designation Tag'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(DesignationForm);
