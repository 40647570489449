import React from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarDropdown from './SidebarDropdown';
import { ReactComponent as LoginIcon } from '../../../../asset/icons/userGuide/login.svg';
import { ReactComponent as KeyIcon } from '../../../../asset/icons/userGuide/key_vertical.svg';
import { ReactComponent as BookIcon } from '../../../../asset/icons/userGuide/auto_stories.svg';
import { ReactComponent as HatIcon } from '../../../../asset/icons/userGuide/school.svg';
import { ReactComponent as ProgramIcon } from '../../../../asset/icons/userGuide/Program.svg';
import { ReactComponent as LiveClassIcon } from '../../../../asset/icons/userGuide/Liveclass.svg';
import { ReactComponent as BadgeIcon } from '../../../../asset/icons/userGuide/badge.svg';
import { ReactComponent as AlertIcon } from '../../../../asset/icons/userGuide/add_alert.svg';
import { ReactComponent as LeaderBoardIcon } from '../../../../asset/icons/userGuide/social_leaderboard.svg';
import { ReactComponent as StarIcon } from '../../../../asset/icons/userGuide/family_star.svg';
import { ReactComponent as LogoutIcon } from '../../../../asset/icons/userGuide/logout.svg';

const links = [
    {
        path: 'helpLogin',
        name: 'Login',
        child: ['Login'],
        icon: <LoginIcon />,
    },
    {
        path: 'helpForgotPassword',
        name: 'Forgot Password',
        child: ['Forgot Password'],
        icon: <KeyIcon />,
    },
    {
        path: 'helpDashboard',
        name: 'Dashboard',
        child: ['Overview', 'Statistics', 'Activity monitor'],
        icon: <BadgeIcon />,
    },
    {
        path: 'helpNotification',
        name: 'Notification',
        child: ['Notification'],
        icon: <AlertIcon />,
    },
    {
        path: 'change-password',
        name: 'Change password',
        child: ['Change password'],
        icon: <KeyIcon />,
    },
    {
        path: 'department',
        name: 'Department',
        child: ['Department details'],
        icon: <BadgeIcon />,
    },
    {
        path: 'course-category',
        name: 'Course category',
        child: ['Course category details'],
        icon: <BookIcon />,
    },
    {
        path: 'dept-course-category-mapping',
        name: 'Department-course category mapping',
        child: ['Department-course category mapping details'],
        icon: <BadgeIcon />,
    },
    {
        path: 'participants',
        name: 'Participants',
        child: ['Participant details', 'Bulk-upload participant details'],
        icon: <HatIcon />,
    },
    {
        path: 'instructors',
        name: 'Instructors',
        child: ['Instructor details'],
        icon: <StarIcon />,
    },
    {
        path: 'courses',
        name: 'Courses',
        child: [
            'Course details',
            'Publish and retire course',
            'Course structure',
        ],
        icon: <BookIcon />,
    },
    {
        path: 'instructor-course',
        name: 'Courses by instructor',
        child: ['Course-instructor mapping details'],
        icon: <BadgeIcon />,
    },
    {
        path: 'media-lib-approval',
        name: 'Media library approval',
        child: ['Media library approval'],
        icon: <ProgramIcon />,
    },
    {
        path: 'course-struct-approval',
        name: 'Course structure approval',
        child: ['Course structure approval'],
        icon: <BookIcon />,
    },
    {
        path: 'program',
        name: 'Program',
        child: ['Program details'],
        icon: <ProgramIcon />,
    },
    {
        path: 'live-class-recording',
        name: 'Live class recording',
        child: ['View live class recordings'],
        icon: <LiveClassIcon />,
    },
    {
        path: 'logout',
        name: 'Log out',
        child: ['Log out'],
        icon: <LogoutIcon />,
    },
];

function Sidebar() {
    const navigate = useNavigate();

    return (
        <div
            style={{
                height: '100vh',
                width: '245px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.3)',
                fontSize: '14px',
                overflow: 'auto',
                zIndex: '900',
                backgroundColor: 'whiteGray',
                position: 'fixed',
                top: '0',
                left: '0',
                paddingTop: '10%',
            }}
        >
            <div
                style={{
                    borderRadius: '12px',
                    padding: '10px',
                    marginBottom: '8px',
                    fontSize: '14px',
                    fontWeight: '400',
                    marginLeft: '16px',
                    marginRight: '16px',
                    paddingRight: '10px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    backgroundColor: '#1C1C1C0D',
                }}
            >
                Overview
            </div>
            <div style={{ textAlign: 'center', margin: '0 5px' }}>
                {links.map((link) => (
                    <SidebarDropdown
                        key={link.path}
                        label={link.name}
                        path={link.path}
                        child={link.child}
                        icon={link.icon}
                    />
                ))}
            </div>
        </div>
    );
}

export default Sidebar;
