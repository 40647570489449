import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../../src/asset/images/AdminUserGuide';

const LiveClassRecording = ({ direction = 'column' }) => {
    const [column] = useState(direction === 'column');
    const steps = [
        {
            id: 311,
            para: 'To view live class recordings go to live class section.',
            imageUrl:
                images.Admin_LiveClassRec_img_1,
        },
        {
            id: 312,
            para: 'The recordings of the live class will be available after the session ends. Click "CREATE MP4" to begin creating the video.',
            imageUrl:
                images.Admin_LiveClassRec_img_2,
        },
        {
            id: 313,
            para: 'After the video is created, it will be available for viewing and downloading. Click the "VIEW AND DOWNLOAD" button to access it.',
            imageUrl:
                images.Admin_LiveClassRec_img_3,
        },
        {
            id: 314,
            para: 'You can play the recording and download the file by clicking on the three-dot icon.',
            imageUrl:
                images.Admin_LiveClassRec_img_4,
        },
        {
            id: 315,
            para: 'Click on download to download the video.',
            imageUrl:
                images.Admin_LiveClassRec_img_5,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Live class recording'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default LiveClassRecording;
