import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide';

const steps = [
    {
        id: 37,
        para: 'Clicking on the "Course Categories" module in the sidebar allows you to navigate to the Course Categories page.',
        imageUrl: images.Admin_CourseCategory_img_1,
    },
    {
        id: 38,
        para: 'Here, we can see the list of Course Categories.',
        imageUrl: images.Admin_CourseCategory_img_2,
    },
    {
        id: 39,
        para: 'To add a Course Category, Click on "Add Course Category".',
        imageUrl: images.Admin_CourseCategory_img_3,
    },
    {
        id: 40,
        para: 'Fill the form with "Course Category" name.',
        imageUrl: images.Admin_CourseCategory_img_4,
    },
    {
        id: 41,
        para: 'Press the "Submit" button to successfully add the Course Category.',
        imageUrl: images.Admin_CourseCategory_img_5,
    },
    {
        id: 42,
        para: 'Here, you can see the newly added Course Category.',
        imageUrl: images.Admin_CourseCategory_img_6,
    },
    {
        id: 43,
        para: 'Click on the "Download Icon" to download the Course category details as an Excel file.',
        imageUrl: images.Admin_CourseCategory_img_7,
    },
    {
        id: 44,
        para: 'To modify the Course Category, click the "Edit" button.',
        imageUrl: images.Admin_CourseCategory_img_8,
    },
    {
        id: 45,
        para: 'Update the Course Category details to modify the Course Category details.',
        imageUrl: images.Admin_CourseCategory_img_9,
    },
    {
        id: 46,
        para: 'Press the "Submit" button to successfully update the Course Category.',
        imageUrl: images.Admin_CourseCategory_img_10,
    },
    {
        id: 47,
        para: 'Click the "Delete" button to delete the Course Category.',
        imageUrl: images.Admin_CourseCategory_img_11,
    },
];

const CourseCategory = ({ direction = 'column' }) => {
    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Course category'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default CourseCategory;
