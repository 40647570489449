import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function LiveClassMediaTopicMappingForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response, loading } = useAxiosGetMultiple({
        topicList: endpoints.liveClassMedia.topicsList,
        mediaList: endpoints.liveClassMedia.mediaList,

    });

    const { topicList, mediaList } = response;

    const submitHandler = (values, { setSubmitting }) => {
        setSubmitting(true);
        console.log(values);
        let formData = { MEDIA_ID: values?.MEDIA_ID, TOPIC_ID: values?.LIVE_CLASS_TOPIC_ID }
        axios.post(endpoint, formData)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        LIVE_CLASS_TOPIC_ID: '',
        MEDIA_ID: [],
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOptions = {
        LIVE_CLASS_TOPIC_ID_SELECTED: topicList.find(
            (row) =>
                Number(row.LIVE_CLASS_TOPIC_ID) ===
                Number(initialFormValues.LIVE_CLASS_TOPIC_ID)
        ),
        MEDIA_ID_SELECTED: mediaList.filter(
            (row) =>
                !!initialFormValues.MEDIA_ID.find(
                    (row2) =>
                        Number(row2.MEDIA_ID) ===
                        Number(row.MEDIA_ID)
                )
        ),

    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                LIVE_CLASS_TOPIC_ID: Yup.string().required(),
                MEDIA_ID: Yup.array()
                    .min(1, 'Select at least one media.')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values }) => (
                <Form>
                    {!updateValues &&
                        <FormikControl
                            control='select-advanced'
                            required
                            name='LIVE_CLASS_TOPIC_ID'
                            label='Live Class Topics'
                            options={topicList}
                            optionValue='LIVE_CLASS_TOPIC_ID'
                            defaultValue={preSelectedOptions.LIVE_CLASS_TOPIC_ID_SELECTED}
                            optionLabel='LIVE_CLASS_TOPIC_NAME'
                            styles={{ borderColor: "#E2EFFF", borderWidth: 6, height:52 }}
                        />
                    }

                    <FormikControl
                        control='list-form-input'
                        required
                        name='MEDIA_ID'
                        label='Live Class Medias'
                        options={mediaList}
                        optionValue='MEDIA_ID'
                        optionLabel='MEDIA_DESC'
                        defaultValue={preSelectedOptions.MEDIA_ID_SELECTED}
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {(isSubmitting || loading) && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(LiveClassMediaTopicMappingForm);
