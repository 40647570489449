import { Tab } from "bootstrap";
import React from "react";
import {Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useAxiosPost } from "../../../services/axiosHooks";
import endpoints from "../../../services/endpoints";
import Approved from "./Approved";
import ForReview from "./ForReview";
import Rejected from "./Rejected";

function CourseStructureApproval() {
    const { state: courseData } = useLocation();

    const fetchUtils = useAxiosPost(endpoints.courseStructure.selectBy, {
        selectcolumns: "*",
        wherecondition: `Course_ID = ${courseData.courseId} AND 
        Course_Category_ID = ${courseData.courseCategoryId} AND 
        Version_ID = ${courseData.versionId}`,
    });

    const forReview = fetchUtils.response.filter(
        (structure) =>
            Number(structure.Course_Structure_Status_ID) === 2 &&
            Number(structure.Reviewed_Status) !== 1
    );
    const approved = fetchUtils.response.filter(
        (structure) => Number(structure.Course_Structure_Status_ID) === 3
    );
    const rejected = fetchUtils.response.filter(
        (structure) =>
            Number(structure.Course_Structure_Status_ID) === 2 &&
            Number(structure.Reviewed_Status) === 1
    );

    return (
        <Tabs fill className='mx-5' mountOnEnter>
            <Tab eventKey='review' title='Under Review'>
                <ForReview response={forReview} fetchUtils={fetchUtils} />
            </Tab>
            <Tab eventKey='approved' title='Approved'>
                <Approved response={approved} fetchUtils={fetchUtils} />
            </Tab>
            <Tab eventKey='rejected' title='Rejected'>
                <Rejected response={rejected} fetchUtils={fetchUtils} />
            </Tab>
        </Tabs>
    );
}

export default CourseStructureApproval;
