import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import ParticipantForm from './ParticipantForm';
import viewProps from '../viewProps';
import BulkUpload from '../../../components/BulkUpload';
import ParticipantAvatar from '../../../asset/icons/InsAvatar.svg';

function Participant() {
    const fetchUtils = useAxiosPost(endpoints.participant.selectBy, {
        selectcolumns: '*',
        wherecondition: '1=1',
    });

    const cellModifier = {
        DATEOFJOINING: ({ value }) => new Date(value).toDateString(),
        Is_Active: ({ value }) => {
            if (Number(value) === 1) {
                return 'Active';
            }
            return 'Inactive';
        },
        DP: ({ value }) => {
            let pic = value ?? ParticipantAvatar;
            return (
                <div
                    style={{
                        width: '50px',
                        height: '50px',
                        backgroundImage: 'url(' + encodeURI(pic) + ')',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                    }}
                ></div>
            );
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.participant}
                Form={ParticipantForm}
                {...viewProps.Participant}
                // irremovable
                {...fetchUtils}
                cellModifier={cellModifier}
                formSize='md'
                headerExtras={
                    <BulkUpload
                        confirmNewItemsUrl={endpoints?.participant?.bulkUploadConfirm}
                        uploadUrl={endpoints?.participant?.bulkUpload}
                        templateUrl={endpoints?.participant?.bulkUploadTemplate}
                        afterUpload={fetchUtils.reFetch}
                        templateExtension='.xlsx'
                        filename='Participant Details'
                        errorObjects={[{
                            errorType: 'repeatEmail',
                            errorArea: 'Row no:',
                            errorNum: 'row',
                            errorField: 'PARTICIPANT_EMAIL',
                            errorMsg: 'Email repeated xlsx template: '
                        },
                        {
                            errorType: 'invalidEmail',
                            errorArea: 'Row no:',
                            errorNum: 'row',
                            errorField: 'PARTICIPANT_EMAIL',
                            errorMsg: 'Email already in use: ',
                        },
                        {
                            errorType: 'invalidHr',
                            errorArea: 'Row no:',
                            errorNum: 'row',
                            errorField: 'HR_EMP_NO',
                            errorMsg: 'An HR EMP NO should be of a valid user: ',
                        },
                        {
                            errorType: 'invalidManager',
                            errorArea: 'Row no:',
                            errorNum: 'row',
                            errorField: 'MANAGER_EMP_NO',
                            errorMsg: 'A Manager EMP NO should be of a valid user: ',
                        },
                        {
                            errorType: 'allErrorResponseData',
                            errorArea: 'Row no:',
                            errorNum: 'row',
                            errorColumn: 'column',
                            josonErrorMsg: 'message'
                        },
                        {
                            errorType: 'errorColumnValues',
                            errorArea: 'Row no:',
                            errorNum: 'row',
                            errorColumn: 'column',
                            josonErrorMsg: 'message'
                        }
                        ]}
                        
                    />
                }
            />
        </>
    );
}

export default Participant;
