import React from 'react';
import { Button } from 'react-bootstrap';

function MandatoryCourseSubmitBtn(props) {
    const { onCancel, submitBtnName, cancelBtnName } = props;
    const containerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
    };
    return (
        <>
            <div
                className='text-center pt-4'
                style={containerStyle}
            >
                <Button
                    className='px-3 py-1 rounded-smooth'
                    variant='outline-light'
                    onClick={onCancel}
                    style={{
                        width: '48%',
                        border: ' 1px solid #107BFF',
                        color: '#107BFF',
                        fontSize: '16px',
                        fontWeight: '500',
                    }}
                >
                    <span className='small'>{cancelBtnName || 'Cancel'}</span>
                </Button>
                <Button
                    className='px-3 py-1 rounded-smooth'
                    type='submit'
                    style={{
                        width: '48%',
                        background: '#107BFF',
                        border: 'none',
                        fontSize: '16px',
                        fontWeight: '600',
                    }}
                >
                    <span className='small'>{submitBtnName || 'Submit'}</span>
                </Button>
            </div>
        </>
    );
}

export default MandatoryCourseSubmitBtn;
