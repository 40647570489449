import CRUDTable from '../../../components/table/CRUDTable';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import WithModal from '../../../components/WithModal';
import PDFPreview from '../../../components/PDFPreview';
import VideoPreview from '../../../components/VideoPreview';
import { ReactComponent as PlayButton } from '../../../asset/icons/PlayButton.svg';
import { ReactComponent as ImgPreview } from '../../../asset/icons/ImgPreview.svg';
import { ReactComponent as Document } from '../../../asset/icons/Document.svg';
import { useAuthenticationState } from '../../../context/Auth.context';
// import MediaLibraryForm from '../../faculty/mediaLibrary/MediaLibraryForm';
import { Button, Image } from 'react-bootstrap';
import { format } from 'date-fns';
import MediaUpload from '../../faculty/mediaLibrary/mediaForm/Index';

function Approved({ fetchUtils }) {
    const { user } = useAuthenticationState();

    const cellModifier = {
        EXPIRY_DATE: ({ value }) => {
            return value ? format(new Date(value), 'dd/MM/yyyy') : 'NA';
        },
        MEDIA_URL: ({ row, value }) => {
            switch (row.original.MEDIA_TYPE) {
                case 1:
                    return (
                        <WithModal
                            modalAttrs={{ backdrop: 'none' }}
                            modalTitle={row.original.MEDIA_DESC}
                            renderModalBody={(closeModal) => (
                                <div className='text-center'>
                                    <Image
                                        style={{ width: '100%' }}
                                        src={value}
                                    />
                                </div>
                            )}
                        >
                            <ImgPreview />
                        </WithModal>
                    );
                case 2 || 3:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <VideoPreview url={value} />
                                </div>
                            )}
                        >
                            <PlayButton />
                        </WithModal>
                    );
                case 4:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <PDFPreview url={value} />
                                </div>
                            )}
                        >
                            <Document />
                        </WithModal>
                    );
                case 5:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <VideoPreview url={value} />
                                </div>
                            )}
                        >
                            <PlayButton />
                        </WithModal>
                    );
                default:
                    return <span>Error</span>;
            }
        },
    };
    return (
        <CRUDTable
            immutable
            insertable={false}
            headerExtras={
                user.type === 'BOTH' && (
                    <WithModal
                        renderModalBody={(closeModal) => (
                            <MediaUpload
                                onCancel={closeModal}
                                onAfterSubmit={() => {
                                    closeModal();
                                    fetchUtils.reFetch();
                                }}
                            />
                        )}
                        disableHeader
                    >
                        <Button
                            variant='transparent'
                            className='rounded-smooth bg-white border-0'
                            style={{
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                            }}
                            size='sm'
                        >
                            <small>Add Media</small>
                        </Button>
                    </WithModal>
                )
            }
            // irremovable
            endpoints={endpoints.mediaLibrary}
            {...fetchUtils}
            response={fetchUtils.response.approved}
            cellModifier={cellModifier}
            {...viewProps.MediaApproval}
            name='Approved Media'
        />
    );
}

export default Approved;
