import React, { useContext } from "react";
import Menu from "./Menu";

function Sidebar({ routes, show }) {
    const sidebarStyle = {
        width: "250px",
        position: "fixed",
        height: "100vh",
        transform: `translateX(${show ? "0%" : "-100%"})`,
        transition: "transform .25s",
    };

    return (
        <div style={sidebarStyle} className='bg-primary '>
            <div
                className='h4 pt-4 ps-4 text-secondary'
                style={{ fontWeight: 700, height: '100px'}}
            >
                Streamhall
            </div>
            <div
                style={{
                    height: 'calc(100vh - 100px)',
                    overflowY: 'auto',
                }}
                className='px-4 py-3'
            >
                <Menu routes={routes} />
            </div>
        </div>
    );
}

export default Sidebar;
