import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../../src/asset/images/AdminUserGuide';


const MediaLibApproval = ({ direction = 'column' }) => {
    const [column] = useState(direction === 'column');
    const steps = [
        {
            id: 202,
            para: 'Login as Admin to Approve the Media Library',
            imageUrl: images.Admin_MediaLibApp_img_1,
        },
        {
            id: 203,
            para: 'Click on "Review and Approve Media" from the sidebar to approve the media.',
            imageUrl:
                images.Admin_MediaLibApp_img_2,
        },
        {
            id: 204,
            para: 'Here, we can see the list of Media under review.',
            imageUrl:
                images.Admin_MediaLibApp_img_3,
        },
        {
            id: 205,
            para: 'Click on the icon to preview the media.',
            imageUrl:
                images.Admin_MediaLibApp_img_3,
        },
        {
            id: 206,
            para: 'Admin can view and verify the media.',
            imageUrl:
                images.Admin_MediaLibApp_img_4,
        },
        {
            id: 207,
            para: 'After the review, Click on "Approve" button to approve the media.',
            imageUrl:
                images.Admin_MediaLibApp_img_5,
        },
        {
            id: 208,
            para: 'By clicking "Yes", the media will be approved.',
            imageUrl:
                images.Admin_MediaLibApp_img_6,
        },
        {
            id: 209,
            para: 'Click the "Delete" button to delete the Media under review.',
            imageUrl:
                images.Admin_MediaLibApp_img_7,
        },
        {
            id: 210,
            para: 'To see approved media Click on "Approved" tab.',
            imageUrl:
                images.Admin_MediaLibApp_img_8,
        },
        {
            id: 211,
            para: 'Admin can view the media by clicking the highlighted portion.',
            imageUrl:
                images.Admin_MediaLibApp_img_9,
        },
        {
            id: 212,
            para: 'Preview of the media is displayed.',
            imageUrl:
                images.Admin_MediaLibApp_img_10,
        },
        {
            id: 213,
            para: 'Click the "Delete" button to delete the approved media.',
            imageUrl:
                images.Admin_MediaLibApp_img_11,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Media library approval'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};
export default MediaLibApproval;
