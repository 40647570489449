import { Button } from 'react-bootstrap';

const ErrorsView = ({ onClose, errors, onBack }) => {
    const ErrorComp = ({ rowNo, description }) => {
        return (
            <div
                style={{
                    fontSize: '15px',
                    marginBottom: '20px',
                    borderBottom: '1px solid #00000017',
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        fontSize: '14px',
                        color: 'red',
                        padding: '4px 6px',
                        margin: '8px',
                        background: 'rgba(231, 29, 19, 0.1)',
                        borderRadius: '5px',
                        width: '20%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {rowNo}
                </div>
                <div
                    style={{
                        fontSize: '14px',
                        width: '80%',
                        color: '#FE453D',
                        alignSelf: 'center',
                    }}
                >
                    {description}
                </div>
            </div>
        );
    };

    let totalDiscrepancy = errors.length;

    return (
        <div style={{ fontSize: '16px' }}>
            <div
                style={{
                    fontSize: '18px',
                    fontWeight: '600',
                }}
            >
                Re-Upload Required
            </div>
            <hr />
            <h1 style={{ fontSize: '15px', marginBottom: '10px' }}>
                <b>Upload Contains {totalDiscrepancy} Discrepancies </b>
            </h1>
            <h2
                style={{
                    fontSize: '13px',
                    fontFamily: 'Inter',
                    marginBottom: '40px',
                }}
            >
                Please review the following issues and re-upload accordingly
            </h2>

            <div style={{ maxHeight: '250px', overflow: 'auto' }}>
                {errors?.map((error, index) => {
                    const [rowNo, description] = error.split(':');
                    return (
                        <ErrorComp
                            rowNo={rowNo + ' : '}
                            key={index}
                            description={description}
                        />
                    );
                })}
            </div>
            <div className='d-flex justify-content-between mt-3'>
                <Button
                    onClick={onBack}
                    style={{
                        width: '48%',
                        backgroundColor: '#117bff',
                        border: 'none',
                    }}
                >
                    Back
                </Button>
                <Button
                    style={{ width: '48%', border: '1px solid gray' }}
                    variant='light'
                    onClick={() => {
                        onClose();
                    }}
                >
                    Close
                </Button>
            </div>
            <div
                style={{
                    color: '#667085',
                    fontSize: '11px',
                    lineHeight: '17.72px',
                    textAlign: 'justify',
                }}
                className='mt-3'
            >
                Kindly review and rectify the errors that have arisen, and
                re-upload.
            </div>
        </div>
    );
};

export default ErrorsView;
