import React from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import images from '../../../../asset/images/AdminUserGuide'

const Dashboard = () => {
    const steps = [
        {
            id: 9,
            para: 'After the Successful login Admin is navigated to the Dashboard.',
            imageUrl: images.Admin_Dashboard_img_1,
        },
        {
            id: 10,
            para: 'Here, the highlighted portion in the "Overview" section Admin can see the "Total participants", "Instructors", "Milestone", and "New Courses".',
            imageUrl: images.Admin_Dashboard_img_2,
        },
        {
            id: 11,
            para: 'In this section, Admin can see the "Course Growth" feature, which displays statistics related to the number of participants that have enrolled in the courses.',
            imageUrl: images.Admin_Dashboard_img_3,
        },
        {
            id: 12,
            para: 'In this section, Admin can see the "Activity Monitor" which displays the "Active Courses" and the count of enrolled students in each course.',
            imageUrl: images.Admin_Dashboard_img_4,
        },
        {
            id: 13,
            para: 'This section displays the "Top Tutors", listed according to their Live Class Ratings.',
            imageUrl: images.Admin_Dashboard_img_5,
        },
        {
            id: 14,
            para: 'In this section, Admin can see the "Top Courses" based on the Ratings.',
            imageUrl: images.Admin_Dashboard_img_6,
        },
        {
            id: 15,
            para: 'Here, Admin can see the list of "Participants who missed live class".',
            imageUrl: images.Admin_Dashboard_img_7,
        },
        {
            id: 16,
            para: 'In this section, "Top Participants" are listed based on the credit they earned.',
            imageUrl: images.Admin_Dashboard_img_8,
        },
        {
            id: 17,
            para: 'Here, the "Instructor\'s Schedule" is displayed in accordance with the Upcoming Live Classes.',
            imageUrl: images.Admin_Dashboard_img_9,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading text='Dashboard' numberOfScreens={steps.length} />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default Dashboard;
