function ActivityMonitor({ activeCourses }) {
    return (
        <div className='bg-white rounded-smoother h-100'>
            <div className='p-3 fs-5 border-bottom '>Activity Monitor</div>
            <div
                className='p-3'
                style={{ maxHeight: '320px', overflowY: 'auto' }}
            >
                <div className='text-muted'>Active Courses</div>

                {activeCourses.length ? (
                    activeCourses.map((course) => (
                        <div
                            className='py-3 border-bottom'
                            key={course.Course_ID}
                            style={{ maxHeight: '300px', overflow: 'auto' }}
                        >
                            <div className='d-flex justify-content-between'>
                                <div
                                    className='fw-bold'
                                    style={{
                                        width: '60%',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {course.Course_NAME}
                                </div>
                                <small
                                    style={{ fontSize: '12px' }}
                                    className='text-success'
                                >
                                    {course.Total_Participants} students
                                    enrolled
                                </small>
                            </div>
                            <div style={{ fontSize: '12px' }} className='mt-2'>
                                Started Date:
                                <span className='ms-3'>
                                    {new Date(
                                        course.Last_Course_Schedule_Date
                                    ).toDateString()}
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='mt-5 text-center'>No active courses</div>
                )}
            </div>
        </div>
    );
}

export default ActivityMonitor;
