import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import Loader from '../../components/Loader';
import WithModal from '../../components/WithModal';
import withAlert from '../../hoc/withAlert';
import endpoints from '../../services/endpoints';
import toast from 'react-hot-toast';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';

function ForgotPassword({ showAlert }) {
    function forgotPassword(values, setSubmitting, closeModal) {
        axios
            .post(endpoints.authentication.forgetPassword, {
                EMAIL: values.email,
            })
            .then((res) => {
                console.log(res.data.statusText);
                toast.success(res.data.statusText);
                closeModal();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
                // showAlert(
                //     'error',
                //     extractErrorFromRes(err)
                // );
            })
            .finally((res) => setSubmitting(false));
    }

    return (
        <>
            <WithModal
                modalTitle={'Forgot password'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <Formik
                        onSubmit={(values, { setSubmitting }) => {
                            forgotPassword(values, setSubmitting, closeModal);
                        }}
                        initialValues={{ email: '' }}
                        validationSchema={Yup.object({
                            email: Yup.string()
                                .trim()
                                .matches(
                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
                                    'Please enter valid registered email'
                                )
                                .required(),
                        })}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <FormikControl
                                    label='Email'
                                    required
                                    name='email'
                                />
                                <FormSubmissionBtn onCancel={closeModal} />
                                {isSubmitting && <Loader />}
                            </Form>
                        )}
                    </Formik>
                )}
            >
                <div className='small text-end'>
                    <span type='button' className='text-decoration-none'>
                        Forgot Password ?
                    </span>
                </div>
            </WithModal>
        </>
    );
}

export default withAlert(ForgotPassword);
