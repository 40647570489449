import axios from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import Loader from '../../components/Loader';
import WithModal from '../../components/WithModal';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import withAlert from '../../hoc/withAlert';
import endpoints from '../../services/endpoints';
import toast from 'react-hot-toast';

function FirstTimeLogin({ showAlert }) {
    function firstTimeLogin(values, setSubmitting, closeModal) {
        axios
            .post(endpoints.instructor.activate, {
                Instructor_EMAIL: values.email,
                // Type: values.type,
            })
            .then((response) => {
                toast.success(response.data.statusText);
                closeModal();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
                // showAlert('error', err.response.data.statusText);
            })
            .finally((res) => setSubmitting(false));
    }
    return (
        <div className='small text-center mt-2'>
            <WithModal
                modalTitle={'New user'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <Formik
                        onSubmit={(values, { setSubmitting }) => {
                            firstTimeLogin(values, setSubmitting, closeModal);
                        }}
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={Yup.object({
                            email: Yup.string()
                                .trim()
                                .matches(
                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
                                    'Please enter valid email'
                                )
                                .required(),
                        })}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <FormikControl
                                    label='Email'
                                    required
                                    name='email'
                                />
                                <div style={{ fontSize: '12px' }}>
                                    Please provide the email registered in your
                                    organization
                                </div>

                                <FormSubmissionBtn onCancel={closeModal} />

                                {isSubmitting && <Loader />}
                            </Form>
                        )}
                    </Formik>
                )}
            >
                <span
                    type='button'
                    className='text-info fw-bold bg-white px-2 py-1 rounded-2'
                >
                    Logging in for first time ?
                </span>
            </WithModal>
        </div>
    );
}

export default withAlert(FirstTimeLogin);
