import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import LiveClassForm from './LiveClassForm';
import viewProps from '../viewProps';
import { Button } from 'react-bootstrap';
import { useAuthenticationState } from '../../../context/Auth.context';
import { joinLiveClass, startLiveClass } from './liveClassService';
import moment from 'moment';

function LiveClass() {
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosPost(endpoints.liveclass.selectBy, {
        selectcolumns: '*',
        wherecondition: 'INSTRUCTOR_ID=' + user.id,
    });

    const cellModifier = {
        LIVECLASSSCHEDULESTARTDATE: ({ value }) =>
            new Date(value).toDateString(),
        LIVECLASSSCHEDULEENDDATE: ({ value }) => new Date(value).toDateString(),
        LIVECLASSSCHEDULESTARTTIME: ({ row }) =>
            new Date(
                row.original.LIVECLASSSCHEDULESTARTTIME
            ).toLocaleTimeString(),
        LIVECLASSSCHEDULEENDTIME: ({ row }) =>
            new Date(
                row.original.LIVECLASSSCHEDULEENDTIME
            ).toLocaleTimeString(),
        Start: ({ row }) => {
            
            const isbetween = moment(Date()).isBetween(
                moment(row.original.LIVECLASSSCHEDULESTARTTIME).subtract(5,'minutes'),
                row.original.LIVECLASSSCHEDULEENDTIME
            );
            
            if (isbetween)
                return (
                    <Button
                        onClick={() => {
                            fetchUtils.setLoading(true);
                            startLiveClass({
                                LIVE_CLASS_TITLE: row.original.LIVE_CLASS_TITLE,
                                LIVE_CLASS_ROOM_ID:
                                    row.original.LIVE_CLASS_ROOM_ID,
                                LIVECLASSSCHEDULEDURATION:
                                    row.original.LIVECLASSSCHEDULEDURATION,
                                LIVE_COURSE_CLASS_ID:
                                    row.original.LIVE_COURSE_CLASS_ID,
                            }).then((isCreated) => {
                                if (isCreated) {
                                    joinLiveClass(
                                        row.original.LIVE_CLASS_ROOM_ID,
                                        user.username,
                                        row.original.LIVE_COURSE_CLASS_ID
                                    )
                                        .then((response) => {
                                            if (response.data.responseData.data)
                                                window.open(
                                                    response.data.responseData
                                                        .data,
                                                    '_blank'
                                                );
                                        })
                                        .finally(() => {
                                            fetchUtils.setLoading(false);
                                        });
                                } else {
                                    fetchUtils.setLoading(false);
                                }
                            });
                        }}
                        size='sm'
                        title='You can enter the class 5 minutes earlier than the actual start time'
                    >
                        Start
                    </Button>
                );

            return (
                <span
                    className='bg-primary text-white rounded opacity-75'
                    style={{
                        padding: '0.25rem 0.5rem',
                        lineHeight: '1.5',
                        fontSize: '0.875rem',
                    }}
                    title='You can only enter the class 5 minutes earlier than the actual start time, if time has started and button is disabled, please reload the page'
                >
                    Start
                </span>
            );
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.liveclass}
                Form={LiveClassForm}
                {...viewProps.LiveClass}
                addBtnLabel = 'Add Schedule'
                {...fetchUtils}
                cellModifier={cellModifier}
            />
        </>
    );
}

export default LiveClass;
