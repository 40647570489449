import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import { Col, Collapse, FormLabel, Row } from 'react-bootstrap';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import {
    getMediaUploadLink,
    uploadMedia,
} from '../../faculty/mediaLibrary/mediaServices';
import Avatar from '../../../asset/icons/UserAvatar.svg';
import toast from 'react-hot-toast';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

function InstructorForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response: departments, loading } = useAxiosGet(
        endpoints.department.get
    );

    const [file, setFile] = useState(null);

    const [fileError, setFileError] = useState(null);
    const [progress, setProgress] = useState(0);

    const initialFormValues = updateValues || {
        Instructor_EMP_ID: '',
        Instructor_Name: '',
        Instructor_EMAIL: '',
        Instructor_PHONE: '',
        Instructor_ADDRESS: '',
        Instructor_DEPT_ID: '',
        DATEOFJOINING: '',
        Instructor_LOCATION: '',
        Instructor_EMPTYPE: '',
        SUPERVISOR_NAME: '',
        SUPERVISOR_EMAIL: '',
        SUPERVISOR_PHONE_NO: '',
    };

    if (updateValues) {
        initialFormValues.DATEOFJOINING = new Date(
            initialFormValues?.DATEOFJOINING
        )
            .toISOString()
            .slice(0, 10);
    }
    const submitHandler = async (values, { setSubmitting }) => {
        if (file) {
            if (!file.type.includes('image')) {
                setFileError(true);
                setSubmitting(false);
                console.log('first');
                return;
            }

            const uploadLink = await getMediaUploadLink(file.name);

            await uploadMedia(uploadLink.signedUrl, file, setProgress);

            values.DP_URL = uploadLink.publicUrl;
        }

        axios
            .post(endpoint, values)
            .then((res) => {
                onAfterSubmit();
                toast.success(res.data.statusText);
                // showAlert('success', res.data.statusText);
                console.log(res.data.statusText);
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally((res) => {
                setSubmitting(false);
            });
    };
    if (loading) { 
        return <Loader />;
    }
    const preSelectedOptions = {
        dept:
            departments.find(
                (dept) =>
                    Number(dept.DEPT_ID) ===
                    Number(initialFormValues?.Instructor_DEPT_ID)
            ) || '',
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const phoneRegExp =
        /^(?!.-.-.-)(?=(?:\d{8,10}$)|(?:(?=.{9,11}$)[^-]-[^-]$)|(?:(?=.{10,12}$)[^-]-[^-]-[^-]$)  )[\d-]+$/;
    const emailRegExp = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Instructor_EMP_ID: Yup.string().required(),
                Instructor_Name: Yup.string().required(),
                Instructor_EMAIL: Yup.string()
                    .email()
                    .matches(emailRegExp, 'Provide valid email')
                    .required(),
                Instructor_PHONE: Yup.string()
                    .required()
                    .test(
                        'Instructor_PHONE',
                        'Enter a valid phone number',
                        (value) => {
                            if (value) {
                                return isPossiblePhoneNumber(value);
                            }
                            return false;
                        }
                    ),
                Instructor_ADDRESS: Yup.string().required(),
                Instructor_DEPT_ID: Yup.string().required(),
                Instructor_LOCATION: Yup.string()
                    .test(
                        'no-leading-space',
                        'Location should not be empty or consist of only spaces',
                        (value) => {
                            return !value || value.trim() !== '';
                        }
                    )
                    .required(),
                Instructor_EMPTYPE: Yup.string().required(),
                SUPERVISOR_NAME: Yup.string()
                    .test(
                        'no-leading-space',
                        'Name should not be empty or consist of only spaces',
                        (value) => {
                            return !value || value.trim() !== '';
                        }
                    )
                    .required(),
                SUPERVISOR_EMAIL: Yup.string()
                    .email()
                    .matches(emailRegExp, 'Provide valid email')
                    .required(),
                SUPERVISOR_PHONE_NO: Yup.string()
                    .required()
                    .test(
                        'SUPERVISOR_PHONE_NO',
                        'Enter a valid phone number',
                        (value) => {
                            if (value) {
                                return isPossiblePhoneNumber(value);
                            }
                            return false;
                        }
                    ),
                DATEOFJOINING: Yup.date()
                    .max(today, 'Upcoming dates not permitted')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Row>
                        {console.log(isSubmitting)}
                        {/* {!updateValues && ( */}
                        <>
                            <Col sm='6'>
                                <FormikControl
                                    control='input'
                                    type='number'
                                    required
                                    name='Instructor_EMP_ID'
                                />
                            </Col>

                            <Col sm='6'>
                                <FormikControl
                                    control='select-advanced'
                                    required
                                    name='Instructor_DEPT_ID'
                                    label='Department'
                                    options={departments}
                                    defaultValue={preSelectedOptions.dept}
                                    optionValue='DEPT_ID'
                                    optionLabel='DEPT_NAME'
                                />
                            </Col>
                        </>
                        {/* )} */}
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='Instructor_Name'
                                label='Instructor Name'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='email'
                                required
                                name='Instructor_EMAIL'
                            />
                        </Col>
                        <Row className='m-0 p-0'>
                            <Col sm='6'>
                                <FormikControl
                                    control='phone-input'
                                    required
                                    name='Instructor_PHONE'
                                />
                                <FormikControl
                                    control='input'
                                    required
                                    name='Instructor_LOCATION'
                                />
                            </Col>
                            <Col sm='6'>
                                <FormikControl
                                    control='textarea'
                                    placeholder='Address'
                                    required
                                    name='Instructor_ADDRESS'
                                />
                            </Col>
                        </Row>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Instructor_EMPTYPE'
                            >
                                <option>Select Instructor Type</option>
                                <option value={'FACULTY'}>Instructor</option>
                                <option value={'ADMIN'}>Admin</option>
                                <option value={'BOTH'}>
                                    Admin + Instructor
                                </option>
                            </FormikControl>
                        </Col>
                        <Col sm='6'>
                            {updateValues && !file && (
                                <>
                                    <FormLabel>Image</FormLabel>

                                    <FormLabel
                                        as={FormLabel}
                                        htmlFor='insImage'
                                        src={updateValues.DP_URL}
                                        style={{
                                            backgroundImage:
                                                'url(' +
                                                encodeURI(
                                                    updateValues?.DP_URL ||
                                                        Avatar
                                                ) +
                                                ')',
                                            width: '100%',
                                            height: '150px',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                        }}
                                    />
                                </>
                            )}
                            <div
                                style={{
                                    visibility:
                                        updateValues && !file
                                            ? 'hidden'
                                            : 'visible',
                                    marginBottom:
                                        updateValues && !file ? '-50px' : '0',
                                }}
                            >
                                <FormikControl
                                    control='input'
                                    type='file'
                                    name='image'
                                    id='insImage'
                                    label='Instructor Image'
                                    onChange={(e) => {
                                        setFileError(false);
                                        console.log(e);
                                        setFile(e.target.files[0]);
                                    }}
                                />
                                <Collapse in={fileError}>
                                    <div className='text-danger text-center small mt--1'>
                                        Only supports image
                                    </div>
                                </Collapse>
                            </div>
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='SUPERVISOR_NAME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='email'
                                required
                                name='SUPERVISOR_EMAIL'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='phone-input'
                                required
                                name='SUPERVISOR_PHONE_NO'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='DATEOFJOINING'
                            />
                        </Col>
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && (
                        <Loader type='progress' progress={progress} />
                    )}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(InstructorForm);
