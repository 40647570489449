const viewProps = Object.freeze({
    Department: {
        name: 'Department',
        relevants: ['DEPT_NAME', 'DEPTTAG'],
        columnHeads: ['Department Name', 'Department Tag'],
    },
    CourseCategory: {
        name: 'Course Category',
        relevants: [
            'Course_Category_NAME',
            // 'Course_Category_STATUS'
        ],
        columnHeads: [
            'Course Category',
            //  'Category Status'
        ],
    },
    DeptCourseCategoryMap: {
        name: 'Department-Course Category Mapping',
        relevants: ['DEPT_NAME', 'departmentcourseMapping'],
        columnHeads: ['Department Name', 'Course Category'],
    },
    Course: {
        name: 'Course ',
        relevants: [
            'Course_NAME',
            'COURSE_DP_URL',
            'Course_Category_NAME',
            'Structure',
            'courserating',
            'Reviews',
            'Course_Description',
            'Contents',
            'Course_Credits',
            'Course_Duration_Days',
            'Course_Duration_hours',
            'Version_ID',
            'Last_Course_Schedule_Date',
            'Last_Course_End_Date',
            'Suggested_Course_Frequency',
        ],
        columnHeads: [
            'Course Name',
            'Course DP',
            'Course Category',
            'Structure',
            'Rating',
            'Reviews',
            'Course Description',
            'Contents in Course',
            'Credits',
            'Duration Days',
            'Duration Hours',
            'Version',
            'Schedule Date',
            'Course End Date',
            'Suggested Course Frequency',
        ],
    },
    CourseReviews: {
        name: 'Reviews of',
        relevants: [
            'Course_NAME',
            'PARTICIPANT_NAME',
            'Review_Comments',
            'Review_DATE',
        ],
        columnHeads: [
            'Course Name',
            'Reviewed By',
            'Review Comments',
            'Reviewed Date',
        ],
    },
    Instructor: {
        name: 'Instructor',
        relevants: [
            'DP_URL',
            'Instructor_Name',
            'Instructor_EMP_ID',
            'DEPT_NAME',
            'Instructor_EMPTYPE',
            'Instructor_EMAIL',
            'Instructor_PHONE',
            'Instructor_ADDRESS',
            'Instructor_LOCATION',
            'DATEOFJOINING',
            'SUPERVISOR_NAME',
            'SUPERVISOR_EMAIL',
            'SUPERVISOR_PHONE_NO',
            'Active_Status',
        ],
        columnHeads: [
            'Profile Picture',
            'Instructor Name',
            'Instructor EMP No',
            'Department',
            'Instructor EMP TYPE',
            'Instructor Email',
            'Instructor Phone',
            'Instructor Address',
            'Instructor Location',
            'Date of Joining',
            'Supervisor Name',
            'Supervisor Email',
            'Supervisor phone',
            'Active Status',
        ],
    },
    InstructorCourseMap: {
        name: 'Instructor and Course-Mapping',
        relevants: ['Instructor_Name', 'Courses'],
        columnHeads: ['Instructor', 'Course'],
    },
    MediaApproval: {
        name: 'Review and Approve Media',
        relevants: [
            'MEDIA_DESC',
            'MEDIA_FILENAME',
            'Course_Category_NAME',
            'MEDIA_TYPE_NAME',
            'EXPIRY_DATE',
            'MEDIA_URL',
        ],
        columnHeads: [
            'Media Description',
            'Media Filename',
            'Course Category',
            'Media Type',
            'Media Expiry Date',
            'Preview',
        ],
    },
    CourseStructure: {
        name: 'View Course Structure',
        relevants: [
            'Course_NAME',
            'Course_Category_NAME',
            'Course_Credits',
            'Course_Duration_Days',
            // 'Course_Structure_Name',
            'Structure',
        ],
        columnHeads: [
            'Course',
            'Course Category',
            'Credits',
            'Duration Days',
            // 'Duration Hours',
            'View Structure',
        ],
    },
    CourseStructureApproval: {
        name: 'Review and Approve Course Structure',
        relevants: [
            'Course_NAME',
            'Course_Structure_Priority_ID',
            'Course_Structure_TitleName',
            'Course_Structure_Content_Type',
        ],
        columnHeads: ['Course', 'Priority', 'Item name', 'Content Type'],
    },
    CoursePublish: {
        name: 'Publish Course',
        relevants: [
            'Course_NAME',
            'Course_Category_NAME',
            'Course_Credits',
            'Course_Duration_Days',
            'Course_Duration_hours',
            'Publish',
            'Reject',
        ],
        columnHeads: [
            'Course',
            'Category',
            'Credit',
            'Duration (Days)',
            'Duration (Hours)',
            'Publish',
            'Reject',
        ],
    },
    Program: {
        name: 'Programs',
        relevants: [
            'Course_Program_NAME',
            'Course_Program_Description',
            'Course_Program_Duration_Days',
            'Course_Program_Duration_hours',
            'Course_Program_Credits',
            'Course_Category_NAME',
            'Course_Program_STATUS',
            'Delete',
        ],
        columnHeads: [
            'Program Name',
            'Description',
            'Duration Days',
            'Duration Hours',
            'Program Credits',
            'Course Category',
            'Status',
            'Delete',
        ],
    },
    ProgramCourseMap: {
        name: 'Course and Program Mapping',
        relevants: ['Course_Program_NAME', 'Courses'],
        columnHeads: ['Program', 'Courses'],
    },
    Participant: {
        name: 'Participant',
        relevants: [
            'PARTICIPANT_NAME',
            'DP',
            'PARTICIPANT_EMP_NO',
            'PARTICIPANT_PHONE',
            'DESIGNATION_NAME',
            'Location_name',
            'HR_SPOC_NAME',
            'HR_SPOC_EMAILID',
            'PARTICIPANT_EMAIL',
            'PARTICIPANT_ADDRESS',
            'MANAGER_NAME',
            'MANAGER_PHONE',
            'MANAGER_EMAILID',
            'DATEOFJOINING',
            'Dept_Name',
            'Is_Active',
        ],
        columnHeads: [
            'Participant Name',
            'DP',
            'Participant EMP',
            'Participant Phone',
            'Designation',
            'Location',
            'HR SPOC name',
            'HR SPOC email',
            'Participant Email',
            'Participant Address',
            'Manger Name',
            'Manager Phone',
            'Manager Email',
            'Date of Joining',
            'Department',
            'Active Status',
        ],
    },

    team: {
        name: 'Team',
        relevants: [
            'TEAM_NAME',
            'TEAMTAG',
            'TEAM_LEADER_NAME',
            'TEAM_LEADER_EMP_ID',
            'TEAM_LEADER_EMAILID',
            'DEPT_NAME',
        ],
        columnHeads: [
            'TEAM NAME',
            'TEAM TAG',
            'TEAM LEADER NAME',
            'TEAM LEADER EMPLOYEE ID',
            'TEAM LEADER EMAIL ID',
            'MANAGING DEPARTMENT',
        ],
    },
    CourseContent: {
        name: 'Course Content',
        relevants: [
            'Course_NAME',
            'Course_Content_CONTENT',
            'Course_Content_date',
            // 'Review_Comments',
            'Special_Notes',
            'special_exception',
        ],
        columnHeads: [
            'Course',
            'Course Content',
            'Course Content Date',
            // 'Review',
            'Special Notes',
            'Special Exception',
        ],
    },
    CourseContentApproval: {
        name: 'Review and Approve Course Content',
        relevants: [
            'Course_NAME',
            'Course_Content_CONTENT',
            'Review_Comments',
            'Special_Notes',
            'special_exception',
            'Approve',
            'Reject',
        ],
        columnHeads: [
            'Course',
            'Course Content',
            'Review Comments',
            'Special Notes',
            'Special Exception',
            'Approve',
            'Reject',
        ],
    },
    LiveClassVideoRecordings: {
        name: 'Live Class Video Recordings',
        relevants: [
            'meetingName',
            'createdDate',
            'expiryDate',
            'Instructor_Name',
            'videoRecordingStatus',
            'Start',
        ],
        columnHeads: [
            'Topic',
            'Created Date',
            'Expiry Date',
            'Instructor',
            'Status',
            'Action',
        ],
    },
    TeamsCourseCategoryMap: {
        name: 'Teams-Course Category Mapping',
        relevants: ['TEAM_NAME', 'teamscourseMapping'],
        columnHeads: ['Teams', 'Course Category'],
    },
    Location: {
        name: 'Location',
        relevants: ['Location_NAME', 'LocationTAG'],
        columnHeads: ['Location Name', 'Location Tag'],
    },

    Designation: {
        name: 'Designation',
        relevants: ['DESIGNATION_NAME', 'DESIGNATIONTAG'],
        columnHeads: ['Designation Name', 'Designation Tag'],
    },
    LocationCourseCategoryMap: {
        name: 'Location-Course Category Mapping',
        relevants: ['Location_NAME', 'locationcourseMapping'],
        columnHeads: ['Location Name', 'Course Category'],
    },
    TeamParticipantMap: {
        name: 'Team-Participant Mapping',
        relevants: ['TEAM_NAME', 'teamparticipantMapping'],
        columnHeads: ['Team Name', 'Participant Name'],
    },
    DesignationCourseCategoryMap: {
        name: 'Designation-Course Category Mapping',
        relevants: ['DESIGNATION_NAME', 'designationcourseMapping'],
        columnHeads: ['Designation Name', 'Course Category'],
    },
    MandatoryCourse: {
        name: 'Mandatory Course',
        relevants: ['courseID','Course_NAME','edit'],
        columnHeads: ['Course ID','Course NAME','Edit'],
    },
});

export default viewProps;
