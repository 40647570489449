import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide';

const Notification = ({ direction = 'column' }) => {
    const [column] = useState(direction === 'column');
    const steps = [
        {
            id: 18,
            para: 'Click on the Highlighted portion, to see the "Notifications".',
            imageUrl: images.Admin_Notification_img_1,
        },
        {
            id: 19,
            para: 'Admin can see the list of notifications here.',
            imageUrl: images.Admin_Notification_img_2,
        },
        {
            id: 20,
            para: 'By clicking on the "Unread" section Admin can see the unread notifications.',
            imageUrl: images.Admin_Notification_img_3,
        },
        {
            id: 21,
            para: 'By clicking on the "All" section Admin can see all the notifications.',
            imageUrl: images.Admin_Notification_img_4,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Notification'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default Notification;
