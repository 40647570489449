import React from 'react';
import { Button } from 'react-bootstrap';

function FormSubmissionBtn({ onCancel }) {
    const styles = {
        width: '40px',
        height: '40px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
    };

    return (
        <>
            <div className='text-center pt-4'>
                <Button
                    className='px-3 py-1 rounded-smooth'
                    type='submit'
                    style={{
                        width: '100%',
                        background: '#107BFF',
                        border: 'none',
                        fontSize: '16px',
                        fontWeight: '600',
                    }}
                >
                    <span className='small'>Submit</span>
                </Button>
                <Button
                    className='me-4 px-3 py-1 mt-3 rounded-smooth'
                    variant='outline-light'
                    onClick={onCancel}
                    style={{
                        width: '100%',
                        border: ' 1px solid #107BFF',
                        color: '#107BFF',
                        fontSize: '16px',
                        fontWeight: '500',
                    }}
                >
                    <span className='small'>Cancel</span>
                </Button>
            </div>
        </>
    );
}

export default FormSubmissionBtn;
