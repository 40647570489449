import React from "react";
import CRUDTable from "../../../components/table/CRUDTable";
import { useAxiosGet } from "../../../services/axiosHooks";
import endpoints from "../../../services/endpoints";
import LiveClassTopicForm from "./LiveClassTopicForm";
import viewProps from "../viewProps";

function LiveClassTopic() {
    const fetchUtils = useAxiosGet(endpoints.liveclassTopic.get);

    return (
        <>
            <CRUDTable
                endpoints={endpoints.liveclassTopic}
                Form={LiveClassTopicForm}
                {...viewProps.LiveClassTopic}
                {...fetchUtils}
            />
        </>
    );
}

export default LiveClassTopic;
