import { Col, Row } from 'react-bootstrap';
import { ReactComponent as FullStar } from '../../../asset/icons/FullStar.svg';
import { ReactComponent as HalfStar } from '../../../asset/icons/HalfStar.svg';
import { ReactComponent as EmptyStar } from '../../../asset/icons/EmptyStar.svg';
import { ReactComponent as StarStamp } from '../../../asset/icons/StarStamp.svg';
import { ReactComponent as UsersStamp } from '../../../asset/icons/UsersStamp.svg';

function TopCourses({ courseRatings }) {
    return (
        <div className='bg-white rounded-smoother p-3'>
            <div className='fs-5'>Top Courses</div>
            <Row className='align-items-center'>
                <Col sm='10'>
                    <span className='text-muted'>Reviews</span>
                </Col>
                <Col className='p-0' sm={1}>
                    <StarStamp />
                </Col>
                <Col className='p-0' sm={1}>
                    <UsersStamp />
                </Col>
            </Row>
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {courseRatings.length > 0 ? (
                    courseRatings.map((rating) => (
                        <Rating
                            course={rating.Course_NAME}
                            averageRating={
                                rating.totalratings /
                                rating.no_of_participants_Rated
                            }
                            numberOfRatings={rating.no_of_participants_Rated}
                        />
                    ))
                ) : (
                    <div className='text-muted text-center py-5'>
                        No Courses has been rated so far
                    </div>
                )}
            </div>
        </div>
    );
}

function Rating({ course, averageRating, numberOfRatings }) {
    const noOfFullStars = Math.floor(averageRating);
    
    const decimal = averageRating - noOfFullStars;

    const stars = Array(noOfFullStars).fill(<FullStar />);

    if (decimal) stars.push(<HalfStar />);

    for (let i = 1; i <= 5; i++) {
        if (stars.length < i) {
            stars.push(<EmptyStar />);
        }
    }

    return (
        <Row className=' mt-4 align-items-center mx-0'>
            <Col sm={3}>
                <span className='fw-bold'>{course}</span>
            </Col>
            <Col sm={7} className='text-center'>
                <div>{stars}</div>
            </Col>
            <Col sm={1} className='text-center'>
                <span className='fw-bold '>
                    {averageRating.toFixed(1)}
                </span>
            </Col>
            <Col sm={1} className='text-center'>
                <span className='fw-bold'>{numberOfRatings}</span>
            </Col>
        </Row>
    );
}
export default TopCourses;
