import React from 'react';
import '../helpcss/scrolldiv.css';

function ScrollDiv({ children, ...rest }) {
    return (
        <div className='scroll' {...rest}>
            {children}
        </div>
    );
}

export default ScrollDiv;
