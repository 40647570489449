import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
// import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import { Col, FormLabel, Row } from 'react-bootstrap';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import InsAvatar from '../../../asset/icons/InsAvatar.svg';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';
// import {
//     getMediaUploadLink,
//     uploadMedia,
// } from '../../faculty/mediaLibrary/mediaServices';

function CourseContentForm({
    updateValues,
    onCancel,
    onAfterSubmit,
    disabled,
}) {
    const initialFormValues = updateValues;

    const submitHandler = async (values, { setSubmitting }) => {
        setSubmitting(true);

        axios
            .post(endpoints.course.contentUpdate, values)
            .then(() => {
                setSubmitting(false);
                onAfterSubmit();
            })
            .catch((err) => {
                console.log(err);
                setSubmitting(false);
                toast.error(extractErrorFromRes(err));
            });
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Course_Content_CONTENT: Yup.string()
                    .required()
                    .test(
                        'no-leading-space',
                        'Content should not be empty or consist of only spaces',
                        (value) => {
                            return !value || value.trim() !== '';
                        }
                    )
                    .nullable(),
                Special_Notes: Yup.string().nullable(),
                special_exception: Yup.string().nullable(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Row>
                        <Col sm='6'>
                            <FormikControl
                                disabled
                                control='input'
                                name='Course_NAME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                disabled
                                value={updateValues.Course_Category_NAME}
                                name='Course_Category_NAME'
                            />
                        </Col>
                        <Col sm='12'>
                            <FormikControl
                                disabled={disabled}
                                control='textarea'
                                height='200px'
                                required
                                name='Course_Content_CONTENT'
                                label='Contents in Course'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                disabled={disabled}
                                name='Special_Notes'
                                control='textarea'
                                label='Special Notes'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                disabled={disabled}
                                name='special_exception'
                                control='textarea'
                                label='Special Exception'
                            />
                        </Col>
                    </Row>
                    {!disabled && <FormSubmissionBtn onCancel={onCancel} />}
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(CourseContentForm);
