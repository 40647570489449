import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide'

const DeptCourseCategoryMapping = () => {
    const steps = [
        {
            id: 48,
            para: 'Clicking on the "Department-Course Category Mapping" module in the sidebar allows you to navigate to the Department-Course Category Mapping page.',
            imageUrl: images.Admin_DepartmentCCM_img_1,
        },
        {
            id: 49,
            para: 'Here, we can see the list of Department mapped to the Course category.',
            imageUrl: images.Admin_DepartmentCCM_img_2,
        },
        {
            id: 50,
            para: 'Click the "Map Category to Department" button to map the Course category with a department.',
            imageUrl: images.Admin_DepartmentCCM_img_3,
        },
        {
            id: 51,
            para: 'Fill the form by selecting the "Department" and the "Course Category".',
            imageUrl: images.Admin_DepartmentCCM_img_4,
        },
        {
            id: 52,
            para: 'Press the "Submit" button to successfully Map Category to Department.',
            imageUrl: images.Admin_DepartmentCCM_img_5,
        },
        {
            id: 53,
            para: 'Here, you can see the newly mapped Department to Course category.',
            imageUrl: images.Admin_DepartmentCCM_img_6,
        },
        {
            id: 54,
            para: 'Download the Department -Course Category details, by clicking the "Download Icon".',
            imageUrl: images.Admin_DepartmentCCM_img_7,
        },
        {
            id: 55,
            para: 'To modify the Department-Course Category, click the "Edit" button.',
            imageUrl: images.Admin_DepartmentCCM_img_8,
        },
        {
            id: 56,
            para: 'Update the Department-Course Category details to modify the data.',
            imageUrl: images.Admin_DepartmentCCM_img_9,
        },
        {
            id: 57,
            para: 'Press the "Submit" button to successfully update the Department-Course Category.',
            imageUrl: images.Admin_DepartmentCCM_img_10,
        },
        {
            id: 58,
            para: 'Click the "Delete" button to delete the Department-Course Category.',
            imageUrl: images.Admin_DepartmentCCM_img_11,
        },
    ];
    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Department-Course category mapping'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default DeptCourseCategoryMapping;
