import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import {useAxiosGet} from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import InstructorCourseMapForm from './InstructorCourseMapForm';

function InstructorCourseMap() {
    const fetchUtils = useAxiosGet(endpoints.courseInstructor.get);

    // can use cellmodifer here,
    // but if used, searching for the value in modified cell and also download table data wont work,
    // so added a new property (Courses) to show the values in array 
    
    let mapping = [];
    if (!fetchUtils.loading) {
        mapping = fetchUtils.response.map((data) => {
            if (Array.isArray(data.Course)) {
                data.Courses = data.Course.map(
                    (courseCat) => courseCat.Course_NAME
                ).join(', ');
            }
            return data;
        });
    }

    return (
        <>
            <CRUDTable
                endpoints={endpoints.courseInstructor}
                Form={InstructorCourseMapForm}
                addBtnLabel='Add Mapping'
                // irremovable
                // cellModifier={cellModifier}
                {...fetchUtils}
                response = {mapping}
                {...viewProps.InstructorCourseMap}
            />
        </>
    );
}

export default InstructorCourseMap;
