import axios from 'axios';
import React, { useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import Loader from '../../../components/Loader';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import { ReactComponent as DeleteIcon } from '../../../asset/icons/Delete.svg';

function Chat({ mediaId, participantId, userId }) {
    const { response, loading, setLoading, reFetch } = useAxiosPost(
        endpoints.mediaAssignReview.conversation,
        {
            ASSIGNED_MEDIA_ID: mediaId,
            PARTICIPANT_ID: participantId,
            INSTRUCTOR_ID: userId,
        }
    );
    const [reply, setReply] = useState('');

    const sendReply = () => {
        setLoading(true);
        axios
            .post(endpoints.mediaAssignReview.add, {
                ASSIGNED_MEDIA_ID: mediaId,
                ASSIGNED_BY: response[0].ASSIGNED_BY,
                Review_User_ID: userId,
                Review_Comments: reply,
                Course_Category_ID: response[0].Course_Category_ID,
                Course_ID: response[0].Course_ID,
                PARTICIPANT_ID: response[0].PARTICIPANT_ID,
                Review_DATE: new Date(),
                UPDATED_BY: userId,
                UPDATED_DATE: new Date(),
            })
            .then((res) => {
                console.log(res);
                reFetch();
            })
            .catch(() => {})
            .finally(() => {
                setReply('');
                setLoading(false);
            });
    };

    const DeleteMessage = ({
        reviewId,
        reviewUserId,
        participantId,
        mediaId,
    }) => {
        const removeReply = () => {
            let body = {
                Review_ID: reviewId,
                ASSIGNED_MEDIA_ID: mediaId,
                Review_User_ID: reviewUserId,
                PARTICIPANT_ID: participantId,
            };
            setLoading(true);
            axios
                .post(endpoints.mediaAssignReview.delete, body)
                .then(() => {
                    reFetch();
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        return (
            <span onClick={removeReply} type='button'>
                <DeleteIcon />
            </span>
        );
    };

    return (
        <div >
            {response.map((chat) => (
                <div
                    style={{
                        textAlign: chat.isParticipant ? 'left' : 'end',
                        margin: '15px 0',
                    }}
                >
                    <div
                        className='bg-light rounded-smooth shadow text-start px-3 py-1 d-inline-block'
                        style={{
                            maxWidth: '70%',
                        }}
                    >
                        <div className='mb-1'>{chat.Review_Comments}</div>

                        <div style={{ fontSize: '11px', textAlign: 'end' }}>
                            {new Date(chat.Review_DATE).toLocaleDateString()}
                        </div>
                        <div style={{ fontSize: '11px', textAlign: 'end' }}>
                            {new Date(chat.Review_DATE).toLocaleTimeString()}
                        </div>

                        {!chat.isParticipant &&
                            (new Date().getTime() -
                                new Date(chat.Review_DATE).getTime()) /
                                60000 <
                                3 && (
                                <DeleteMessage
                                    reviewId={chat.Review_ID}
                                    reviewUserId={chat.Review_User_ID}
                                    participantId={chat.PARTICIPANT_ID}
                                    mediaId={mediaId}
                                />
                            )}
                    </div>
                </div>
            ))}
            <div className='pt-2 d-flex gap-2'>
                <FormControl
                    onChange={(e) => {
                        setReply(e.target.value);
                    }}
                    value={reply}
                />
                <Button
                    onClick={sendReply}
                    disabled={!reply}
                    className='py-1 shadow'
                >
                    Send
                </Button>
            </div>
            {loading && <Loader />}
        </div>
    );
}

export default Chat;
