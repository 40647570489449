import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet} from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import MandatoryCourseForm from './MandatoryCourseForm';
import viewProps from '../viewProps';
import WithModal from '../../../components/WithModal';
import { Button } from 'react-bootstrap';
import { ReactComponent as Edit } from "../../../asset/icons/Edit.svg";

function MandatoryCourse() {
    const fetchUtils = useAxiosGet(endpoints.mandatoryCourse.get);
    let mapping = [];

    if (!fetchUtils.loading) {
        mapping = fetchUtils.response.map((data) => {
                return {
                    'COURSE_ID': data.courseID,
                    ...data
                };
        });
        
    }
    
    const cellModifier = {
        edit: ({ row }) => (<WithModal
            modalAttrs={{
                size: 'lg',
                dialogClassName: 'modal-below-lg',
            }}
            dlgClassName='modal-below-lg'
            renderModalBody={(closeModal) => (
                <MandatoryCourseForm
                    onCancel={closeModal}
                    updateValues={row.original}
                    isEditForm = {true}
                    endpoint={endpoints.mandatoryCourse.update}
                    onAfterSubmit={() => {
                        closeModal();
                        fetchUtils.reFetch();   
                    }}
                />
            )}
            disableHeader
        >
                <Edit/>
        </WithModal>)
    }
    

    return (
        <>
            <CRUDTable
                endpoints={endpoints.mandatoryCourse}
                // Form={MandatoryCourseForm}
                {...viewProps.MandatoryCourse}
                insertable={false}
                immutable
                {...fetchUtils}
                response={mapping}
                cellModifier={cellModifier}
                headerExtras={
                    <WithModal
                        modalAttrs={{
                            size: 'lg',
                            dialogClassName: 'modal-below-lg',
                        }}
                        dlgClassName='modal-below-lg'
                        renderModalBody={(closeModal) => (
                            <MandatoryCourseForm
                                onCancel={closeModal}
                                endpoint={endpoints.mandatoryCourse.add}
                                data={fetchUtils.response}
                                onAfterSubmit={() => {
                                    closeModal();
                                    fetchUtils.reFetch();
                                }}
                            />
                        )}
                        disableHeader
                    >
                        <Button
                            variant='transparent'
                            className='rounded-smooth bg-white border-0'
                            style={{
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                            }}
                            size='sm'
                        >
                            <small>Add Mandatory Course</small>
                        </Button>
                    </WithModal>
                }
                // formSize='md'
            />
        </>
    );
}

export default MandatoryCourse;
