import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Login from './sections/Login';
import ForgotPassword from './sections/ForgotPassword';
import Dashboard from './sections/Dashboard';
import Notification from './sections/Notification';
import ChangePassword from './sections/ChangePassword';
import Department from './sections/Department';
import CourseCategory from './sections/CourseCategory';
import DeptCourseCategoryMapping from './sections/DeptCourseCategoryMapping';
import Participant from './sections/Participants';
import Instructors from './sections/Instructors';
import Courses from './sections/Courses';
import InstructorCourse from './sections/InstructorCourse';
import MediaLibApproval from './sections/MediaLibApproval';
import CourseStructApproval from './sections/CourseStructApproval';
import Program from './sections/Program';
import LinkCourseToProgram from './sections/LinkCourseToProgram';
import LiveClassRecording from './sections/LiveClassRecording';
import Logout from './sections/Logout';
import HelpLayout from './HelpLayout';
import HelpHome from './Index';

function HelpRoute() {
    return (
        <>
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path='/' element={<HelpLayout />}>
                        <Route index element={<HelpHome />} />
                        <Route path='helpLogin' element={<Login />} />
                        <Route
                            path='helpForgotPassword'
                            element={<ForgotPassword />}
                        />
                        <Route path='helpDashboard' element={<Dashboard />} />
                        <Route
                            path='helpNotification'
                            element={<Notification />}
                        />
                        <Route
                            path='change-password'
                            element={<ChangePassword />}
                        />
                        <Route path='department' element={<Department />} />
                        <Route
                            path='course-category'
                            element={<CourseCategory />}
                        />
                        <Route
                            path='dept-course-category-mapping'
                            element={<DeptCourseCategoryMapping />}
                        />
                        <Route path='participants' element={<Participant />} />
                        <Route path='instructors' element={<Instructors />} />
                        <Route path='courses' element={<Courses />} />
                        <Route
                            path='instructor-course'
                            element={<InstructorCourse />}
                        />
                        <Route
                            path='media-lib-approval'
                            element={<MediaLibApproval />}
                        />
                        <Route
                            path='course-struct-approval'
                            element={<CourseStructApproval />}
                        />
                        <Route path='program' element={<Program />} />
                        <Route
                            path='link-course-to-program'
                            element={<LinkCourseToProgram />}
                        />
                        <Route
                            path='live-class-recording'
                            element={<LiveClassRecording />}
                        />
                        <Route path='logout' element={<Logout />} />
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default HelpRoute;
