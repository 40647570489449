import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide';

const Instructors = ({ direction = 'column' }) => {
    const [column] = useState(direction === 'column');
    const steps = [
        {
            id: 73,
            para: 'Click on "Instructor" from the sidebar to see more options regarding Instructor.',
            imageUrl: images.Admin_Instructor_img_1,
        },
        {
            id: 74,
            para: 'Clicking on the "Instructors" module in the sidebar allows you to navigate to the Instructors page.',
            imageUrl: images.Admin_Instructor_img_2,
        },
        {
            id: 75,
            para: 'Here, we can see the list of Instructors.',
            imageUrl: images.Admin_Instructor_img_3,
        },
        {
            id: 76,
            para: 'To add Instructor, Click on "Add Instructor".',
            imageUrl: images.Admin_Instructor_img_4,
        },
        {
            id: 77,
            para: 'Fill the form with appropriate details to add the Instructor.',
            imageUrl: images.Admin_Instructor_img_5,
        },
        {
            id: 78,
            para: 'Press the "Submit" button to successfully add the Instructor.',
            imageUrl: images.Admin_Instructor_img_6,
        },
        {
            id: 79,
            para: 'Here, you can see the newly added Instructor.',
            imageUrl: images.Admin_Instructor_img_7,
        },
        {
            id: 80,
            para: 'Click on the "Download Icon" to download the Instructor details as an Excel file.',
            imageUrl: images.Admin_Instructor_img_8,
        },
        {
            id: 81,
            para: 'To modify the Instructor, click the "Edit" button.',
            imageUrl: images.Admin_CoursesByInst_img_9,
        },
        {
            id: 82,
            para: 'Update the Instructor details to modify the Instructor details.',
            imageUrl: images.Admin_Instructor_img_10,
        },
        {
            id: 83,
            para: 'Press the "Submit" button to successfully update the Instructor details.',
            imageUrl: images.Admin_Instructor_img_11,
        },
        {
            id: 84,
            para: 'Click the "Delete" button to delete the Instructor.',
            imageUrl: images.Admin_Instructor_img_12,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Instructor details'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default Instructors;
