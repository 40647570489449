import React from 'react';
import { PDFReader } from 'reactjs-pdf-reader';

function PDFPreview({ url }) {

    return (
        <>
            <PDFReader
                url={url}
                width={700}
                showAllPage={true}
            />

        </>
    );
}

export default PDFPreview;
