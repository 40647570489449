import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import DeptCourseCategoryMapForm from './DeptCourseCategoryMapForm';

function DeptCourseCategoryMap() {
    const fetchUtils = useAxiosGet(endpoints.departmentCourseCategory.get);

    // can use cellmodifer here,
    // but if used, searching for the value in modified cell and also download table data wont work
    // so added a new property (departmentcourseMapping) to show the values in array
    let mapping = [];
    if (!fetchUtils.loading) {
        mapping = fetchUtils.response.map((data) => {
            if (Array.isArray(data.departmentcourse)) {
                data.departmentcourseMapping = data.departmentcourse
                    .map((courseCat) => courseCat.Course_Category_NAME)
                    .join(', ');
            }
            return data;
        });
    }

    return (
        <>
            <CRUDTable
                endpoints={endpoints.departmentCourseCategory}
                Form={DeptCourseCategoryMapForm}
                addBtnLabel='Map Category to Department'
                // irremovable
                {...fetchUtils}
                response={mapping}
                {...viewProps.DeptCourseCategoryMap}
                name='Department-Course Category'
            />
        </>
    );
}

export default DeptCourseCategoryMap;


/*

Instructor Bulk Upload

API : /api/admin/csvupload/import-instructor
INSTRUCTOR TEMPLATE API : /api/excel/bulkupload/template/InstuctorCsv


Participant Bulk Upload

API : /api/admin/csvupload/import-participant
PARTICIPANT TEMPLATE API : /api/excel/bulkupload/template/participants

Course Bulk Upload

API : /api/admin/csvupload/import-course
COURSE TEMPLATE API : /api/excel/bulkupload/template/CourseCsv

Quiz Bulk Upload

API : /api/admin/csvupload/import-quiz
QUIZ TEMPLATE API : /api/excel/bulkupload/template/QuestionbankCsv
*/