import React from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import images from '../../../../asset/images/AdminUserGuide'

const ForgotPassword = () => {
    const steps = [
        {
            id: 4,
            para: 'In case the admin forgets their password, Click on the "Forgot Password?"',
            imageUrl:
                images.Admin_ForgotPassword_img_1,
        },
        {
            id: 5,
            para: 'Enter the Registered Email id to get the Temporary password.',
            imageUrl:
                images.Admin_ForgotPassword_img_2,
        },
        {
            id: 6,
            para: 'Click on "Submit" to get the Temporary password.',
            imageUrl:
                images.Admin_ForgotPassword_img_3,
        },
        {
            id: 7,
            para: 'Here, Admin get a temporary password to login.',
            imageUrl:
                images.Admin_ForgotPassword_img_4,
        },
        {
            id: 8,
            para: 'Admin can login with the temporary password.',
            imageUrl:
                images.Admin_ForgotPassword_img_5
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Forgot Password'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default ForgotPassword;
