import Course from '../views/admin/course/Course';
import CourseCategory from '../views/admin/course_category/CourseCategory';
import Department from '../views/admin/department/Department';
import DeptCourseCategoryMap from '../views/admin/dept-course_category/DeptCourseCategoryMap';
import InstructorCourseMap from '../views/admin/instructor-course/InstructorCourseMap';
import Instructor from '../views/admin/instructor/Instructor';
import Participant from '../views/admin/participant/Participant';
import MediaLibrary from '../views/admin/mediaLibrary/MediaLibrary';
import Overview from '../views/admin/overview/Overview';
import CourseStructureApproval from '../views/admin/course_structure_approval/CourseStructureApproval';
import Program from '../views/admin/program/Program';
import ProgramCourseMap from '../views/admin/program-course/ProgramCourseMap';
// import CourseContent from '../views/admin/course_content/CourseContent';
import CourseStructure from '../views/admin/course_structure/CourseStructure';
import CourseReviews from '../views/admin/course_reviews/Index';
import LiveClassVideoRecordings from '../views/admin/liveclass/LiveClassVideoRecordings';
// import Team from "../views/admin/team/Team"
import TeamsCourseCategoryMap from '../views/admin/teams-course_category/TeamsCourseCategoryMap';
import Location from '../views/admin/location/Location';
import Designation from '../views/admin/designation/Designation';
import LocationCourseCategoryMap from '../views/admin/location-course_category/LocationCourseCategoryMap';
import TeamParticipantMap from '../views/admin/team-participant/TeamParticipantMap';
import DesignationCourseCategoryMap from '../views/admin/designation-course_category/DesignationCourseCategoryMap';
import Teams from '../views/admin/teams/Teams';
// import AdminUserGuide from '../views/admin/helpAdmin/AdminIndex';
import MandatoryCourse from '../views/admin/mandatory_course/MandatoryCourse';

// Order of menu links is based on order of routes
const adminRoutes = Object.freeze([
    {
        path: 'overview',
        page: Overview,
        name: 'Dashboard',
    },

    {
        path: 'department',
        page: Department,
        name: 'Departments',
    },
    {
        path: 'course-category',
        page: CourseCategory,
        name: 'Course Categories',
    },
    {
        path: 'dept-course-category',
        page: DeptCourseCategoryMap,
        name: 'Department-Course Category Mapping',
    },
    {
        path: 'media-approve',
        page: MediaLibrary,
        name: 'Review and Approve Media',
    },
    {
        path: 'course',
        page: Course,
        name: 'Courses',
        module: 'course',
    },
    // {
    //     path: 'course-content',
    //     page: CourseContent,
    //     name: 'Course Contents',
    //     module: 'course',
    //     hiddenFromMenu: true,
    // },
    {
        path: 'course-structure',
        page: CourseStructure,
        name: 'Course Structure',
        module: 'course',
    },
    {
        path: 'course-structure-approve',
        page: CourseStructureApproval,
        hiddenFromMenu: true,
        module: 'course',
    },
    {
        path: 'instructor',
        page: Instructor,
        name: 'Instructors',
        module: 'instructor',
    },
    {
        path: 'instructor-course',
        page: InstructorCourseMap,
        name: 'Courses By Instructor',
        module: 'instructor',
    },
    {
        path: 'location',
        page: Location,
        name: 'Location',
        module: 'master settings',
    },
    {
        path: 'designation',
        page: Designation,
        name: 'Designation',
        module: 'master settings',
    },
    {
        path: 'team',
        page: Teams,
        name: 'Team',
        module: 'master settings',
    },
    {
        path: 'team-course_category',
        page: TeamsCourseCategoryMap,
        name: 'Teams-Course Category Mapping',
    },
    {
        path: 'location-course-category',
        page: LocationCourseCategoryMap,
        name: 'Location-Course Category Mapping',
    },
    {
        path: 'participants',
        page: Participant,
        name: 'Participants',
    },
    {
        path: 'team-participant',
        page: TeamParticipantMap,
        name: 'Teams-Participant Mapping',
    },
    {
        path: 'designation-course_category',
        page: DesignationCourseCategoryMap,
        name: 'Designation-Course Category Mapping',
    },
    {
        path: 'program',
        page: Program,
        name: 'Program',
        module: 'program',
    },
    {
        path: 'program-course',
        page: ProgramCourseMap,
        name: 'Link Course to Program',
        module: 'program',
    },
    {
        path: 'course-reviews',
        page: CourseReviews,
        hiddenFromMenu: true,
        module: 'course',
    },
    {
        path: 'live-class-video-recordings',
        page: LiveClassVideoRecordings,
        name: 'Live Class Video Recordings',
        module: 'live-class',
    },
    {
        path: 'mandatory-course',
        page: MandatoryCourse,
        name: 'Mandatory Course',
    },
    // {
    //     path: 'helpAdmin',
    //     page: AdminUserGuide,
    //     name: 'User Guide',
    // },
]);
export default adminRoutes;
