import React from 'react';
import '../helpcss/head.css';
import { ReactComponent as GreenDot } from '../../../../asset/icons/userGuide/Dot.svg';

function Heading({ text, numberOfScreens, ...rest }) {
    return (
        <div className='heading-container' {...rest}>
            <span className='heading-text'>{text}</span>
            <div className='heading-badge'>
                <GreenDot />
                <span>
                    {numberOfScreens} Screen{numberOfScreens > 1 && 's'}
                </span>
            </div>
        </div>
    );
}

export default Heading;

// import React from 'react';
// import { ReactComponent as GreenDot } from '../../../../asset/icons/userGuide/Dot.svg';
// import head from '../helpcss';

// function Heading({ text, numberOfScreens, ...rest }) {
//     return (
//         <flex alignItems='center' mb='4' mt='5' {...rest}>
//             <span>{text}</span>
//             <div
//                 borderRadius='16px'
//                 ml='2'
//                 bg='Green'
//                 py='1'
//                 px='2'
//                 color='darkGreen'
//             >
//                 <GreenDot />
//                 <span pl='1'>
//                     {numberOfScreens} Screen{numberOfScreens > 1 && 's'}
//                 </span>
//             </div>
//         </flex>
//     );
// }

// export default Heading;
