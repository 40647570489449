import { isToday } from 'date-fns';
import React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';

const todayLiveClass = {
    instructor_ID: 'today',
    Instructor_Name: "Today's Live classes",
};
function InstructorSchedule({ schedules }) {
    const [selectedInstructor, selectInstructor] = useState(todayLiveClass);

    const todaysSchedules = schedules.filter((sched) => {
        return isToday(new Date(sched.LIVECLASSSCHEDULESTARTTIME));
    });

    const instructors = schedules.map((schedule) => ({
        instructor_ID: schedule.INSTRUCTOR_ID,
        Instructor_Name: schedule.Instructor_Name,
    }));
    const uniques = {};
    const uniqueInstructors = instructors.filter((obj) => {
        if (!uniques[obj.instructor_ID]) {
            uniques[obj.instructor_ID] = true;
            return true;
        }
        return false;
    });

    let insSchedules = schedules.filter(
        (sched) => sched.INSTRUCTOR_ID === selectedInstructor.instructor_ID
    );
    console.log([todayLiveClass, ...uniqueInstructors]);

    if (selectedInstructor.instructor_ID === 'today') {
        insSchedules = todaysSchedules;
    }

    return (
        <div
            className='p-3 border rounded-smoother h-100'
            style={{ background: '#F9F9F9' }}
        >
            <div className='d-flex justify-content-between pe-3 mb-4'>
                <div className='d-flex flex-column'>
                    <span className='text-primary fw-bol fs-5'>
                        Instructor's Schedule
                    </span>
                    <small className='text-muted'>Upcoming Live classes</small>
                </div>
                <Select
                    {...selectProps}
                    options={[todayLiveClass, ...uniqueInstructors]}
                    onChange={(selected, action) => {
                        selectInstructor(selected);
                    }}
                    defaultValue={() => {
                        return [todayLiveClass, ...uniqueInstructors].find(
                            (ins) =>
                                ins.instructor_ID ===
                                selectedInstructor.instructor_ID
                        );
                    }}
                />
            </div>

            {insSchedules.length === 0 ? (
                <div className='text-center fs-4 pt-5'>
                    {selectedInstructor.instructor_ID === 'today' ? (
                        'No Live class has been scheduled for today'
                    ) : (
                        <span>
                            No Live class has been scheduled by{' '}
                            <span className='text-capitalize'>
                                {selectedInstructor.Instructor_Name.toLowerCase()}
                            </span>
                        </span>
                    )}
                </div>
            ) : (
                <div className='rounded-smoother p-3'>
                    <div className='mb-3 fs-5 fw-bold text-capitalize'>
                        {selectedInstructor.Instructor_Name.toLowerCase()}
                    </div>
                    <Row>
                        {insSchedules.map((item) => (
                            <Schedule
                                startTime={item.LIVECLASSSCHEDULESTARTTIME}
                                endTime={item.LIVECLASSSCHEDULEENDTIME}
                                topic={item.LIVE_CLASS_TOPIC_NAME}
                                instructor={item.Instructor_Name}
                            />
                        ))}
                    </Row>
                </div>
            )}
        </div>
    );
}

function Schedule({ startTime, endTime, topic, instructor }) {
    return (
        <Col sm={4} className='mt-3 px-2'>
            <div className='bg-white rounded-smoother p-3 small h-100 d-flex flex-column'>
                <div>
                    {new Date(startTime).toLocaleDateString('en', {
                        dateStyle: 'medium',
                    })}
                </div>
                <div className='text-end'>
                    <span
                        style={{
                            width: '15px',
                            height: '15px',
                            background: '#6FA5E7',
                            display: 'inline-block',
                            borderRadius: '50%',
                            marginTop: '12px',
                        }}
                    ></span>
                </div>
                <div className='fs-5'>{instructor}</div>
                <div>{topic}</div>
                <div
                    className='small mt-auto pt-2'
                    style={{ fontSize: '.8em' }}
                >
                    {new Date(startTime).toLocaleTimeString('dd', {
                        timeStyle: 'short',
                    })}{' '}
                    -{' '}
                    {new Date(endTime).toLocaleTimeString('dd', {
                        timeStyle: 'short',
                    })}
                </div>
            </div>
        </Col>
    );
}
export default InstructorSchedule;

const selectProps = {
    isSearchable: true,
    theme: (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary: '#021C41',
        },
        spacing: {
            ...theme.spacing,
            controlHeight: 18,
            baseUnit: 4,
        },
    }),
    styles: {
        menu: (provided) => ({
            ...provided,
            zIndex: 10,
        }),
        input: (provided) => ({
            ...provided,
            color: 'white',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'white',
        }),
        control: (provided) => ({
            ...provided,
            fontSize: '.9em',
            borderRadius: '16px',
            borderTopLeftRadius: '0',
            boxShadow: 'none',
            border: 'none',
            background: '#07203F',
            padding: '0 15px',
            width: '180px',
        }),
    },
    getOptionValue: (option) => option.instructor_ID,
    getOptionLabel: (option) => (
        <span className='text-capitalize'>
            {option.Instructor_Name.toLowerCase()}
        </span>
    ),
    placeholder: 'Instructors',
};
