import React from 'react';

const MediaSingleDoc = ({ color }) => {
    return (
        <svg
            width='15'
            height='15'
            viewBox='0 0 15 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <mask
                id='mask0_48_8331'
                style={{ maskType: 'alpha' }}
                maskUnits='userSpaceOnUse'
                x='0'
                y='0'
                width='15'
                height='15'
            >
                <rect width='15' height='15' fill='#D9D9D9' />
            </mask>
            <g mask='url(#mask0_48_8331)'>
                <path
                    d='M5 11.25H10V10H5V11.25ZM5 8.75H10V7.5H5V8.75ZM3.75 13.75C3.40625 13.75 3.11198 13.6276 2.86719 13.3828C2.6224 13.138 2.5 12.8438 2.5 12.5V2.5C2.5 2.15625 2.6224 1.86198 2.86719 1.61719C3.11198 1.3724 3.40625 1.25 3.75 1.25H8.75L12.5 5V12.5C12.5 12.8438 12.3776 13.138 12.1328 13.3828C11.888 13.6276 11.5938 13.75 11.25 13.75H3.75ZM8.125 5.625V2.5H3.75V12.5H11.25V5.625H8.125Z'
                    fill={color}
                />
            </g>
        </svg>
    );
};

export default MediaSingleDoc;
