import React from 'react';

const MediaMultiDoc = ({ color }) => {
    return (
        <svg
            width='15'
            height='15'
            viewBox='0 0 15 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.5191 3.88711L10.1754 1.54336C10.1318 1.49984 10.0801 1.46534 10.0232 1.44181C9.96631 1.41829 9.90533 1.4062 9.84375 1.40625H5.15625C4.90761 1.40625 4.66915 1.50502 4.49334 1.68084C4.31752 1.85665 4.21875 2.09511 4.21875 2.34375V3.28125H3.28125C3.03261 3.28125 2.79415 3.38002 2.61834 3.55584C2.44252 3.73165 2.34375 3.97011 2.34375 4.21875V12.6562C2.34375 12.9049 2.44252 13.1433 2.61834 13.3192C2.79415 13.495 3.03261 13.5938 3.28125 13.5938H9.84375C10.0924 13.5938 10.3308 13.495 10.5067 13.3192C10.6825 13.1433 10.7812 12.9049 10.7812 12.6562V11.7188H11.7188C11.9674 11.7188 12.2058 11.62 12.3817 11.4442C12.5575 11.2683 12.6562 11.0299 12.6562 10.7812V4.21875C12.6563 4.15717 12.6442 4.09619 12.6207 4.03929C12.5972 3.98239 12.5627 3.93067 12.5191 3.88711ZM9.84375 12.6562H3.28125V4.21875H7.7748L9.84375 6.2877V11.2594V12.6562ZM11.7188 10.7812H10.7812V6.09375C10.7813 6.03217 10.7692 5.97119 10.7457 5.91429C10.7222 5.85739 10.6877 5.80567 10.6441 5.76211L8.30039 3.41836C8.25683 3.37484 8.20512 3.34034 8.14821 3.31681C8.09131 3.29329 8.03033 3.2812 7.96875 3.28125H5.15625V2.34375H9.6498L11.7188 4.4127V10.7812ZM8.4375 8.90625C8.4375 9.03057 8.38811 9.1498 8.30021 9.23771C8.2123 9.32561 8.09307 9.375 7.96875 9.375H5.15625C5.03193 9.375 4.9127 9.32561 4.82479 9.23771C4.73689 9.1498 4.6875 9.03057 4.6875 8.90625C4.6875 8.78193 4.73689 8.6627 4.82479 8.57479C4.9127 8.48689 5.03193 8.4375 5.15625 8.4375H7.96875C8.09307 8.4375 8.2123 8.48689 8.30021 8.57479C8.38811 8.6627 8.4375 8.78193 8.4375 8.90625ZM8.4375 10.7812C8.4375 10.9056 8.38811 11.0248 8.30021 11.1127C8.2123 11.2006 8.09307 11.25 7.96875 11.25H5.15625C5.03193 11.25 4.9127 11.2006 4.82479 11.1127C4.73689 11.0248 4.6875 10.9056 4.6875 10.7812C4.6875 10.6569 4.73689 10.5377 4.82479 10.4498C4.9127 10.3619 5.03193 10.3125 5.15625 10.3125H7.96875C8.09307 10.3125 8.2123 10.3619 8.30021 10.4498C8.38811 10.5377 8.4375 10.6569 8.4375 10.7812Z'
                fill={color}
            />
        </svg>
    );
};

export default MediaMultiDoc;
