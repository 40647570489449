import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function CourseCategoryForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const submitHandler = (values, { setSubmitting: setLoading }) => {
        setLoading(true);

        console.log(values);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
                toast.success('Success');
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setLoading(false));
    };

    const initialFormValues = updateValues || {
        Course_Category_NAME: '',
        Course_Category_STATUS: '3',
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Course_Category_NAME: Yup.string()
                    .trim()
                    .required('Course category is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces are allowed'
                    )
                    .notOneOf([''], 'Course category cannot be empty')
                    .required(),
                // Course_Category_STATUS: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormikControl
                        control='input'
                        required
                        name='Course_Category_NAME'
                    />
                    {/* <FormikControl
                        control='select'
                        required
                        name='Course_Category_STATUS'
                    >
                        <option value={''}>Choose Status</option>
                        <option value={1}>Draft</option>
                        <option value={2}>Under Review</option>
                        <option value={3}>Published</option>
                        <option value={4}>Retired</option>
                    </FormikControl> */}

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(CourseCategoryForm);
