import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import MediaSingleDoc from '../../../../asset/icons/MediaSingleDoc';
import MediaMultiDoc from '../../../../asset/icons/MediaMultiDoc';
import MediaUploadForm from './MediaUploadForm';
import MediaBulkUploadForm from './MediaBulkUploadForm';

function MediaUpload({ onCancel, onAfterSubmit }) {
    const [activeTab, setActiveTab] = useState('single');

    const handleTabSelect = (key) => {
        setActiveTab(key);
    };

    return (
        <div className='media-library-form'>
            <Tab.Container
                defaultActiveKey='single'
                activeKey={activeTab}
                onSelect={handleTabSelect}
                className='bg-danger w-100'
            >
                <Nav
                    variant='pills'
                    className='my-3 justify-content-center nav-tabs mx-auto'
                >
                    <Nav.Item className='w-50 text-center'>
                        <Nav.Link
                            type='button'
                            className='p-2 small'
                            eventKey='single'
                        >
                            <span
                                style={{
                                    color:
                                        activeTab === 'single'
                                            ? '#ffffff'
                                            : '#10182875',
                                }}
                                className='fw-bold me-1'
                            >
                                Add Media File
                            </span>
                            <span>
                                <MediaSingleDoc
                                    color={
                                        activeTab === 'single'
                                            ? '#ffffff'
                                            : '#7F8B9C'
                                    }
                                />
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='w-50 text-center'>
                        <Nav.Link
                            type='button'
                            className='p-2 small'
                            eventKey='multi'
                        >
                            <span
                                style={{
                                    color:
                                        activeTab === 'multi'
                                            ? '#ffffff'
                                            : '#10182875',
                                }}
                                className='fw-bold me-1'
                            >
                                Add Media Files
                            </span>
                            <span>
                                <MediaMultiDoc
                                    color={
                                        activeTab === 'multi'
                                            ? '#ffffff'
                                            : '#7F8B9C'
                                    }
                                />
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey='single' title='Single file upload'>
                        <MediaUploadForm
                            onCancel={onCancel}
                            onAfterSubmit={onAfterSubmit}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey='multi' title='Multiple file upload'>
                        <MediaBulkUploadForm
                            onCancel={onCancel}
                            onAfterSubmit={onAfterSubmit}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
}

export default MediaUpload;
