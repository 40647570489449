import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import DesignationCourseCategoryMapForm from './DesignationCourseCategoryMapForm';

function DesignationCourseCategoryMap() {
    const fetchUtils = useAxiosGet(endpoints.designationCourseCategory.get);

    // can use cellmodifer here,
    // but if used, searching for the value in modified cell and also download table data wont work
    // so added a new property (teamscourseMapping) to show the values in array
    let mapping = [];
    if (!fetchUtils.loading) {
        mapping = fetchUtils.response.map((data) => {
            if (Array.isArray(data.designationCourse)) {
                data.designationcourseMapping = data.designationCourse
                    .map((courseCat) => courseCat.Course_Category_NAME)
                    .join(', ');
            }
            return data;
        });
    }
    return (
        <>
            <CRUDTable
                endpoints={endpoints.designationCourseCategory}
                Form={DesignationCourseCategoryMapForm}
                addBtnLabel='Map Category to Designation'
                {...fetchUtils}
                response={mapping}
                {...viewProps.DesignationCourseCategoryMap}
                name='Designation-Course Category Mapping'

            />
        </>
    );
}

export default DesignationCourseCategoryMap;
