import FormikControl from '../../../components/formikControl/FormikControl';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import QuizContent from './QuizContent';
function QuizCreation({ setFieldValue, courseCategoryId, formValues }) {
    return (
        <Row>
            <Col sm='12'>
                <FormikControl
                    control='input'
                    required
                    label='Quiz Title'
                    placeholder='Add Title'
                    name='Quiz.Quiz_Title'
                    className='w-100 d-inline-block mb-3'
                />
            </Col>
            <Col sm='6'>
                <FormikControl
                    control='input'
                    required
                    label='Total Number of Questions'
                    type='number'
                    placeholder='Add Choices'
                    name='Quiz.Question_Number'
                    className='w-100 d-inline-block mb-3'
                />
            </Col>

            <Col sm='6'>
                <FormikControl
                    control='input'
                    label='Pass Mark (Percentage)'
                    required
                    type='number'
                    title='Enter the Pass Mark Percentage'
                    placeholder='Enter the Pass Mark Percentage'
                    name='Quiz.PassMark'
                    className='w-100 d-inline-block mb-3'
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                />
            </Col>

            <Col sm='6'>
                <FormikControl
                    control='input'
                    required
                    type='number'
                    label='Retake Attempts'
                    placeholder='Mention the attempts'
                    name='Quiz.Retake_Attempts'
                    className='w-100 d-inline-block mb-3'
                />
            </Col>
            <Col
                sm='12'
                className='mt-3'
                style={{ fontSize: '17px', fontWeight: '500' }}
            >
                Assign Question Tag to Course Structure
                <QuizContent
                    formValues={formValues}
                    setFieldValue={setFieldValue}
                    courseCategoryId={courseCategoryId}
                />
            </Col>
        </Row>
    );
}

export default QuizCreation;
