import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide';

const LinkCourseToProgram = ({ direction = 'column' }) => {
    const [column] = useState(direction === 'column');
    const steps = [
        {
            id: 268,
            para: 'Clicking on the "Link Course to Program" module in the sidebar allows you to navigate to the Link Course to Program page.',
            imageUrl:
                images.Admin_LinkCourse_img_1,
        },
        {
            id: 269,
            para: 'Here, we can see the list of Course and Program Mapping.',
            imageUrl:
                images.Admin_LinkCourse_img_2,
        },
        {
            id: 270,
            para: 'To add a course to program mapping, click on "Add Mapping".',
            imageUrl:
                images.Admin_LinkCourse_img_3,
        },
        {
            id: 271,
            para: 'Fill the form by selecting "Program" and "Course".',
            imageUrl:
                images.Admin_LinkCourse_img_4,
        },
        {
            id: 272,
            para: 'Press the "Submit" button to successfully add the Course and Program mapping.',
            imageUrl:
                images.Admin_LinkCourse_img_5,
        },
        {
            id: 273,
            para: 'Here, you can see the newly added mapping of Course to Program.',
            imageUrl:
                images.Admin_LinkCourse_img_6,
        },
        {
            id: 274,
            para: 'Click on the "Download Icon" to download the Course and program mapping details as an Excel file.',
            imageUrl:
                images.Admin_LinkCourse_img_7,
        },
        {
            id: 275,
            para: 'To modify the Course and Program mapping, click the "Edit" button.',
            imageUrl:
                images.Admin_LinkCourse_img_8,
        },
        {
            id: 276,
            para: 'Update the mapping details to modify the mapping of course and program.',
            imageUrl:
                images.Admin_LinkCourse_img_9,
        },
        {
            id: 277,
            para: 'Press the "Submit" button to successfully update the mapping of Course to Program.',
            imageUrl:
                images.Admin_LinkCourse_img_10,
        },
        {
            id: 278,
            para: 'Click the "Delete" button to delete the mapping of Course to Program.',
            imageUrl:
                images.Admin_LinkCourse_img_11,
        },
    ];

    return (
        <div className={`dashboard-container ${column ? '' : 'dashboard-row'}`}>
            <div
                className={`dashboard-header ${column ? '' : 'dashboard-row'}`}
            >
                <Heading text='Dashboard' numberOfScreens={steps.length} />
            </div>
            <ScrollDiv>
                <div
                    className={`dashboard-scroll ${column ? '' : 'dashboard-row'
                        }`}
                >
                    <div
                        className={`dashboard-heading ${column ? '' : 'dashboard-row'
                            }`}
                    >
                        <Heading
                            mb='0'
                            text='Media Library Approval'
                            numberOfScreens={steps.length}
                        />
                    </div>
                    {steps.map((step) => (
                        <div className='dashboard-step' key={step.id}>
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={direction}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default LinkCourseToProgram;
