import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import {useAxiosGet} from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import DepartmentForm from './DepartmentForm';
import viewProps from '../viewProps'

function Department() {
    const fetchUtils = useAxiosGet(endpoints.department.get)

    return (
        <>
            <CRUDTable
                endpoints={endpoints.department}
                Form={DepartmentForm}
                {...viewProps.Department}
                // irremovable
                {...fetchUtils}

            />
        </>
    );
}

export default Department;
