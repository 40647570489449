import PaginatedLineChart from '../../../components/PaginatedChart';

function CourseGrowth({ counts }) {
    const courses = [];
    const participantCounts = [];

    counts.forEach((count) => {
        let courseName =
            count.Course_NAME.length > 10
                ? count.Course_NAME.slice(0, 10) + '...'
                : count.Course_NAME;
        courses.push(courseName);
        participantCounts.push(count.Total_Participants);
    });

    return (
        <div
            className='bg-white rounded-smoother h-100 p-3 pb-0 pr-4'
            style={{
                borderLeft: '10px solid #70A6E8',
            }}
        >
            <div className='fw-bold mb-2'>Course Growth</div>
            <div className='d-flex'>
                <div className='d-flex justify-content-between flex-column py-4 mb-5'>
                    <small className='text-muted'>Enrollments</small>
                    <small className='text-muted'>Course Category</small>
                </div>
                <div className='flex-fill'>
                    <PaginatedLineChart
                        dataPerPage={10}
                        data={{
                            labels: [...courses],
                            datasets: [...participantCounts],
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default CourseGrowth;
