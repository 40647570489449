import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import {useAxiosGet} from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import CourseCategoryForm from './CourseCategoryForm';

function CourseCategory() {

    const fetchUtils = useAxiosGet(endpoints.courseCategory.get);

    const cellModifier = {
        Course_Category_STATUS: ({value}) => {
            switch(Number(value)){
                case 1: 
                return 'In draft'
                case 2:
                return 'Under Review'  
                case 3:
                return 'Published'  
                case 4:
                return 'Retired'  

                default:
                return value

            }
        }
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.courseCategory}
                Form={CourseCategoryForm}
                cellModifier={cellModifier}
                {...fetchUtils}
                // irremovable
                {...viewProps.CourseCategory}
            />
        </>
    );
}

export default CourseCategory;
