import React from 'react';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import Draft from './Draft';
import Published from './Published';
import Retired from './Retired';
import { Button, Tab, Tabs } from 'react-bootstrap';
import AllCourses from './AllCoures';
import { useNavigate } from 'react-router-dom';
import CustomSecondaryBtn from '../../../components/CustomSecondaryBtn';
import WithModal from '../../../components/WithModal';
import ContentsForm from './ContentsForm';

function Course() {
    const { response, ...fetchUtils } = useAxiosPost(
        endpoints.course.selectBy,
        {
            selectcolumns: '*',
            wherecondition: '(1=1)',
        }
    );
    const navigate = useNavigate();

    const draft = response.filter(
        (content) => Number(content.Course_STATUS) === 1
    );
    const approved = response.filter(
        (content) => Number(content.Course_STATUS) === 3
    );
    const retired = response.filter(
        (content) => Number(content.Course_STATUS) === 4
    );

    const cellModifier = {
        Structure: ({ row }) => (
            <CustomSecondaryBtn
                onClick={() => {
                    navigate('/course-structure-approve', {
                        state: {
                            courseId: row.original.Course_ID,
                            versionId: row.original.Version_ID,
                            courseCategoryId: row.original.Course_Category_ID,
                        },
                    });
                }}
            >
                Structure
            </CustomSecondaryBtn>
        ),
        Contents: ({ row }) => (
            <WithModal
                renderModalBody={({ closeModal }) => (
                    <ContentsForm values={row.original} />
                )}
                modalTitle={'Contents in Course'}
            >
                <span
                    onClick={() => {}}
                    className='py-1 px-1 border-2 text-info'
                    type='button'
                >
                    Contents in course
                </span>
            </WithModal>
        ),
        Reviews: ({ row }) => (
            <span
                onClick={() => {
                    navigate('/course-reviews', {
                        state: {
                            courseId: row.original.Course_ID,
                            courseName: row.original.Course_NAME,
                        },
                    });
                }}
                className='py-1 px-1 border-2 text-warning'
                type='button'
            >
                View Reviews
            </span>
        ),
        COURSE_DP_URL: ({ value }) => {
            if (value) {
                return (
                    <div
                        style={{
                            width: '50px',
                            height: '50px',
                            backgroundImage: 'url(' + value + ')',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                );
            }
            return <div>No image for Course</div>;
        },
    };
    return (
        <Tabs fill className='mx-5' mountOnEnter>
            <Tab eventKey='draft' title='Draft'>
                <Draft
                    response={draft}
                    fetchUtils={fetchUtils}
                    courseCellModifier={cellModifier}
                />
            </Tab>

            <Tab eventKey='published' title='Published'>
                <Published
                    response={approved}
                    fetchUtils={fetchUtils}
                    courseCellModifier={cellModifier}
                />
            </Tab>
            <Tab eventKey='retired' title='Retired'>
                <Retired
                    response={retired}
                    fetchUtils={fetchUtils}
                    courseCellModifier={cellModifier}
                />
            </Tab>
            <Tab eventKey='all' title='All Courses'>
                <AllCourses
                    response={response}
                    fetchUtils={fetchUtils}
                    courseCellModifier={cellModifier}
                />
            </Tab>
        </Tabs>
    );
}

export default Course;
