import React from 'react';

function CustomSecondaryBtn({children,...props}) {
    return (
        <span
            type='button'
            className='text-primary border small border-secondary p-2 py-1 rounded-3'
            {...props}
        >
            {children}
        </span>
    );
}

export default CustomSecondaryBtn;
