import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide';

const ChangePassword = () => {
    const steps = [
        {
            id: 1,
            para: 'Click on the "Profile" Icon to "Change Password" & "Logout".',
            imageUrl: images.Admin_ChangePassword_img_1,
        },
        {
            id: 2,
            para: 'Click on "Change Password" to change the password.',
            imageUrl: images.Admin_ChangePassword_img_2,
        },
        {
            id: 3,
            para: 'Enter the "Old password", "New password" & "Confirm Password".',
            imageUrl: images.Admin_ChangePassword_img_3,
        },
        {
            id: 4,
            para: 'Clicking the "Change" button, the password is updated successfully.',
            imageUrl: images.Admin_ChangePassword_img_4,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Change password'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default ChangePassword;
