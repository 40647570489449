import axios from 'axios';
import React from 'react';
import { Col, FormCheck, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import endpoints from '../../../services/endpoints';
import withAlert from '../../../hoc/withAlert';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import toast from 'react-hot-toast';

function NoMoreSchedule({ data, setLoading, refreshStructure, showAlert }) {
    const onChange = () => {
        const endpoint =
            Number(data?.no_more_lc_shedules) === 2
                ? endpoints.liveclass.endSchedules
                : endpoints.liveclass.restartSchedules;

        setLoading(true);

        axios
            .post(endpoint, {
                Course_ID: data.Course_ID,
                Course_Structure_Content_ID: data.Course_Structure_Content_ID,
                Course_Structure_ID: data.Course_Structure_ID,
            })
            .then((res) => {
                refreshStructure();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // const infoContent =
    //     Number(data?.no_more_lc_shedules) === 2
    //         ? `By turning off this setting, you make sure that
    //         no more schedules will be assigned to this topic.`
    //         : '';

    const Information = () => (
        <Tooltip>
            <p className='small'></p>
        </Tooltip>
    );

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <FormCheck
                type='switch'
                checked={Number(data?.no_more_lc_shedules) !== 1}
                value={data?.no_more_lc_shedules}
                onChange={onChange}
                disabled={Number(data?.no_more_lc_shedules) === 3}
            />
            <OverlayTrigger
                overlay={
                    <Tooltip>
                        <p className='small'>
                            {Number(data?.no_more_lc_shedules) === 3
                                ? `This live
                                    class cannot be disabled as it has not yet been
                                    scheduled at least once.`
                                : `Turning off this setting makes sure that no more schedules will
                                    be assigned to this topic.`}
                        </p>
                    </Tooltip>
                }
            >
                <span
                    className='ms-3 d-flex align-items-center justify-content-center border border-dark fw-bolder'
                    style={{
                        display: 'inline-block',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        cursor: 'help',
                        fontFamily: 'monospace',
                    }}
                >
                    i
                </span>
            </OverlayTrigger>
        </div>
    );
}

export default withAlert(NoMoreSchedule);
