import React, { useState } from 'react';
import { ReactComponent as DownloadTemp } from '../../../../asset/icons/MediaDownloadTemp.svg';
import { Button } from 'react-bootstrap';
import MediaUploadSelector from './MediaUploadSelector';
import SelectedMedia from './SelectedMedia';
import toast from 'react-hot-toast';
import extractErrorFromRes from '../../../../helpers/extractErrorFromRes';
import {
    confirmMediaAdd,
    getMediaBulkUploadLink,
    uploadMedia,
} from '../mediaServices';
import Loader from '../../../../components/Loader';
import ErrorsView from './ErrorsView';
import endpoints from '../../../../services/endpoints';
import axios from 'axios';

function MediaBulkUploadForm({ onCancel, onAfterSubmit }) {
    const [mediaFiles, setMediaFiles] = useState([]);
    const [excelFile, setExcelFile] = useState(null);
    const [progress, setProgress] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [view, setView] = useState('upload');
    const [errors, setErrors] = useState([]);
    const [simpleError, setSimpleError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleMediaSelect = (event) => {
        const files = event.target.files;
        setMediaFiles([...mediaFiles, ...files]);
        setSimpleError('');
    };
    const handleExcelSelect = (event) => {
        const file = event.target.files[0];
        setExcelFile(file);
        setSimpleError('');
    };

    const handleTemplateDownload = () => {
        setLoading(true);
        axios({
            url: endpoints.mediaLibrary.downloadTemplete,
            method: 'GET',
            responseType: 'blob',
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'Media Bulk Upload template' + '.xlsx'
                );
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmit = async () => {
        const mediaNames = mediaFiles.map((file) => file.name);

        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('excel_file', excelFile);
        formData.append('media_filenames', JSON.stringify(mediaNames));

        try {
            const data = await getMediaBulkUploadLink(formData);

            const urls = data.data.responseData;

            // combine media and corresponding urls to same object
            const mediaAndUrls = urls.map((url) => {
                const matchMedia = mediaFiles.find((media) => {
                    return url.filename === media.name;
                });
                url.file = matchMedia;
                return url;
            });

            // set upload progress of all media to 0
            const initialProgress = mediaNames.reduce((obj, item) => {
                obj[item] = 0;
                return obj;
            }, {});

            setProgress(initialProgress);

            async function uploadAllMedia() {
                try {
                    await Promise.all(
                        mediaAndUrls.map(async (media) => {
                            await uploadMedia(
                                media.signedUploadURL,
                                media.file,
                                () => {}, // since setProgress logic is different here
                                {
                                    onUploadProgress: (ProgressEvent) => {
                                        setProgress((prev) => ({
                                            ...prev,
                                            [media.filename]: Math.round(
                                                (ProgressEvent.loaded /
                                                    ProgressEvent.total) *
                                                    100
                                            ),
                                        }));
                                    },
                                }
                            );
                        })
                    );
                    setIsSubmitting(false);
                    setProgress({});
                } catch (error) {
                    setIsSubmitting(false);
                    setProgress({});
                    toast.error(extractErrorFromRes(error));
                }
            }

            try {
                await uploadAllMedia();
                await confirmMediaAdd();
                setMediaFiles([]);
                setExcelFile(null);
                onAfterSubmit();
            } catch (error) {
                setIsSubmitting(false);
                setProgress({});
                toast.error(extractErrorFromRes(error));
            }
        } catch (error) {
            setIsSubmitting(false);
            setProgress({});

            if (error.response?.data?.errors) {
                setErrors(error.response.data?.errors);
                setView('errors');
            } else {
                setSimpleError(extractErrorFromRes(error));
            }
        }
    };
    const handleMediaDelete = () => {
        setSimpleError('');
        setMediaFiles([]);
    };
    const handleExcelDelete = () => {
        setSimpleError('');
        setExcelFile(null);
    };

    const averageLoadingProgress = Math.round(
        Object.values(progress).reduce((prev, curr) => prev + curr, 0) /
            mediaFiles.length
    );

    return (
        <>
            {view === 'errors' ? (
                <ErrorsView
                    errors={errors}
                    onBack={() => setView('upload')}
                    noOfFiles={mediaFiles.length}
                    onClose={onCancel}
                />
            ) : (
                <div>
                    <div
                        className='fs-5'
                        style={{
                            fontWeight: '600',
                        }}
                    >
                        Select files to upload
                    </div>
                    <div className='text-muted mt-2'>
                        You can select multiple files for upload.
                    </div>
                    <div
                        style={{
                            border: '1px solid #00000005',
                            margin: '10px -24px',
                        }}
                    />

                    {mediaFiles.length > 0 ? (
                        <SelectedMedia
                            title={'Media Files Selected'}
                            selectedTitle={
                                'Total Media Files : ' + mediaFiles.length
                            }
                            onDelete={handleMediaDelete}
                        />
                    ) : (
                        <>
                            <div
                                style={{ color: '#454545', fontWeight: '500' }}
                            >
                                Upload Media Files
                            </div>
                            <MediaUploadSelector
                                onChange={handleMediaSelect}
                                label='PNG, JPG, JPEG, MP4, PDF'
                                id='media-upload'
                                inputAttrs={{ multiple: true }}
                            />
                        </>
                    )}
                    <div
                        style={{
                            border: '1px solid #00000005',
                            margin: '14px -24px',
                        }}
                    />
                    <div className='d-flex justify-content-between align-items-center'>
                        <span
                            style={{
                                color: '#454545',
                                fontWeight: '700',
                            }}
                        >
                            Upload Excel Document
                        </span>
                        <Button
                            style={{ background: '#2E8BFF21' }}
                            className='border-0'
                            onClick={handleTemplateDownload}
                        >
                            <DownloadTemp />
                            <span
                                style={{
                                    color: '#555555',
                                    fontSize: '13px',
                                    fontWeight: '500',
                                }}
                            >
                                {' '}
                                Download Template
                            </span>
                        </Button>
                    </div>
                    <div
                        style={{
                            color: '#667085',
                            fontSize: '14px',
                            textAlign: 'justify',
                            marginTop: '5px',
                        }}
                    >
                        Ensure media file names in the Excel sheet exactly match
                        the uploaded file names.
                    </div>
                    {excelFile ? (
                        <SelectedMedia
                            title={'Excel File Selected'}
                            selectedTitle={excelFile?.name}
                            onDelete={handleExcelDelete}
                        />
                    ) : (
                        <MediaUploadSelector
                            onChange={handleExcelSelect}
                            label='Excel File - .xlsx'
                            accept='.xlsx'
                            id='excel-upload'
                        />
                    )}

                    {isSubmitting && (
                        <Loader
                            type='progress'
                            progress={averageLoadingProgress}
                        />
                    )}
                    {loading && <Loader />}

                    {!!simpleError && (
                        <div
                            className='text-danger small mt-2'
                            style={{ wordBreak: 'break-word' }}
                        >
                            {simpleError}
                        </div>
                    )}

                    <div className='text-center pt-4 '>
                        <Button
                            className='rounded-smooth'
                            type='submit'
                            style={{
                                width: '100%',
                                padding: '12px 0',
                                background: '#107BFF',
                                border: 'none',
                                fontSize: '16px',
                                fontWeight: '600',
                            }}
                            disabled={mediaFiles.length === 0 || !excelFile}
                            onClick={handleSubmit}
                        >
                            <span className='small'>Submit</span>
                        </Button>
                        <Button
                            className='me-4 mt-3 rounded-smooth'
                            variant='outline-light'
                            onClick={onCancel}
                            style={{
                                padding: '12px 0',
                                width: '100%',
                                border: ' 1px solid #107BFF',
                                color: '#107BFF',
                                fontSize: '16px',
                                fontWeight: '500',
                            }}
                        >
                            <span className='small'>Cancel</span>
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
}

export default MediaBulkUploadForm;
