import { Field, ErrorMessage, getIn } from 'formik';
import React, { useState } from 'react';
import { Form as BSForm } from 'react-bootstrap';
import Select, { components } from 'react-select';
import { ReactComponent as DropDownIcon } from '../../asset/icons/DropdownIcon.svg';

function LtdAdvancedSelect({
    name,
    icon = <></>,
    dropdownIcon = <DropDownIcon />,
    options = [{ name: 'provide options' }],
    optionValue = 'name',
    optionLabel = 'name',
    label,
    onChange,
    isMulti = false,
    className = 'mb-3',
    disabled = false,
    size = 'md',
    required = false,
    ErrorFeedback,
    validityClass,
    fontSize,
    keyProp = '',
    labelProvider,
    ...props
}) {
    const [selectedValue,setSelected]=useState();
    const onChangeHandler = (selected, action, setFieldValue) => {
        if (!!keyProp) {
            if (isMulti) {
                const temp = [];
                selected.forEach((item) => {
                    temp.push({
                        [keyProp]: item[optionValue],
                    });
                });
                setFieldValue(name, temp);
            } else {
                setFieldValue(
                    name,
                    selected ? { [keyProp]: selected[optionValue] } : ''
                );
            }
        } else {
            if (onChange) {
                if (action.action === 'clear') {
                    onChange(isMulti ? [] : {}, action);
                    return;
                }
                onChange(selected, action);
            } else {
                if (isMulti) {
                    setFieldValue(
                        name,
                        selected.map((obj) => obj[optionValue])
                    );
                } else {
                    setFieldValue(name, selected ? selected[optionValue] : '');
                }
            }
        }
    };

    const CustomControl = ({ children, ...rest }) => (
        <components.Control {...rest}>
            {icon && (
                <span style={{ marginLeft: '10px', display: 'flex' }}>
                    {icon}
                </span>
            )}
            {children}
        </components.Control>
    );

    const CustomDropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            {dropdownIcon}
        </components.DropdownIndicator>
    );

    return (
        <BSForm.Group
            className={className}
            controlId={name}
        >
            <Field name={name}>
                {({
                    form: {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        setFieldTouched,
                    },
                }) => (
                    <>
                        <BSForm.Label>
                            {label ||
                                labelProvider[name] ||
                                name ||
                                'Provide Label'}
                            {required && (
                                <span
                                    className={`small 
                                    ${
                                        isMulti
                                            ? validityClass(
                                                  getIn(errors, name),
                                                  values[name][0],
                                                  getIn(touched, name)
                                              )
                                            : validityClass(
                                                  getIn(errors, name),
                                                  getIn(values, name),
                                                  getIn(touched, name)
                                              )
                                    }`}
                                >
                                    *
                                </span>
                            )}
                        </BSForm.Label>

                        <Select
                            isClearable
                            isMulti={isMulti}
                            isSearchable
                            name={name}
                            isDisabled={disabled}
                            className='border-0'
                            onChange={(selected, action) =>
                                onChangeHandler(selected, action, setFieldValue)
                            }
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: '#021C41',
                                },
                                spacing: {
                                    ...theme.spacing,
                                    controlHeight: 18,
                                    baseUnit: 4,
                                },
                            })}
                            styles={{
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 10,
                                }),

                                control: (provided, state) => ({
                                    ...provided,
                                    fontSize: '.9em',
                                    borderRadius: '36px',
                                    boxShadow: 'none',
                                    border: '1px solid #75757580',
                                    background: '#FFFFFF',
                                    display: 'flex',
                                    alignItems: 'center',
                                }),
                                indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: 'none',
                                }),
                            }}
                            components={{
                                Control: CustomControl,
                                DropdownIndicator: CustomDropdownIndicator,
                            }}
                            getOptionValue={(option) => option[optionValue]}
                            onBlur={() => {
                                setFieldTouched(name, true);
                            }}
                            options={options}
                            getOptionLabel={(option) => option[optionLabel]}
                            {...props}
                        />
                    </>
                )}
            </Field>
            <ErrorMessage
                name={name}
                component={ErrorFeedback}
            />
        </BSForm.Group>
    );
}

export default LtdAdvancedSelect;
