function Count({ item, count, bg }) {
    
    return (
        <div
            className='flex-column d-flex justify-content-center border border-2 border-dark align-items-center rounded-smoother'
            style={{
                height: '140px',
                background: bg,
                boxShadow: '5px 4px 4px 2px rgba(0, 0, 0, 0.25)',
            }}
        >
            <span className='fw-bold'>{item}</span>
            <span className='fs-1' style={{ fontWeight: '800' }}>
                {count}
            </span>
        </div>
    );
}

export default Count;
