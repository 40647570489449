import axios from 'axios';
import React from 'react';
import PDFPreview from '../../../components/PDFPreview';
import CRUDTable from '../../../components/table/CRUDTable';
import VideoPreview from '../../../components/VideoPreview';
import WithModal from '../../../components/WithModal';
import { useAuthenticationState } from '../../../context/Auth.context';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import { ReactComponent as PlayButton } from '../../../asset/icons/PlayButton.svg';
import { ReactComponent as Document } from '../../../asset/icons/Document.svg';
import { ReactComponent as ImgPreview } from '../../../asset/icons/ImgPreview.svg';
import withAlert from '../../../hoc/withAlert';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import { Button, Image } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import MediaUpload from '../../faculty/mediaLibrary/mediaForm/Index';

function ForReview({ showAlert, fetchUtils }) {
    const { user } = useAuthenticationState();

    const approveMedia = (body, closeModal) => {
        fetchUtils.setLoading(true);
        body.APPROVEDBY = user.id;
        axios
            .post(endpoints.mediaLibrary.approve, body)
            .then((res) => {
                closeModal();
                fetchUtils.reFetch();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => {
                fetchUtils.setLoading(false);
            });
    };

    const cellModifier = {
        EXPIRY_DATE: ({ value }) => {
            return value ? format(new Date(value), 'dd/MM/yyyy') : 'NA';
        },
        Approve: ({ row }) => (
            <WithModal
                modalTitle={'Approve Media ?'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <div className='text-center'>
                        <Button
                            variant='outline-danger'
                            className='mx-2 px-4'
                            onClick={() =>
                                approveMedia(row.original, closeModal)
                            }
                        >
                            Yes
                        </Button>
                        <Button
                            variant='outline-success'
                            className='mx-2 px-4'
                            onClick={closeModal}
                        >
                            No
                        </Button>
                    </div>
                )}
            >
                <span className='border border-success p-1 text-success rounded'>
                    Approve
                </span>
            </WithModal>
            // <span
            //     className='border border-success p-1 text-success rounded'
            //     type='button'
            //     onClick={() => approveMedia(row.original)}
            // >
            //     Approve
            // </span>
        ),
        MEDIA_URL: ({ row, value }) => {
            switch (row.original.MEDIA_TYPE) {
                case 1:
                    return (
                        <WithModal
                            modalAttrs={{ backdrop: 'none' }}
                            modalTitle={row.original.MEDIA_DESC}
                            renderModalBody={(closeModal) => (
                                <div className='text-center'>
                                    <Image
                                        style={{ width: '100%' }}
                                        src={value}
                                    />
                                </div>
                            )}
                        >
                            <ImgPreview />
                        </WithModal>
                    );
                case 2 || 3:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <VideoPreview url={value} />
                                </div>
                            )}
                        >
                            <PlayButton />
                        </WithModal>
                    );
                case 4:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <PDFPreview url={value} />
                                </div>
                            )}
                        >
                            <Document />
                        </WithModal>
                    );
                case 5:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <VideoPreview url={value} />
                                </div>
                            )}
                        >
                            <PlayButton />
                        </WithModal>
                    );
                default:
                    return <span>Error</span>;
            }
        },
    };
    return (
        <>
            <CRUDTable
                immutable
                endpoints={endpoints.mediaLibrary}
                insertable={false}
                headerExtras={
                    user.type === 'BOTH' && (
                        <WithModal
                            renderModalBody={(closeModal) => (
                                <MediaUpload
                                    onCancel={closeModal}
                                    onAfterSubmit={() => {
                                        closeModal();
                                        fetchUtils.reFetch();
                                    }}
                                />
                            )}
                            disableHeader
                        >
                            <Button
                                variant='transparent'
                                className='rounded-smooth bg-white border-0'
                                style={{
                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                                }}
                                size='sm'
                            >
                                <small>Add Media</small>
                            </Button>
                        </WithModal>
                    )
                }
                {...fetchUtils}
                response={fetchUtils.response.forReview}
                relevants={[...viewProps.MediaApproval.relevants, 'Approve']}
                columnHeads={[
                    ...viewProps.MediaApproval.columnHeads,
                    'Approve',
                ]}
                name='Review Media'
                cellModifier={cellModifier}
            />
        </>
    );
}

export default withAlert(ForReview);
