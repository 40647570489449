import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';

function Retired({ fetchUtils, response, courseCellModifier }) {
const cellModifier = {
    courserating: ({ row, value }) => {
        if (value) {
            return value / (row.original?.rated_participants_count || 1);
        }
        console.log(row.original.rated_participants_count);
        return 'No rating';
    },
    ...courseCellModifier,
};
    return (
        <CRUDTable
            endpoints={endpoints.course}
            relevants={[...viewProps.Course.relevants]}
            columnHeads={[...viewProps.Course.columnHeads]}
            {...fetchUtils}
            cellModifier={cellModifier}
            name='Retired Courses'
            response={response}
            immutable
            insertable={false}
            formSize='md'
        />
    );
}

export default Retired;
