import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Loader from '../../../components/Loader';
import { useAuthenticationState } from '../../../context/Auth.context';
import { useAxiosPostMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import ClassSchedule from './ClassSchedule';
import Count from './Count';
import OngoingCourses from './OngoingCourses';
import StudentProgress from './StudentProgress';
import TopStudents from './TopStudents';
import LCMissedParticipants from './LCMissedParticipants';

function Dashboard() {
    const { user } = useAuthenticationState();

    const { response, loading } = useAxiosPostMultiple({
        counts: {
            url: endpoints.ins_overview.counts,
            data: { instructor_ID: user.id },
        },
        ongoingCourses: {
            url: endpoints.ins_overview.onGoingCourses,
            data: { instructor_ID: user.id },
        },
        topStudents: {
            url: endpoints.ins_overview.topStudents,
            data: { instructor_ID: user.id },
        },
        studentProgress: {
            url: endpoints.ins_overview.studentProgress,
            data: { instructor_ID: user.id },
        },
        liveClassSchedules: {
            url: endpoints.ins_overview.liveClassSchedules,
            data: { instructor_ID: user.id },
        },
        lcMissedList: {
            url: endpoints.ins_overview.lcMissedList,
            data: { INSTRUCTOR_ID: user.id },
        },
    });

    if (loading) {
        return <Loader />;
    }
    const counts = {
        publishedCourseCount: 'Error',
        allCoursesCount: 'Error',
        participantsCount: 'Error',
    };

    const topStudents = response.topStudents.sort(
        (x, y) => y.studentrating - x.studentrating
    );

    return (
        <Row className='pt-4'>
            <Col md={8} className='pe-5'>
                <Row style={{ width: '90%' }}>
                    <Col md='4'>
                        <Count
                            item={'Ongoing Courses'}
                            count={
                                response.counts?.publishedCourseCount ??
                                counts.publishedCourseCount
                            }
                            bg='#A2EA74'
                        />
                    </Col>
                    <Col md='4'>
                        <Count
                            item={'All Courses'}
                            count={
                                response.counts?.allCoursesCount ??
                                counts.allCoursesCount
                            }
                            bg='#A6AAF4'
                        />
                    </Col>
                    <Col md='4'>
                        <Count
                            item={'Participants'}
                            count={
                                response.counts?.participantsCount ??
                                counts.participantsCount
                            }
                            bg='#FEE4CB'
                        />
                    </Col>
                </Row>
                <OngoingCourses
                    courses={response.ongoingCourses?.course_array}
                />
                <TopStudents studentsList={topStudents} />
                <StudentProgress studentProgress={response.studentProgress} />
            </Col>
            <Col md={4}>
                <div className='d-flex flex-column'>
                    <ClassSchedule schedules={response.liveClassSchedules} />
                    <LCMissedParticipants/>
                </div>
            </Col>
        </Row>
    );
}

export default Dashboard;
