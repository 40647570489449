import axios from "axios";
import endpoints from "./endpoints";

export const approveStructure = (body, user) => {
    body.Course_Structure_Status = 3;
    body.Review_Comments = "_";
    body.Reviewed_By = user;
    body.Reviewed_Status = 1;
    body.Approved_By = user;

    return axios.post(endpoints.courseStructure.update, body);
};

export const rejectStructure = (body, user) => {

    body.Course_Structure_Status = 2;
    body.Review_Comments = "_";
    body.Reviewed_By = user;
    body.Reviewed_Status = 1;
    body.Approved_By = user;

    return axios.post(endpoints.courseStructure.update, body);
};

export const submitStructureForReview = (body) => {
    body.Course_Structure_Status = 2;
    body.Review_Comments = "_";
    body.Reviewed_By = "0";
    body.Reviewed_Status = 2;
    body.Approved_By = "0";

    return axios.post(endpoints.courseStructure.update, body);
};