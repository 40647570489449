import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function TeamParticipantMapForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response, loading } = useAxiosGetMultiple({
        teams: endpoints.teams.get,
        participants: endpoints.participant.get,
    });

    const { teams, participants } = response;

    const submitHandler = (values, { setSubmitting }) => {
        setSubmitting(true);

        const updatedObject = { ...values };

        updatedObject.teamParticipant = updatedObject.teamParticipant.map(
            (participant) => {
                if ('PARTICIPANT_ID' in participant) {
                    const { PARTICIPANT_ID, ...rest } = participant;
                    return { Participant_ID: PARTICIPANT_ID, ...rest };
                } else {
                    return participant;
                }
            }
        );

        axios
            .post(endpoint, updatedObject)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        teamParticipant: [],
        Team_ID: '',
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOptions = {
        team: teams.find(
            (team) => Number(team.TEAM_ID) === Number(initialFormValues.Team_ID)
        ),
        // courseCat: courseCategories.find(
        //     (course) =>
        //         Number(course.Course_Category_ID) ===
        //         Number(initialFormValues.teamparticipant)
        // ),
        particip: participants.filter(
            (part) =>
                !!initialFormValues.teamParticipant.find(
                    (partMap) =>
                        Number(partMap.PARTICIPANT_ID) ===
                        Number(part.PARTICIPANT_ID)
                )
        ),
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Team_ID: Yup.string().required(),
                teamParticipant: Yup.array()
                    .of(
                        Yup.object({
                            Participant_ID: Yup.string(),
                        })
                    )
                    .min(1, 'Select min 1 participant')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='select-advanced'
                            required
                            name='Team_ID'
                            label='Team'
                            options={teams}
                            optionValue='TEAM_ID'
                            defaultValue={preSelectedOptions.team}
                            optionLabel='TEAM_NAME'
                        />
                    )}
                    <FormikControl
                        control='select-advanced'
                        required
                        name='teamParticipant'
                        label='Participant'
                        options={participants}
                        defaultValue={preSelectedOptions.particip}
                        isMulti
                        keyProp='Participant_ID'
                        optionValue='PARTICIPANT_ID'
                        optionLabel='PARTICIPANT_NAME'
                    />
                    <FormSubmissionBtn onCancel={onCancel} />
                    {(isSubmitting || loading) && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(TeamParticipantMapForm);
