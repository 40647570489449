import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import LocationCourseCategoryMapForm from './LocationCourseCategoryMapForm';

function LocationCourseCategoryMap() {
    const fetchUtils = useAxiosGet(endpoints.locationCourseCategory.get);

    // can use cellmodifer here,
    // but if used, searching for the value in modified cell and also download table data wont work
    // so added a new property (departmentcourseMapping) to show the values in array
   let mapping = [];
    if (!fetchUtils.loading) {
        mapping = fetchUtils.response.map((data) => {
            if (Array.isArray(data.categoryLocation)) {
                data.locationcourseMapping = data.categoryLocation
                    .map((courseCat) => courseCat.Course_Category_NAME)
                    .join(', ');
            }
            return data;
        }); 
    } 

    return (
        <>
            <CRUDTable
                endpoints={endpoints.locationCourseCategory}
                Form={LocationCourseCategoryMapForm}
                addBtnLabel='Map Category to Location'
                // irremovable
                {...fetchUtils}
                response={mapping}
                {...viewProps.LocationCourseCategoryMap}
                name='Location-Course Category Mapping'
            />
        </>
    );
}

export default LocationCourseCategoryMap;
