import React, { useState, Suspense } from 'react';
import '../helpcss/image.css';
import Loader from '../../../../components/Loader';

const LazyImage = React.lazy(() => import('./LazyImageComponent'));

const ImageComponent = ({ imageUrl, direction, desc }) => {
    const [column] = useState(direction === 'column');

    return (
        <div className={`flex-container ${column ? '' : 'row'}`}>
            <Suspense fallback={<Loader />}>
                <LazyImage
                    src={imageUrl}
                    className={`image ${column ? '' : 'image-row'}`}
                    style={{
                        width: column ? '100%' : 'auto',
                    }}
                    name={desc}
                    alt={desc}
                />
            </Suspense>
            <div className={`description ${column ? '' : 'row'}`}>{desc}</div>
        </div>
    );
};

export default ImageComponent;

// import React, { useState, Suspense } from 'react';
// import Loader from '../../../../components/Loader';

// const LazyImage = React.lazy(() => import('./LazyImageComponent'));

// const ImageComponent = ({ imageUrl, direction, desc }) => {
//     const [column, setColumn] = useState(direction === 'column');

//     return (
//         <flex
//             p={column ? '5' : 0}
//             bg={column && 'deepGray'}
//             borderRadius='10px'
//             border='1px solid #0000000D'
//         >
//             <Suspense fallback={<Loader />}>
//                 <LazyImage
//                     src={imageUrl}
//                     style={{ borderRadius: '10px' }}
//                     height={!column && '260px'}
//                     width={column && '100%'}
//                     name={desc}
//                     alt={desc}
//                 />
//             </Suspense>
//             <div
//                 display={!column && 'none'}
//                 pt='2'
//                 fontWeight='500'
//                 fontSize='16px'
//             >
//                 {desc}
//             </div>
//         </flex>
//     );
// };

// export default ImageComponent;
