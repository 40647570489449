import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Loader from '../../../components/Loader';
import CRUDTable from '../../../components/table/CRUDTable';
import WithModal from '../../../components/WithModal';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import CourseForm from './CourseForm';
import { useAuthenticationState } from '../../../context/Auth.context';
import SuperAdminCourseForm from './SuperAdminCourseForm';
import BulkUpload from '../../../components/BulkUpload';
import toast from 'react-hot-toast';

function Draft({ fetchUtils, showAlert, response, courseCellModifier }) {
    const [loading, setLoading] = useState(false);

    const { user } = useAuthenticationState();

    const publishCourse = (body, closeModal) => {
        setLoading(true);
        body.DP_URL = body.COURSE_DP_URL;
        body.Course_STATUS = 3;
        axios
            .post(endpoints.course.update, body)
            .then((res) => {
                closeModal();
                fetchUtils.reFetch();
                toast.success('success');

                // console.log(res, 'res');
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const retireCourse = (body, closeModal) => {
        body.Course_STATUS = 4;
        body.DP_URL = body.COURSE_DP_URL;
        setLoading(true);

        axios
            .post(endpoints.course.update, body)
            .then((res) => {
                closeModal();
                fetchUtils.reFetch();
                toast.success('success');
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const cellModifier = {
        Publish: ({ row }) => (
            <WithModal
                modalTitle={'Publish Course ?'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <div className='text-center'>
                        <Button
                            variant='outline-danger'
                            className='mx-2 px-4'
                            onClick={() =>
                                publishCourse(row.original, closeModal)
                            }
                        >
                            Yes
                        </Button>
                        <Button
                            variant='outline-success'
                            className='mx-2 px-4'
                            onClick={closeModal}
                        >
                            No
                        </Button>
                    </div>
                )}
            >
                <Button size='sm' className='py-0' variant='outline-success'>
                    Publish
                </Button>
            </WithModal>
        ),
        Retire: ({ row }) => (
            <WithModal
                modalTitle={'Retire Course ?'}
                modalAttrs={{ size: 'sm' }}
                renderModalBody={(closeModal) => (
                    <div className='text-center'>
                        <Button
                            variant='outline-danger'
                            className='mx-2 px-4'
                            onClick={() =>
                                retireCourse(row.original, closeModal)
                            }
                        >
                            Yes
                        </Button>
                        <Button
                            variant='outline-success'
                            className='mx-2 px-4'
                            onClick={closeModal}
                        >
                            No
                        </Button>
                    </div>
                )}
            >
                <Button size='sm' className='py-0' variant='outline-danger'>
                    Retire
                </Button>
            </WithModal>
        ),
        ...courseCellModifier,
    };

    if (cellModifier.Reviews) {
        delete cellModifier.Reviews;
    }
    if (loading) {
        return <Loader />;
    } else {
        return (
            <>
                <CRUDTable
                    irremovable
                    {...fetchUtils}
                    relevants={[
                        ...viewProps.Course.relevants.filter(
                            (str) => str !== 'Reviews' && str !== 'courserating'
                        ),
                        'Publish',
                        'Retire',
                    ]}
                    columnHeads={[
                        ...viewProps.Course.columnHeads.filter(
                            (str) => str !== 'Reviews' && str !== 'Rating'
                        ),
                        'Publish',
                        'Retire',
                    ]}
                    response={response}
                    Form={
                        user.type === 'BOTH' ? SuperAdminCourseForm : CourseForm
                    }
                    endpoints={endpoints.course}
                    formSize='lg'
                    name='Courses In Draft'
                    addBtnLabel='Add Course'
                    cellModifier={cellModifier}
                    headerExtras={
                        <BulkUpload
                            confirmNewItemsUrl={endpoints?.course?.bulkUploadConfirm}
                            uploadUrl={endpoints?.course?.bulkUpload}
                            templateUrl={endpoints?.course?.bulkUploadTemplate}
                            afterUpload={fetchUtils.reFetch}
                            filename='Course Details'
                            templateExtension='.xlsx'
                            errorObjects={[{
                                errorType: 'allErrorResponseData',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorColumn: 'column',
                                josonErrorMsg: 'message'
                            },
                            {
                                errorType: 'errorColumnValues',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorColumn: 'column',
                                josonErrorMsg: 'message'
                            },
                            {
                                errorType: 'invalidMedia',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorField: 'COURSE_STRUCTURE_CONTENT_NAME',
                                errorMsg: 'Media name does not exist: '
                            },
                            {
                                errorType: 'invalidLiveClass',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorField: 'COURSE_STRUCTURE_CONTENT_NAME',
                                errorMsg: 'Live class topic does not exist: ',
                            },
                            {
                                errorType: 'invalidStructureRow',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorField: 'COURSE_STRUCTURE_NAME',
                                errorMsg: 'Structure name is already exist: ',
                            },
                            {
                                errorType: 'invalidRows',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorField: 'COURSE_STRUCTURE_CONTENT_TYPE',
                                errorMsg: 'Should contain values - 1,2 or 4. Applied value is: ',
                            }                       
                            ]}
                        />
                    }
                />
            </>
        );
    }
}

export default withAlert(Draft);
