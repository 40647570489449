const viewProps = {
    LiveClassTopic: {
        name: 'Live Class Topic',
        relevants: ['LIVE_CLASS_TOPIC_NAME', 'LIVE_CLASS_TOPIC_DESCRIPTION'],
        columnHeads: ['Live Class topic', 'Description'],
    },
    LiveClass: {
        name: 'Live Class Schedules',
        relevants: [
            'LIVE_CLASS_TOPIC_NAME',
            'LIVECLASSSCHEDULESTARTDATE',
            'LIVECLASSSCHEDULEENDDATE',
            'LIVECLASSSCHEDULESTARTTIME',
            'LIVECLASSSCHEDULEENDTIME',
            'LIVECLASSSCHEDULEDURATION',
            'Start',
        ],
        columnHeads: [
            'Topic',
            'Start Date',
            'End Date',
            'Start Time',
            'End Time',
            'Duration (min)',
            'Start',
        ],
    },
    LiveClassVideoRecordings: {
        name: 'Live Class Video Recordings',
        relevants: [
            'meetingName',
            'createdDate',
            'expiryDate',
            'videoRecordingStatus',
            'Start',
        ],
        columnHeads: [
            'Topic',
            'Created Date',
            'Expiry Date',
            'Status',
            'Action',
        ],
    },
    LiveClassMediaMapping: {
        name: 'Live Class Media & Topic Mapping',
        relevants: [
            'LIVE_CLASS_TOPIC_NAME',
            // 'LIVE_CLASS_TOPIC_DESCRIPTION',
            'CREATEDBYNAME',
            'CREATEDDATE',
            'UPDATEDBYNAME',
            'UPDATEDDATE',
        ],
        columnHeads: [
            'Live Class Topic',
            // 'Description',
            'Created By',
            'Created Date',
            'Updated By',
            'Updated Date',
        ],
    },
    MediaLibrary: {
        name: 'Media Library',
        relevants: [
            'MEDIA_DESC',
            'MEDIA_FILENAME',
            'Course_Category_NAME',
            'MEDIA_TYPE_NAME',
            'EXPIRY_DATE',
            'ApprovalStatus',
            'MEDIA_URL',
        ],
        columnHeads: [
            'Media Description',
            'Media Filename',
            'Course Category',
            'Media Type',
            'Media Expiry Date',
            'Approval Status',
            'Preview',
        ],
    },
    AssignMedia: {
        name: 'Assigned Media',
        relevants: [
            'Course_NAME',
            'MEDIA_FILENAME',
            // 'SEQUENCE_ID',
            // 'Ratings',
            // 'Reviews',
        ],
        columnHeads: [
            'Course',
            'File',
            // 'Sequence',
            'Ratings',
            'Reviews',
        ],
    },
    MediaRatings: {
        name: 'Media Ratings',
        relevants: ['PARTICIPANT_NAME', 'Ratings'],
        columnHeads: ['Participant', 'Ratings'],
    },
    MediaReviews: {
        name: 'Media Reviews',
        relevants: ['PARTICIPANT_NAME', 'Conversation'],
        columnHeads: ['Pariticipant', 'Conversation'],
    },
    QuestionBank: {
        name: 'Question Bank',
        relevants: [
            'Course_Category_name',
            'QUESTION_TEXT',
            'Question_Tag',
            'QUESTION_COMPLEXITY',
            'QUESTION_WEIGHTAGE_SUGGEST',
            'NEGATIVE_SCORE',
            'QUESTION_DURATION',
            'FILEURL',
        ],
        columnHeads: [
            'Course Category',
            'Question Text',
            'Question Tag',
            'Question Complexity',
            'Weightage Suggest',
            'Negative Score',
            'Question Duration',
            'Image',
        ],
    },
    QuizLibrary: {
        name: 'Quiz Library',
        relevants: [
            'QUIZ_NAME',
            'QUIZ_DURATION',
            'QUIZ_TOTALMARKS',
            'Course_Category_name',
        ],
        columnHeads: [
            'Quiz Name',
            'Quiz Duration(min)',
            'Quiz Total Marks',
            'Course Category',
        ],
    },
    CourseContent: {
        name: 'Course Content',
        relevants: [
            'Course_NAME',
            'Course_Content_CONTENT',
            'Course_Content_date',
            // 'Review_Comments',
            'Special_Notes',
            'special_exception',
        ],
        columnHeads: [
            'Course',
            'Course Content',
            'Course Content Date',
            // 'Review',
            'Special Notes',
            'Special Exception',
        ],
    },
    QuizAssignment: {
        name: 'Quiz Assignment',
        relevants: [
            'Course_NAME',
            'QUIZ_NAME',
            // 'SEQUENCE_ID',
            'QUIZ_DATE',
            'QUIZ_TIME',
            'QUIZ_DURATION',
            'Results',
        ],
        columnHeads: [
            'Course',
            'Quiz',
            // 'Sequence',
            'Quiz Date',
            'Quiz Time',
            'Quiz Duration (min)',
            'Results',
        ],
    },
    QuizEvaluation: {
        name: 'Quiz Results',
        relevants: ['QUIZ_NAME', 'PARTICIPANT_NAME', 'COURSE_NAME', 'Result'],
        columnHeads: ['Quiz', 'Participant', 'Course', 'Result'],
    },
    Course: {
        name: 'Course',
        relevants: [
            'Course_NAME',
            'COURSE_DP_URL',
            'Course_Category_NAME',
            'Structure',
            'Course_Description',
            'Contents',
            'Course_Credits',
            'Course_Duration_Days',
            'Course_Duration_hours',
            'Last_Course_Schedule_Date',
            'End_date',
        ],
        columnHeads: [
            'Course',
            'Course Image',
            'Course Category',
            'Structure',
            'Course Description',
            'Contents in Course',
            'Credits',
            'Duration Days',
            'Duration Hours',
            'Scheduled date',
            'Course end date',
        ],
    },
    CourseStructure: {
        name: 'Course Structure',
        relevants: [
            'Course_NAME',
            'Course_Structure_Priority_ID',
            'Course_Structure_Content_Type',
            'Course_Structure_Status',
            'Review',
            'Delete',
        ],
        columnHeads: [
            'Course',
            'Priority',
            'Content Type',
            'Structure Status',
            'Review',
            'Delete',
        ],
    },
    CourseStructureView: {
        name: 'View Course Structure',
        relevants: [
            'Course_NAME',
            'Course_Category_NAME',
            'Course_Credits',
            'Course_STATUS',
            'Course_Duration_Days',
            // 'Course_Structure_Name',
            'Structure',
        ],
        columnHeads: [
            'Course',
            'Course Category',
            'Credits',
            'Course Status',
            'Duration Days',
            // 'Duration Hours',
            'View Structure',
        ],
    },
    CourseReviews: {
        name: 'Reviews of',
        relevants: [
            'Course_NAME',
            'PARTICIPANT_NAME',
            'Review_Comments',
            'Review_DATE',
        ],
        columnHeads: [
            'Course Name',
            'Reviewed By',
            'Review Comments',
            'Reviewed Date',
        ],
    },
    Participant: {
        name: 'Participant',
        relevants: [
            'PARTICIPANT_NAME',
            'PARTICIPANT_EMP_NO',
            // 'PARTICIPANT_PHONE',
            // 'PARTICIPANT_EMAIL',
            // 'PARTICIPANT_ADDRESS',
            // 'MANAGER_NAME',
            // 'MANAGER_PHONE',
            // 'MANAGER_EMAILID',
            // 'DATEOFJOINING',
            'Dept_Name',
        ],
        columnHeads: [
            'Participant Name',
            'Participant EMP',
            // 'Participant Phone',
            // 'Participant Email',
            // 'Participant Address',
            // 'Manger Name',
            // 'Manager Phone',
            // 'Manager Email',
            // 'Date of Joining',
            'Department',
        ],
    },
};
export default viewProps;
