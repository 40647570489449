import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import VideoPreview from '../../../components/VideoPreview';
import WithModal from '../../../components/WithModal';
import PDFPreview from '../../../components/PDFPreview';
import { ReactComponent as PlayButton } from '../../../asset/icons/PlayButton.svg';
import { ReactComponent as Document } from '../../../asset/icons/Document.svg';
import { ReactComponent as ImgPreview } from '../../../asset/icons/ImgPreview.svg';
import { Button, Image } from 'react-bootstrap';
import { format } from 'date-fns';
import MediaBulkUpload from './mediaForm/Index';

function MediaLibrary() {
    const fetchUtils = useAxiosGet(endpoints.mediaLibrary.get);

    const cellModifier = {
        EXPIRY_DATE: ({ value }) => {
            return value ? format(new Date(value), 'dd/MM/yyyy') : 'NA';
        },
        ApprovalStatus: ({ row }) => {
            if (Number(row.original.APPROVED_STATUS) === 1)
                return (
                    <div className='p-1 w-75 text-center bg-success text-white rounded'>
                        Approved
                    </div>
                );
            return (
                <div className='p-1 w-75 text-center bg-info text-white rounded'>
                    Under Review
                </div>
            );
        },
        MEDIA_URL: ({ row, value }) => {
            switch (row.original.MEDIA_TYPE) {
                case 1:
                    return (
                        <WithModal
                            modalAttrs={{ backdrop: 'none' }}
                            modalTitle={row.original.MEDIA_DESC}
                            renderModalBody={(closeModal) => (
                                <div className='text-center'>
                                    <Image
                                        style={{ width: '100%' }}
                                        src={value}
                                    />
                                </div>
                            )}
                        >
                            <ImgPreview />
                        </WithModal>
                    );
                case 2 || 3:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <VideoPreview url={value} />
                                </div>
                            )}
                        >
                            <PlayButton />
                        </WithModal>
                    );
                case 4:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <PDFPreview url={value} />
                                </div>
                            )}
                        >
                            <Document />
                        </WithModal>
                    );
                case 5:
                    return (
                        <WithModal
                            modalAttrs={{ size: 'lg', backdrop: 'none' }}
                            renderModalBody={(closeModal) => (
                                <div className='w-100'>
                                    <VideoPreview url={value} />
                                </div>
                            )}
                        >
                            <PlayButton />
                        </WithModal>
                    );
                default:
                    return <span>Error</span>;
            }
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.mediaLibrary}
                immutable
                irremovable
                insertable={false}
                {...viewProps.MediaLibrary}
                {...fetchUtils}
                cellModifier={cellModifier}
                headerExtras={
                    <WithModal
                        renderModalBody={(closeModal) => (
                            <MediaBulkUpload
                                onCancel={closeModal}
                                onAfterSubmit={() => {
                                    closeModal();
                                    fetchUtils.reFetch();
                                }}
                            />
                        )}
                        disableHeader
                    >
                        <Button
                            variant='transparent'
                            className='rounded-smooth bg-white border-0'
                            style={{
                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                            }}
                            size='sm'
                        >
                            <small>Add Media</small>
                        </Button>
                    </WithModal>
                }
                // headerExtras={
                //     <BulkUpload
                //         uploadUrl={endpoints.mediaLibrary?.bulkUpload}
                //         templateUrl={endpoints.mediaLibrary?.bulkUploadTemplate}
                //         afterUpload={fetchUtils.reFetch}
                //         filename='Media'
                //     />
                // }
            />
        </>
    );
}

export default MediaLibrary;
