import { Col, Row } from 'react-bootstrap';
import Boy from '../../../asset/images/Boy.png';
import Girl from '../../../asset/images/Girl.png';
import { ReactComponent as Milestone } from '../../../asset/icons/Milestone.svg';

function Counts({
    instructorCount,
    userCount,
    newCourseCount,
    newParticipantCount,
}) {
    return (
        <Row className='h-100'>
            <Col sm={6} className='h-50 pb-3'>
                <div className='bg-white d-flex flex-column rounded-smoother h-100'>
                    <div
                        className='mx-auto d-inline-flex align-items-end py-2 rounded-bottom'
                        style={{ background: '#E9F3FF' }}
                    >
                        <img src={Boy} />
                    </div>

                    <div className='d-flex flex-column text-center my-3'>
                        <span className='fs-3'>{userCount}</span>
                        <small className='text-muted'>Total Participants</small>
                    </div>
                </div>
            </Col>
            <Col sm={6} className='h-50 pb-3'>
                <div className='bg-white d-flex flex-column rounded-smoother h-100'>
                    <div
                        className='mx-auto d-inline-flex align-items-end py-2 rounded-bottom'
                        style={{ background: '#DDF9CC' }}
                    >
                        <img src={Girl} />
                    </div>

                    <div className='d-flex flex-column text-center my-3'>
                        <span className='fs-3'>{instructorCount}</span>
                        <small className='text-muted'>Instructors</small>
                    </div>
                </div>
            </Col>
            <Col sm={6} className='h-50'>
                <div className='bg-white d-flex flex-column rounded-smoother py-2 px-3 h-100'>
                    <div className='d-flex flex-column mb-3 text-muted'>
                        <span className='fs-5'>{newParticipantCount}</span>
                        <small>Newly joined participants</small>
                    </div>

                    <Milestone />
                    <div>Milestone</div>
                </div>
            </Col>
            <Col sm={6} className='h-50'>
                <div className='bg-white d-flex flex-column rounded-smoother p-3 h-100'>
                    <div
                        className='rounded-circle fs-3 fw-bold d-flex justify-content-center text-white align-items-center'
                        style={{
                            width: '55px',
                            height: '55px',
                            background:
                                'linear-gradient(180deg, #FD653F 0%, #FE977E 100%)',
                            boxShadow: '2px 6px 0px rgba(0, 0, 0, 0.02)',
                        }}
                    >
                        {newCourseCount}
                    </div>

                    <div className='d-flex flex-column mt-3'>
                        <span className='fs-'>New Courses</span>
                        <small className='text-muted'>Updated</small>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default Counts;
