import React, { useState } from 'react';
import {
    Form,
    // Collapse,
    Button,
    Row,
    Col,
    Spinner,
    InputGroup,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import LoginImage from '../../asset/images/LoginImage.png';
import FirstTimeLogin from './FirstTimeLogin';
import ForgotPassword from './ForgotPassword';
import eyeopen from '../../asset/icons/Eyeopen.svg';
import eyeclose from '../../asset/icons/Eyeclose.svg';
import withAlert from '../../hoc/withAlert';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import Loader from '../../components/Loader';
import toast from 'react-hot-toast';

function Login({ showAlert }) {
    const [visible, setVisible] = useState(false);
    const togglePssword = () => {
        setVisible(!visible);
    };
    const [userCredential, setUserCredential] = useState({
        USERNAME: '',
        PASSWORD: '',
    });
    // const [error, setError] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);

    const { login } = useAuthenticationState();

    const navigate = useNavigate();

    const onLogin = (e) => {
        e.preventDefault();

        setLoggingIn(true);

        login(userCredential)
            .then((user) => {
                setLoggingIn(false);
                navigate('/overview');
            })
            .catch((err) => {
                setLoggingIn(false);
                toast.error(extractErrorFromRes(err));

                // setError(true);
            });
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        // if (error) {
        //     setError(false);
        // }
        setUserCredential({
            ...userCredential,
            [name]: value,
        });
    };
    return (
        <div className='position-relative'>
            <span
                className='small text-muted position-absolute w-fit-content'
                style={{ bottom: '2%', right: '2%' }}
            >
                Streamhall Admin 1.5 <br /> Build 1.5.0.2 - 2024-04-03 18:50
            </span>
            <Row className='justify-content-center align-items-center m-0 vh-100 '>
                <Col sm='6' className='px-0'>
                    <div
                        style={{
                            backgroundImage: 'url(' + LoginImage + ')',
                            width: '100%',
                            height: '100vh',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            textAlign: 'center',
                            color: '#07203F',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <h1
                            className='display-2 pt-4'
                            style={{
                                position: 'absolute',
                                top: '170px',
                                left: '30px',
                                fontWeight: 300,
                            }}
                        >
                            STREAMHALL
                        </h1>
                    </div>
                </Col>
                <Col sm='5' className='mx-auto'>
                    <div className='font-weight-bold w-75 px-4 mx-auto'>
                        <div className='h5'>Welcome back</div>

                        <div
                            className='fw-bolder fs-2 mb-3'
                            style={{ lineHeight: '1' }}
                        >
                            Login to your account
                        </div>

                        <Form onSubmit={onLogin}>
                            <label
                                className='small'
                                style={{ color: '#4A5568' }}
                                htmlFor='username'
                            >
                                Username
                            </label>
                            <Form.Control
                                className='clearFocusDecorate py-2 mt-2 mb-2'
                                name='USERNAME'
                                id='username'
                                value={userCredential.USERNAME}
                                placeholder='Username'
                                required
                                onChange={onChangeHandler}
                            />
                            <label
                                className='small'
                                style={{ color: '#4A5568' }}
                                htmlFor='password'
                            >
                                Password
                            </label>
                            <InputGroup className='mb-3'>
                                <Form.Control
                                    className='clearFocusDecorate py-2 mt-2 mb-1'
                                    name='PASSWORD'
                                    id='password'
                                    value={userCredential.PASSWORD}
                                    type={visible ? 'text' : 'Password'}
                                    placeholder='password'
                                    required
                                    onChange={onChangeHandler}
                                />
                                <InputGroup.Text
                                    className='clearFocusDecorate py-2 mt-2 mb-1'
                                    id='basic-addon2'
                                    onClick={togglePssword}
                                >
                                    {visible ? (
                                        <img src={eyeopen} />
                                    ) : (
                                        <img src={eyeclose} />
                                    )}
                                </InputGroup.Text>
                            </InputGroup>

                            <div className='text-end '>
                                <ForgotPassword />
                            </div>

                            <div className='d-grid mt-2'>
                                <Button
                                    type='submit'
                                    className='text-white fw-bold'
                                    disabled={loggingIn}
                                >
                                    {loggingIn ? (
                                        <Spinner size='sm' animation='border' />
                                    ) : (
                                        'Login'
                                    )}
                                </Button>
                            </div>
                        </Form>
                        <FirstTimeLogin />
                    </div>
                </Col>
                {loggingIn && <Loader />}
            </Row>
        </div>
    );
}

export default withAlert(Login);
