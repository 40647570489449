import axios from 'axios';
import endpoints from '../../../services/endpoints';

export async function getMediaUploadLink(filename) {
    let body = { filename: filename };
    const response = await axios.post(
        endpoints.mediaLibrary.getUploadLink,
        body
    );
    return {
        signedUrl: response.data.responseData.signedUploadURL,
        publicUrl: response.data.responseData.publicReadURL,
    };
}
export function uploadMedia(
    url,
    file,
    setProgress = () => {},
    { ...restConfig } = {}
) {
    return axios.put(url, file, {
        headers: {
            'Content-Type': 'application/octet-stream',
            'x-amz-acl': 'public-read',
            Authorization: '',
        },
        onUploadProgress: (ProgressEvent) => {
            setProgress(
                Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
            );
        },
        ...restConfig,
    });
}
export function updateMediaLibrary(body) {
    return axios.post(endpoints.mediaLibrary.update, body);
}

export const getMediaBulkUploadLink = async (data) => {
    try {
        const res = await axios.post(
            endpoints.mediaLibrary.bulkGetSignedUrl,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return res;
    } catch (error) {
        throw error;
    }
};
export const confirmMediaAdd = async () => {
    try {
        const res = await axios.post(
            endpoints.mediaLibrary.confirmMediaBulkAdd
            // data,
        );

        return res;
    } catch (error) {
        throw error;
    }
};
