import { Button, Collapse } from 'react-bootstrap';
import Loader from '../Loader';

const InitialBody = ({
    closeModal,
    setFileError,
    setSelectedFile,
    upload,
    downloadTemplate,
    fileError,
    selectedFile,
}) => {
    return (
        <div>
            <p>
                If the template is not available, download{' '}
                <span
                    className='text-primary'
                    type='button'
                    onClick={downloadTemplate}
                >
                    here
                </span>
            </p>

            <input
                type='file'
                className='form-control'
                onChange={(e) => {
                    setFileError(false);
                    setSelectedFile(e.target.files[0]);
                }}
            />

            <Collapse in={fileError}>
                <div className='text-danger text-center small'>
                    Only supported xlsx file formats
                </div>
            </Collapse>

            <div className='text-center mt-3'>
                <Button
                    disabled={!selectedFile}
                    onClick={() => {
                        upload(closeModal);
                    }}
                >
                    Upload
                </Button>

                <Button
                    variant='light'
                    className='border mx-2'
                    onClick={() => {
                        setSelectedFile(null);
                        closeModal();
                    }}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

export default InitialBody;
