import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
// import {
//     useAxiosGet,
//     useAxiosPost,
// } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import {
    Button,
    Col,
    Row,
    Collapse,
    Tooltip,
    OverlayTrigger,
} from 'react-bootstrap';
import { useAuthenticationState } from '../../../context/Auth.context';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import { ReactComponent as Image } from '../../../asset/icons/File.svg';
import { ReactComponent as Add } from '../../../asset/icons/Add.svg';

import { ReactComponent as Info } from '../../../asset/icons/Info.svg';
import QuestionImageList from './QuestionImageList';
import WithModal from '../../../components/WithModal';
import Choices from './Choices';
import { debounce } from 'lodash';
import toast from 'react-hot-toast';
function QuestionBankForm({ endpoint, updateValues, onCancel, onAfterSubmit }) {
    const { user } = useAuthenticationState();

    const [choiceError, setChoiceError] = useState(null);
    const [images, setImages] = useState([]);

    const [courseCategories, setCourseCategories] = useState([]);
    const [questionTags, setQuestionTags] = useState([]);

    const [loading, setLoading] = useState(true);
    const [mediaData, setMediaData] = useState({
        MEDIA_TYPE: '1',
        MEDIA_ID: updateValues?.MEDIA_ID || '',
        FILE_NAME: updateValues?.FILE_NAME || '',
        MEDIA_URL: updateValues?.FILEURL || '',
    });

    const getImageByCourseCategoryId = (courseCategoryId) => {
        axios
            .get(
                endpoints.mediaLibrary.getlistbycoursecategory +
                    courseCategoryId
            )
            .then((res) => {
                setImages(res.data.responseData);
            })
            .catch((err) => setImages([]))
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        axios
            .post(endpoints.departmentCourseCategory.selectBy, {
                selectcolumns: '*',
                wherecondition: 'Participant_Dept_ID = ' + user.deptId,
            })
            .then((res) => {
                setCourseCategories(res.data.responseData);
                if (formType === 'update') {
                    getImageByCourseCategoryId(updateValues.Course_Category_ID);
                } else {
                    setLoading(false);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);

    const fetchTags = debounce(async (keyword, courseCatId) => {
        return axios
            .post(endpoints.course.autopopulateTag, {
                Course_Category_ID: courseCatId,
                keyword: keyword,
            })
            .then((res) => {
                const tags = res.data.responseData.map((tag) => ({
                    name: tag,
                    value: tag,
                }));
                setQuestionTags(tags);
            })
            .catch((err) => {
                setQuestionTags([]);
            });
    }, 500);

    const formType = updateValues ? 'update' : 'add';
    console.log(updateValues, 'updateValues');
    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };
        body.NEGATIVE_SCORE =
            body.NEGATIVE_SCORE === '' ? '0' : body.NEGATIVE_SCORE;

        // body.HAS_ATTACHMENT = mediaData.MEDIA_ID == '' ? '0' : '1';
        body.HAS_ATTACHMENT = mediaData.MEDIA_ID == '' ? '0' : '1';

        const noOfRightAnswers = body.MCQ_OPTIONS.filter(
            (option) => Number(option.MCQRIGHTANSWER) === 1
        ).length;

        body.ISMCQ = '1';

        if (body.MCQ_OPTIONS.length < 2) {
            setChoiceError('Provide more than 1 choices');
            setSubmitting(false);
            return;
        }
        if (noOfRightAnswers < 1) {
            setChoiceError('Pick one right option');
            setSubmitting(false);
            return;
        }

        body.ATT_MEDIA_FILE = [mediaData];
        body.MEDIA_ID = mediaData.MEDIA_ID;

        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        Course_Category_ID: '',
        Question_Tag: '',
        QUESTION_TEXT: '',
        QUESTION_COMPLEXITY: '',
        QUESTION_WEIGHTAGE_SUGGEST: '',
        MCQ_OPTIONS: [],
        HAS_ATTACHMENT: '',
        MEDIA_ID: '',
        NEGATIVE_SCORE: '0',
        QUESTION_DURATION: '',
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOptions = {
        courseCat: {},
    };
    if (formType === 'update') {
        const selectedCourseCat = courseCategories.find(
            (course) =>
                Number(course.Course_Category_ID) ===
                Number(initialFormValues.Course_Category_ID)
        );
        preSelectedOptions.courseCat = selectedCourseCat || {};
    }

    const handleUpload = (quizimage) => {
        setMediaData({
            MEDIA_TYPE: '1',
            MEDIA_ID: quizimage.MEDIA_ID,
            FILE_NAME: quizimage.MEDIA_FILENAME,
            MEDIA_URL: quizimage.MEDIA_URL,
        });
    };

    return (
        <>
            <Formik
                initialValues={initialFormValues}
                validationSchema={Yup.object({
                    Course_Category_ID: Yup.string().required(),
                    QUESTION_TEXT: Yup.string().required(),
                    Question_Tag: Yup.string()
                        .matches(
                            /^[a-zA-Z]+$/,
                            'Please enter only alphabets in this field. Special characters, numbers, and spaces are not allowed.'
                        )
                        .required(),

                    QUESTION_COMPLEXITY: Yup.number()
                        .min(1, 'Value must be at least 1')
                        .max(5, 'Value must be at most 5')
                        .required(),
                    QUESTION_WEIGHTAGE_SUGGEST: Yup.number()
                        .min(1, 'Value must be at least 1')
                        .required(),
                    MCQ_OPTIONS: Yup.array()
                        .of(
                            Yup.object().shape({
                                MCQ_CHOICE_TEXT: Yup.string().required(),
                                MCQRIGHTANSWER: Yup.string(),
                            })
                        )
                        .min(2, 'Provide atleat 2 choices')
                        // .max(4, 'Must not have more than 4 choices')
                        .test(
                            'unique-choices',
                            'Choices must be unique',
                            function (value) {
                                // 'this' refers to the Yup context, including the current field being validated
                                if (!value || !Array.isArray(value)) {
                                    return true; // Skip validation if the array is empty or not an array
                                }

                                const seenChoices = new Set();

                                for (const choice of value) {
                                    const choiceText = choice.MCQ_CHOICE_TEXT;

                                    if (seenChoices.has(choiceText)) {
                                        return false; // Duplicate found, validation fails
                                    }

                                    seenChoices.add(choiceText);
                                }

                                return true; // No duplicates found, validation passes
                            }
                        ),
                    OPTION: Yup.string(),
                    NEGATIVE_SCORE: Yup.number().min(
                        0,
                        'Value must be at least 0'
                    ),
                    QUESTION_DURATION: Yup.number().min(
                        1,
                        'Value must be at least 1'
                    ),
                    // .required(),
                })}
                onSubmit={submitHandler}
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                        <Row>
                            <Col sm='12'>
                                <FormikControl
                                    control='select-advanced'
                                    required
                                    name='Course_Category_ID'
                                    placeholder='Select Course Category'
                                    options={courseCategories}
                                    defaultValue={preSelectedOptions.courseCat}
                                    onChange={(selected) => {
                                        setFieldValue('MEDIA_ID', '');
                                        setFieldValue('Question_Tag', '');
                                        setFieldValue(
                                            'Course_Category_ID',
                                            selected.Course_Category_ID
                                        );

                                        handleUpload({
                                            MEDIA_TYPE: '1',
                                            MEDIA_ID: '',
                                            FILE_NAME: '',
                                            MEDIA_URL: '',
                                        });
                                        getImageByCourseCategoryId(
                                            selected.Course_Category_ID
                                        );
                                    }}
                                    optionValue='Course_Category_ID'
                                    label='Course Category'
                                    optionLabel='Course_Category_NAME'
                                />
                            </Col>
                            <Col sm='12' style={{ position: 'relative' }}>
                                <FormikControl
                                    disabled={!values.Course_Category_ID}
                                    control='creatable-select'
                                    required
                                    defaultInputValue={
                                        updateValues?.Question_Tag ?? ''
                                    }
                                    name='Question_Tag'
                                    placeholder='Add or search tag...'
                                    options={questionTags}
                                    onInputChange={(val) => {
                                        if (val) {
                                            fetchTags(
                                                val,
                                                values.Course_Category_ID
                                            );
                                        }
                                    }}
                                    label='Question Tag'
                                />
                            </Col>

                            <Col sm='12'>
                                <FormikControl
                                    control={'textarea'}
                                    required
                                    label='Question'
                                    name='QUESTION_TEXT'
                                    placeholder='Enter the Question'
                                />
                            </Col>
                            <Col sm='12'>
                                <span
                                    style={{
                                        color: '#344054',
                                        fontSize: '.8em',
                                    }}
                                >
                                    Question Image
                                </span>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '5px',
                                        marginBottom: '5px',
                                    }}
                                    title={
                                        !values.Course_Category_ID
                                            ? 'Please select course category'
                                            : 'Upload from media library'
                                    }
                                >
                                    <div
                                        style={{
                                            width: '20%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '64px',
                                                height: '64px',
                                                background: '#F2F4F7',
                                                borderRadius: '50%',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Image />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: '80%',
                                            border: '1px solid #E2E8F0',
                                            borderRadius: '12px',
                                            textAlign: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <WithModal
                                            modalTitle={
                                                'Upload Image from Media Library'
                                            }
                                            disabled={
                                                !values.Course_Category_ID
                                            }
                                            modalAttrs={{ size: 'xl' }}
                                            renderModalBody={(closeModal) => (
                                                <QuestionImageList
                                                    medias={images}
                                                    selectedImages={
                                                        mediaData.MEDIA_URL
                                                    }
                                                    handleUpload={(
                                                        quizimage
                                                    ) => {
                                                        handleUpload(quizimage);
                                                        closeModal();
                                                    }}
                                                    onClose={() => closeModal()}
                                                />
                                            )}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        color: '#6941C6',
                                                        width: '100%',
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    {mediaData.MEDIA_URL
                                                        ? 'Click to update from media Library'
                                                        : 'Click to upload from media Library'}
                                                </div>
                                                <div
                                                    style={{
                                                        color: '#475467',
                                                        width: '100%',
                                                        fontSize: '12px',
                                                        fontWeight: '400',
                                                    }}
                                                >
                                                    PNG or JPG
                                                </div>
                                            </div>
                                        </WithModal>
                                    </div>
                                </div>
                            </Col>
                            <div>
                                <div
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        display: mediaData.MEDIA_URL
                                            ? 'block'
                                            : 'none',
                                    }}
                                >
                                    <img
                                        src={mediaData.MEDIA_URL}
                                        width='100%'
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <Col
                                    sm='12'
                                    style={{
                                        width: '93%',
                                        alignContent: 'center',
                                    }}
                                >
                                    <FormikControl
                                        control='input'
                                        label='Choices'
                                        placeholder='Add Choices'
                                        name='OPTION'
                                        onChange={({ target }) => {
                                            setFieldValue(
                                                'OPTION',
                                                target.value
                                            );
                                            if (choiceError)
                                                setChoiceError(null);
                                        }}
                                        className='w-100 d-inline-block mb-2'
                                        style={{
                                            borderTopRightRadius: '0px',
                                            borderBottomRightRadius: '0px',
                                            borderRight: 'none',
                                        }}
                                    />
                                </Col>
                                <div
                                    style={{
                                        width: '10%',
                                        alignItems: 'end',
                                        display: 'flex',
                                        paddingBottom: '8px',
                                    }}
                                >
                                    <button
                                        style={{
                                            height: '38px',
                                            alignItems: 'center',
                                            display: 'flex',
                                            borderTopRightRadius: '8px',
                                            borderBottomRightRadius: '8px',
                                            background:
                                                'rgba(242, 242, 242, 0.4)',
                                            paddingLeft: '0',
                                            border: '1px solid #bdbdbd',
                                            borderLeft: 'none',
                                        }}
                                        disabled={!values.OPTION}
                                        onClick={() => {
                                            const options = [
                                                ...values.MCQ_OPTIONS,
                                                {
                                                    MCQ_CHOICE_TEXT:
                                                        values.OPTION,
                                                    MCQRIGHTANSWER: '0',
                                                },
                                            ];

                                            setFieldValue(
                                                'MCQ_OPTIONS',
                                                options,
                                                false
                                            );
                                            setFieldValue('OPTION', '');
                                        }}
                                    >
                                        <Add />
                                    </button>
                                </div>
                                <Col style={{ width: '100%' }}></Col>
                            </div>
                            <Col sm='12' className='mb-3'>
                                <>
                                    {values.MCQ_OPTIONS.map((option, i) => (
                                        <Choices
                                            i={i}
                                            option={option}
                                            onRemove={(selected, index) => {
                                                const optionsAfterRemoval =
                                                    values.MCQ_OPTIONS.filter(
                                                        (opt, ind) =>
                                                            opt.MCQ_CHOICE_TEXT +
                                                                ind !==
                                                            selected.MCQ_CHOICE_TEXT +
                                                                i
                                                    );
                                                setFieldValue(
                                                    'MCQ_OPTIONS',
                                                    optionsAfterRemoval
                                                );
                                            }}
                                            onChange={(selected) => {
                                                if (
                                                    Number(selected.value) === 1
                                                ) {
                                                    values.MCQ_OPTIONS.forEach(
                                                        (option, indexAll) => {
                                                            setFieldValue(
                                                                `MCQ_OPTIONS[${indexAll}].MCQRIGHTANSWER`,
                                                                indexAll === i
                                                                    ? '1'
                                                                    : '0'
                                                            );
                                                        }
                                                    );
                                                    setChoiceError(null);
                                                } else {
                                                    setFieldValue(
                                                        `MCQ_OPTIONS[${i}].MCQRIGHTANSWER`,
                                                        selected.value,
                                                        true
                                                    );
                                                }
                                            }}
                                            name={`MCQ_OPTIONS[${i}].MCQRIGHTANSWER`}
                                        />
                                    ))}

                                    <div className='small text-danger'>
                                        <ErrorMessage name='MCQ_OPTIONS' />
                                    </div>
                                </>
                            </Col>

                            <Col sm='6'>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <FormikControl
                                        control={'input'}
                                        required
                                        type='number'
                                        label='Question Complexity'
                                        size='lg'
                                        name='QUESTION_COMPLEXITY'
                                        placeholder='Enter the Complexity'
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '55%',
                                            right: '2%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    <div
                                                        style={{
                                                            padding: '5px',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: '#39F056',
                                                            }}
                                                        >
                                                            Question’s
                                                            Complexity
                                                        </span>
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'start',
                                                            }}
                                                        >
                                                            <li>
                                                                <span
                                                                    style={{
                                                                        color: '#67EF14',
                                                                    }}
                                                                >
                                                                    SuperEasy
                                                                </span>{' '}
                                                                <span>
                                                                    {' '}
                                                                    - 1
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span
                                                                    style={{
                                                                        color: '#9FFF64',
                                                                    }}
                                                                >
                                                                    Easy{' '}
                                                                </span>
                                                                <span>- 2</span>
                                                            </li>
                                                            <li>
                                                                <span
                                                                    style={{
                                                                        color: '#FFF27E',
                                                                    }}
                                                                >
                                                                    Moderate
                                                                </span>
                                                                <span>- 3</span>
                                                            </li>
                                                            <li>
                                                                <span
                                                                    style={{
                                                                        color: '#FF4747',
                                                                    }}
                                                                >
                                                                    Hard{' '}
                                                                </span>
                                                                <span>- 4</span>
                                                            </li>
                                                            <li>
                                                                <span
                                                                    style={{
                                                                        color: '#FF1515',
                                                                    }}
                                                                >
                                                                    Super Hard{' '}
                                                                </span>
                                                                <span>- 5</span>
                                                            </li>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        >
                                            <Info />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </Col>

                            <Col sm='6'>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <FormikControl
                                        control={'input'}
                                        type='number'
                                        label='Negative Score'
                                        size='lg'
                                        min='0'
                                        placeholder='Enter the -ve score'
                                        name='NEGATIVE_SCORE'
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '55%',
                                            right: '2%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    <div
                                                        style={{
                                                            padding: '5px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: '#39F056',
                                                                textAlign:
                                                                    'start',
                                                            }}
                                                        >
                                                            Negative Score
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: '#ffffff',
                                                                textAlign:
                                                                    'start',
                                                                marginTop:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {' '}
                                                            Assign a negative
                                                            score if the
                                                            question carries a
                                                            penalty or incorrect
                                                            response. Use the
                                                            '-' symbol before
                                                            the score to
                                                            indicate negatives
                                                            (e.g., -5 for a
                                                            deduction of five
                                                            points).
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        >
                                            <Info />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </Col>
                            <Col sm='6'>
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <FormikControl
                                        control={'input'}
                                        required
                                        type='number'
                                        label='Question Weightage'
                                        size='lg'
                                        placeholder='Enter the Weightage'
                                        name='QUESTION_WEIGHTAGE_SUGGEST'
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '55%',
                                            right: '2%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={(props) => (
                                                <Tooltip {...props}>
                                                    <div
                                                        style={{
                                                            padding: '5px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                color: '#39F056',
                                                                textAlign:
                                                                    'start',
                                                            }}
                                                        >
                                                            Weightage of the
                                                            Question
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: '#ffffff',
                                                                textAlign:
                                                                    'start',
                                                                marginTop:
                                                                    '10px',
                                                            }}
                                                        >
                                                            {' '}
                                                            Question Weightage
                                                            indicates the
                                                            contribution of each
                                                            question to your
                                                            overall score
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            )}
                                        >
                                            <Info />
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </Col>

                            <Col sm='6'>
                                <FormikControl
                                    control={'input'}
                                    required
                                    type='number'
                                    label='Duration'
                                    size='lg'
                                    placeholder='Approx Question Duration '
                                    name='QUESTION_DURATION'
                                />
                            </Col>

                            <Collapse in={!!choiceError}>
                                <div className='text-danger text-center small my-1'>
                                    {choiceError}
                                </div>
                            </Collapse>
                        </Row>
                        <FormSubmissionBtn onCancel={onCancel} />
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default withAlert(QuestionBankForm);
