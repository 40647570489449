import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function ProgramCourseMapForm({
    updateValues,
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response, loading } = useAxiosGetMultiple({
        programs: endpoints.program.get,
        courses: endpoints.course.get,
    });

    const { programs, courses } = response;

    const submitHandler = (values, { setSubmitting }) => {
        setSubmitting(true);

        console.log(values);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
                // showAlert("error", extractErrorFromRes(err));
            })
            .then((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        Program_Course: [],
        Course_Program_ID: '',
        Course_Category_ID: '',
    };

    const preSelectedOptions = {
        program: programs.find(
            (program) =>
                Number(program.Course_Program_ID) ===
                Number(initialFormValues.Course_Program_ID)
        ),
        course: courses.filter(
            (course) =>
                !!initialFormValues.Program_Course.find(
                    (proCourse) =>
                        Number(proCourse.Course_ID) === Number(course.Course_ID)
                )
        ),
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Course_Program_ID: Yup.string().required(),
                Program_Course: Yup.array()
                    .of(
                        Yup.object({
                            Course_ID: Yup.string(),
                        })
                    )
                    .min(1, 'Select min 1 course')
                    .required(),
                Course_Category_ID: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='select-advanced'
                            required
                            name='Course_Program_ID'
                            options={programs}
                            defaultValue={preSelectedOptions.program}
                            optionValue='Course_Program_ID'
                            onChange={(selected) => {
                                setFieldValue(
                                    'Course_Category_ID',
                                    selected.Course_Category_ID
                                );
                                setFieldValue(
                                    'Course_Program_ID',
                                    selected.Course_Program_ID
                                );
                            }}
                            optionLabel='Course_Program_NAME'
                            label='Program'
                        />
                    )}
                    <FormikControl
                        control='select-advanced'
                        required
                        name='Program_Course'
                        defaultValue={preSelectedOptions.course}
                        options={courses.filter(
                            (course) =>
                                Number(course.Course_STATUS) === 3 &&
                                Number(values.Course_Category_ID) ===
                                    Number(course.Course_Category_ID)
                        )}
                        isMulti={true}
                        keyProp='Course_ID'
                        optionValue='Course_ID'
                        optionLabel='Course_NAME'
                        label='Course'
                        placeholder='Published courses'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {(isSubmitting || loading) && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(ProgramCourseMapForm);
