import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import QuestionBankForm from './QuestionBankForm';
import BulkUpload from '../../../components/BulkUpload';

function QuestionBank() {
    const fetchUtils = useAxiosPost(endpoints.questionBank.selectBy, {
        selectcolumns: '*',
        wherecondition: '1=1',
    });

    const cellModifier = {
        FILEURL: ({ value }) => {
            if (value) {
                return (
                    <img
                        style={{
                            width: '50px',
                            height: '50px',
                        }}
                        src={value}
                    />
                );
            }
            return <div>No image for Quiz</div>;
        },
    };

    return (
        <>
            <CRUDTable
                endpoints={endpoints.questionBank}
                Form={QuestionBankForm}
                {...fetchUtils}
                {...viewProps.QuestionBank}
                formSize='md'
                addBtnLabel='Add Question'
                cellModifier={cellModifier}
                headerExtras={
                    <BulkUpload
                        uploadUrl={endpoints.questionBank?.bulkUpload}
                        confirmNewItemsUrl={
                            endpoints.questionBank?.bulkUploadConfirm
                        }
                        templateUrl={endpoints.questionBank?.bulkUploadTemplate}
                        filename='Questions Bulk Upload'
                        afterUpload={fetchUtils.reFetch}
                        templateExtension='.xlsx'
                        errorObjects={[
                            {
                                errorType: 'allErrorResponseData',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorColumn: 'column',
                                josonErrorMsg: 'message',
                            },
                            {
                                errorType: 'errorColumnValues',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorColumn: 'column',
                                josonErrorMsg: 'message',
                            },
                            {
                                errorType: 'removedMediaRows',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorField: 'FILE_NAME',
                                errorMsg: 'The media is not approved: ',
                            },
                            {
                                errorType: 'excludedCourseRow',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorField: 'COURSE_CATEGORY_NAME',
                                errorMsg:
                                    'The course is not assigned to the faculty: ',
                            },
                            {
                                errorType: 'duplicateChoice',
                                errorArea: 'Row no:',
                                errorNum: 'row',
                                errorField: 'MCQ_CHOICE_TEXT',
                                errorMsg:
                                    'Only unique choices are expected in the choice text',
                            },
                        ]}
                    />
                }
            />
        </>
    );
}

export default QuestionBank;
