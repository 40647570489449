import Admin_Login_Img_1 from './Login/Admin_Login_Img_1.png';
import Admin_Login_Img_2 from './Login/Admin_Login_Img_2.png';
import Admin_Login_Img_3 from './Login/Admin_Login_Img_3.png';

import Admin_ForgotPassword_img_1 from './Forgot_Password/Admin_ForgotPassword_img_1.png';
import Admin_ForgotPassword_img_2 from './Forgot_Password/Admin_ForgotPassword_img_2.png';
import Admin_ForgotPassword_img_3 from './Forgot_Password/Admin_ForgotPassword_img_3.png';
import Admin_ForgotPassword_img_4 from './Forgot_Password/Admin_ForgotPassword_img_4.png';
import Admin_ForgotPassword_img_5 from './Forgot_Password/Admin_ForgotPassword_img_5.png';

import Admin_ChangePassword_img_1 from './Change_Password/Admin_ChangePassword_img_1.png';
import Admin_ChangePassword_img_2 from './Change_Password/Admin_ChangePassword_img_2.png';
import Admin_ChangePassword_img_3 from './Change_Password/Admin_ChangePassword_img_3.png';
import Admin_ChangePassword_img_4 from './Change_Password/Admin_ChangePassword_img_4.png';

import Admin_CourseCategory_img_1 from './Course_Category/Admin_CourseCategory_img_1.png';
import Admin_CourseCategory_img_2 from './Course_Category/Admin_CourseCategory_img_2.png';
import Admin_CourseCategory_img_3 from './Course_Category/Admin_CourseCategory_img_3.png';
import Admin_CourseCategory_img_4 from './Course_Category/Admin_CourseCategory_img_4.png';
import Admin_CourseCategory_img_5 from './Course_Category/Admin_CourseCategory_img_5.png';
import Admin_CourseCategory_img_6 from './Course_Category/Admin_CourseCategory_img_6.png';
import Admin_CourseCategory_img_7 from './Course_Category/Admin_CourseCategory_img_7.png';
import Admin_CourseCategory_img_8 from './Course_Category/Admin_CourseCategory_img_8.png';
import Admin_CourseCategory_img_9 from './Course_Category/Admin_CourseCategory_img_9.png';
import Admin_CourseCategory_img_10 from './Course_Category/Admin_CourseCategory_img_10.png';
import Admin_CourseCategory_img_11 from './Course_Category/Admin_CourseCategory_img_11.png';

import Admin_CourseStructApp_img_1 from './Course_Structure_Approval/Admin_CourseStructApp_img_1.png';
import Admin_CourseStructApp_img_2 from './Course_Structure_Approval/Admin_CourseStructApp_img_2.png';
import Admin_CourseStructApp_img_3 from './Course_Structure_Approval/Admin_CourseStructApp_img_3.png';
import Admin_CourseStructApp_img_4 from './Course_Structure_Approval/Admin_CourseStructApp_img_4.png';
import Admin_CourseStructApp_img_5 from './Course_Structure_Approval/Admin_CourseStructApp_img_5.png';
import Admin_CourseStructApp_img_6 from './Course_Structure_Approval/Admin_CourseStructApp_img_6.png';
import Admin_CourseStructApp_img_7 from './Course_Structure_Approval/Admin_CourseStructApp_img_7.png';
import Admin_CourseStructApp_img_8 from './Course_Structure_Approval/Admin_CourseStructApp_img_8.png';
import Admin_CourseStructApp_img_9 from './Course_Structure_Approval/Admin_CourseStructApp_img_9.png';
import Admin_CourseStructApp_img_10 from './Course_Structure_Approval/Admin_CourseStructApp_img_10.png';
import Admin_CourseStructApp_img_11 from './Course_Structure_Approval/Admin_CourseStructApp_img_11.png';
import Admin_CourseStructApp_img_12 from './Course_Structure_Approval/Admin_CourseStructApp_img_12.png';
import Admin_CourseStructApp_img_13 from './Course_Structure_Approval/Admin_CourseStructApp_img_13.png';

import Admin_Courses_img_1 from './Courses/Admin_Courses_img_1.png';
import Admin_Courses_img_2 from './Courses/Admin_Courses_img_2.png';
import Admin_Courses_img_3 from './Courses/Admin_Courses_img_3.png';
import Admin_Courses_img_4 from './Courses/Admin_Courses_img_4.png';
import Admin_Courses_img_5 from './Courses/Admin_Courses_img_5.png';
import Admin_Courses_img_6 from './Courses/Admin_Courses_img_6.png';
import Admin_Courses_img_7 from './Courses/Admin_Courses_img_7.png';
import Admin_Courses_img_8 from './Courses/Admin_Courses_img_8.png';
import Admin_Courses_img_9 from './Courses/Admin_Courses_img_9.png';
import Admin_Courses_img_10 from './Courses/Admin_Courses_img_10.png';
import Admin_Courses_img_11 from './Courses/Admin_Courses_img_11.png';
import Admin_Courses_img_12 from './Courses/Admin_Courses_img_12.png';
import Admin_Courses_img_13 from './Courses/Admin_Courses_img_13.png';
import Admin_Courses_img_14 from './Courses/Admin_Courses_img_14.png';
import Admin_Courses_img_15 from './Courses/Admin_Courses_img_15.png';
import Admin_Courses_img_16 from './Courses/Admin_Courses_img_16.png';
import Admin_Courses_img_17 from './Courses/Admin_Courses_img_17.png';
import Admin_Courses_img_18 from './Courses/Admin_Courses_img_18.png';
import Admin_Courses_img_19 from './Courses/Admin_Courses_img_19.png';
import Admin_Courses_img_20 from './Courses/Admin_Courses_img_20.png';
import Admin_Courses_img_21 from './Courses/Admin_Courses_img_21.png';
import Admin_Courses_img_22 from './Courses/Admin_Courses_img_22.png';
import Admin_Courses_img_23 from './Courses/Admin_Courses_img_23.png';
import Admin_Courses_img_24 from './Courses/Admin_Courses_img_24.png';
import Admin_Courses_img_25 from './Courses/Admin_Courses_img_25.png';

import Admin_CoursesByInst_img_1 from './Courses_By_Instructor/Admin_CoursesByInst_img_1.png';
import Admin_CoursesByInst_img_2 from './Courses_By_Instructor/Admin_CoursesByInst_img_2.png';
import Admin_CoursesByInst_img_3 from './Courses_By_Instructor/Admin_CoursesByInst_img_3.png';
import Admin_CoursesByInst_img_4 from './Courses_By_Instructor/Admin_CoursesByInst_img_4.png';
import Admin_CoursesByInst_img_5 from './Courses_By_Instructor/Admin_CoursesByInst_img_5.png';
import Admin_CoursesByInst_img_6 from './Courses_By_Instructor/Admin_CoursesByInst_img_6.png';
import Admin_CoursesByInst_img_7 from './Courses_By_Instructor/Admin_CoursesByInst_img_7.png';
import Admin_CoursesByInst_img_8 from './Courses_By_Instructor/Admin_CoursesByInst_img_8.png';
import Admin_CoursesByInst_img_9 from './Courses_By_Instructor/Admin_CoursesByInst_img_9.png';
import Admin_CoursesByInst_img_10 from './Courses_By_Instructor/Admin_CoursesByInst_img_10.png';
import Admin_CoursesByInst_img_11 from './Courses_By_Instructor/Admin_CoursesByInst_img_11.png';
import Admin_CoursesByInst_img_12 from './Courses_By_Instructor/Admin_CoursesByInst_img_12.png';

import Admin_Dashboard_img_1 from './Dashboard/Admin_Dashboard_img_1.png';
import Admin_Dashboard_img_2 from './Dashboard/Admin_Dashboard_img_2.png';
import Admin_Dashboard_img_3 from './Dashboard/Admin_Dashboard_img_3.png';
import Admin_Dashboard_img_4 from './Dashboard/Admin_Dashboard_img_4.png';
import Admin_Dashboard_img_5 from './Dashboard/Admin_Dashboard_img_5.png';
import Admin_Dashboard_img_6 from './Dashboard/Admin_Dashboard_img_6.png';
import Admin_Dashboard_img_7 from './Dashboard/Admin_Dashboard_img_7.png';
import Admin_Dashboard_img_8 from './Dashboard/Admin_Dashboard_img_8.png';
import Admin_Dashboard_img_9 from './Dashboard/Admin_Dashboard_img_9.png';

import Admin_DepartmentCCM_img_1 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_1.png';
import Admin_DepartmentCCM_img_2 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_2.png';
import Admin_DepartmentCCM_img_3 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_3.png';
import Admin_DepartmentCCM_img_4 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_4.png';
import Admin_DepartmentCCM_img_5 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_5.png';
import Admin_DepartmentCCM_img_6 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_6.png';
import Admin_DepartmentCCM_img_7 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_7.png';
import Admin_DepartmentCCM_img_8 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_8.png';
import Admin_DepartmentCCM_img_9 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_9.png';
import Admin_DepartmentCCM_img_10 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_10.png';
import Admin_DepartmentCCM_img_11 from './Department_Course_Category_Mapping/Admin_DepartmentCCM_img_11.png';

import Admin_Instructor_img_1 from './Instructors/Admin_Instructor_img_1.png';
import Admin_Instructor_img_2 from './Instructors/Admin_Instructor_img_2.png';
import Admin_Instructor_img_3 from './Instructors/Admin_Instructor_img_3.png';
import Admin_Instructor_img_4 from './Instructors/Admin_Instructor_img_4.png';
import Admin_Instructor_img_5 from './Instructors/Admin_Instructor_img_5.png';
import Admin_Instructor_img_6 from './Instructors/Admin_Instructor_img_6.png';
import Admin_Instructor_img_7 from './Instructors/Admin_Instructor_img_7.png';
import Admin_Instructor_img_8 from './Instructors/Admin_Instructor_img_8.png';
import Admin_Instructor_img_9 from './Instructors/Admin_Instructor_img_9.png';
import Admin_Instructor_img_10 from './Instructors/Admin_Instructor_img_10.png';
import Admin_Instructor_img_11 from './Instructors/Admin_Instructor_img_11.png';
import Admin_Instructor_img_12 from './Instructors/Admin_Instructor_img_12.png';

import Admin_LiveClassRec_img_1 from './Live_Class_Recording/Admin_LiveClassRec_img_1.png';
import Admin_LiveClassRec_img_2 from './Live_Class_Recording/Admin_LiveClassRec_img_2.png';
import Admin_LiveClassRec_img_3 from './Live_Class_Recording/Admin_LiveClassRec_img_3.png';
import Admin_LiveClassRec_img_4 from './Live_Class_Recording/Admin_LiveClassRec_img_4.png';
import Admin_LiveClassRec_img_5 from './Live_Class_Recording/Admin_LiveClassRec_img_5.png';

import Admin_LogOut_Img_1 from './Log_Out/Admin_LogOut_Img_1.png';
import Admin_LogOut_Img_2 from './Log_Out/Admin_LogOut_Img_2.png';
import Admin_LogOut_Img_3 from './Log_Out/Admin_LogOut_Img_3.png';

import Admin_Notification_img_1 from './Notification/Admin_Notification_img_1.png';
import Admin_Notification_img_2 from './Notification/Admin_Notification_img_2.png';
import Admin_Notification_img_3 from './Notification/Admin_Notification_img_3.png';
import Admin_Notification_img_4 from './Notification/Admin_Notification_img_4.png';

import Admin_Department_img_1 from './Department/Admin_Department_img_1.png';
import Admin_Department_img_2 from './Department/Admin_Department_img_2.png';
import Admin_Department_img_3 from './Department/Admin_Department_img_3.png';
import Admin_Department_img_4 from './Department/Admin_Department_img_4.png';
import Admin_Department_img_5 from './Department/Admin_Department_img_5.png';
import Admin_Department_img_6 from './Department/Admin_Department_img_6.png';
import Admin_Department_img_7 from './Department/Admin_Department_img_7.png';
import Admin_Department_img_8 from './Department/Admin_Department_img_8.png';
import Admin_Department_img_9 from './Department/Admin_Department_img_9.png';
import Admin_Department_img_10 from './Department/Admin_Department_img_10.png';
import Admin_Department_img_11 from './Department/Admin_Department_img_11.png';

import Admin_Participant_img_1 from './Participants/Admin_Participant_img_1.png';
import Admin_Participant_img_2 from './Participants/Admin_Participant_img_2.png';
import Admin_Participant_img_3 from './Participants/Admin_Participant_img_3.png';
import Admin_Participant_img_4 from './Participants/Admin_Participant_img_4.png';
import Admin_Participant_img_5 from './Participants/Admin_Participant_img_5.png';
import Admin_Participant_img_6 from './Participants/Admin_Participant_img_6.png';
import Admin_Participant_img_7 from './Participants/Admin_Participant_img_7.png';
import Admin_Participant_img_8 from './Participants/Admin_Participant_img_8.png';
import Admin_Participant_img_9 from './Participants/Admin_Participant_img_9.png';
import Admin_Participant_img_10 from './Participants/Admin_Participant_img_10.png';
import Admin_Participant_img_11 from './Participants/Admin_Participant_img_11.png';
import Admin_Participant_img_12 from './Participants/Admin_Participant_img_12.png';
import Admin_Participant_img_13 from './Participants/Admin_Participant_img_13.png';
import Admin_Participant_img_14 from './Participants/Admin_Participant_img_14.png';

import Admin_MediaLibApp_img_1 from './Media_Library_Approval/Admin_MediaLibApp_img_1.png';
import Admin_MediaLibApp_img_2 from './Media_Library_Approval/Admin_MediaLibApp_img_2.png';
import Admin_MediaLibApp_img_3 from './Media_Library_Approval/Admin_MediaLibApp_img_3.png';
import Admin_MediaLibApp_img_4 from './Media_Library_Approval/Admin_MediaLibApp_img_4.png';
import Admin_MediaLibApp_img_5 from './Media_Library_Approval/Admin_MediaLibApp_img_5.png';
import Admin_MediaLibApp_img_6 from './Media_Library_Approval/Admin_MediaLibApp_img_6.png';
import Admin_MediaLibApp_img_7 from './Media_Library_Approval/Admin_MediaLibApp_img_7.png';
import Admin_MediaLibApp_img_8 from './Media_Library_Approval/Admin_MediaLibApp_img_8.png';
import Admin_MediaLibApp_img_9 from './Media_Library_Approval/Admin_MediaLibApp_img_9.png';
import Admin_MediaLibApp_img_10 from './Media_Library_Approval/Admin_MediaLibApp_img_10.png';
import Admin_MediaLibApp_img_11 from './Media_Library_Approval/Admin_MediaLibApp_img_11.png';

import Admin_Program_img_1 from './Program/Admin_Program_img_1.png';
import Admin_Program_img_2 from './Program/Admin_Program_img_2.png';
import Admin_Program_img_3 from './Program/Admin_Program_img_3.png';
import Admin_Program_img_4 from './Program/Admin_Program_img_4.png';
import Admin_Program_img_5 from './Program/Admin_Program_img_5.png';
import Admin_Program_img_6 from './Program/Admin_Program_img_6.png';
import Admin_Program_img_7 from './Program/Admin_Program_img_7.png';
import Admin_Program_img_8 from './Program/Admin_Program_img_8.png';
import Admin_Program_img_9 from './Program/Admin_Program_img_9.png';
import Admin_Program_img_10 from './Program/Admin_Program_img_10.png';
import Admin_Program_img_11 from './Program/Admin_Program_img_11.png';
import Admin_Program_img_12 from './Program/Admin_Program_img_12.png';

import Admin_LinkCourse_img_1 from './Link_Course/Admin_LinkCourse_img_1.png';
import Admin_LinkCourse_img_2 from './Link_Course/Admin_LinkCourse_img_2.png';
import Admin_LinkCourse_img_3 from './Link_Course/Admin_LinkCourse_img_3.png';
import Admin_LinkCourse_img_4 from './Link_Course/Admin_LinkCourse_img_4.png';
import Admin_LinkCourse_img_5 from './Link_Course/Admin_LinkCourse_img_5.png';
import Admin_LinkCourse_img_6 from './Link_Course/Admin_LinkCourse_img_6.png';
import Admin_LinkCourse_img_7 from './Link_Course/Admin_LinkCourse_img_7.png';
import Admin_LinkCourse_img_8 from './Link_Course/Admin_LinkCourse_img_8.png';
import Admin_LinkCourse_img_9 from './Link_Course/Admin_LinkCourse_img_9.png';
import Admin_LinkCourse_img_10 from './Link_Course/Admin_LinkCourse_img_10.png';
import Admin_LinkCourse_img_11 from './Link_Course/Admin_LinkCourse_img_11.png';

export default {
    Admin_Login_Img_1,
    Admin_Login_Img_2,
    Admin_Login_Img_3,
    Admin_ForgotPassword_img_1,
    Admin_ForgotPassword_img_2,
    Admin_ForgotPassword_img_3,
    Admin_ForgotPassword_img_4,
    Admin_ForgotPassword_img_5,
    Admin_ChangePassword_img_1,
    Admin_ChangePassword_img_2,
    Admin_ChangePassword_img_3,
    Admin_ChangePassword_img_4,
    Admin_CourseCategory_img_1,
    Admin_CourseCategory_img_2,
    Admin_CourseCategory_img_3,
    Admin_CourseCategory_img_4,
    Admin_CourseCategory_img_5,
    Admin_CourseCategory_img_6,
    Admin_CourseCategory_img_7,
    Admin_CourseCategory_img_8,
    Admin_CourseCategory_img_9,
    Admin_CourseCategory_img_10,
    Admin_CourseCategory_img_11,
    Admin_CourseStructApp_img_1,
    Admin_CourseStructApp_img_2,
    Admin_CourseStructApp_img_3,
    Admin_CourseStructApp_img_4,
    Admin_CourseStructApp_img_5,
    Admin_CourseStructApp_img_6,
    Admin_CourseStructApp_img_7,
    Admin_CourseStructApp_img_8,
    Admin_CourseStructApp_img_9,
    Admin_CourseStructApp_img_10,
    Admin_CourseStructApp_img_11,
    Admin_CourseStructApp_img_12,
    Admin_CourseStructApp_img_13,
    Admin_Courses_img_1,
    Admin_Courses_img_2,
    Admin_Courses_img_3,
    Admin_Courses_img_4,
    Admin_Courses_img_5,
    Admin_Courses_img_6,
    Admin_Courses_img_7,
    Admin_Courses_img_8,
    Admin_Courses_img_9,
    Admin_Courses_img_10,
    Admin_Courses_img_11,
    Admin_Courses_img_12,
    Admin_Courses_img_13,
    Admin_Courses_img_14,
    Admin_Courses_img_15,
    Admin_Courses_img_16,
    Admin_Courses_img_17,
    Admin_Courses_img_18,
    Admin_Courses_img_19,
    Admin_Courses_img_20,
    Admin_Courses_img_21,
    Admin_Courses_img_22,
    Admin_Courses_img_23,
    Admin_Courses_img_24,
    Admin_Courses_img_25,
    Admin_CoursesByInst_img_1,
    Admin_CoursesByInst_img_2,
    Admin_CoursesByInst_img_3,
    Admin_CoursesByInst_img_4,
    Admin_CoursesByInst_img_5,
    Admin_CoursesByInst_img_6,
    Admin_CoursesByInst_img_7,
    Admin_CoursesByInst_img_8,
    Admin_CoursesByInst_img_9,
    Admin_CoursesByInst_img_10,
    Admin_CoursesByInst_img_11,
    Admin_CoursesByInst_img_12,
    Admin_Dashboard_img_1,
    Admin_Dashboard_img_2,
    Admin_Dashboard_img_3,
    Admin_Dashboard_img_4,
    Admin_Dashboard_img_5,
    Admin_Dashboard_img_6,
    Admin_Dashboard_img_7,
    Admin_Dashboard_img_8,
    Admin_Dashboard_img_9,
    Admin_DepartmentCCM_img_1,
    Admin_DepartmentCCM_img_2,
    Admin_DepartmentCCM_img_3,
    Admin_DepartmentCCM_img_4,
    Admin_DepartmentCCM_img_5,
    Admin_DepartmentCCM_img_6,
    Admin_DepartmentCCM_img_7,
    Admin_DepartmentCCM_img_8,
    Admin_DepartmentCCM_img_9,
    Admin_DepartmentCCM_img_10,
    Admin_DepartmentCCM_img_11,
    Admin_Instructor_img_1,
    Admin_Instructor_img_2,
    Admin_Instructor_img_3,
    Admin_Instructor_img_4,
    Admin_Instructor_img_5,
    Admin_Instructor_img_6,
    Admin_Instructor_img_7,
    Admin_Instructor_img_8,
    Admin_Instructor_img_9,
    Admin_Instructor_img_10,
    Admin_Instructor_img_11,
    Admin_Instructor_img_12,
    Admin_LiveClassRec_img_1,
    Admin_LiveClassRec_img_2,
    Admin_LiveClassRec_img_3,
    Admin_LiveClassRec_img_4,
    Admin_LiveClassRec_img_5,
    Admin_LogOut_Img_1,
    Admin_LogOut_Img_2,
    Admin_LogOut_Img_3,
    Admin_Notification_img_1,
    Admin_Notification_img_2,
    Admin_Notification_img_3,
    Admin_Notification_img_4,
    Admin_Department_img_1,
    Admin_Department_img_2,
    Admin_Department_img_3,
    Admin_Department_img_4,
    Admin_Department_img_5,
    Admin_Department_img_6,
    Admin_Department_img_7,
    Admin_Department_img_8,
    Admin_Department_img_9,
    Admin_Department_img_10,
    Admin_Department_img_11,
    Admin_Participant_img_1,
    Admin_Participant_img_2,
    Admin_Participant_img_3,
    Admin_Participant_img_4,
    Admin_Participant_img_5,
    Admin_Participant_img_6,
    Admin_Participant_img_7,
    Admin_Participant_img_8,
    Admin_Participant_img_9,
    Admin_Participant_img_10,
    Admin_Participant_img_11,
    Admin_Participant_img_12,
    Admin_Participant_img_13,
    Admin_Participant_img_14,
    Admin_MediaLibApp_img_1,
    Admin_MediaLibApp_img_2,
    Admin_MediaLibApp_img_3,
    Admin_MediaLibApp_img_4,
    Admin_MediaLibApp_img_5,
    Admin_MediaLibApp_img_6,
    Admin_MediaLibApp_img_7,
    Admin_MediaLibApp_img_8,
    Admin_MediaLibApp_img_9,
    Admin_MediaLibApp_img_10,
    Admin_MediaLibApp_img_11,
    Admin_Program_img_1,
    Admin_Program_img_2,
    Admin_Program_img_3,
    Admin_Program_img_4,
    Admin_Program_img_5,
    Admin_Program_img_6,
    Admin_Program_img_7,
    Admin_Program_img_8,
    Admin_Program_img_9,
    Admin_Program_img_10,
    Admin_Program_img_11,
    Admin_Program_img_12,
    Admin_LinkCourse_img_1,
    Admin_LinkCourse_img_2,
    Admin_LinkCourse_img_3,
    Admin_LinkCourse_img_4,
    Admin_LinkCourse_img_5,
    Admin_LinkCourse_img_6,
    Admin_LinkCourse_img_7,
    Admin_LinkCourse_img_8,
    Admin_LinkCourse_img_9,
    Admin_LinkCourse_img_10,
    Admin_LinkCourse_img_11,
};


