import React from 'react';
import { useLocation } from 'react-router-dom';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import { ReactComponent as ActiveStar } from '../../../asset/icons/StarActive.svg';
import { ReactComponent as InactiveStar } from '../../../asset/icons/StarInactive.svg';
function MediaRatings() {
    const { state: assignedMediaId } = useLocation();

    const fetchUtils = useAxiosPost(endpoints.mediaAssignRating.selectBy, {
        selectcolumns: '*',
        wherecondition: `ASSIGNED_MEDIA_ID = ${assignedMediaId}`,
    });

    const cellModifier = {
        Ratings: ({ value }) => {
            const ratings = [];
            for (let i = 1; i <= 5; i++) {
                if (i <= Number(value)) {
                    ratings.push(<ActiveStar />)
                }
                else{
                    ratings.push(<InactiveStar/>)
                }
            }
            return ratings;
        },
    };

    return (
        <>
            <CRUDTable
                immutable
                irremovable
                insertable={false}
                {...fetchUtils}
                {...viewProps.MediaRatings}
                cellModifier={cellModifier}
                formSize='md'
            />
        </>
    );
}

export default MediaRatings;
