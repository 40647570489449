import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import { Col, Row } from 'react-bootstrap';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function ProgramForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response: courseCategories, loading } = useAxiosGet(
        endpoints.courseCategory.get
    );

    const formType = updateValues ? 'update' : 'add';

    const initialFormValues = updateValues || {
        Course_Program_NAME: '',
        Course_Category_ID: '',
        Course_Program_Credits: '',
        Course_Program_Description: '',
        Course_Program_Duration_Days: '',
        Course_Program_Duration_hours: '',
        Course_Program_STATUS: '1',
    };

    const submitHandler = (values, { setSubmitting }) => {
        setSubmitting(true);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setSubmitting(false));
    };
    if (loading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Course_Program_NAME: Yup.string().required(),
                Course_Category_ID: Yup.string().required(),
                Course_Program_Description: Yup.string().required(),
                Course_Program_Credits: Yup.number().min(0).required(),
                Course_Program_Duration_Days: Yup.number().min(0).required(),
                Course_Program_Duration_hours: Yup.number().min(0).required(),
                Course_Program_STATUS: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Row>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='Course_Program_NAME'
                                label='Program Name'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select-advanced'
                                required
                                name='Course_Category_ID'
                                label='Course Category'
                                options={courseCategories}
                                defaultValue={courseCategories.find(
                                    (cat) =>
                                        Number(cat.Course_Category_ID) ===
                                        Number(
                                            initialFormValues.Course_Category_ID
                                        )
                                )}
                                optionValue='Course_Category_ID'
                                optionLabel='Course_Category_NAME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='textarea'
                                required
                                name='Course_Program_Description'
                                label='Program Description'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                required
                                name='Course_Program_Credits'
                                label='Program Credit'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                required
                                name='Course_Program_Duration_Days'
                                label='Duration Days'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='number'
                                required
                                name='Course_Program_Duration_hours'
                                label='Duration Hours'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                type='number'
                                required
                                name='Course_Program_STATUS'
                                label='Program Status'
                                disabled={formType === 'add'}
                            >
                                <option>Select status</option>
                                <option value={1}>Draft</option>
                                <option value={3}>Published</option>
                                <option value={4}>Retired</option>
                            </FormikControl>
                        </Col>
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(ProgramForm);
