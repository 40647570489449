import React from "react";
// import { Button } from "react-bootstrap";
import CRUDTable from "../../../components/table/CRUDTable";
import { useAuthenticationState } from "../../../context/Auth.context";
// import { submitStructureForReview } from "../../../services/courseStructureService";
import endpoints from "../../../services/endpoints";
import StructureForm from "../../faculty/courseStructure/StructureForm";
import viewProps from "../viewProps";

function Rejected({ fetchUtils, response }) {
    const {user} = useAuthenticationState()

    const cellModifier = {
        Course_Structure_Content_Type: ({ value }) => {
            switch (value) {
                case 1:
                    return "Live Class";
                case 2:
                    return "Video";
                case 3:
                    return "Quiz";
                case 4:
                    return "Document";
                default:
                    break;
            }
        },
        // Rereview: ({ row }) => (
        //     <Button
        //         variant="outline-info"
        //         className="py-1 px-1"
        //         onClick={() => {
        //             fetchUtils.setLoading(true);

        //             const body = { ...row.original };
        //             submitStructureForReview(body)
        //                 .then((res) => {
        //                     fetchUtils.reFetch();
        //                 })
        //                 .catch(() => {
        //                     fetchUtils.setLoading(false);
        //                 });
        //         }}
        //     >
        //         <small>Rereview</small>
        //     </Button>
        // ),
    };
    return (
        <CRUDTable
            endpoints={endpoints.courseStructure}
            relevants={[
                ...viewProps.CourseStructureApproval.relevants,
                // "Rereview",
            ]}
            columnHeads={[
                ...viewProps.CourseStructureApproval.columnHeads,
                // "Rereview",
            ]}
            {...fetchUtils}
            cellModifier={cellModifier}
            name='Rejected Structure'
            response={response}
            immutable
            insertable={user.type === 'BOTH'}
            Form={StructureForm}
            addBtnLabel='Add Structure'
        />
    );
}

export default Rejected;
