import React, { useCallback, useEffect, useState } from 'react';
import { Button, FormCheck } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLocation } from 'react-router-dom';
import DeleteWithWarning from '../../../components/DeleteWithWarning';
import { useAxiosPost } from '../../../services/axiosHooks';
import { submitStructureForReview } from '../../../services/courseStructureService';
import endpoints from '../../../services/endpoints';
import Drag from './Drag';
import StructureForm from './StructureForm';
import WithModal from '../../../components/WithModal';
import Loader from '../../../components/Loader';
import axios from 'axios';
import withAlert from '../../../hoc/withAlert';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import NoMoreSchedule from './NoMoreSchedule';
import toast from 'react-hot-toast';

function CourseStructure({ showAlert }) {
    const { state: courseData } = useLocation();

    const { response, loading, reFetch, setLoading } = useAxiosPost(
        endpoints.courseStructure.selectBy,
        {
            selectcolumns: '*',
            wherecondition: `Course_ID = ${courseData.courseId} AND 
        Course_Category_ID = ${courseData.courseCategoryId} AND 
        Version_ID = ${courseData.versionId}`,
        }
    );

    const [structures, setStructure] = useState([]);
    useEffect(() => {
        setStructure(response);
    }, [response]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const newOrder = [...structures];
        const [selectedItem] = newOrder.splice(dragIndex, 1);

        newOrder.splice(hoverIndex, 0, selectedItem);

        setStructure((prevCards) => {
            const temp = [...prevCards];
            const [selectedItem] = temp.splice(dragIndex, 1);

            temp.splice(hoverIndex, 0, selectedItem);
            return temp;
        });
    }, []);

    const sortOrder = () => {
        structures.forEach((strucuture, i) => {
            strucuture.Course_Structure_Priority_ID = i;
        });
        axios
            .post(endpoints.courseStructure.sort, {
                course_orderlist: structures,
            })
            .then((res) => {
                console.log(res);
                reFetch();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
                // showAlert('error', extractErrorFromRes(err));
            });
    };

    const getType = (value) => {
        switch (Number(value)) {
            case 1:
                return 'Live Class';
            case 2:
                return 'Video';
            case 3:
                return 'Quiz';
            case 4:
                return 'Document';
            case 5:
                return 'Text';
            default:
                break;
        }
    };

    return (
        <>
            <div className='px-2 py-4 px-md-4 d-flex align-items-center'>
                <span className='me-auto fs-4'>
                    {structures[0]?.Course_NAME} Course Structure
                </span>
                {Number(structures[0]?.Course_STATUS) === 3 ? (
                    <span
                        className='btn-primary opacity-50 px-3 py-2 rounded-1'
                        title='Cannot add structure to Published course'
                    >
                        Add Structure
                    </span>
                ) : (
                    <WithModal
                        modalTitle={'Add Structure'}
                        modalAttrs={{ size: 'md' }}
                        renderModalBody={(closeModal) => (
                            <StructureForm
                                onAfterSubmit={() => {
                                    closeModal();
                                    reFetch();
                                }}
                                onCancel={closeModal}
                                endpoint={endpoints.courseStructure.add}
                            />
                        )}
                    >
                        <Button>Add Structure</Button>
                    </WithModal>
                )}
            </div>
            <div className='table-responsive'>
                <DndProvider backend={HTML5Backend}>
                    <div className='table'>
                        <div className='thead'>
                            <div className='th'>Order</div>
                            <div className='th'>Type</div>
                            <div className='th'>Course Structure Title</div>
                            {/* <div className='th'>Structure Name</div> */}
                            <div className='th'>Item Name</div>
                            <div className='th'>Item Tag</div>
                            <div className='th'>No More Schedules</div>
                            <div className='th'>Status</div>
                            <div className='th'>Submit</div>
                            <div className='th text-center'>Delete</div>
                        </div>
                        <div className='tbody'>
                            {structures.map((data, i) => renderRow(data, i))}
                        </div>
                    </div>
                </DndProvider>
                {loading && <Loader />}
            </div>
        </>
    );

    function renderRow(data, index) {
        return (
            <Drag
                key={data.id}
                index={index}
                id={data.id}
                moveCard={moveCard}
                onDrop={sortOrder}
                className='tr border-top'
            >
                <div className='td'>{index + 1}</div>
                <div className='td'>
                    {getType(data?.Course_Structure_Content_Type)}
                </div>
                <div className='td'>{data?.Course_Structure_TitleName}</div>
                <div className='td'>{data?.Course_Structure_Name}</div>
                <div className='td'>{data?.Course_ContentTags}</div>

                <div className='td text-center'>
                    {Number(data?.Course_Structure_Content_Type) === 1 ? (
                        <NoMoreSchedule
                            data={data}
                            setLoading={setLoading}
                            refreshStructure={reFetch}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <div className='td'>
                    {Number(data?.Course_Structure_Status_ID) === 2 &&
                    Number(data?.Reviewed_Status) === 1
                        ? 'Rejected'
                        : data?.Course_Structure_Status}
                </div>
                <div className='td'>
                    <Button
                        variant='outline-info'
                        className='py-1 px-1'
                        disabled={
                            Number(data?.Course_Structure_Status_ID) !== 1
                        }
                        onClick={() => {
                            setLoading(true);

                            submitStructureForReview(data)
                                .then((res) => {
                                    console.log(res);
                                    reFetch();
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                    >
                        <small>Submit for Review</small>
                    </Button>
                </div>
                <div className='td text-center'>
                    <DeleteWithWarning
                        disabled={Number(data?.Course_STATUS) === 3}
                        title={'Structure'}
                        configBody={{
                            ...data,
                        }}
                        onAfterDelete={() => {
                            if (
                                Number(data.Course_Structure_Content_Type) !==
                                    1 &&
                                Number(data.Course_Structure_Content_Type) !== 3
                            ) {
                                setLoading(true);
                                axios
                                    .post(endpoints.mediaAssign.delete, {
                                        ...data,
                                        ASSIGNED_MEDIA_ID:
                                            data.Course_Structure_Content_ID,
                                    })
                                    .then(() => {
                                        reFetch();
                                    })
                                    .finally(() => setLoading(false));
                            } else {
                                reFetch();
                            }
                        }}
                        endpoint={endpoints.courseStructure.delete}
                    />
                </div>
            </Drag>
        );
    }
}

export default withAlert(CourseStructure);
