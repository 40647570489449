import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import endpoints from '../../../services/endpoints';
import { useAxiosGet } from '../../../services/axiosHooks';
import LiveClassTopicForm from '../liveClassTopic/LiveClassTopicForm';
import { v4 as uuidv4 } from 'uuid';
import { useAuthenticationState } from '../../../context/Auth.context';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { checkIsoAndReturnYMDFormat } from '../../../helpers/dateFunctions';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function LiveClassForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const formType = updateValues ? 'update' : 'add';

    const {
        response: classTopics,
        loading,
        reFetch,
    } = useAxiosGet(endpoints.liveclassTopic.get, {
        preventCall: formType === 'update',
    });

    const { user } = useAuthenticationState();

    const submitHandler = (values, { setSubmitting: setLoading }) => {
        const body = { ...values };

        body.LIVECLASSSCHEDULESTARTTIME = moment(
            body.LIVECLASSSCHEDULESTARTDATE
        )
            .add(moment(body.STARTTIME).format('HH'), 'hours')
            .add(moment(body.STARTTIME).format('mm'), 'minutes')
            .toISOString();

        body.LIVECLASSSCHEDULEENDDATE = moment(body.LIVECLASSSCHEDULESTARTTIME)
            .add(body.LIVECLASSSCHEDULEDURATION, 'minutes')
            .toISOString();

        body.LIVECLASSSCHEDULEENDTIME = body.LIVECLASSSCHEDULEENDDATE;

        body.LIVECLASSSCHEDULESTARTDATE = body.LIVECLASSSCHEDULESTARTTIME;

        if (formType === 'add') {
            body.LIVE_CLASS_ROOM_ID = uuidv4();
            body.INSTRUCTOR_ID = user.id;
            body.LIVE_CLASS_EVENT_STATUS = 1;
            body.LIVE_CLASS_TITLE = classTopics.find(
                (topic) =>
                    Number(topic.LIVE_CLASS_TOPIC_ID) ===
                    Number(body.LIVE_CLASS_TOPIC_ID)
            ).LIVE_CLASS_TOPIC_NAME;
        }
        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                console.log(err.response.data.statusText);
                toast.error(extractErrorFromRes(err));
                // showAlert('error', err.response.data.statusText);
            })
            .finally((res) => setLoading(false));
    };

    // if (loading) {
    //     return <Loader />;
    // }

    if (formType === 'update') {
        updateValues.STARTTIME = updateValues.LIVECLASSSCHEDULESTARTTIME;
        updateValues.ENDTIME = updateValues.LIVECLASSSCHEDULEENDTIME;

        updateValues.LIVECLASSSCHEDULESTARTDATE = checkIsoAndReturnYMDFormat(
            updateValues.LIVECLASSSCHEDULESTARTDATE
        );
        updateValues.LIVECLASSSCHEDULEENDDATE = checkIsoAndReturnYMDFormat(
            updateValues.LIVECLASSSCHEDULEENDDATE
        );
    }

    const initialFormValues = updateValues || {
        LIVE_CLASS_ROOM_ID: '',
        LIVE_CLASS_TOPIC_ID: '',
        INSTRUCTOR_ID: '',
        LIVE_CLASS_TITLE: '',
        LIVECLASSSCHEDULESTARTDATE: '',
        STARTTIME: new Date(),
        LIVECLASSSCHEDULEENDDATE: '',
        ENDTIME: '',
        LIVECLASSSCHEDULEDURATION: '',
        LIVE_CLASS_EVENT_STATUS: '',
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                LIVE_CLASS_TOPIC_ID: Yup.string().required(),
                LIVECLASSSCHEDULESTARTDATE: Yup.date()
                    .min(today, 'Past date are not allowed')
                    .required(),
                STARTTIME: Yup.string()
                    .test(
                        'STARTTIME',
                        'The schedule time should be at least 5 minutes later from now',
                        function (value) {
                            const { LIVECLASSSCHEDULESTARTDATE } = this.parent;

                            if (
                                moment(value).format('ddd MMM DD YYYY') ===
                                moment(LIVECLASSSCHEDULESTARTDATE).format(
                                    'ddd MMM DD YYYY'
                                )
                            ) {
                                const liveClassStartTimeValid =
                                    new Date().setSeconds(0, 0);

                                return moment(value).isSameOrAfter(
                                    moment(liveClassStartTimeValid).add(
                                        5,
                                        'minutes'
                                    )
                                );
                            } else {
                                return true;
                            }
                        }
                    )
                    .required(),
                ENDTIME: Yup.string(),
                LIVECLASSSCHEDULEDURATION: Yup.number()
                    .min(30, 'Minimum Duration is 30 mins')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, setFieldValue, values, errors }) => (
                <Form>
                    {formType === 'add' && (
                        <FormikControl
                            control='select-add'
                            required
                            name='LIVE_CLASS_TOPIC_ID'
                            label='Live Class Topic'
                            options={classTopics}
                            optionValue='LIVE_CLASS_TOPIC_ID'
                            optionLabel='LIVE_CLASS_TOPIC_NAME'
                            Form={LiveClassTopicForm}
                            formProps={{
                                onAfterSubmit: () => {
                                    setFieldValue('LIVE_CLASS_TOPIC_ID', '');
                                    reFetch();
                                },
                                endpoint: endpoints.liveclassTopic.add,
                                formSize: 'sm',
                            }}
                        />
                    )}
                    {console.log(errors)}
                    <FormikControl
                        name='LIVECLASSSCHEDULEDURATION'
                        control='input'
                        type='number'
                        required
                        min='0'
                        label='Duration (min)'
                        onChange={(e) => {
                            setFieldValue(
                                'LIVECLASSSCHEDULEDURATION',
                                e.target.value
                            );
                            if (values.STARTTIME) {
                                setFieldValue(
                                    'ENDTIME',
                                    moment(values.STARTTIME).add(
                                        e.target.value || 0,
                                        'minutes'
                                    )._d
                                );
                            }
                        }}
                    />

                    <FormikControl
                        name='LIVECLASSSCHEDULESTARTDATE'
                        type='date'
                        control='input'
                        required
                    />

                    <FormikControl
                        name='STARTTIME'
                        label='Start Time'
                        control='time'
                        required
                        onChange={(date) => {
                            if (date) {
                                console.log(date);
                                setFieldValue('STARTTIME', date._d);

                                setFieldValue(
                                    'ENDTIME',
                                    moment(date._d).add(
                                        values.LIVECLASSSCHEDULEDURATION || 0,
                                        'minutes'
                                    )._d
                                );
                            } else {
                                setFieldValue('STARTTIME', moment()._d);

                                setFieldValue('ENDTIME', moment()._d);
                            }
                        }}
                    />

                    <FormikControl
                        control='time'
                        name='ENDTIME'
                        label='End Time'
                        disabled
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {(isSubmitting || loading) && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(LiveClassForm);
