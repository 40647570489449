import { Col, Row } from 'react-bootstrap';
import Avatar from '../../../asset/icons/InsAvatar.svg';

function Tutors({ instructors }) {
    // const instructors = instructors.filter((ins, i) =>
    //     instructors.findIndex(
    //         (ins2) => ins2.instructor_ID === ins.instructor_ID
    //     ) === i
    // );

    return (
        <div className='bg-white rounded-smoother p-3 h-100'>
            <div className='d-flex flex-column mb-3'>
                <div className='fs-5'>Top Tutors</div>
                <small className='text-muted'>
                    Based on Live Class Ratings
                </small>
            </div>
            <Row
                className='m-0'
                style={{ maxHeight: '320px', overflowY: 'auto' }}
            >
                {instructors.map((data) => (
                    <Col sm={6} className='ps-0 pe-4 mb-3'>
                        <TutorCard
                            name={data.Instructor_Name}
                            sub={data.Instructor_EMAIL}
                            pic={data.DP_URL ?? Avatar}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default Tutors;

function TutorCard({ name, pic, sub }) {
    return (
        <div
            className='d-flex rounded-smooth p-2'
            style={{ backgroundColor: '#F7F8F7' }}
        >
            <div style={{ width: 100 / 3 + '%' }}>
                <div
                    style={{
                        backgroundImage: 'url(' + pic + ')',
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        width: '100%',
                        height: '100%',
                    }}
                />
            </div>
            <div style={{ width: 100 / 3 + '%' }}>
                <div className='d-flex flex-column'>
                    <span>{name}</span>
                    <small className='text-muted'>{sub}</small>
                </div>
            </div>
        </div>
    );
}
