const endpoints = Object.freeze({
    authentication: {
        login: '/auth/login',
        verify: '/auth/token',
        changePassword:
            '/instructor/activate_instrutor/changePasswordinstuctor',
        forgetPassword:
            '/instructor/activate_instrutor/forgotpassword_instructor',
    },
    notification: {
        instructor: '/common/notifications/instructornotification',
        instructorRead: '/common/notifications/instructornotificationread',
        admin: '/admin/adminnotification/list',
        adminRead: '/common/notifications/adminnotificationread',
    },
    calendarInstructor: {
        selectBy: '/admin/cb/calendar_Instructor/selectby',
        add: '/admin/cb/calendar_Instructor/add',
        update: '/admin/cb/calendar_Instructor/update',
        delete: '/admin/cb/calendar_Instructor/delete',
    },

    course: {
        selectBy: '/admin/cb/cb_vw_course_listing/selectby',
        get: '/admin/cb/Course_Listing/',
        add: '/admin/cb/Course_Listing/add',
        update: '/admin/cb/Course_Listing/update',
        delete: '/admin/cb/Course_Listing/delete',
        participant: '/admin/cb/cb_vw_Participants_AvailableCourses/selectby',
        contentUpdate: 'admin/cb/Course_Content/instructor_update',
        autopopulateTag:
            '/admin/cb/streamhall_credit_mcq_questionbank_updated/SH_1.5/AutopopulateTags',
        bulkUpload: '/admin/bu/course/',
        bulkUploadConfirm: '/admin/bu/course/dependency/addNew',
        bulkUploadTemplate: '/excel/bulkupload/template/CourseCsv',
    },
    courseReviews: {
        get: '/mobile/reviewrating/coursereview/completelist/',
        get2: 'mobile/reviewrating/review/course/list/',
    },
    courseCategory: {
        get: '/admin/cb/Course_Category/',
        add: '/admin/cb/Course_Category/add',
        update: '/admin/cb/Course_Category/update',
        delete: '/admin/cb/Course_Category/delete',
    },
    courseInstructor: {
        get: '/admin/course/list/instructormapping',
        add: '/admin/courseinstructorupdate/update',
        update: '/admin/courseinstructorupdate/update',
        delete: '/admin/cb/Course_Instructor_map/delete',
        selectBy: '/admin/cb/cb_vw_Instructor_Course_Mapping/selectby',
    },

    courseProgram: {
        get: '/admin/programcourse/list/programcoursemapping',
        add: '/admin/courseprogramupdate/update',
        update: '/admin/courseprogramupdate/update',
        delete: '/admin/cb/Course_Program/delete',
        // delete: '/admin/cb/Course_Program_Map/delete',
        selectBy: '/admin/cb/cb_vw_ProgramCourse/selectby',
    },
    courseStructure: {
        selectIn: '/admin/cb/Course_Structure/selectin',
        selectBy: '/admin/cb/cb_VW_COURSE_STRUCTURE/selectby',
        add: '/admin/cb/Course_Structure/add',
        update: '/admin/cb/Course_Structure/update',
        sort: '/admin/course_structure_orderupdate/courseorder_update',
        delete: '/admin/cb/Course_Structure/delete',
        remove: '/admin/cb/Course_Structure/remove',
        addSructureTag: '/admin/cb/Course_Content/Coursecontenttag/insert',
    },

    department: {
        get: '/admin/cb/participant_dept/',
        add: '/admin/cb/participant_dept/add',
        update: '/admin/cb/participant_dept/update',
        delete: '/admin/cb/participant_dept/delete',
    },
    teams: {
        selectBy: '/admin/cb/participant_Teams/selectby',
        get: '/admin/cb/participant_Teams/',
        add: '/admin/cb/participant_Teams/add',
        update: '/admin/cb/participant_Teams/update',
        delete: '/admin/cb/participant_Teams/delete',
        // bulkUpload: '',
        // bulkUploadTemplate: '',
    },
    teamsCourseCategory: {
        get: '/admin/teamCourseCategory/list/teamCourseCategory',
        add: '/admin/teamCourseCategoryUpdate/add',
        //add: '/admin/teamCourseCategoryUpdate/update',
        update: '/admin/teamCourseCategoryUpdate/update',
        delete: '/admin/cb/Course_Category_Teams_Map/delete',
    },
    designation: {
        get: '/admin/cb/streamhall_participant_Designation_Enum/',
    },
    location: {
        get: '/admin/cb/streamhall_participant_Location_Enum/',
        add: '/admin/cb/streamhall_participant_Location_Enum/add',
        update: '/admin/cb/streamhall_participant_Location_Enum/update',
        delete: '/admin/cb/streamhall_participant_Location_Enum/delete',
    },
    departmentCourseCategory: {
        selectBy: '/admin/cb/cb_vw_Category_Dept_Map/selectby',
        get: '/admin/departmentcourse/list/departmentcoursemapping',
        add: '/admin/departmentcourseupdate/update',
        update: '/admin/departmentcourseupdate/update',
        // delete: '/admin/cb/participant_dept/delete',
        delete: '/admin/cb/Course_Category_Dept_Map/delete',
    },
    designationCourseCategory: {
        // selectBy: '/admin/cb/cb_vw_Category_Dept_Map/selectby',
        get: '/admin/designationCourse/list/designationcoursemapping',
        add: '/admin/designationCourseUpdate/add',
        update: '/admin/designationCourseUpdate/update',
        delete: '/admin/cb/Course_Category_Designation_Map/delete',
        // delete: '/admin/cb/Course_Category_Dept_Map/delete',
    },

    instructor: {
        selectBy: '/admin/cb/cb_vw_Instructor_details/selectby',
        get: '/admin/cb/instructors_det/',
        add: '/admin/cb/instructors_login/add',
        update: '/admin/cb/instructors_login/update',
        delete: '/admin/cb/instructors_det/delete',
        activate: '/instructor/activate_instrutor/activateinstructor',
        bulkUpload: '/admin/bu/instructor/',
        bulkUploadConfirm: '/admin/bu/instructor/dependency/addNew',
        bulkUploadTemplate: '/excel/bulkupload/template/InstuctorCsv',
    },

    liveclass: {
        get: '/admin/cb/credit_liveclassesschedule',
        add: '/admin/cb/credit_liveclassesschedule/add',
        delete: '/admin/cb/credit_liveclassesschedule/delete',
        update: '/admin/cb/credit_liveclassesschedule/update',
        selectBy: '/admin/cb/cb_vw_course_liveclassschedule/selectby',
        start: '/instructor/cb/lc/start',
        join: '/instructor/cb/lc/join',
        endSchedules: '/instructor/liveclass_ended/end',
        restartSchedules: '/instructor/liveclass_ended/restart',
        videoRecordingList: '/instructor/cb/videorecordings',
        processVideoRecording: '/instructor/cb/videorecordings/processvideo',
    },

    liveclassTopic: {
        get: '/admin/cb/STREAMHALL_LIVECLASS_TOPICS/',
        add: '/admin/cb/STREAMHALL_LIVECLASS_TOPICS/add',
        delete: '/admin/cb/STREAMHALL_LIVECLASS_TOPICS/delete',
        update: '/admin/cb/STREAMHALL_LIVECLASS_TOPICS/update',
    },

    mediaAssign: {
        get: '/instructor/cb/assignedmedia/list/byinstructor',
        add: '/instructor/cb/assignedmedia/add',
        selectBy: '/admin/vw/cb_vw_course_assignedmedia/selectby',
        update: '/instructor/cb/assignedmedia/update-status',
        delete: '/instructor/cb/assignedmedia/delete',
    },

    mediaAssignReview: {
        // selectBy: "/admin/cb/credit_assignedmedia_reviewcomments/selectby",
        // selectBy: "/admin/cb/cb_vw_assgnedmedia_review_Instructor/selectby",
        list: '/instructor/cb/assignedmedia/review/user/list',
        add: '/admin/cb/credit_assignedmedia_reviewcomments/add',
        conversation: '/instructor/cb/assignedmedia/review/list',
        delete: '/admin/cb/credit_assignedmedia_reviewcomments/delete',
    },

    mediaAssignRating: {
        selectBy: '/admin/cb/cb_vw_assgnedmedia_rating_participant/selectby',
    },

    mediaLibrary: {
        getUploadLink: '/instructor/cb/medialibrary/getSignedURL',
        get: '/instructor/cb/medialibrary/list/all',
        add: '/instructor/cb/medialibrary/add',
        delete: '/instructor/cb/medialibrary/delete',
        update: '/instructor/cb/medialibrary/update',
        getForApproval: '/instructor/cb/medialibrary/list/forapproval',
        approve: '/instructor/cb/medialibrary/approve',
        getApproved: '/instructor/cb/medialibrary/list/approved',
        bulkUpload: '/excel/bulkupload/media',
        getlistbycoursecategory: '/instructor/cb/quiz/qb/list/',
        mediaLibraryTypes: '/instructor/cb/medialibrarytypes/mediatypes',
        bulkGetSignedUrl: '/instructor/cb/medialibrary/bulkupload/getSignedURL',
        confirmMediaBulkAdd: '/instructor/cb/medialibrary/bulkupload/add',
        downloadTemplete:
            '/instructor/cb/medialibrary/bulkupload/template/download',
    },

    liveClassMedia: {
        get: '/instructor/cb/liveclassmediamapping',
        add: '/instructor/cb/liveclassmediamapping/upsert',
        update: '/instructor/cb/liveclassmediamapping/upsert',
        delete: '/instructor/cb/liveclassmediamapping/delete',
        view: 'instructor/cb/liveclassmediamapping/view',
        topicsList: 'instructor/cb/liveclassmediamapping/topics',
        mediaList: 'instructor/cb/liveclassmediamapping/medias',
    },

    admin_overview: {
        dashboard1: '/auth/dashboard/',
        dashboard2: '/auth/dashboard/cont',
        popularCourses: '/admin/analytics/popularcourses',
        topCourses: '/admin/analytics/topcourses',
        topInstructors: '/admin/cb/instructors_det/',
        activeCourses: '/admin/analytics/activecourses',
        lcMissedList: 'mobile/lc/list/students_notattended/withStudentdetails',
        // newlyJoined: '/admin/analytics/newlyjoined_count',
        // liveClassSchedules: '/admin/analytics/total_liveclassdetails',
    },
    ins_overview: {
        topStudents: '/instructor/analytics_instuctor/instuctor_topstudent',
        counts: '/instructor/analytics_instuctor/counts',
        studentProgress: '/instructor/analytics_instuctor/studentProgress',
        onGoingCourses:
            '/instructor/analytics_instuctor/ongoingcourse_withprogress',
        liveClassSchedules: 'admin/analytics/instuctor_liveclassdetails',
        lcMissedList:
            'mobile/lc/list/students_notattended/withStudentdetails/instructor',
    },
    participant: {
        selectBy: '/admin/cb/cb_vw_participant_details/selectby',
        get: '/admin/cb/participants_det/',
        insbase: '/admin/cb/participants_det/',
        add: '/admin/cb/participants_det/add',
        delete: '/admin/cb/participants_det/delete',
        update: '/admin/cb/participants_det/update',
        bulkUpload: '/admin/bu/participant/',
        bulkUploadConfirm: '/admin/bu/participant/dependency/addNew',
        bulkUploadTemplate: 'excel/bulkupload/template/participants',
    },
    participant_teams: {
        selectBy: '/admin/cb/Participant_Teams_Map/selectby',
    },

    program: {
        selectBy: '/admin/cb/cb_vw_ProgramCourse/selectby',
        select2: '/admin/cb/cb_vw_Program/selectby',
        get: '/admin/cb/Course_Program/',
        add: '/admin/cb/Course_Program/add',
        delete: '/admin/cb/Course_Program/delete',
        update: '/admin/cb/Course_Program/update',
        participant: '/admin/cb/cb_vw_Participants_AvailablePrograms/selectby',
    },

    questionBank: {
        get: '/instructor/cb/quiz/qb/',
        selectBy:
            'admin/cb/streamhall_credit_mcq_questionbank_updated/SH_1.5/selectby',
        add: '/instructor/quizassign/add',
        delete: '/instructor/cb/quiz/qb/delete',
        update: '/instructor/cb/quiz/qb/update',
        bulkUpload: '/admin/bu/quiz/',
        bulkUploadConfirm: '/admin/bu/quiz/dependency/addNew',
        bulkUploadTemplate: 'excel/bulkupload/template/QuestionbankCsv',
    },

    quizAssignment: {
        get: '/instructor/cb/quiz/qa/',
        addQuiz: '/admin/updatedquiz/createquiz',
        add: '/instructor/cb/quiz/qa/add',
        selectBy: '/admin/vw/cb_vw_course_quiz/selectby',
        delete: '/instructor/cb/quiz/qa/delete',
        update: '/instructor/cb/quiz/qa/update',
    },

    quizEvaluation: {
        get: '/instructor/cb/quiz/studentsresult/students_quizresults',
        add: '/admin/cb/credit_quiz_evaluation/add',
        answers: '/instructor/cb/quiz/submit/list/eval/participant_quiz_reslt',
    },

    quizLibrary: {
        get: '/instructor/cb/quiz/ql/',
        quizWithTag: '/admin/updatedquiz/Questionlist',
        add: '/instructor/cb/quiz/ql/add',
        delete: '/instructor/cb/quiz/ql/delete',
        update: '/instructor/cb/quiz/ql/update',
        bulkUpload: '/admin/csvupload/import-quiz',
        bulkUploadTemplate: 'excel/bulkupload/template/QuestionbankCsv',
    },

    location: {
        get: 'admin/cb/streamhall_participant_Location_Enum',
        add: 'admin/cb/streamhall_participant_Location_Enum/add',
        selectBy: 'admin/cb/streamhall_participant_Location_Enum/selectby',
        delete: 'admin/cb/streamhall_participant_Location_Enum/delete',
        update: 'admin/cb/streamhall_participant_Location_Enum/update',
    },

    designation: {
        get: 'admin/cb/streamhall_participant_Designation_Enum/',
        add: 'admin/cb/streamhall_participant_Designation_Enum/add',
        selectBy: 'admin/cb/streamhall_participant_Designation_Enum/selectby',
        delete: 'admin/cb/streamhall_participant_Designation_Enum/delete',
        update: 'admin/cb/streamhall_participant_Designation_Enum/update',
    },

    teams: {
        get: 'admin/cb/participant_Teams/',
        add: 'admin/cb/participant_Teams/add',
        selectBy: 'admin/cb/participant_Teams/selectby',
        delete: 'admin/cb/participant_Teams/delete',
        update: 'admin/cb/participant_Teams/update',
    },

    locationCourseCategory: {
        get: 'admin/cb/streamhallCourseCategoryLocationMap/list/streamhallCourseCategoryLocationMap',
        add: 'admin/cb/streamhallCourseCategoryLocationMapUpdate/add',
        selectBy: 'admin/cb/Streamhall_Course_Category_Location_Map/selectby',
        delete: 'admin/cb/Streamhall_Course_Category_Location_Map/delete',
        update: 'admin/cb/streamhallCourseCategoryLocationMapUpdate/update',
    },

    teamParticipant: {
        get: 'admin/teamParticipant/list/teamParticipant',
        add: 'admin/teamParticipantUpdate/add',
        update: 'admin/teamParticipantUpdate/update',
        delete: 'admin/cb/Participant_Teams_Map/delete',
    },
    mandatoryCourse: {
        location: 'admin/mandatoryCourse/location/list',
        teams: 'admin/mandatoryCourse/teams/list',
        designations: 'admin/mandatoryCourse/designation/list',
        departments: 'admin/mandatoryCourse/department/list',
        mandatoryCourses: 'admin/mandatoryCourse/course/list',
        participants: 'admin/mandatorycourse/participant/list',
        add: 'admin/mandatorycourse/participant/mapping',
        get: 'admin/mandatorycourse/mapped/list',
        delete: 'admin/mandatorycourse/participant/mapping/delete',
        update:'admin/mandatorycourse/participant/mapping/update'
    },
});

export default endpoints;
