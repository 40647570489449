import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import TeamsCourseCategoryMapForm from './TeamsCourseCategoryMapForm';

function TeamsCourseCategoryMap() {
    const fetchUtils = useAxiosGet(endpoints.teamsCourseCategory.get);

    // can use cellmodifer here,
    // but if used, searching for the value in modified cell and also download table data wont work
    // so added a new property (teamscourseMapping) to show the values in array
    let mapping = [];
    if (!fetchUtils.loading) {
        mapping = fetchUtils.response.map((data) => {
            if (Array.isArray(data.teamcourse)) {
                data.teamscourseMapping = data.teamcourse
                    .map((courseCat) => courseCat.Course_Category_NAME)
                    .join(', ');
            }
            return data;
        });
    }

    return (
        <>
            <CRUDTable
                endpoints={endpoints.teamsCourseCategory}
                Form={TeamsCourseCategoryMapForm}
                addBtnLabel='Map Category to Team'
                // irremovable
                {...fetchUtils}
                response={mapping}
                {...viewProps.TeamsCourseCategoryMap}
                name='Teams-Course Category Mapping'

            />
        </>
    );
}

export default TeamsCourseCategoryMap;
