import React from "react";
import { Col, Row } from "react-bootstrap";
import WithModal from "../WithModal";
import AdvancedSelect from "./AdvancedSelect";

const DefaultModalBody = () => <div>Please provide Form</div>;

function SelectWithAdd(props) {
    const {
        name,
        options,
        label = "",
        Form = <DefaultModalBody />,
        formProps = {
            onAfterSubmit: () => {},
            endpoint: "",
            formSize: "md",
        },
        ...rest
    } = props;

    const {onAfterSubmit,endpoint,formSize, ...restFormProps} = formProps
    
    return (
        <div className='mb-3'>
            <Row className='align-items-end '>
                <Col lg={10} className='pe-0'>
                    <AdvancedSelect
                        options={options}
                        name={name}
                        label={label}
                        styles={{
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 10,
                            }),
                            control: (provided, state) => ({
                                ...provided,

                                fontSize: '.9em',
                                borderRadius: '8px',
                                borderBottomRightRadius: '0',
                                borderTopRightRadius: '0',
                                boxShadow: 'none',
                                border: '1px solid #BDBDBD',
                                background: 'rgba(242, 242, 242, 0.4)',
                            }),
                        }}
                        className='mb-0'
                        {...rest}
                    />
                </Col>

                <Col
                    lg={2}
                    className='ps-0' //this margin must equals to the above margin
                >
                    <WithModal
                        modalTitle={'Add ' + label}
                        modalAttrs={{ size: formProps.formSize }}
                        renderModalBody={(closeModal) => (
                            <Form
                                onCancel={closeModal}
                                onAfterSubmit={() => {
                                    closeModal();
                                    formProps.onAfterSubmit();
                                }}
                                endpoint={formProps.endpoint}
                                {...restFormProps}
                            />
                        )}
                    >
                        <div
                            style={{
                                display: 'flex',
                                padding: '0 12px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minHeight: '38px',
                                background: 'rgba(242, 242, 242, 0.4)',
                                fontSize: '1.1em',
                                fontWeight: '600',
                                color: '#cccccc',
                                border: '1px solid #BDBDBD',
                                borderLeft: 'none',
                                borderBottomRightRadius: '8px',
                                borderTopRightRadius: '8px',
                            }}
                        >
                            +
                        </div>
                    </WithModal>
                </Col>
            </Row>

            <div className='d-flex px-1'>
                <span className='text-muted small'>Could'nt find ?</span>
                <span className='text-muted small ms-auto'>Add</span>
            </div>
        </div>
    );
}

export default SelectWithAdd;
