import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function TeamsCourseCategoryMapForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response, loading } = useAxiosGetMultiple({
        teams: endpoints.teams.get,
        courseCategories: endpoints.courseCategory.get,
    });

    const { teams, courseCategories } = response;

    const submitHandler = (values, { setSubmitting }) => {
        setSubmitting(true);

        console.log(values);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || { teamcourse: [], Team_ID: '' }; //,IS_MANDATORY:' ' };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOptions = {
        team: teams.find(
            (team) => Number(team.TEAM_ID) === Number(initialFormValues.Team_ID)
        ),
        courseCat: courseCategories.filter(
            (course) =>
                !!initialFormValues.teamcourse.find(
                    (courseMap) =>
                        Number(courseMap.Course_Category_ID) ===
                        Number(course.Course_Category_ID)
                )
        ),
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Team_ID: Yup.string().required(),
                //IS_MANDATORY:Yup.string().required(),
                teamcourse: Yup.array()
                    .of(
                        Yup.object({
                            Course_Category_ID: Yup.string(),
                        })
                    )
                    .min(1, 'Select min 1 category')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='select-advanced'
                            required
                            name='Team_ID'
                            label='Team'
                            options={teams}
                            optionValue='TEAM_ID'
                            defaultValue={preSelectedOptions.team}
                            optionLabel='TEAM_NAME'
                        />
                    )}

                    <FormikControl
                        control='select-advanced'
                        required
                        name='teamcourse'
                        label='Course Category'
                        options={courseCategories}
                        defaultValue={preSelectedOptions.courseCat}
                        isMulti
                        keyProp='Course_Category_ID'
                        optionValue='Course_Category_ID'
                        optionLabel='Course_Category_NAME'
                    />

                    {/* {updateValues && (<FormikControl
                                    control='check'
                                    type='radio'
                                    required
                                    label='IS_MANDATORY ?'
                                    name='IS_MANDATORY'
                                    options={[
                                        { key: 'YES', value: '1' },
                                        { key: 'NO', value: '2' },
                                    ]}
                                />)}
                             */}

                    <FormSubmissionBtn onCancel={onCancel} />
                    {(isSubmitting || loading) && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(TeamsCourseCategoryMapForm);
