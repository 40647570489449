import axios from 'axios';
import endpoints from '../../services/endpoints';
import toast from 'react-hot-toast';

function Button({ click, placeHolder, style }) {
    const buttonStyle = {
        textAlign: 'center',
        cursor: 'pointer',
        ...style,
    };

    return (
        <div style={buttonStyle} onClick={click}>
            {placeHolder}
        </div>
    );
}

function NewItemConfirmModal({
    closeModal,
    setmodalState,
    newField,
    confirmURL,
    setLoading,
}) {
    const confirm = (selection) => {
        setLoading(true);
        axios
            .post(confirmURL, { proceed: selection })
            .then((res) => {
                if (selection == 'no') {
                    toast.success(res.data.statusText);
                } else {
                    setmodalState('success');
                }
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error.response.data.statusText);
                setLoading(false);
            });
    };

    return (
        <div>
            <div
                style={{
                    fontWeight: '600',
                    'font-size': '22px',
                    lineHeight: '27.57px',
                    paddingBottom: '20px',
                    color: '#192434',
                }}
            >
                New {newField} Found
            </div>
            <div
                style={{
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '25.78px',
                    paddingBottom: '50px',
                }}
            >
                A new {newField} has been identified in your sheet. Would you
                like to add it to the {newField} list ?
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Button
                    placeHolder="Cancel"
                    style={{ color: '#005FDB' }}
                    click={() => {
                        confirm('no');
                        closeModal();
                    }}
                />

                <Button
                    placeHolder="Save & Continue"
                    style={{
                        border: '1px solid #D0D5DD',
                        padding: '10px',
                        'border-radius': '8px',
                        background: '#006AF5',
                        color: '#FFFFFF',
                    }}
                    click={() => {
                        confirm('yes');
                    }}
                />
            </div>
        </div>
    );
}

export default NewItemConfirmModal;
