import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomSecondaryBtn from '../../../components/CustomSecondaryBtn';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import { useAuthenticationState } from '../../../context/Auth.context';

function CourseStructureView() {
    // const fetchUtils = useAxiosPost(endpoints.course.selectBy, {
    //     selectcolumns: '*',
    //     wherecondition: '(1=1)',
    // });
    const { user } = useAuthenticationState();

    const fetchUtils = useAxiosPost(endpoints.courseInstructor.selectBy, {
        selectcolumns: '*',
        wherecondition: 'instructor_ID= ' + user.id,
    });
    const navigate = useNavigate();

    const cellModifier = {
        Structure: ({ row }) => (
            <CustomSecondaryBtn
                onClick={() => {
                    navigate('/course-structure', {
                        state: {
                            courseId: row.original.Course_ID,
                            versionId: row.original.Version_ID,
                            courseCategoryId: row.original.Course_Category_ID,
                        },
                    });
                }}
            >
                Structure
            </CustomSecondaryBtn>
        ),
        Course_STATUS: ({ value }) => {
            switch (value) {
                case 1:
                    return 'In draft';
                case 2:
                    return 'Under Review';
                case 3:
                    return 'Published';
                case 4:
                    return 'Retired';
                default:
                    return value;
            }
        },
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.course}
                immutable
                irremovable
                insertable={false}
                {...viewProps.CourseStructureView}
                {...fetchUtils}
                cellModifier={cellModifier}
            />
        </>
    );
}

export default CourseStructureView;
