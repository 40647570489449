import { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip
);

function PaginatedLineChart(props) {
    const { data, dataPerPage = 20, ...rest } = props;

    const [page, setPage] = useState(1);
    const [paginated, setPaginated] = useState({});
    const [endIndex, setEndIndex] = useState('');

    useEffect(() => {
        const startIndex = page * dataPerPage - dataPerPage;
        const lastIndex = startIndex + dataPerPage;

        setEndIndex(lastIndex);

        const tempLabel = [...data.labels];
        const tempData = [...data.datasets];

        const labelsPerPage =
            tempLabel.length > 0 ? tempLabel.slice(startIndex, lastIndex) : [];
        const valuesPerPage =
            tempData.length > 0 ? tempData.slice(startIndex, lastIndex) : [];

        const temp = {
            ...data,
            labels: labelsPerPage,
            datasets: [
                {
                    data: valuesPerPage,
                },
            ],
        };

        setPaginated(temp);
    }, [page]);

    const options = {
        plugins: {
            // legend: {display: false},
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(99,99,99,.6)',
            },
        },
        scales: {
            x: {
                grid: {
                    borderDash: [5],
                    drawTicks: false,
                },
                ticks: {
                    padding: 10,
                },
            },
            y: {
                grid: {
                    borderDash: [5],
                    drawTicks: false,
                },
                beginAtZero: true,
                ticks: {
                    beginAtZero: true,
                    font: {
                        size: '10px',
                    },
                    stepSize: 2,
                    padding: 10,
                },
            },
        },
        elements: {
            point: {
                radius: 4,
                borderColor: '#D89B65',
                backgroundColor: '#fff',
                borderWidth: 2,
                hoverBorderWidth: 3,
                hoverRadius: 5,
            },
            line: {
                tension: 0.4,
                borderWidth: 4,
                borderColor: '#f8aB9544',
                backgroundColor: 'green',
            },
        },
    };

    if (!paginated.labels) {
        return '';
    }
    return (
        <>
            <div>
                <Line data={paginated} options={options} {...rest} />
            </div>
            <div className='d-flex justify-content-center'>
                <Pagination size='sm'>
                    <Pagination.Prev
                        disabled={page === 1}
                        onClick={(e) => {
                            setPage(page - 1);
                            e.preventDefault();
                        }}
                        style={{borderRadius: '20px'}}
                    />

                    <Pagination.Item>{page}</Pagination.Item>

                    <Pagination.Next
                        disabled={data.labels[endIndex] + 1 ? false : true}
                        onClick={(e) => {
                            setPage(page + 1);
                            e.preventDefault();
                        }}
                    />
                </Pagination>
            </div>
        </>
    );
}

export default PaginatedLineChart;
