import React, { useState } from 'react';
import {
    Button,
    Col,
    FormControl,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { ReactComponent as Info } from '../../../asset/icons/Info.svg';
import { ReactComponent as Add } from '../../../asset/icons/AddButton.svg';
import { ReactComponent as Search } from '../../../asset/icons/SearchIcon.svg';
import { useNavigate } from 'react-router-dom';
import QuizTagList from './QuizTagList';
import QuizQuestionList from './QuizQuestionList';
import axios from 'axios';
import endpoints from '../../../services/endpoints';
import { debounce } from 'lodash';

function QuizContent({
    setFieldValue,
    formValues,
    courseCategoryId,
    hadleClick,
}) {
    const navigate = useNavigate();
    const [questions, setQuestions] = useState([]);
    const [filteredQuestionsList, setFilteredQuestionsList] = useState([]);

    const [questionTag, setQuestionTag] = useState([]);

    const filteredQuestionsByTag = (array) => {
        axios
            .post(endpoints.quizLibrary.quizWithTag, {
                Course_Category_ID: courseCategoryId,
                Question_Tag_array: array,
            })
            .then((res) => {
                setQuestions(res.data.responseData);
                setFilteredQuestionsList(res.data.responseData);
            })
            .catch((err) => console.log(err));
    };

    const handleTagSelection = (tag) => {
        const isTagIncluded = formValues.Quiz.Question_Tag_array.some(
            (item) => item.Question_Tag === tag
        );
        if (!isTagIncluded) {
            const newTagList = [
                ...formValues.Quiz.Question_Tag_array,
                {
                    Question_Tag: tag,
                },
            ];

            setFieldValue('Quiz.Question_Tag_array', newTagList);

            filteredQuestionsByTag(newTagList);
        } else {
            const newTagList = formValues.Quiz.Question_Tag_array.filter(
                (item) => item.Question_Tag !== tag
            );

            setFieldValue('Quiz.Question_Tag_array', newTagList);

            if (newTagList.length > 0) {
                filteredQuestionsByTag(newTagList);
            } else {
                setQuestions([]);
                setFieldValue('Quiz.Selected_Questions', []);
                setFilteredQuestionsList([]);
            }
        }
    };

    //selecting the question from the list

    const filterQuestionBySearch = (event) => {
        const query = event.target.value;
        const result = query
            ? questions.filter((question) => {
                  return question.QUESTION_TEXT.toLowerCase().includes(
                      query.toLowerCase()
                  );
              })
            : questions;

        setFilteredQuestionsList(result);
    };

    const getTagList = debounce((e) => {
        axios
            .post(endpoints.course.autopopulateTag, {
                Course_Category_ID: courseCategoryId,
                keyword: e,
            })
            .then((res) => {
                setQuestionTag(res.data.responseData);
            })
            .catch((err) => {
                setQuestionTag([]);
            });
    }, 500);

    return (
        <>
            <Col
                style={{ border: '1px solid #CBD5E1', borderRadius: '6px' }}
                className='mt-2'
            >
                <div
                    style={{
                        position: 'relative',
                    }}
                >
                    <FormControl
                        type='input'
                        placeholder='Type or Search the Course Structure Tag'
                        onChange={(e) => {
                            if (e.target.value) {
                                getTagList(e.target.value);
                            } else {
                                setQuestionTag([]);
                            }
                        }}
                        style={{
                            border: 'none',
                            background: 'none',
                            fontSize: '14px',
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '20%',
                            right: '2%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <OverlayTrigger
                            placement='top'
                            delay={{ hide: 100, show: 400 }}
                            overlay={(props) => (
                                <Tooltip {...props}>
                                    <div
                                        style={{
                                            padding: '5px',
                                            fontSize: '12px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: '#FFFFFF',
                                                textAlign: 'start',
                                                fontWeight: '600',
                                            }}
                                        >
                                            Assign Course Structure Tag
                                        </div>
                                        <div
                                            style={{
                                                color: '#ffffff',
                                                textAlign: 'start',
                                                marginTop: '7px',
                                                fontWeight: '400',
                                            }}
                                        >
                                            {' '}
                                            Assign the respective Question tag
                                            to the Course Structure you want to
                                            create . To Add
                                        </div>
                                        <div
                                            style={{
                                                borderTop:
                                                    '1px solid rgba(255, 255, 255, 0.49)',
                                                marginTop: '7px',
                                                paddingTop: '5px',
                                                display: 'flex',
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    background: 'none',
                                                    marginLeft: 'auto',
                                                    border: 'none',
                                                    color: '#39F056',
                                                    textDecoration: 'underline',
                                                    lineHeight: '18px',
                                                    padding: '0px',
                                                    width: '45%',
                                                }}
                                                onClick={() => {
                                                    navigate('/question-bank');
                                                }}
                                            >
                                                To Add
                                                <Add />
                                            </Button>
                                            <span
                                                style={{
                                                    color: '#E7E7E7',
                                                    fontSize: '8px',
                                                    fontWeight: '400',
                                                    width: '55%',
                                                }}
                                            >
                                                Quiz / Question Bank / Add
                                                Question Bank / Assign Tag
                                            </span>
                                        </div>
                                    </div>
                                </Tooltip>
                            )}
                        >
                            <Info />
                        </OverlayTrigger>
                    </div>
                </div>
                <QuizTagList
                    handleTagChange={handleTagSelection}
                    questionTag={questionTag}
                    selectedTags={formValues.Quiz.Question_Tag_array}
                    hadleClick={hadleClick}
                />
                <div
                    style={{
                        position: 'relative',
                        paddingTop: '10px',
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            paddingBottom: '10px',
                            paddingLeft: '15px',
                        }}
                    >
                        <Search />
                        <div
                            style={{
                                position: 'absolute',
                                top: '0%',
                                left: '10%',
                                width: '90%',
                            }}
                        >
                            <FormControl
                                type='input'
                                placeholder='Type or Search the Question'
                                onChange={(event) => {
                                    filterQuestionBySearch(event);
                                }}
                                style={{
                                    border: 'none',
                                    background: 'none',
                                    padding: '0px',
                                    fontSize: '14px',
                                    color: '#000',
                                }}
                            />
                        </div>
                    </div>
                    <QuizQuestionList
                        questions={filteredQuestionsList}
                        tags={formValues.Quiz.Question_Tag_array}
                        selectedQuestions={formValues.Quiz.Selected_Questions}
                        handleSelectAll={() => {
                            const selectedQuestions =
                                formValues.Quiz.Selected_Questions;

                            if (selectedQuestions.length === questions.length) {
                                setFieldValue('Quiz.Selected_Questions', []);
                            } else {
                                setFieldValue(
                                    'Quiz.Selected_Questions',
                                    questions
                                );
                            }
                        }}
                        handleQuestionSelection={(question) => {
                            const questId = question.QUESTION_BANK_ID;

                            const alreadySelectedQuestion =
                                formValues.Quiz.Selected_Questions?.find(
                                    (question) => {
                                        console.log(
                                            questId ===
                                                question?.QUESTION_BANK_ID
                                        );
                                        return (
                                            questId ===
                                            question?.QUESTION_BANK_ID
                                        );
                                    }
                                );

                            if (alreadySelectedQuestion) {
                                setFieldValue(
                                    'Quiz.Selected_Questions',
                                    formValues.Quiz.Selected_Questions.filter(
                                        (quest) =>
                                            quest.QUESTION_BANK_ID !==
                                            alreadySelectedQuestion.QUESTION_BANK_ID
                                    )
                                );
                            } else {
                                setFieldValue('Quiz.Selected_Questions', [
                                    ...formValues.Quiz.Selected_Questions,
                                    question,
                                ]);
                            }
                        }}
                    />
                </div>
            </Col>
        </>
    );
}

export default QuizContent;
