import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useAuthenticationState } from '../context/Auth.context';
import Dashboard from '../layout/Dashboard';
import Login from '../views/login/Login';
import adminRoutes from './adminRoutes';
import bothRoutes from './bothRoutes';
import facultyRoutes from './facultyRoutes';
import { Toaster } from 'react-hot-toast';
import HelpLayout from '../views/admin/helpAdmin/HelpLayout';
import HelpRoute from '../views/admin/helpAdmin/HelpRoute';

function GenerateRoutes() {
    const { user } = useAuthenticationState();

    let routes = [];
    switch (user?.type?.toLowerCase()) {
        case 'admin':
            routes = [...adminRoutes];
            break;

        case 'faculty':
            routes = [...facultyRoutes];
            break;

        case 'both':
            routes = [...bothRoutes];
            break;

        default:
            break;
    }

    return (
        <>
            <Routes>
                <Route exact path='/login' element={<Login />} />
                <Route path={'/'} element={<Dashboard routes={routes} />}>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <ProtectedRoute>
                                    {<route.page />}
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Route>
                {user?.type?.toLowerCase() === 'admin' && (
                    <Route path='help/*' element={<HelpRoute />} />
                )}
                <Route path='*' element={<Navigate to={'/login'} />} />
            </Routes>
            <Toaster />
        </>
    );
}

function ProtectedRoute({ children }) {
    const { user } = useAuthenticationState();
    let location = useLocation();

    if (!user.type) {
        return <Navigate to='/login' state={{ from: location }} replace />;
    }
    return children;
}

export default GenerateRoutes;
