import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../../components/FormSubmissionBtn';
import Loader from '../../../../components/Loader';
import endpoints from '../../../../services/endpoints';
import { getMediaUploadLink, uploadMedia } from '../mediaServices';
import { Collapse } from 'react-bootstrap';
import { useAuthenticationState } from '../../../../context/Auth.context';
import extractErrorFromRes from '../../../../helpers/extractErrorFromRes';
import axios from 'axios';
import toast from 'react-hot-toast';
import { format } from 'date-fns';

function MediaUploadForm({ onCancel, onAfterSubmit }) {
    const { user } = useAuthenticationState();

    const [data, setData] = useState({
        courseCategories: [],
        mediaTypes: [],
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const courseCategoriesResponse = await axios.post(
                    endpoints.departmentCourseCategory.selectBy,
                    {
                        selectcolumns: '*',
                        wherecondition: 'Participant_Dept_ID = ' + user.deptId,
                    }
                );
                const courseCategoriesData =
                    courseCategoriesResponse.data.responseData;

                const mediaTypesResponse = await axios.get(
                    endpoints.mediaLibrary.mediaLibraryTypes,
                    {
                        selectcolumns: '*',
                    }
                );
                const mediaTypesData = mediaTypesResponse.data.responseData;

                setData({
                    courseCategories: courseCategoriesData,
                    mediaTypes: mediaTypesData,
                });

                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [user.deptId]);

    const [file, setFile] = useState(null);

    const [fileError, setFileError] = useState(false);
    const [progress, setProgress] = useState(0);

    const [acceptedMediaTypes, setAcceptedMediaTypes] = useState([]);
    const [mediaType, setMediaType] = useState('');
    const [acceptedMediaFormats, setAcceptedMediaFormats] = useState('');

    const submitHandler = async (values, { setSubmitting: setLoading }) => {
        setLoading(true);

        if (!acceptedMediaTypes.includes(file.type)) {
            setFileError(true);
            setLoading(false);
            return;
        }

        try {
            const uploadLink = await getMediaUploadLink(file.name);
            await uploadMedia(uploadLink.signedUrl, file, setProgress);

            const body = {
                MEDIA_TYPE: values.MEDIA_TYPE_ID,
                MEDIA_FILENAME: file.name,
                MEDIA_DESC: values.MEDIA_DESC,
                MEDIA_URL: uploadLink.publicUrl,
                Course_Category_ID: values.Course_Category_ID,
                EXPIRY_DATE: values.EXPIRY_DATE,
                // QUESTION_BANK_ID:,
            };

            const addMediaResponse = await axios.post(
                endpoints.mediaLibrary.add,
                body
            );

            if (user.type === 'BOTH') {
                body.MEDIA_ID = addMediaResponse.data.responseData.insertId;
                body.APPROVEDBY = user.id;

                await axios.post(endpoints.mediaLibrary.approve, body);
                setLoading(false);
                onAfterSubmit();
                toast.success(addMediaResponse.data.statusText);
            } else {
                setLoading(false);
                onAfterSubmit();
            }
        } catch (err) {
            setLoading(false);
            toast.error(extractErrorFromRes(err));
        }
    };

    const initialFormValues = {
        Course_Category_ID: '',
        MEDIA_DESC: '',
        MEDIA_TYPE_ID: '',
        EXPIRY_DATE: '',
    };

    const { courseCategories, mediaTypes } = data;

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Course_Category_ID: Yup.string().required(),
                MEDIA_DESC: Yup.string().required(),
                MEDIA_TYPE_ID: Yup.string().required(),
                EXPIRY_DATE: Yup.string(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, setFieldValue }) => (
                <Form>
                    <FormikControl
                        control='select-advanced'
                        options={courseCategories}
                        required
                        name='Course_Category_ID'
                        label='Course Category'
                        optionValue='Course_Category_ID'
                        optionLabel='Course_Category_NAME'
                    />
                    <FormikControl control='input' required name='MEDIA_DESC' />
                    <FormikControl
                        control='select-advanced'
                        options={mediaTypes}
                        required
                        name='MEDIA_TYPE_ID'
                        label='Media Type'
                        optionValue='MEDIA_TYPE_ID'
                        optionLabel='MEDIA_TYPE_NAME'
                        onChange={(item) => {
                            setFileError(false);
                            setFieldValue('MEDIA_TYPE_ID', item?.MEDIA_TYPE_ID);
                            // setFieldValue("file", null)
                            // setFile(null)
                            let ACCEPTED_TYPES = item?.ACCEPTED_TYPES
                                ? JSON.parse(item.ACCEPTED_TYPES)
                                : [];
                            setAcceptedMediaTypes(ACCEPTED_TYPES);
                            setMediaType(item?.MEDIA_TYPE_NAME);
                            setAcceptedMediaFormats(
                                item?.ACCEPTED_FORMATS
                                    ? item.ACCEPTED_FORMATS
                                    : ''
                            );
                        }}
                    />
                    <FormikControl
                        control='input'
                        name='EXPIRY_DATE'
                        type='date'
                        label='Media Expiry Date'
                        min={format(new Date(), 'yyyy-MM-dd')}
                    />
                    <FormikControl
                        label={`Media File ${
                            acceptedMediaFormats
                                ? `(Ex:- ${acceptedMediaFormats})`
                                : ''
                        }`}
                        accept={acceptedMediaTypes}
                        control='input'
                        type='file'
                        required
                        onChange={(e) => {
                            setFileError(false);
                            setFile(e.target.files[0]);
                        }}
                        name='file'
                    />
                    <Collapse in={fileError}>
                        <div className='text-danger text-center small mb-3'>
                            {`${mediaType} supported file formats are ${acceptedMediaFormats}`}
                        </div>
                        {/* <div className='text-danger text-center small mb-3'>
                            Only supported file formats are pdf,mp4,png,jpg and
                            jpeg
                        </div> */}
                    </Collapse>

                    <FormSubmissionBtn onCancel={onCancel} />

                    {loading && <Loader />}
                    {isSubmitting && (
                        <Loader type='progress' progress={progress} />
                    )}
                </Form>
            )}
        </Formik>
    );
}

export default MediaUploadForm;
