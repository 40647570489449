import { Field, ErrorMessage, getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form as BSForm } from 'react-bootstrap';
import { ReactComponent as VideoThumb } from '../../asset/icons/VideoThumb.svg';
import { ReactComponent as LiveClassVideoPlay } from '../../asset/icons/LiveClassVideoPlay.svg';
import { format } from 'date-fns';
import VideoPreview from '../../components/VideoPreview';
import WithModal from '../../components/WithModal';
import { ReactComponent as SearchClear } from '../../asset/icons/SearchClear.svg';
import { ReactComponent as SearchMediaIcon } from '../../asset/icons/SearchMediaIcon.svg';


function ListFormInput({
    name,
    options = [{ name: 'provide options' }],
    optionValue = 'name',
    optionLabel = 'name',
    label,
    onChange,
    isMulti = false,
    className = 'mb-3',
    disabled = false,
    size = 'md',
    required = false,
    ErrorFeedback,
    validityClass,
    fontSize,
    keyProp = '',
    labelProvider,
    ...props
}) {

    const [searchKey, setSearchKey] = useState('')
    const [filteredData, setFilteredData] = useState([])

    const filterData = () => {
        let filtereddata = options.filter((item) => {
            if (searchKey) {
                if (item.MEDIA_DESC.toLowerCase().match(searchKey.toLowerCase())) {
                    return item
                }
            } else {
                return item
            }
        })
        setFilteredData(filtereddata)
    }

    useEffect(() => {
        filterData()
    }, [searchKey])

    return (
        <BSForm.Group className={className} controlId={name}>
            <Field name={name}>
                {({
                    field,
                    form: {
                        errors,
                        touched,
                        values,
                        setFieldValue,
                        setFieldTouched,
                    },
                }) => (
                    <>
                        <BSForm.Label>
                            {label ||
                                labelProvider[name] ||
                                name ||
                                'Provide Label'}
                            {required && (
                                <span
                                    className={`small 
                                    ${validityClass(
                                        getIn(errors, name),
                                        values[name][0],
                                        getIn(touched, name)
                                    )
                                        }
                                    
                                `}
                                >
                                    *
                                </span>
                            )}
                        </BSForm.Label>

                        <div style={{ position: 'relative' }}>

                            <SearchMediaIcon style={{ position: 'absolute', margin: 15 }} />
                            <BSForm.Control
                                style={{ borderRadius: 6, borderColor: "#107BFF", paddingLeft: 45, fontSize:14,height:52 }}
                                placeholder="Search the Video"
                                className='mb-0'
                                type={'text'}
                                size={'lg'}
                                onChange={(e) => {
                                    setSearchKey(e.target.value)
                                }}
                                autoComplete='off'
                                value={searchKey}
                            />
                            {searchKey &&
                                <SearchClear
                                fill='red'
                                    onClick={() => { setSearchKey('') }}
                                    style={{
                                        position: "absolute",
                                        right: 15,
                                        top: "40%"
                                    }} />
                            }
                        </div>

                        <div className='px-md-4 mt-3' style={{ height: '350px', overflow: 'auto' }}>
                            {filteredData.map((option) => {
                                return (
                                    <div className='d-flex w-100' style={{ alignItems: 'center', paddingTop: 15, paddingBottom: 15, justifyContent: 'space-between', borderBottomWidth: 0.1, borderBottomColor: '#0B0B0B', borderBottomStyle: 'solid' }}>
                                        <div className='d-flex' style={{ alignItems: 'center' }}>
                                            <BSForm.Check
                                                {...field}
                                                {...props}
                                                key={option[optionValue]}
                                                name={name}
                                                value={option[optionValue]}
                                                type={'checkbox'}
                                                onChange={(e) => {
                                                    if (onChange) {
                                                        onChange(e);
                                                        return;
                                                    }
                                                    field.onChange(e);
                                                    console.log(field.value)
                                                }}
                                                checked={
                                                    String(field.value).includes(
                                                        String(option[optionValue])
                                                    )
                                                }
                                                label={''}
                                                className='px-2 mb-0'
                                                id={name + option[optionValue]}
                                            />
                                            <div className=''>
                                                <WithModal
                                                    modalAttrs={{ size: 'xl', backdrop: 'none' }}
                                                    renderModalBody={(closeModal) => (
                                                        <div className='w-100'>
                                                            <VideoPreview url={option['MEDIA_URL']} />
                                                        </div>
                                                    )}
                                                >
                                                    <VideoThumb />
                                                </WithModal>
                                            </div>

                                            <div className='px-2' style={{ display: 'inline-grid' }}>
                                                <label for={name + option[optionValue]} style={{ color: "#5A6777", fontSize: 19, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{option[optionLabel]}</label>
                                                <label style={{ color: "#FF5252", fontSize: 15 }}>{`Expiry Date : ${option['EXPIRY_DATE'] ? format(new Date(option['EXPIRY_DATE']), "dd/MM/yyyy") : "NA"}`}</label>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <WithModal
                                                modalAttrs={{ size: 'xl', backdrop: 'none' }}
                                                renderModalBody={(closeModal) => (
                                                    <div className='w-100'>
                                                        <VideoPreview url={option['MEDIA_URL']} />
                                                    </div>
                                                )}
                                            >
                                                <LiveClassVideoPlay />
                                            </WithModal>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        {errors[name] && (
                            <ErrorFeedback>{errors[name]}</ErrorFeedback>
                        )}
                    </>
                )}
            </Field>
        </BSForm.Group>
    );
}
export default ListFormInput;
