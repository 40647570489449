import React, { useState } from 'react';
import Heading from '../components/Heading';
import ImageComponent from '../components/ImageComponent';
import ScrollDiv from '../components/Scroll';
import '../helpcss/dashboard.css';
import images from '../../../../asset/images/AdminUserGuide';

const InstructorCourse = ({ direction = 'column' }) => {
    const [column] = useState(direction === 'column');
    const steps = [
        {
            id: 110,
            para: 'Click on "Instructor" from the sidebar to see more options regarding Instructor.',
            imageUrl: images.Admin_CoursesByInst_img_1,
        },
        {
            id: 111,
            para: 'Clicking on the "Courses By Instructor" module in the sidebar allows you to navigate to the Courses By Instructor page.',
            imageUrl:
                images.Admin_CoursesByInst_img_2,
        },
        {
            id: 112,
            para: 'Here, we can see the list of Instructor and Course-mapping.',
            imageUrl:
                images.Admin_CoursesByInst_img_3,
        },
        {
            id: 113,
            para: 'To map a course with an instructor, Click on "Add Mapping".',
            imageUrl:
                images.Admin_CoursesByInst_img_4,
        },
        {
            id: 114,
            para: 'Fill the form with "Instructor" and "Course".',
            imageUrl:
                images.Admin_CoursesByInst_img_5,
        },
        {
            id: 115,
            para: 'Press the "Submit" button to successfully map the course with the instructor.',
            imageUrl:
                images.Admin_CoursesByInst_img_6,
        },
        {
            id: 116,
            para: 'Here, you can see the newly added mapping between the course and the Instructor.',
            imageUrl:
                images.Admin_CoursesByInst_img_7,
        },
        {
            id: 117,
            para: 'Click on the "Download Icon" to download the Courses by Instructor details as an Excel file.',
            imageUrl:
                images.Admin_CoursesByInst_img_8,
        },
        {
            id: 118,
            para: 'To modify the Instructor and Course-Mapping, click the "Edit" button.',
            imageUrl:
                images.Admin_CoursesByInst_img_9,
        },
        {
            id: 119,
            para: 'Update the Instructor and Course-Mapping details to modify the Courses by Instructor.',
            imageUrl:
                images.Admin_CoursesByInst_img_10,
        },
        {
            id: 120,
            para: 'Press the "Submit" button to successfully update the Instructor and Course-Mapping.',
            imageUrl:
                images.Admin_CoursesByInst_img_11,
        },
        {
            id: 121,
            para: 'Click the "Delete" button to delete the Instructor and Course-Mapping.',
            imageUrl:
                images.Admin_CoursesByInst_img_12,
        },
        {
            id: 122,
            para: 'Once an Instructor has been assigned to a course, then Login as an Instructor to add media library to the course.',
            imageUrl:
                images.Admin_CoursesByInst_img_12,
        },
    ];

    return (
        <div
            style={{
                backgroundColor: '#F3F2F2',
                width: '95%',
                borderRadius: '32px',
                paddingBottom: '6px',
            }}
        >
            <ScrollDiv>
                <div
                    className='scroll'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowX: 'auto',
                        alignItems: 'center',
                    }}
                >
                    <Heading
                        text='Courses by instructor'
                        numberOfScreens={steps.length}
                    />
                    {steps.map((step) => (
                        <div
                            style={{
                                padding: '16px',
                                marginRight: '20px',
                                width: '60%',
                            }}
                            key={step.id}
                        >
                            <ImageComponent
                                imageUrl={step.imageUrl}
                                direction={'column'}
                                desc={step.para}
                            />
                        </div>
                    ))}
                </div>
            </ScrollDiv>
        </div>
    );
};

export default InstructorCourse;
