import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import LiveClassMediaTopicMappingForm from './LiveClassMediaTopicMappingForm';
import { format } from 'date-fns';

function LiveClassMediaTopicMapping() {
    const fetchUtils = useAxiosGet(endpoints.liveClassMedia.get);

    const cellModifier = {
        CREATEDDATE: ({ value }) => {
            return value ? format(new Date(value), "dd/MM/yyyy") : '';
        },
        UPDATEDDATE: ({ value }) => {
            return value ? format(new Date(value), "dd/MM/yyyy") : '';
        },
    };

    return (
        <>
            <CRUDTable
                cellModifier={cellModifier}
                endpoints={endpoints.liveClassMedia}
                Form={LiveClassMediaTopicMappingForm}
                addBtnLabel='Map Live Class Media & Topic'
                formSize = 'lg'
                // irremovable
                {...fetchUtils}
                {...viewProps.LiveClassMediaMapping}
                name='Live Class Media & Topic Mapping'
            />
        </>
    );
}

export default LiveClassMediaTopicMapping;