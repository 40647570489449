import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import TeamParticipantMapForm from './TeamParticipantMapForm';

function TeamParticipantMap() {
    const fetchUtils = useAxiosGet(endpoints.teamParticipant.get);

    // can use cellmodifer here,
    // but if used, searching for the value in modified cell and also download table data wont work
    // so added a new property (departmentcourseMapping) to show the values in array
    let mapping = [];
    if (!fetchUtils.loading) {
        mapping = fetchUtils.response.map((data) => {
            if (Array.isArray(data.teamParticipant)) {
                data.teamparticipantMapping = data.teamParticipant
                    .map((particip) => particip.PARTICIPANT_NAME)
                    .join(', ');
            }
            return data;
        });
    }

    return (
        <>
            <CRUDTable
                endpoints={endpoints.teamParticipant}
                Form={TeamParticipantMapForm}
                addBtnLabel='Map Participant to Team'
                // irremovable
                {...fetchUtils}
                response={mapping}
                {...viewProps.TeamParticipantMap}
                name='Team-Participant Mapping'
            />
        </>
    );
}

export default TeamParticipantMap;
