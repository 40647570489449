import React from 'react';
import CRUDTable from '../../../components/table/CRUDTable';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';

function AllCourses({ fetchUtils, response, courseCellModifier }) {
    const cellModifier = {
        Course_STATUS: ({ value }) => {
            switch (value) {
                case 1:
                    return 'In Draft';
                case 2:
                    return 'Under Review';
                case 3:
                    return 'Approved';
                case 4:
                    return 'Retired';
                default:
                    break;
            }
        },
        ...courseCellModifier,
    };
     if (cellModifier.Reviews) {
         delete cellModifier.Reviews;
         delete cellModifier.courserating;
     }
    return (
        <CRUDTable
            {...fetchUtils}
            response={response}
            relevants={[
                ...viewProps.Course.relevants.filter(
                    (str) => str !== 'Reviews' && str !== 'courserating'
                ),
                'Course_STATUS',
            ]}
            columnHeads={[
                ...viewProps.Course.columnHeads.filter(
                    (str) => str !== 'Reviews' && str !== 'Rating'
                ),

                'Course Status',
            ]}
            endpoints={endpoints.course}
            name='All Courses'
            cellModifier={cellModifier}
            immutable
            insertable={false}
            formSize='md'
            irremovable
        />
    );
}

export default AllCourses;
