import React from 'react';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CRUDTable from '../../../components/table/CRUDTable';
import { useAxiosPost } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps';
import { useAuthenticationState } from '../../../context/Auth.context';
import CustomSecondaryBtn from '../../../components/CustomSecondaryBtn';
import CourseContentForm from './CourseContentForm';
import WithModal from '../../../components/WithModal';

function Course() {
    const { user } = useAuthenticationState();
    const navigate = useNavigate();

    const {
        response: courseInsMap,
        reFetch,
        ...fetchUtils
    } = useAxiosPost(endpoints.courseInstructor.selectBy, {
        selectcolumns: '*',
        wherecondition: 'instructor_ID= ' + user.id,
    });

    const cellModifier = {
        Structure: ({ row }) => (
            <CustomSecondaryBtn
                onClick={() => {
                    navigate('/course-structure', {
                        state: {
                            courseId: row.original.Course_ID,
                            versionId: row.original.Version_ID,
                            courseCategoryId: row.original.Course_Category_ID,
                        },
                    });
                }}
            >
                Structure
            </CustomSecondaryBtn>
        ),
        COURSE_DP_URL: ({ value }) => {
            if (value) {
                return (
                    <div
                        style={{
                            width: '50px',
                            height: '50px',
                            backgroundImage: 'url(' + value + ')',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                        }}
                    ></div>
                );
            }
            return <div>No image for Course</div>;
        },
    };

    const draft = courseInsMap.filter(
        (course) => Number(course.Course_STATUS) === 1
    );
    const published = courseInsMap.filter(
        (course) => Number(course.Course_STATUS) === 3
    );

    return (
        <>
            <Tabs fill style={{ margin: '0 80px' }} mountOnEnter>
                <Tab eventKey='draft' title='Draft'>
                    <CRUDTable
                        // endpoints={endpoints.course}
                        immutable
                        irremovable
                        insertable={false}
                        Form={CourseContentForm}
                        formSize='lg'
                        {...viewProps.Course}
                        name='Courses In draft'
                        {...fetchUtils}
                        response={draft}
                        cellModifier={{
                            ...cellModifier,
                            Contents: ({ row }) => (
                                <WithModal
                                    renderModalBody={(closeModal) => (
                                        <CourseContentForm
                                            updateValues={row.original}
                                            onAfterSubmit={() => {
                                                reFetch();
                                                closeModal();
                                            }}
                                            onCancel={closeModal}
                                        />
                                    )}
                                    modalTitle={'Contents in Course'}
                                >
                                    <span
                                        onClick={() => {}}
                                        className='py-1 px-1 border-2 text-info'
                                        type='button'
                                    >
                                        Contents in course
                                    </span>
                                </WithModal>
                            ),
                        }}
                    />
                </Tab>
                <Tab eventKey='published' title='Published'>
                    <CRUDTable
                        immutable
                        irremovable
                        insertable={false}
                        {...viewProps.Course}
                        name='Published Courses'
                        {...fetchUtils}
                        response={published}
                        cellModifier={{
                            ...cellModifier,
                            Contents: ({ row }) => (
                                <WithModal
                                    renderModalBody={(closeModal) => (
                                        <CourseContentForm
                                            disabled={true}
                                            updateValues={row.original}
                                            onAfterSubmit={() => {
                                                reFetch();
                                                closeModal();
                                            }}
                                            onCancel={closeModal}
                                        />
                                    )}
                                    modalTitle={'Contents in Course'}
                                >
                                    <span
                                        onClick={() => {}}
                                        className='py-1 px-1 border-2 text-info'
                                        type='button'
                                    >
                                        Contents in course
                                    </span>
                                </WithModal>
                            ),
                        }}
                    />
                </Tab>
            </Tabs>
        </>
    );
}

export default Course;
