import { ReactComponent as MediaSelectedDelete } from '../../../../asset/icons/MediaSelectedFileDelete.svg';
import { ReactComponent as MediaSelectedExcel } from '../../../../asset/icons/MediaSelectedExcel.svg';
import { ReactComponent as MediaSelected } from '../../../../asset/icons/MediaFileSelectSuccess.svg';

function SelectedMedia({ title, selectedTitle, onDelete }) {
    return (
        <>
            <div
                style={{
                    background: '#D1FADF45',
                    border: '1px solid #107BFF',
                    borderRadius: '12px',
                    padding: '12px 18px',
                }}
                className='d-flex align-items-center flex-column mt-3'
            >
                <MediaSelected />
                <div
                    style={{
                        color: '#107BFF',
                        fontWeight: '600',
                        textTransform: 'none',
                    }}
                    className='mt-2'
                >
                    {title}
                </div>
            </div>

            <div
                style={{
                    border: '1px solid #EAECF0',
                    padding: '16px',
                    fontWeight: '500',
                }}
                className='d-flex align-items-center rounded-3 mt-3'
            >
                <span className='pe-3'>
                    <MediaSelectedExcel />
                </span>
                <span>{selectedTitle}</span>
                <span role='button' onClick={onDelete} className='ms-auto'>
                    <MediaSelectedDelete />
                </span>
            </div>
        </>
    );
}

export default SelectedMedia;
