import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import '../../../App.css';
import { ReactComponent as Right } from '../../../asset/icons/RightArrow.svg';
import { ReactComponent as Left } from '../../../asset/icons/LeftArrow.svg';

function QuizQuestionList({
    tags,
    handleQuestionSelection,
    questions,
    selectedQuestions,
    handleSelectAll,
}) {
    const itemsPerPage = 10;

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = questions.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(questions.length / itemsPerPage);
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % questions.length;

        setItemOffset(newOffset);
    };
    useEffect(() => {
        setItemOffset(0);
    }, [questions]);

    return (
        <div
            className='px-3 pt-3 pb-4'
            style={{
                borderTop: '1px solid rgba(174, 174, 174, 0.64)',
            }}
        >
            <div
                className='d-flex'
                style={{
                    fontSize: '14px',
                    color: '#334155',
                }}
            >
                <span
                    style={{
                        fontWeight: '600',
                    }}
                >
                    Questions related to selected tags
                </span>
                {currentItems.length > 0 && (
                    <span
                        style={{
                            textDecoration: 'underline',
                            marginLeft: 'auto',
                            cursor: 'pointer',
                        }}
                        className='ms-auto'
                        onClick={handleSelectAll}
                    >
                        Select all
                    </span>
                )}
            </div>

            <div className='pt-2 mt-3'>
                {currentItems.map((item, id) => {
                    const questId = item.QUESTION_BANK_ID;
                    const isChecked = selectedQuestions?.find((question) => {
                        return questId === question?.QUESTION_BANK_ID;
                    });

                    return (
                        <li
                            className='pb-2 '
                            style={{
                                listStyle: 'none',
                                display: 'flex',
                                alignItems: 'baseline',
                            }}
                        >
                            <input
                                type='checkbox'
                                key={id}
                                checked={isChecked}
                                className='m-1'
                                onChange={() => handleQuestionSelection(item)}
                                value={item.QUESTION_BANK_ID}
                            />
                            <span style={{ paddingLeft: '5px' }}>
                                {item.QUESTION_TEXT}
                            </span>
                        </li>
                    );
                })}
            </div>

            <div
                style={{ display: 'flex', justifyContent: 'center' }}
                className='mt-2'
            >
                <ReactPaginate
                    breakLabel='...'
                    nextLabel={
                        <span>
                            Next &nbsp;&nbsp;
                            <Right />
                        </span>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel={
                        <span>
                            <Left /> Prev
                        </span>
                    }
                    renderOnZeroPageCount={null}
                    containerClassName='paginationContainerQuestion'
                    pageLinkClassName='page-numQuestion'
                    previousLinkClassName='pagePrevQuestion'
                    nextLinkClassName='pagenextQuestion'
                    activeClassName='activeNumber'
                    activeLinkClassName='activeLink'
                    pageClassName='pageNumber'
                />
            </div>
        </div>
    );
}

export default QuizQuestionList;
