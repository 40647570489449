import React from 'react';
import fallback from '../../../asset/icons/UserAvatar.svg';

function TopParticipants({ participants }) {
    return (
        <div className='bg-white rounded-smoother p-3'>
            <div className='text-muted mb-3'>Top Participants</div>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                {participants.map((participant) => {
                    const image = participant.DP || fallback;

                    return (
                        <div className='d-flex mb-2'>
                            <div
                                className='rounded-smooth'
                                style={{
                                    backgroundImage: 'url(' + image + ')',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    width: '64px',
                                    height: '64px',
                                }}
                            />
                            {/* <img src={participant.DP} /> */}
                            <div className='d-flex flex-column ms-3'>
                                <span className='fw-bold'>
                                    {participant.PARTICIPANT_NAME}
                                </span>
                                <small>{participant.Dept_Name}</small>
                                <small>
                                    {participant.Total_Coursecompleted || 0} <small className='text-info'>Courses Completed</small>
                                </small>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default TopParticipants;
