import React, { useState } from 'react';
import { Badge, Col, FormSelect, Image, Row } from 'react-bootstrap';
import avatar from '../../../asset/icons/UserAvatar.svg';

function LCMissedParticipants({ data }) {
    const missedList = data?.Studentdetails ?? [];
    const [filters, setFilters] = useState({
        dept: '',
        course: '',
    });
    const depts = missedList.map((detail) => {
        return {
            deptId: detail.PARTICIPANT_DEPT_ID,
            deptName: detail.Dept_Name,
        };
    });
    console.log(missedList);
    const deptJson = depts.map(JSON.stringify);
    let uniqueDept = new Set(deptJson);
    let uniqueDeptsArr = Array.from(uniqueDept).map(JSON.parse);

    const courses = missedList.map((detail) => {
        return { courseId: detail.Course_ID, courseName: detail.Course_NAME };
    });

    const coursesJson = courses.map(JSON.stringify);
    let uniqueCourse = new Set(coursesJson);
    let uniqueCoursesArr = Array.from(uniqueCourse).map(JSON.parse);

    const filteredData = missedList?.filter((detail) => {
        
        if (filters.dept && filters.course) {
            return (
                Number(detail.PARTICIPANT_DEPT_ID) === Number(filters.dept) &&
                Number(detail.Course_ID) === Number(filters.course)
            );
        }
        if(filters.dept){
            return Number(detail.PARTICIPANT_DEPT_ID) === Number(filters.dept);
        }
        if(filters.course){
            return Number(detail.Course_ID) === Number(filters.course);
        }
        return detail
    });

    return (
        <div className='bg-white rounded-smoother p-3 h-100'>
            <Row className='pb-3'>
                <Col sm={9}>
                    <span className='fw-bold'>
                        Participants who missed live class
                    </span>
                </Col>
                <Col sm={3} className='text-center'>
                    <Badge className='px-3 py-2 rounded-pill fs-6'>{missedList.length}</Badge>
                </Col>
                <Col sm={6} className='pe-1 mt-3'>
                    <FormSelect
                        className=' rounded-pill py-2 ps-3'
                        onChange={(e) => {
                            setFilters({ ...filters, dept: e.target.value });
                        }}
                    >
                        <option value = {''}>Select Department</option>
                        {uniqueDeptsArr.map((dept) => (
                            <option value={dept.deptId}>{dept.deptName}</option>
                        ))}
                    </FormSelect>
                </Col>
                <Col sm={6} className='ps-1 mt-3'>
                    <FormSelect
                        className=' rounded-pill py-2 ps-3'
                        onChange={(e) => {
                            setFilters({ ...filters, course: e.target.value });
                        }}
                        size = 'sm'
                    >
                        <option value = {''}>Select Course</option>
                        {uniqueCoursesArr.map((course) => (
                            <option value={course.courseId}>
                                {course.courseName}
                            </option>
                        ))}
                    </FormSelect>
                </Col>
            </Row>
            <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                {filteredData.map((partDet) => (
                    <Row className='mt-3 mx-0'>
                        <Col sm={3} className='text-end  px-0'>
                            <div
                                className='rounded-smooth'
                                style={{
                                    backgroundImage:
                                        'url(' + (partDet?.DP || avatar) + ')',
                                    height: '5.5vw',
                                    width: '5.5vw',
                                    backgroundSize: 'cover',
                                    backgroundPositionX: 'center',
                                    backgroundRepeat: 'no-repeat',
                                }}
                            />
                        </Col>
                        <Col sm='9' className=' px-0'>
                            <div className='fw-bold'>
                                {partDet.PARTICIPANT_NAME}
                            </div>
                            <div className='small text-muted'>
                                {partDet.Dept_Name}
                            </div>
                            <Row className='m-0 small text-muted'>
                                <Col sm={5} className='p-0'>
                                    <b>Topic:</b> {partDet.LIVE_CLASS_TITLE}
                                </Col>
                                <Col sm={1} className='p-0 text-center'>
                                    |
                                </Col>
                                <Col sm={5} className='p-0'>
                                    <b>Course:</b> {partDet.Course_NAME}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
}

export default LCMissedParticipants;
