import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import withAlert from '../../../hoc/withAlert';
import toast from 'react-hot-toast';

function LocationCourseCategoryMapForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response, loading } = useAxiosGetMultiple({
        locat: endpoints.location.get,
        courseCategories: endpoints.courseCategory.get,
    });

    const { locat, courseCategories } = response;

    const submitHandler = (values, { setSubmitting }) => {
        setSubmitting(true);

        console.log(values);
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        categoryLocation: [],
        Location_ID: '',
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOptions = {
        loc: locat.find(
            (loc) =>
                Number(loc.Location_ID) ===
                Number(initialFormValues.Location_ID)
        ),
        // courseCat: courseCategories.find(
        //     (course) =>
        //         Number(course.Course_Category_ID) ===
        //         Number(initialFormValues.departmentcourse)
        // ),
        courseCat: courseCategories.filter(
            (course) =>
                !!initialFormValues.categoryLocation.find(
                    (courseMap) =>
                        Number(courseMap.Course_Category_ID) ===
                        Number(course.Course_Category_ID)
                )
        ),
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Location_ID: Yup.string().required(),
                categoryLocation: Yup.array()
                    .of(
                        Yup.object({
                            Course_Category_ID: Yup.string(),
                        })
                    )
                    .min(1, 'Select min 1 category')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values }) => (
                <Form>
                    {!updateValues && (
                        <FormikControl
                            control='select-advanced'
                            required
                            name='Location_ID'
                            label='Location'
                            options={locat}
                            optionValue='Location_ID'
                            defaultValue={preSelectedOptions.loc}
                            optionLabel='Location_NAME'
                        />
                    )}
                    <FormikControl
                        control='select-advanced'
                        required
                        name='categoryLocation'
                        label='Course Category'
                        options={courseCategories}
                        defaultValue={preSelectedOptions.courseCat}
                        isMulti
                        keyProp='Course_Category_ID'
                        optionValue='Course_Category_ID'
                        optionLabel='Course_Category_NAME'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {(isSubmitting || loading) && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(LocationCourseCategoryMapForm);
