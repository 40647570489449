import { Col, Row } from 'react-bootstrap';
import Loader from '../../../components/Loader';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import ActivityMonitor from './ActivityMonitor';
import Counts from './Counts';
import CourseGrowth from './CourseGrowth';
import InstructorSchedule from './InstructorSchedule';
import TopCourses from './TopCourses';
import TopParticipants from './TopParticipants';
import Tutors from './Tutors';
import LCMissedParticipants from './LCMissedParticipants';

function Overview() {
    const { response, loading } = useAxiosGetMultiple({
        dash1: endpoints.admin_overview.dashboard1,
        dash2: endpoints.admin_overview.dashboard2,
        popularCourses: endpoints.admin_overview.popularCourses,
        topInstructors: endpoints.admin_overview.topInstructors,
        activeCourses: endpoints.admin_overview.activeCourses,
        lcMissedList: endpoints.admin_overview.lcMissedList,
        // newlyJoined: endpoints.admin_overview.newlyJoined,
        // liveClassSchedules: endpoints.admin_overview.liveClassSchedules,
    });

    const {
        popularCourses,
        topInstructors,
        activeCourses,
        // newlyJoined,
        // liveClassSchedules,
        dash1,
        dash2,
        lcMissedList,
    } = response;

    const {
        Instructorslist,
        Total_Userscount,
        Total_InstuctorsCount,
        course_Participantcount,
        courseratings,
        participantcoursedetails,
        topParticipants,
    } = dash1;

    const {
        NO_OF_NEW_COURSES,
        No_OF_NEW_PARTICIPANTS,
        Liveclass_Details,
        Instructor_List,
    } = dash2;

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <div className='mb-3 fs-5'>Overview</div>
            <Row className='m-0'>
                <Col md={4} className='pb-4 pe-4 ps-0'>
                    <Counts
                        instructorCount={
                            Total_InstuctorsCount[0].Total_Instructors
                        }
                        userCount={Total_Userscount[0].Total_Users}
                        newCourseCount={NO_OF_NEW_COURSES.New_Courses_Count}
                        newParticipantCount={
                            No_OF_NEW_PARTICIPANTS.New_Participants_count
                        }
                    />
                </Col>
                <Col md={8} className='pb-4 px-0'>
                    <CourseGrowth counts={course_Participantcount} />
                </Col>
                <Col md={4} className='pb-4 pe-4 ps-0'>
                    <ActivityMonitor activeCourses={activeCourses} />
                </Col>
                <Col md={8} className='pb-4 px-0'>
                    <Tutors instructors={topInstructors} />
                </Col>
                <Col md={7} className='pb-4 pe-4 ps-0'>
                    <TopCourses courseRatings={courseratings} />
                </Col>

                <Col md={5} className='pb-4 px-0'>
                    <LCMissedParticipants data={lcMissedList} />
                </Col>
                <Col md={4} className='pb-4 pe-4 px-0'>
                    <TopParticipants participants={topParticipants} />
                </Col>
                <Col md={8} className='pb-4 px-0'>
                    <InstructorSchedule
                        schedules={Liveclass_Details}
                        instructors={Instructor_List}
                    />
                </Col>
            </Row>
        </>
    );
}

export default Overview;
