import moment from 'moment';
import React from 'react';
import { ReactComponent as Calender } from '../../../asset/icons/Date.svg';

function QuizImage({ image, handleClick, isSelected }) {
    const updatedDate = new Date(image.APPROVEDDATE).toLocaleDateString();

    return (
        <div
            onClick={() => {
                handleClick(image);
            }}
            style={{
                cursor: 'pointer',
                marginTop: '15px',
            }}
            className={isSelected ? 'shadow' : ''}
        >
            {/* <div
                style={{
                    width: '162px',
                    height: '172px',
                    // border: '2px solid black',
                }}
            > */}
            <img src={image?.MEDIA_URL} style={{ width: '100%' }} />
            {/* </div> */}

            <div className='p-2'>
                {/* <div> */}
                <div style={{ fontSize: '14px' }}>{image?.MEDIA_DESC}</div>
                <div style={{ fontSize: '12px' }}>{image?.Instructor_Name}</div>
                <div
                    style={{
                        color: '#80878F',
                        fontSize: '13px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Calender />

                    <span
                        style={{
                            paddingLeft: '3px',
                        }}
                    >
                        Approved: {updatedDate}
                    </span>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
}

export default QuizImage;
