import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
import endpoints from '../../../services/endpoints';
import withAlert from '../../../hoc/withAlert';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import toast from 'react-hot-toast';

function TeamsForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response, loading } = useAxiosGetMultiple({
        participants: endpoints.participant.get,
    });
    const { participants } = response;

    const initialFormValues = updateValues || {
        TEAMTAG: '',
        TEAM_NAME: '',
        TEAM_LEADER_NAME: '',
        TEAM_LEADER_EMP_ID: '',
        TEAM_LEADER_EMAILID: '',
        MANAGING_DEPT_ID: '',
    };

    const submitHandler = (values, { setSubmitting }) => {
        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .finally(() => setSubmitting(false));
    };

    if (loading) {
        return <Loader />;
    }

    const preSelectedOptions = {
        particip:
            participants.find(
                (particip) =>
                    Number(particip.PARTICIPANT_ID) ===
                    Number(initialFormValues.TEAM_LEADER_EMP_ID)
            ) || '',
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                TEAMTAG: Yup.string()
                    .trim()
                    .required('Team Tag is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces between letters are allowed'
                    )
                    .notOneOf([''], 'Team Tag cannot be empty')
                    .required(),
                TEAM_NAME: Yup.string()
                    .trim()
                    .required('Team Name is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces between letters are allowed'
                    )
                    .notOneOf([''], 'Team Name cannot be empty')
                    .required(),
                TEAM_LEADER_NAME: Yup.string().required(),
                TEAM_LEADER_EMP_ID: Yup.string().required(),
                TEAM_LEADER_EMAILID: Yup.string().required(),
                MANAGING_DEPT_ID: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, setFieldValue }) => (
                <Form>
                    <FormikControl
                        required
                        name='TEAM_NAME'
                        label='Team Name'
                    />
                    <FormikControl required name='TEAMTAG' label='Team Tag' />
                    <FormikControl
                        control='select-advanced'
                        required
                        name='TEAM_LEADER_EMP_ID'
                        label='Team Leader Name'
                        options={participants}
                        defaultValue={preSelectedOptions.particip}
                        optionValue='PARTICIPANT_ID'
                        optionLabel='PARTICIPANT_NAME'
                        onChange={(obj) => {
                            setFieldValue(
                                'TEAM_LEADER_EMP_ID',
                                obj.PARTICIPANT_ID
                            );
                            setFieldValue(
                                'TEAM_LEADER_NAME',
                                obj.PARTICIPANT_NAME
                            );
                            setFieldValue(
                                'TEAM_LEADER_EMAILID',
                                obj.PARTICIPANT_EMAIL
                            );
                            setFieldValue(
                                'MANAGING_DEPT_ID',
                                obj.PARTICIPANT_DEPT_ID
                            );
                        }}
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(TeamsForm);
