import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../../components/formikControl/FormikControl';
import FormSubmissionBtn from '../../../components/FormSubmissionBtn';
import axios from 'axios';
import Loader from '../../../components/Loader';
//import { useAxiosGet } from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import { Col, Row } from 'react-bootstrap';
import withAlert from '../../../hoc/withAlert';
import extractErrorFromRes from '../../../helpers/extractErrorFromRes';
import { useAxiosGetMultiple } from '../../../services/axiosHooks';
import toast from 'react-hot-toast';
import PhoneInputWithCountrySelect, {
    isPossiblePhoneNumber,
} from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';

function InstructorForm({
    endpoint,
    updateValues,
    onCancel,
    onAfterSubmit,
    showAlert,
}) {
    const { response, loading } = useAxiosGetMultiple({
        location: endpoints.location.get,
        departments: endpoints.department.get,
        designation: endpoints.designation.get,
    });

    const { location, departments, designation } = response;

    const initialFormValues = updateValues || {
        PARTICIPANT_EMP_NO: '',
        PARTICIPANT_DEPT_ID: '',
        PARTICIPANT_NAME: '',
        PARTICIPANT_DESIGNATION_ID: '',
        PARTICIPANT_LOCATION_ID: '',
        HR_SPOC_EMP_NO: '',
        HR_SPOC_NAME: '',
        HR_SPOC_EMAILID: '',
        HR_SPOC_DEPT_ID: '',
        MANAGER_NAME: '',
        PARTICIPANT_EMAIL: '',
        PARTICIPANT_PHONE: '',
        PARTICIPANT_ADDRESS: '',
        MANAGER_EMAILID: '',
        MANAGER_PHONE: '',
        DATEOFJOINING: '',
        Is_Active: 2,
    };

    if (updateValues) {
        initialFormValues.DATEOFJOINING = new Date(updateValues.DATEOFJOINING)
            .toISOString()
            .slice(0, 10);
    }

    const submitHandler = (values, { setSubmitting }) => {
        setSubmitting(true);

        axios
            .post(endpoint, values)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                toast.error(extractErrorFromRes(err));
            })
            .then((res) => setSubmitting(false));
    };
    if (loading) {
        return <Loader />;
    }

    const preSelectedOptions = {
        loc:
            location.find(
                (loc) =>
                    Number(loc.Location_ID) ===
                    Number(initialFormValues?.PARTICIPANT_LOCATION_ID)
            ) ?? '',
        dept:
            departments.find(
                (dept) =>
                    Number(dept.DEPT_ID) ===
                    Number(initialFormValues?.PARTICIPANT_DEPT_ID)
            ) ?? '',
        desig:
            designation.find(
                (desig) =>
                    Number(desig.DESIGNATION_ID) ===
                    Number(initialFormValues?.PARTICIPANT_DESIGNATION_ID)
            ) ?? '',
    };
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const phoneRegExp =
        /^(?!.-.-.-)(?=(?:\d{8,10}$)|(?:(?=.{9,11}$)[^-]-[^-]$)|(?:(?=.{10,12}$)[^-]-[^-]-[^-]$)  )[\d-]+$/;
    const emailRegExp = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/;
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                PARTICIPANT_EMP_NO: Yup.number()
                    .typeError("That doesn't look like a number")
                    .positive("Can't start with a minus")
                    .integer("Can't include a decimal point")
                    .required(),
                PARTICIPANT_DEPT_ID: Yup.string().required(),
                PARTICIPANT_NAME: Yup.string().required(),
                PARTICIPANT_DESIGNATION_ID: Yup.string().required(),
                PARTICIPANT_LOCATION_ID: Yup.string().required(),
                HR_SPOC_NAME: Yup.string()
                    .trim()
                    .required('HR SPOC Name is required')
                    .matches(
                        /^[A-Za-z\s]+$/,
                        'Only letters and spaces are allowed'
                    )
                    .notOneOf([''], 'Location Name cannot be empty')
                    .required(),
                HR_SPOC_EMP_NO: Yup.number()
                    .typeError("That doesn't look like a number")
                    .positive("Can't start with a minus")
                    .integer("Can't include a decimal point")
                    .required('A Number is required'),
                HR_SPOC_EMAILID: Yup.string()
                    .email()
                    .matches(emailRegExp, 'Provide valid email')
                    .required(),
                PARTICIPANT_EMAIL: Yup.string()
                    .email()
                    .matches(emailRegExp, 'Provide valid email')
                    .required(),
                HR_SPOC_DEPT_ID: Yup.string().required(),
                PARTICIPANT_PHONE: Yup.string()
                    .required()
                    .test(
                        'PARTICIPANT_PHONE',
                        'Enter a valid phone number',
                        (value) => {
                            if (value) {
                                return isPossiblePhoneNumber(value);
                            }
                            return false;
                        }
                    ),
                PARTICIPANT_ADDRESS: Yup.string().required(),
                MANAGER_EMP_NO: Yup.number()
                    .typeError("That doesn't look like a number")
                    .positive("Can't start with a minus")
                    .integer("Can't include a decimal point")
                    .required('A Number is required'),
                MANAGER_NAME: Yup.string().required(),
                MANAGER_DEPT_ID: Yup.string().required(),
                Is_Active: Yup.string().required(),
                MANAGER_PHONE: Yup.string()
                    .required()
                    .test(
                        'MANAGER_PHONE',
                        'Enter a valid phone number',
                        (value) => {
                            if (value) {
                                return isPossiblePhoneNumber(value);
                            }
                            return false;
                        }
                    ),
                MANAGER_EMAILID: Yup.string()
                    .email()
                    .matches(emailRegExp, 'Provide valid email')
                    .required(),
                DATEOFJOINING: Yup.date()
                    .max(today, 'Upcoming dates not permitted')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Row>
                        {!updateValues && (
                            <Col sm='6'>
                                <FormikControl
                                    required
                                    name='PARTICIPANT_EMP_NO'
                                    label='Participant Emp No'
                                />
                            </Col>
                        )}
                        <Col sm='6'>
                            <FormikControl
                                required
                                name='PARTICIPANT_NAME'
                                label='Participant Name'
                            />
                        </Col>

                        <Col sm='6'>
                            <FormikControl
                                control='select-advanced'
                                required
                                name='PARTICIPANT_DEPT_ID'
                                label='Department'
                                options={departments}
                                defaultValue={preSelectedOptions.dept}
                                optionValue='DEPT_ID'
                                optionLabel='DEPT_NAME'
                            />
                        </Col>

                        <Col sm='6'>
                            <FormikControl
                                control='select-advanced'
                                required
                                name='PARTICIPANT_DESIGNATION_ID'
                                label='Designation'
                                options={designation}
                                defaultValue={preSelectedOptions.desig}
                                optionValue='DESIGNATION_ID'
                                optionLabel='DESIGNATION_NAME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select-advanced'
                                required
                                name='PARTICIPANT_LOCATION_ID'
                                label='location'
                                options={location}
                                defaultValue={preSelectedOptions.loc}
                                optionValue='Location_ID'
                                optionLabel='Location_NAME'
                            />
                        </Col>

                        <Col sm='6'>
                            <FormikControl
                                type='email'
                                required
                                name='PARTICIPANT_EMAIL'
                                label='Participant Email'
                            />
                        </Col>
                        <Row className='m-0 p-0'>
                            <Col sm='6'>
                                <FormikControl
                                    control='phone-input'
                                    required
                                    name='PARTICIPANT_PHONE'
                                    label='Participant Phone'
                                />
                            </Col>
                            <Col sm='6'>
                                <FormikControl
                                    control='textarea'
                                    placeholder='Address'
                                    required
                                    name='PARTICIPANT_ADDRESS'
                                    label='Address'
                                />
                            </Col>
                        </Row>
                        <Col sm='6'>
                            <FormikControl
                                required
                                name='HR_SPOC_NAME'
                                label='HR SPOC Name'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                required
                                name='HR_SPOC_EMP_NO'
                                label='HR SPOC Emp No'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                type='email'
                                required
                                name='HR_SPOC_EMAILID'
                                label='HR SPOC Email Id'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select-advanced'
                                required
                                name='HR_SPOC_DEPT_ID'
                                label='HR SPOC department'
                                options={departments}
                                defaultValue={preSelectedOptions.dept}
                                optionValue='DEPT_ID'
                                optionLabel='DEPT_NAME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                required
                                name='MANAGER_EMP_NO'
                                label='Manager Emp No'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                required
                                name='MANAGER_NAME'
                                label='Manager Name'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='phone-input'
                                required
                                label='Manager Phone'
                                name='MANAGER_PHONE'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                type='email'
                                required
                                label='Manager Email'
                                name='MANAGER_EMAILID'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select-advanced'
                                required
                                name='MANAGER_DEPT_ID'
                                label='Manager Department'
                                options={departments}
                                defaultValue={preSelectedOptions.dept}
                                optionValue='DEPT_ID'
                                optionLabel='DEPT_NAME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                type='date'
                                required
                                label='Date of Joining'
                                name='DATEOFJOINING'
                            />
                        </Col>
                        {updateValues && (
                            <Col sm='6'>
                                <FormikControl
                                    control='check'
                                    type='radio'
                                    required
                                    label='Is Active ?'
                                    name='Is_Active'
                                    options={[
                                        { key: 'Active', value: '1' },
                                        { key: 'Inactive', value: '2' },
                                    ]}
                                />
                            </Col>
                        )}
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(InstructorForm);
