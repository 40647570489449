import React, { useEffect } from 'react';
import { ReactComponent as Logo } from '../../../asset/icons/userGuide/ShLogo_UserGuide.svg';
import { ReactComponent as ArrowLeft } from '../../../asset/icons/userGuide/ArrowLeft.svg';
import Sidebar from './components/Sidebar';
import SearchBar from './components/SearchBar';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

const HelpLayout = () => {
    const navigate = useNavigate();
    const mainContent = React.useRef(null);
    const location = useLocation();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if (mainContent.current) {
            mainContent.current.scrollTop = 0;
        }
    }, [location]);

    return (
        <>
            <div
                style={{
                    top: '0',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '3%',
                    paddingTop: '25px',
                    paddingBottom: '25px',
                    backgroundColor: '#F7CB87',
                    zIndex: '1000',
                    position: 'fixed',
                }}
            >
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 400,
                        fontSize: '35px',
                    }}
                    onClick={() => navigate('/')}
                >
                    <span style={{ paddingLeft: '30px' }}></span>
                    <ArrowLeft />
                    <span style={{ paddingLeft: '10px' }}></span>
                </div>
                <Logo />
                <div
                    style={{
                        paddingLeft: '5px',
                        fontWeight: '400',
                        fontSize: '35px',
                        marginLeft: '6px',
                    }}
                >
                    Admin Guide
                </div>
            </div>
            <Sidebar />
            <SearchBar />
            <div style={{ marginTop: '13%' }}>
                <div
                    style={{
                        marginLeft: '20%',
                        paddingBottom: '6',
                        color: 'black',
                    }}
                    ref={mainContent}
                >
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default HelpLayout;
