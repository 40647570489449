import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomSecondaryBtn from '../../../components/CustomSecondaryBtn';
import CRUDTable from '../../../components/table/CRUDTable';
import {useAxiosPost} from '../../../services/axiosHooks';
import endpoints from '../../../services/endpoints';
import viewProps from '../viewProps'

function CourseStructure() {
    
    const fetchUtils = useAxiosPost(endpoints.course.selectBy, {
        selectcolumns: '*',
        wherecondition: '(1=1)',
    });

    const navigate = useNavigate()

    const cellModifier = {
        Structure: ({ row }) => (
            <CustomSecondaryBtn
                onClick={() => {
                    navigate('/course-structure-approve', {
                        state: {
                            courseId: row.original.Course_ID,
                            versionId: row.original.Version_ID,
                            courseCategoryId: row.original.Course_Category_ID,
                        },
                    });
                }}
            >
                Structure
            </CustomSecondaryBtn>
        ),
    };
    return (
        <>
            <CRUDTable
                endpoints={endpoints.course}
                immutable
                irremovable
                insertable={false}
                {...viewProps.CourseStructure}
                {...fetchUtils}
                cellModifier={cellModifier}
            />
        </>
    );
}

export default CourseStructure;
