import React from 'react';
import { Col, Row } from 'react-bootstrap';

const colors = ['#E9E7FD', '#FEE4CB', '#DBF6FD'];

function OngoingCourses({ courses }) {
    return (
        <div className='mt-5'>
            <div style={{ fontWeight: '900' }} className='small mb-4'>
                Ongoing Courses
            </div>
            <Row
                style={{
                    maxWidth: '100%',
                    flexWrap: 'nowrap',
                    overflowX: 'auto',
                }}
            >
                {courses?.length > 0 ? (
                    courses?.map((course, i) => (
                        <Col md='4' className='mb-3' key={course.Course_ID}>
                            <CourseProgress
                                bg={colors[i % 3]}
                                progressBg='#FF942E'
                                name={course.Course_NAME}
                                date={new Date(
                                    course.Last_Course_Schedule_Date
                                ).toDateString()}
                                category={course.Course_Category_NAME}
                            />
                        </Col>
                    ))
                ) : (
                    <div className='text-center fs-4 my-5'>
                        No Ongoing courses
                    </div>
                )}
            </Row>
        </div>
    );
}

export default OngoingCourses;

const CourseProgress = ({
    bg,
    category,
    name,
    date,
}) => {
    return (
        <div
            className='border border-2 border-dark rounded-smoother p-3 small h-100'
            style={{ backgroundColor: bg }}
        >
            <div className='text-muted small'>{date}</div>
            <div
                className='fw-bold mt-3 text-center fs-5'
                style={{
                    lineHeight: '30px',
                    height: '90px',
                    overflow: 'hidden',
                }}
            >
                {name}
            </div>
            <div className='small text-muted text-center mt-2'>{category}</div>
            {/* <div className='d-flex flex-column mb-2 mt-3 small fw-bolder'>
                <span>Progress</span>
                <div
                    className='position-relative rounded-smooth mt-1 w-100 overflow-hidden bg-white'
                    style={{
                        height: '4px',
                    }}
                >
                    <div
                        className='position-absolute rounded-smooth'
                        style={{
                            width: (completedTaskNo / totalTaskNo) * 100 + '%',
                            backgroundColor: progressBg,
                            height: '4px',
                        }}
                    />
                </div>
                <span className='ms-auto'>
                    {completedTaskNo}/{totalTaskNo}
                </span>
            </div> */}
        </div>
    );
};
