import React from 'react';
import { FormControl, FormLabel, FormText } from 'react-bootstrap';

function ContentsForm({ values }) {
    return (
        <form>
            <FormLabel>Contents in Course</FormLabel>
            <FormControl
                as='textarea'
                readOnly
                value={values.Course_Content_CONTENT}
                style={{ minHeight: '150px' }}
            />
            <FormLabel className='mt-2'>Special Notes</FormLabel>
            <FormControl readOnly value={values.Special_Notes} />
            <FormLabel className='mt-2'>Special Exception</FormLabel>
            <FormControl readOnly value={values.special_exception} />
        </form>
    );
}

export default ContentsForm;
