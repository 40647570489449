// import AssignMedia from "../views/faculty/mediaAssignment/AssignMedia";
// import CourseContent from '../views/faculty/courseContent/CourseContent';
import Course from '../views/faculty/course/Course';
import CourseStructure from '../views/faculty/courseStructure/Structure';
import LiveClass from '../views/faculty/liveClass/LiveClass';
import LiveClassTopic from '../views/faculty/liveClassTopic/LiveClassTopic';
import MediaLibrary from '../views/faculty/mediaLibrary/MediaLibrary';
import QuestionBank from '../views/faculty/questionBank/QuestionBank';
// import QuizAssignment from "../views/faculty/quizAssignment/QuizAssignment";
// import QuizLibrary from "../views/faculty/quizLibrary/QuizLibrary";
// import QuizEvaluation from '../views/faculty/quizEvaluation/QuizEvaluation';
import MediaRatings from '../views/faculty/mediaAssignment/Ratings';
import MediaReviews from '../views/faculty/mediaAssignment/Reviews';
import Dashboard from '../views/faculty/dashboard/Index';
import CourseReviews from '../views/faculty/course_reviews/Index';
import CourseStructureView from '../views/faculty/courseStructureView/CourseStructureView';
// import Participant from "../views/faculty/participant/Participant";
import LiveClassVideoRecordings from '../views/faculty/liveClass/LiveClassVideoRecordings';
import InstructorUserGuide from '../views/faculty/helpInstructor/InstIndex';
import LiveClassMediaTopicMapping from '../views/faculty/liveClassMediaMapping/LiveClassMediaTopicMapping';

// Order of menu links is based on order of routes
const facultyRoutes = Object.freeze([
    {
        path: 'overview',
        page: Dashboard,
        name: 'Dashboard',
    },
    {
        path: 'live-class-topic',
        page: LiveClassTopic,
        name: 'Live Class Topic',
        module: 'live-class',
    },
    {
        path: 'live-class',
        page: LiveClass,
        name: 'Live Class Schedules',
        module: 'live-class',
    },
    {
        path: 'live-class-video-recordings',
        page: LiveClassVideoRecordings,
        name: 'Video Recordings',
        module: 'live-class',
    },
    {
        path: 'live-class-media-topic-mapping',
        page: LiveClassMediaTopicMapping,
        name: 'Live Class Media & Topic Mapping',
        module: 'live-class',
    },
    {
        path: 'question-bank',
        page: QuestionBank,
        name: 'Question Bank',
        module: 'quiz',
    },
    // {
    //     path: 'quiz-library',
    //     page: QuizLibrary,
    //     name: 'Quiz Library',
    //     module: 'quiz',
    // },
    {
        path: 'media-library',
        page: MediaLibrary,
        name: 'Media Library',
    },
    {
        path: 'course',
        page: Course,
        name: 'Course',
        module: 'course',
    },
    // {
    //     path: 'course-content',
    //     page: CourseContent,
    //     name: 'Course Content',
    //     hiddenFromMenu: true,
    // },
    {
        path: 'course-structure-view',
        page: CourseStructureView,
        name: 'Course Structures',
        module: 'course',
    },
    {
        path: 'course-structure',
        page: CourseStructure,
        name: 'Course Structure',
        hiddenFromMenu: true,
        module: 'course',
    },
    // {
    //     path: 'quiz-assignment',
    //     page: QuizAssignment,
    //     name: 'Quiz Assignment',
    //     module: 'course',
    // },
    // {
    //     path: 'quiz-results',
    //     page: QuizEvaluation,
    //     module: 'course',
    //     name: 'Quiz Results',
    //     // hiddenFromMenu: true,
    // },
    // {
    //     path: 'assign-media',
    //     page: AssignMedia,
    //     name: 'Assign Media',
    //     module: 'course',
    // },
    {
        path: 'media-ratings',
        page: MediaRatings,
        hiddenFromMenu: true,
    },
    {
        path: 'media-reviews',
        page: MediaReviews,
        hiddenFromMenu: true,
    },
    {
        path: 'Coures_Review',
        page: CourseReviews,
        // name: 'Course',
        hiddenFromMenu: true,
    },
    // {
    //     path: 'helpInstructor',
    //     page: InstructorUserGuide,
    //     name: 'User Guide',
    // },
    // {
    //     path: 'participants',
    //     page: Participant,
    //     name: 'Participants',
    // },
]);

export default facultyRoutes;
