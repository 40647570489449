import Course from '../views/admin/course/Course';
import CourseCategory from '../views/admin/course_category/CourseCategory';
import Department from '../views/admin/department/Department';
import DeptCourseCategoryMap from '../views/admin/dept-course_category/DeptCourseCategoryMap';
import InstructorCourseMap from '../views/admin/instructor-course/InstructorCourseMap';
import Instructor from '../views/admin/instructor/Instructor';
import Participant from '../views/admin/participant/Participant';
import MediaLibrary from '../views/admin/mediaLibrary/MediaLibrary';
import Overview from '../views/admin/overview/Overview';
import CourseStructureapproval from '../views/faculty/courseStructure/Structure';
import Program from '../views/admin/program/Program';
import ProgramCourseMap from '../views/admin/program-course/ProgramCourseMap';
// import CourseContent from '../views/admin/course_content/CourseContent';
import CourseStructure from '../views/admin/course_structure/CourseStructure';
import LiveClassTopic from '../views/faculty/liveClassTopic/LiveClassTopic';
import LiveClass from '../views/faculty/liveClass/LiveClass';
import QuestionBank from '../views/faculty/questionBank/QuestionBank';
// import QuizLibrary from '../views/faculty/quizLibrary/QuizLibrary';
// import QuizAssignment from '../views/faculty/quizAssignment/QuizAssignment';
// import QuizEvaluation from '../views/faculty/quizEvaluation/QuizEvaluation';
// import AssignMedia from '../views/faculty/mediaAssignment/AssignMedia';
import MediaRatings from '../views/faculty/mediaAssignment/Ratings';
import MediaReviews from '../views/faculty/mediaAssignment/Reviews';
import Dashboard from '../views/faculty/dashboard/Index';
import CourseReviews from '../views/admin/course_reviews/Index';
import LiveClassVideoRecordings from '../views/faculty/liveClass/LiveClassVideoRecordings';
import LiveClassMediaTopicMapping from '../views/faculty/liveClassMediaMapping/LiveClassMediaTopicMapping';

// Order of menu links is based on order of routes
const bothRoutes = Object.freeze([
    {
        path: 'overview',
        page: Overview,
        name: 'Admin Dashboard',
    },
    {
        path: 'ins-overview',
        page: Dashboard,
        name: 'Instructor Dashboard',
    },
    {
        path: 'department',
        page: Department,
        name: 'Departments',
    },
    {
        path: 'course-category',
        page: CourseCategory,
        name: 'Course Categories',
    },
    {
        path: 'dept-course-category',
        page: DeptCourseCategoryMap,
        name: 'Department-Course Category Mapping',
    },
    {
        path: 'live-class-topic',
        page: LiveClassTopic,
        name: 'Live Class Topic',
        module: 'live-class',
    },
    {
        path: 'live-class',
        page: LiveClass,
        name: 'Live Class Scehdules',
        module: 'live-class',
    },
    {
        path: 'live-class-video-recordings',
        page: LiveClassVideoRecordings,
        name: 'Video Recordings',
        module: 'live-class',
    },
    {
        path: 'live-class-media-topic-mapping',
        page: LiveClassMediaTopicMapping,
        name: 'Live Class Media & Topic Mapping',
        module: 'live-class',
    },
    {
        path: 'question-bank',
        page: QuestionBank,
        name: 'Question Bank',
        module: 'quiz',
    },
    // {
    //     path: 'quiz-library',
    //     page: QuizLibrary,
    //     name: 'Quiz Library',
    //     module: 'quiz',
    // },
    {
        path: 'media-approve',
        page: MediaLibrary,
        name: 'Review and Approve Media',
    },
    {
        path: 'course',
        page: Course,
        name: 'Courses',
        module: 'course',
    },
    // {
    //     path: 'course-content',
    //     page: CourseContent,
    //     name: 'Course Contents',
    //     module: 'course',
    //     hiddenFromMenu: true,
    // },
    {
        path: 'course-structure',
        page: CourseStructure,
        name: 'Course Structure',
        module: 'course',
    },
    {
        path: 'Coures_Review',
        page: CourseReviews,
        hiddenFromMenu: true,
        module: 'course',
    },
    {
        path: 'course-structure-approve',
        page: CourseStructureapproval,
        hiddenFromMenu: true,
        module: 'course',
    },
    // {
    //     path: 'quiz-assignment',
    //     page: QuizAssignment,
    //     name: 'Quiz Assignment',
    //     module: 'course',
    // },
    // {
    //     path: 'quiz-results',
    //     page: QuizEvaluation,
    //     module: 'course',
    //     name: 'Quiz Results',
    //     // hiddenFromMenu: true,
    // },
    // {
    //     path: 'assign-media',
    //     page: AssignMedia,
    //     name: 'Assign Media',
    //     module: 'course',
    // },
    {
        path: 'media-ratings',
        page: MediaRatings,
        hiddenFromMenu: true,
    },
    {
        path: 'media-reviews',
        page: MediaReviews,
        hiddenFromMenu: true,
    },
    {
        path: 'instructor',
        page: Instructor,
        name: 'Instructors',
        module: 'instructor',
    },
    {
        path: 'instructor-course',
        page: InstructorCourseMap,
        name: 'Courses By Instructor',
        module: 'instructor',
    },
    {
        path: 'participants',
        page: Participant,
        name: 'Participants',
    },
    {
        path: 'program',
        page: Program,
        name: 'Program',
        module: 'program',
    },
    {
        path: 'program-course',
        page: ProgramCourseMap,
        name: 'Link Course to Program',
        module: 'program',
    },
]);
export default bothRoutes;
